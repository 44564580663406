import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ActionPayload {
  routeAssetManagmentLevel1: string;
  routeAssetManagmentLevel2: string;
  routeAssetManagmentGroupId: string;
}

const initialState: ActionPayload = {
  routeAssetManagmentLevel1: "",
  routeAssetManagmentLevel2: "",
  routeAssetManagmentGroupId: "",
};

const RouteAssetManagement = createSlice({
  name: "RouteAssetManagement",
  initialState,
  reducers: {
    setRouteAssetManagmentLevel1Value: (
      state: any,
      action: PayloadAction<string>
    ) => {
      state.routeAssetManagmentLevel1 = action.payload;
    },
    setRouteAssetManagmentLevel2alue: (
      state: any,
      action: PayloadAction<string>
    ) => {
      state.routeAssetManagmentLevel2 = action.payload;
    },
    setRouteAssetManagmentGroupIdValue: (
      state: any,
      action: PayloadAction<string>
    ) => {
      state.routeAssetManagmentGroupId = action.payload;
    },
  },
});

export const {
  setRouteAssetManagmentLevel1Value,
  setRouteAssetManagmentLevel2alue,
  setRouteAssetManagmentGroupIdValue,
} = RouteAssetManagement.actions;

export default RouteAssetManagement;
