import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const CiaAuthentication = createSlice({
  name: "CiaAuthentication",
  initialState: {
    userInfo: [],
    SiteData: "",
    isAuth: false,
    userData: {
      name: "",
      email: "",
    },
  },
  reducers: {
    addUser: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload;
      return state;
    },
    SetUserAuth: (state, action: PayloadAction<any>) => {
      state.isAuth = true;
      state.userData = action.payload;
      return state;
    },
    logout: (state, action: PayloadAction<any>) => {
      state.isAuth = false;
      state.userData = {
        name: "",
        email: "",
      };
      return state;
    },
  },
});

export const { addUser, SetUserAuth, logout } = CiaAuthentication.actions;

export default CiaAuthentication;
