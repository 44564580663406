import React from "react";
import "./RadioButton.scss";
interface RadioButtonInterface {
  name: string;
  title: string;
  value: number | string;
  onChange: any;
  checked?: boolean;
}

const RadioButton: React.FC<RadioButtonInterface> = ({
  name,
  title,
  value,
  onChange,
  checked,
}) => {
  return (
    <div className="flex items-center">
      <input
        type="radio"
        name={name}
        className="h-[16px] w-[16px]"
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label className="ml-2 text-sm text-gray-700">{title}</label>
    </div>
  );
};

export default RadioButton;
