import React, { useEffect } from 'react';
import { IoCloseSharp } from 'react-icons/io5';

interface ProvisioningModalProps {
    visible: boolean;
    title: string;
    onClose: () => void;
    onOk?: () => void;
    okText?: string;
    children: any;
    width?: string;
    onSubmit?: () => void;
}

const ProvisioningModal: React.FC<ProvisioningModalProps> = ({
    visible,
    title,
    onClose,
    onOk,
    okText = 'Confirm',
    children,
    width = '534px',
    onSubmit
}) => {
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose();
            }
        };

        if (visible) {
            document.addEventListener("keydown", handleEsc, false);
        } else {
            document.removeEventListener("keydown", handleEsc, false);
        }

        return () => {
            document.removeEventListener("keydown", handleEsc, false);
        };
    }, [onClose, visible]);
    if (!visible) return null;

    return (
        <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50'>
            <div
                className={`flex flex-col bg-[#131F2E] rounded-[8px] shadow-md relative px-[24px] py-[20px]`}
                style={{ 
                    width,
                 }}
            >
                <div className='flex justify-between items-center pb-[20px]'>
                    <h2 className='text-lg font-semibold text-neutral-10'>{title}</h2>
                    <IoCloseSharp
                        onClick={onClose}
                        className='text-neutral-25 absolute top-[20px] right-[10px] cursor-pointer hover:text-darkcolor-200'
                        size={24}
                    />
                </div>
                <hr className="border-t border-neutral-700 mb-[20px] mx-[-24px]" />
                <div className='mb-6 text-neutral-25'>{children}</div>
                <hr className="border-t border-neutral-700 mb-[20px] mx-[-24px]" />
                <div className='flex justify-end space-x-5'>
                    <button
                        onClick={onClose}
                        className='px-4 py-2 text-neutral-25 text-[14px] rounded-[8px] border border-neutral-25 hover:bg-neutral-700'
                    >
                        Cancel
                    </button>
                    <button
                        className='px-4 py-2 text-[14px] flex justify-center items-center rounded-[8px] bg-primary-600 hover:bg-blue-700 text-white'
                        onClick={onSubmit || onOk}
                    >
                        {okText || "Confirm"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProvisioningModal;