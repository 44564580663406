import React from "react";
import { FiEdit } from "react-icons/fi";
import noDataIcon from "../../../assets/img/icon-noData.svg";

interface PumpDetail {
    label: string;
    value: string;
    editLabel: string;
    editValue: string;
    editIcon: boolean;
}

interface PumpDetailsProps {
    title: string;
    onClick?: any;
    data: PumpDetail[];
}

const PumpDetails: React.FC<PumpDetailsProps> = ({ title, data, onClick }) => {
    return (
        <div className='border-[1px] border-darkcolor-400 bg-darkcolor-700 rounded-[8px]' style={{ background: "linear-gradient(181.1deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%)" }}>
            <div className='flex items-center justify-between gap-[16px] pt-5 pb-1 px-[24px] border-b-[1px] border-darkcolor-400'>
                <label className='text-neutral-25 text-[18px] leading-[28px]'>{title}</label>
            </div>
            <div className='flex flex-col'>
                {data.length > 0 ? (
                    data.map((item, index) => (
                        <div
                            key={index}
                            className={`grid sm:grid-cols-2 gap-[24px] py-[12px] px-[24px] w-full border-[1px] ${index === data.length - 1
                                ? "rounded-[8px]"
                                : "border-b-darkcolor-400"
                                }`}
                        >
                            <div className='w-full flex items-center'>
                                <label className='text-neutral-25 text-[16px] leading-[24px]'>
                                    {item.label}
                                </label>
                                <label className='text-neutral-25 text-[14px] leading-[24px] ml-auto text-right'>
                                    {item.value}
                                </label>
                            </div>
                            <div className='w-full flex items-center'>
                                <label className='text-neutral-25 text-[16px] leading-[24px] w-1/2 '>
                                    {item.editLabel}
                                </label>
                                <div className='w-1/2 flex justify-end items-center'>
                                    <label className='text-neutral-25 text-[14px] font-[600] leading-[24px]'>
                                        {item.editValue}
                                    </label>
                                    <FiEdit
                                        className={`text-neutral-25 cursor-pointer ml-4 hidden ${item.editIcon ? "visible" : "invisible"
                                            }`}
                                        onClick={() => {
                                            onClick(
                                                item.editLabel.toLowerCase() ===
                                                    "Injection rate set-point".toLowerCase()
                                                    ? 0
                                                    : 1
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='flex flex-col items-center mb-[10px]'>
                        <div className=' text-neutral-25 mb-4'>
                            <img
                                src={noDataIcon}
                                alt='SiteName'
                                className='object-contain bg-img'
                            />
                        </div>
                        <span className='text-neutral-25 title'>No data found</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PumpDetails;
