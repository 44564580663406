import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import { FaClock } from 'react-icons/fa';
import "./TimePicker.scss"

interface TimePickerProps {
    selectedDate: Date | null;
    onChange: (date: Date) => void;
    backGround?: string
}

const TimePickerComponent: React.FC<TimePickerProps> = ({ selectedDate, onChange, backGround }) => {
    const CustomInput: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
        <div className="flex justify-center items-center">
            <div
                style={{ width: '200px', backgroundColor: backGround }}
                className="border border-darkcolor-400 rounded-[8px] py-[10px] px-[12px]  text-[16px] text-neutral-400 flex items-center justify-between focus:outline-none cursor-pointer"
                onClick={onClick}
            >
                {selectedDate?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                <FaClock className="ml-2" />
            </div>
        </div>
    );

    return (
        <div className="flex justify-center items-center">
            <DatePicker
                selected={selectedDate}
                onChange={(date) => onChange(date as Date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                customInput={<CustomInput />}
            />
        </div>
    );
};

export default TimePickerComponent;
