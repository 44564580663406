import instance from "../api";

export const getRecommendation = async (groupId: string, groupType: string, pageNumber: number, pageSize: number, freeText: string, sortOrder: string, sortBy: string) => {
    const params = {
        ...(groupId ? { GroupId: groupId } : {}),
        ...(groupType ? { GroupType: groupType } : {}),
        ...(pageNumber ? { PageNumber: pageNumber } : {}),
        ...(pageSize ? { PageSize: pageSize } : {}),
        ...(freeText ? { FreeText: freeText } : {}),
        ...(sortOrder ? { SortOrder: sortOrder } : {}),
        ...(sortBy ? { SortBy: sortBy } : {}),
    };
    const response = await instance.get<any>("/SetPoints/Recommendations", { params }
    )
    return response.data;
}

export const getManualSetPoint = async (groupId: string, groupType: string, pageNumber: number, pageSize: number, freeText: string, sortOrder: string, sortBy: string) => {
    const params = {
        ...(groupId ? { GroupId: groupId } : {}),
        ...(groupType ? { GroupType: groupType } : {}),
        ...(pageNumber ? { PageNumber: pageNumber } : {}),
        ...(pageSize ? { PageSize: pageSize } : {}),
        ...(freeText ? { FreeText: freeText } : {}),
        ...(sortOrder ? { SortOrder: sortOrder } : {}),
        ...(sortBy ? { SortBy: sortBy } : {}),
    };
    const response = await instance.get<any>("/SetPoints/Manual", { params }
    )
    return response.data;
}

export const addAllRecommendationSetPoint = async (data: any) => {
    try {
        const response = await instance.put<any>("/SetPoints/Recommendations", data);
        return response.data;
    } catch (error) {
        // Handle error
        console.error("error", error);
        throw error;
    }
};

export const addManualRecommendationSetPoint = async (data: any) => {
    try {
        const response = await instance.put<any>("/SetPoints/Manual", data);
        return response.data;
    } catch (error) {
        // Handle error
        console.error("error", error);
        throw error;
    }
};
export const addHistorySetPoint = async (groupId: string, groupType: string, freeText: string, pageNumber: any, pageSize: any, sortOrder: string, sortBy: string) => {
    try {
        const params = {
            ...(groupId ? { GroupId: groupId } : {}),
            ...(groupType ? { GroupType: groupType } : {}),
            ...(pageNumber ? { PageNumber: pageNumber } : {}),
            ...(pageSize ? { PageSize: pageSize } : {}),
            ...(freeText ? { FreeText: freeText } : {}),
            ...(sortOrder ? { SortOrder: sortOrder } : {}),
            ...(sortBy ? { SortBy: sortBy } : {}),

        }
        const response = await instance.get<any>("/SetPoints/History", { params })
        return response.data;
    }
    catch (error) {
        console.error("error", error);
        throw error;
    }
}