import React from 'react'
import Details from '../../common/Cards/DetailsCard';

interface keyStatusRegistersDetailsInterface {
    communicationDetails: any;
    alertsDetails: any;
    keyStatusRegistersDetails: any
}

const Status: React.FC<keyStatusRegistersDetailsInterface> = ({ keyStatusRegistersDetails }) => {
    return (
        <div className='grid gap-[12px] w-full'>
            <div className='border-[1px] border-darkcolor-400 bg-darkcolor-700 rounded-[8px]' style={{ background: "linear-gradient(181.1deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%)" }}>
                <Details
                    title={"Key status registers"}
                    details={keyStatusRegistersDetails}
                />
            </div>
        </div>
    )
}

export default Status