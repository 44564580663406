import Search from "../Svg/Search";
import React, { ChangeEvent } from "react";
interface SearchInputProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  value: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  placeholder,
  value,
}) => {
  return (
    <div className='relative w-full flex items-center rounded-[8px] border-[1px] border-darkcolor-400 py-[10px] px-[8px]'>
      <div className='absolute inset-y-0 left-0 flex items-center pl-2'>
        <Search size={18} />
      </div>
      <input
        type='text'
        placeholder={placeholder}
        className='w-full pl-[20px] pr-4 text-[16px] outline-none bg-transparent focus:outline-none'
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default SearchInput;
