import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface ActionPayload {
  customerId: string;
  customerGroupDetails: any;
  siteId: string;
  siteGroupDetails: any;
  tankId: string;
  tankGroupDetails: any;

  customLevel1Id: string;
  customLevel1Details: any;
  customLevel1Tanks: any[];
  customLevel2Id: string;
  customLevel2Details: any;
  customLevel3Id: string;
  customLevel3Details: any;

  renderSiteSummaryAPI: boolean;
  test: boolean;
  noSiteClearForSearch: boolean;
  defaultGroupData: any[];
  customGroupData: any[];
  allSiteData: any;
  tank: string;
}

const initialState: ActionPayload = {
  customerId: "",
  customerGroupDetails: {},
  siteId: "",
  siteGroupDetails: {},
  tankId: "",
  tankGroupDetails: {},

  customLevel1Id: "",
  customLevel1Details: {},
  customLevel1Tanks: [],
  customLevel2Id: "",
  customLevel2Details: {},
  customLevel3Id: "",
  customLevel3Details: {},
  renderSiteSummaryAPI: false,
  test: false,
  noSiteClearForSearch: false,
  defaultGroupData: [],
  customGroupData: [],
  allSiteData: {},
  tank: "",
};

const RoutesAndAssets = createSlice({
  name: "RoutesAndAssets",
  initialState,
  reducers: {
    setCustomerId: (state: any, action: PayloadAction<ActionPayload>) => {
      state.customerId = action.payload;
    },
    clearCustomerId: (state: any) => {
      state.customerId = "";
    },
    setCustomerGroupDetails: (
      state: any,
      action: PayloadAction<ActionPayload>
    ) => {
      state.customerGroupDetails = action.payload;
    },
    clearCustomerGroupDetails: (state: any) => {
      state.customerGroupDetails = {};
    },
    setSiteId: (state: any, action: PayloadAction<ActionPayload>) => {
      state.siteId = action.payload;
    },
    clearSiteId: (state: any) => {
      state.siteId = "";
    },
    setSiteGroupDetails: (state: any, action: PayloadAction<ActionPayload>) => {
      state.siteGroupDetails = action.payload;
    },
    clearSiteGroupDetails: (state: any) => {
      state.siteGroupDetails = {};
    },
    setTankId: (state: any, action: PayloadAction<string>) => {
      state.tankId = action.payload;
    },
    clearTankId: (state: any) => {
      state.tankId = "";
    },
    setTankGroupDetails: (state: any, action: PayloadAction<ActionPayload>) => {
      state.tankGroupDetails = action.payload;
    },
    clearTankGroupDetails: (state: any) => {
      state.tankGroupDetails = {};
    },
    setCustomLevel1Id: (state: any, action: PayloadAction<ActionPayload>) => {
      state.customLevel1Id = action.payload;
    },
    clearCustomLevel1Id: (state: any) => {
      state.customLevel1Id = "";
    },
    setCustomLevel1GroupDetails: (
      state: any,
      action: PayloadAction<ActionPayload>
    ) => {
      state.customLevel1Details = action.payload;
    },
    clearCustomLevel1GroupDetails: (state: any) => {
      state.customLevel1Details = {};
    },
    setCustomLevel2Id: (state: any, action: PayloadAction<ActionPayload>) => {
      state.customLevel2Id = action.payload;
    },
    clearCustomLevel2Id: (state: any) => {
      state.customLevel2Id = "";
    },
    setCustomLevel2GroupDetails: (
      state: any,
      action: PayloadAction<ActionPayload>
    ) => {
      state.customLevel2Details = action.payload;
    },
    clearCustomLevel2GroupDetails: (state: any) => {
      state.customLevel2Details = {};
    },
    setCustomLevel3Id: (state: any, action: PayloadAction<ActionPayload>) => {
      state.customLevel3Id = action.payload;
    },
    clearCustomLevel3Id: (state: any) => {
      state.customLevel3Id = "";
    },
    setCustomLevel3GroupDetails: (
      state: any,
      action: PayloadAction<ActionPayload>
    ) => {
      state.customLevel3Details = action.payload;
    },
    clearCustomLevel3GroupDetails: (state: any) => {
      state.customLevel3Details = {};
    },
    setCustomLevel1Tanks: (
      state: any,
      action: PayloadAction<ActionPayload>
    ) => {
      state.customLevel1Tanks = action.payload;
    },
    clearCustomLevel1Tanks: (state: any) => {
      state.customLevel1Tanks = [];
    },
    setRenderSiteSummaryAPI: (state: any) => {
      state.renderSiteSummaryAPI = true;
    },
    clearRenderSiteSummaryAPI: (state: any) => {
      state.renderSiteSummaryAPI = false;
    },
    setTest: (state: any) => {
      state.test = true;
    },
    clearTest: (state: any) => {
      state.test = false;
    },
    setNoSiteClearForSearch: (state: any) => {
      state.noSiteClearForSearch = true;
    },
    clearNoSiteClearForSearch: (state: any) => {
      state.noSiteClearForSearch = false;
    },
    setDefaultGroupData: (state: any, action: PayloadAction<ActionPayload>) => {
      state.defaultGroupData = action.payload;
    },
    setAllSitesData: (state: any, action: PayloadAction<ActionPayload>) => {
      state.allSiteData = action.payload;
    },
    setGroupDataCustom: (state: any, action: PayloadAction<ActionPayload>) => {
      state.customGroupData = action.payload;
    },
    setTank: (state: any, action: PayloadAction<ActionPayload>) => {
      state.tank = action.payload;
    },
  },
});

export const {
  setTest,
  clearTest,

  setCustomerId,
  clearCustomerId,
  setCustomerGroupDetails,
  clearCustomerGroupDetails,
  setSiteId,
  clearSiteId,
  setSiteGroupDetails,
  clearSiteGroupDetails,
  setTankId,
  clearTankId,
  setTankGroupDetails,
  clearTankGroupDetails,
  setCustomLevel1Id,
  clearCustomLevel1Id,
  setCustomLevel1GroupDetails,
  clearCustomLevel1GroupDetails,

  setCustomLevel2Id,
  clearCustomLevel2Id,
  setCustomLevel3Id,
  clearCustomLevel3Id,
  setCustomLevel1Tanks,
  clearCustomLevel1Tanks,
  setCustomLevel2GroupDetails,
  clearCustomLevel2GroupDetails,
  setCustomLevel3GroupDetails,
  clearCustomLevel3GroupDetails,
  setRenderSiteSummaryAPI,
  clearRenderSiteSummaryAPI,
  setNoSiteClearForSearch,
  clearNoSiteClearForSearch,
  setDefaultGroupData,
  setAllSitesData,
  setGroupDataCustom,
  setTank,
} = RoutesAndAssets.actions;
export default RoutesAndAssets;
