import React from "react";

interface inputValues {
  placeholder?: string;
  name: string;
  label?: string;
  onChange?: (() => void) | ((e: React.ChangeEvent<HTMLInputElement>) => void); //() => void;
  disabled?: boolean;
  value: any;
  errors?: any;
}

const TextInput: React.FC<inputValues> = ({
  placeholder,
  name,
  label,
  onChange,
  errors,
  value,
  disabled,
}) => {
  return (
    <div className="flex flex-col mb-4 xl:mb-5 form-group">
      <label className="label--semibold mb-[6px]" htmlFor={name}>
        {label}
      </label>
      <input
        type="text"
        className="input-outline-4 disabled:bg-darkcolor-600 disabled:cursor-not-allowed"
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        autoComplete="off"
        value={value}
        disabled={disabled}
        required={name !== "hversion" ? true : false}
      />
      {errors ? <div className="text-error-600 my-1">{errors}</div> : null}
    </div>
  );
};

export default TextInput;
