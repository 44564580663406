import Tooltip from "../common/Tooltip/Tooltip";
import React, { useRef, DragEvent } from "react";
import { RxDragHandleDots2 } from "react-icons/rx";

interface Checkbox {
    title: string;
    isChecked: boolean;
}

interface TableCheckboxProps {
    checkboxes: Checkbox[];
    updateHeaders: (
        newHeaderOrder: Checkbox[],
        newHeaderDummyDataOrder: any[]
    ) => void;
    onChange: (isChecked: boolean, index: number) => void;
    dummyData: any[];
}

const TableCheckbox: React.FC<TableCheckboxProps> = ({
    dummyData,
    checkboxes,
    updateHeaders,
    onChange,
}) => {
    const draggedItemIndex = useRef<number | null>(null);
    const targetItemIndex = useRef<number | null>(null);
    const handleDragEnd = () => {
        if (draggedItemIndex.current !== null && targetItemIndex.current !== null) {
            const newHeaderOrder = [...checkboxes];
            const newHeaderDummyDataOrder = [...dummyData];

            const draggedItem = newHeaderOrder[draggedItemIndex.current];
            const draggedItemDummy =
                newHeaderDummyDataOrder[draggedItemIndex.current];

            newHeaderOrder.splice(draggedItemIndex.current, 1);
            newHeaderOrder.splice(targetItemIndex.current, 0, draggedItem);

            newHeaderDummyDataOrder.splice(draggedItemIndex.current, 1);
            newHeaderDummyDataOrder.splice(
                targetItemIndex.current,
                0,
                draggedItemDummy
            );

            updateHeaders(newHeaderOrder, newHeaderDummyDataOrder);
        }
        draggedItemIndex.current = null;
        targetItemIndex.current = null;
    };

    const handleDragStart = (
        e: React.DragEvent<HTMLDivElement>,
        index: number
    ) => {
        e.stopPropagation();
        draggedItemIndex.current = index;
    };
    const handleDragEnter = (e: DragEvent<HTMLDivElement>, overIndex: number) => {
        e.stopPropagation();
        targetItemIndex.current = overIndex;
    };

    return (
        <>
            {checkboxes.map((checkbox, index) => (
                <div
                    key={index}
                    className='pl-[10px] flex items-center justify-between mb-[20px]'
                >
                    <div className='flex items-center'>
                        <input
                            type='checkbox'
                            value=''
                            className='accent-primary-600 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:outline-none'
                            checked={checkbox.isChecked}
                            onChange={() => onChange(!checkbox.isChecked, index)}
                        />
                        <label className='ml-[5px] text-[16px] text-white'>
                            {checkbox.title}
                        </label>
                    </div>
                    <div
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragEnd={handleDragEnd}
                        onDragEnter={(e) => handleDragEnter(e, index)}
                        draggable
                    >
                        <Tooltip content={"Drag"} direction='left'>
                            <RxDragHandleDots2
                                className='text-neutral-25 mr-[10px]'
                                size={20}
                            />
                        </Tooltip>
                    </div>
                </div>
            ))}
        </>
    );
};

export default TableCheckbox;
