import React from "react";

interface DownloadProps {
    size?: number | string;
    className?: string;
    onClick?: () => void;
}

const Download: React.FC<DownloadProps> = ({
    size = 24,
    className,
    onClick,
}) => {
    const style = size ? { width: size, height: size } : undefined;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className={className}
            style={style}
            onClick={onClick}
        >
            <path
                d='M17.5 16.6666H2.5M15 8.33329L10 13.3333M10 13.3333L5 8.33329M10 13.3333V1.66663'
                stroke='#B8C5CC'
                strokeWidth='1.67'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default Download;
