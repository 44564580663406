import "./SiteDetails.scss";
import Alarms from "./Tabs/Alarms";
import Analysis from "./Tabs/Analysis";
import Tabs from "../common/Tabs/Tabs1";
import { useEffect, useState } from "react";
import Information from "./Tabs/Information";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadcrumbDropdownSiteDetails from "../common/Dropdown/BreadcrumbDropdownSiteDetails";
import {
    setGroupId,
    setGroupType,
    setIsAmpGroup,
} from "../../store/groupSitesAssets/groupSitesAssets.store";
import {
    setSiteId,
    setTankId,
    clearTankId,
    clearSiteId,
    setCustomerId,
    setSiteGroupDetails,
    clearCustomLevel2Id,
    clearCustomLevel3Id,
    clearSiteGroupDetails,
    clearTankGroupDetails,
    clearCustomLevel1Tanks,
    setCustomerGroupDetails,
    clearCustomLevel2GroupDetails,
    clearCustomLevel3GroupDetails,
} from "../../store/routesAndAssets/routesAndAssets.store";

const SiteDetails = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [isOpenDropdownBreadcrumb, setIsOpenDropdownBreadcrumb] =
        useState<boolean>(false);

    const { groupId, groupType, isAmpGroup } = useSelector(
        (state: RootState) => state.GroupSitesAssets
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        tankId,
        allSiteData,
        defaultGroupData,
        siteGroupDetails,
        customLevel1Tanks,
        customLevel1Details,
        customLevel2Details,
        customLevel3Details,
        customerGroupDetails,
    } = useSelector((state: RootState) => state.RoutesAndAssets);

    useEffect(() => {
        if (isAmpGroup && customerGroupDetails?.groupName === "All Sites") {
            const copyCustomerGroupDetails: any = { ...customerGroupDetails };
            const copyAllSiteData: any = { ...allSiteData };
            copyCustomerGroupDetails.site = copyAllSiteData?.site;
            dispatch(setCustomerGroupDetails(copyCustomerGroupDetails));
            dispatch(setCustomerId(copyCustomerGroupDetails?.id));
        } else if (isAmpGroup && Object.keys(siteGroupDetails).length === 0) {
            customerGroupDetails?.childGroup &&
                customerGroupDetails?.childGroup.some((item: any) => {
                    const findRecord = item?.site.find(
                        (ele: any) => Number(ele?.siteId) === Number(tankId)
                    );
                    if (findRecord) {
                        dispatch(setSiteId(item?.id));
                        dispatch(setSiteGroupDetails(item));
                        return true;
                    } else if (item?.site.length === 0) {
                        const findSite = defaultGroupData?.find(
                            (rec: any) => rec.id === customerGroupDetails?.id
                        );
                        if (findSite) {
                            const { childGroup } = findSite;
                            const findDefaultSiteGroupDataRecord = childGroup?.find(
                                (siteItem: any) => siteItem?.id === item?.id
                            );
                            if (findDefaultSiteGroupDataRecord) {
                                dispatch(setSiteId(findDefaultSiteGroupDataRecord?.id));
                                dispatch(setSiteGroupDetails(findDefaultSiteGroupDataRecord));
                                return true;
                            }
                        }
                    } else if (
                        item?.site.length > 0 &&
                        item?.site.length !== item?.totalTankCount
                    ) {
                        const findDefaultGroupDataRecord = defaultGroupData.find(
                            (item: any) => item?.id === customerGroupDetails?.id
                        );
                        if (findDefaultGroupDataRecord) {
                            const { childGroup } = findDefaultGroupDataRecord;
                            const findDefaultSiteGroupDataRecord = childGroup?.find(
                                (siteItem: any) => siteItem?.id === item?.id
                            );
                            if (findDefaultSiteGroupDataRecord) {
                                dispatch(setSiteId(findDefaultSiteGroupDataRecord?.id));
                                dispatch(setSiteGroupDetails(findDefaultSiteGroupDataRecord));
                                return true;
                            }
                        }
                    }
                    return false;
                });
        } else if (
            isAmpGroup &&
            Object.keys(siteGroupDetails).length > 0 &&
            siteGroupDetails.site.length === 0 &&
            siteGroupDetails.totalTankCount > 0
        ) {
            const findDefaultGroupDataRecord = defaultGroupData.find(
                (item: any) => item?.id === customerGroupDetails?.id
            );
            if (findDefaultGroupDataRecord) {
                const { childGroup } = findDefaultGroupDataRecord;
                const findDefaultSiteGroupDataRecord = childGroup?.find(
                    (siteItem: any) => siteItem?.id === siteGroupDetails?.id
                );
                if (findDefaultSiteGroupDataRecord) {
                    dispatch(setSiteId(findDefaultSiteGroupDataRecord?.id));
                    dispatch(setSiteGroupDetails(findDefaultSiteGroupDataRecord));
                }
            }
        } else if (
            isAmpGroup &&
            Object.keys(siteGroupDetails).length > 0 &&
            siteGroupDetails.site.length > 0 &&
            siteGroupDetails.totalTankCount !== siteGroupDetails.site.length
        ) {
            const findDefaultGroupDataRecord = defaultGroupData.find(
                (item: any) => item?.id === customerGroupDetails?.id
            );
            if (findDefaultGroupDataRecord) {
                const { childGroup } = findDefaultGroupDataRecord;
                const findDefaultSiteGroupDataRecord = childGroup?.find(
                    (siteItem: any) => siteItem?.id === siteGroupDetails?.id
                );
                if (findDefaultSiteGroupDataRecord) {
                    dispatch(setSiteId(findDefaultSiteGroupDataRecord?.id));
                    dispatch(setSiteGroupDetails(findDefaultSiteGroupDataRecord));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tankId]);

    const toggleDropdownBreadcrumb = () =>
        setIsOpenDropdownBreadcrumb(!isOpenDropdownBreadcrumb);

    const handleTabChange = (index: number) => setActiveTab(index);

    const tabs = [
        {
            label: "Information",
            badge: false,
            content: (
                <Information groupId={groupId} tankId={tankId} groupType={groupType} />
            ),
        },
        {
            label: "Analysis",
            badge: false,
            content: <Analysis tankId={tankId} />,
        },
        {
            label: "Alarms",
            badge: false,
            content: <Alarms tankId={tankId} />,
        },
    ];

    const handleSelectOnBreadcrumbDropdown = (option:any) => {
        dispatch(setTankId(option?.siteId ?? option?.id));
    };

    const customLevel1Breadcrumb = (customLevel1: {
        id: string;
        isAmpGroup: boolean;
        ampGroupType: string;
    }) => {
        dispatch(clearTankId());
        dispatch(clearCustomLevel3Id());
        dispatch(clearCustomLevel2Id());
        dispatch(clearTankGroupDetails());
        dispatch(clearCustomLevel1Tanks());
        dispatch(clearCustomLevel2GroupDetails());
        dispatch(clearCustomLevel3GroupDetails());
        dispatch(setGroupId(customLevel1?.id));
        dispatch(setIsAmpGroup(customLevel1?.isAmpGroup));
        dispatch(setGroupType(customLevel1?.ampGroupType));
        navigate("/");
    };

    const customLevel2Breadcrumb = (customLevel2: {
        id: string;
        isAmpGroup: boolean;
        ampGroupType: string;
    }) => {
        dispatch(clearTankId());
        dispatch(clearCustomLevel3Id());
        dispatch(clearTankGroupDetails());
        dispatch(clearCustomLevel1Tanks());
        dispatch(clearCustomLevel3GroupDetails());
        dispatch(setGroupId(customLevel2?.id));
        dispatch(setIsAmpGroup(customLevel2?.isAmpGroup ?? false));
        dispatch(setGroupType(customLevel2?.ampGroupType ?? "Custom2"));
        navigate("/");
    };

    const customLevel3Breadcrumb = (customLevel3: {
        id: string;
        isAmpGroup: boolean;
        ampGroupType: string;
    }) => {
        dispatch(clearTankId());
        dispatch(clearTankGroupDetails());
        dispatch(clearCustomLevel1Tanks());
        dispatch(setGroupId(customLevel3?.id));
        dispatch(setIsAmpGroup(customLevel3?.isAmpGroup ?? false));
        dispatch(setGroupType(customLevel3?.ampGroupType ?? "Custom3"));
        navigate("/");
    };

    const defaultLevel1Breadcrumb = (defaultLevel1: {
        id: string;
        isAmpGroup: boolean;
        ampGroupType: string;
    }) => {
        dispatch(clearSiteId());
        dispatch(clearTankId());
        dispatch(clearSiteGroupDetails());
        dispatch(clearTankGroupDetails());
        dispatch(setGroupId(defaultLevel1?.id));
        dispatch(setIsAmpGroup(defaultLevel1?.isAmpGroup));
        dispatch(setGroupType(defaultLevel1?.ampGroupType));

        if (isAmpGroup) {
            const record = defaultGroupData?.find(
                (item: any) => item?.id === defaultLevel1?.id
            );
            if (record) dispatch(setCustomerGroupDetails(record));
        }
        navigate("/");
    };

    const defaultLevel2Breadcrumb = (defaultLevel2: {
        id: string;
        isAmpGroup: boolean;
        ampGroupType: string;
    }) => {
        dispatch(clearTankId());
        dispatch(clearTankGroupDetails());
        dispatch(setGroupId(defaultLevel2?.id));
        dispatch(setIsAmpGroup(defaultLevel2?.isAmpGroup));
        dispatch(setGroupType(defaultLevel2?.ampGroupType));
        navigate("/");
    };

    return (
        <div className='content-wrapper'>
            <header
                className={`flex bg-darkcolor-700 text-white px-[15px] xl:px-[32px] py-[12px]`}
            >
                <div className='flex flex-wrap items-center breadcrumb-textblock'>
                    {customerGroupDetails?.isAmpGroup ? (
                        <>
                            <label
                                className='text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer'
                                onClick={() => defaultLevel1Breadcrumb(customerGroupDetails)}
                            >
                                {customerGroupDetails?.groupName}
                            </label>
                            <label className='text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline'>
                                &nbsp;\&nbsp;
                            </label>
                            {customerGroupDetails?.groupName === "All Sites" && (
                                <BreadcrumbDropdownSiteDetails
                                    options={customerGroupDetails.site ?? []}
                                    onSelect={handleSelectOnBreadcrumbDropdown}
                                    bgColor='bg-transparent'
                                    selectedOption={tankId}
                                    isOpen={isOpenDropdownBreadcrumb}
                                    onToggle={toggleDropdownBreadcrumb}
                                />
                            )}
                            {Object.keys(siteGroupDetails).length > 0 && (
                                <>
                                    <label
                                        className='text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer'
                                        onClick={() => defaultLevel2Breadcrumb(siteGroupDetails)}
                                    >
                                        {siteGroupDetails?.groupName}
                                    </label>
                                    <label className='text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline'>
                                        &nbsp;\&nbsp;
                                    </label>
                                    <BreadcrumbDropdownSiteDetails
                                        options={siteGroupDetails.site ?? []}
                                        onSelect={handleSelectOnBreadcrumbDropdown}
                                        bgColor='bg-transparent'
                                        selectedOption={tankId}
                                        isOpen={isOpenDropdownBreadcrumb}
                                        onToggle={toggleDropdownBreadcrumb}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <label
                                className='text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer'
                                onClick={() => customLevel1Breadcrumb(customLevel1Details)}
                            >
                                {customLevel1Details?.groupName}
                            </label>
                            <label className='text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline'>
                                &nbsp;\&nbsp;
                            </label>
                            {customLevel2Details &&
                                Object.keys(customLevel2Details).length === 0 ? (
                                <BreadcrumbDropdownSiteDetails
                                    options={customLevel1Tanks ?? []}
                                    onSelect={handleSelectOnBreadcrumbDropdown}
                                    bgColor='bg-transparent'
                                    selectedOption={tankId}
                                    isOpen={isOpenDropdownBreadcrumb}
                                    onToggle={toggleDropdownBreadcrumb}
                                />
                            ) : (
                                <>
                                    <label
                                        className='text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer'
                                        onClick={() => customLevel2Breadcrumb(customLevel2Details)}
                                    >
                                        {customLevel2Details?.groupName}
                                    </label>
                                    <label className='text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline'>
                                        &nbsp;\&nbsp;
                                    </label>
                                    {customLevel3Details &&
                                        Object.keys(customLevel3Details).length === 0 ? (
                                        <BreadcrumbDropdownSiteDetails
                                            options={customLevel1Tanks ?? []}
                                            onSelect={handleSelectOnBreadcrumbDropdown}
                                            bgColor='bg-transparent'
                                            selectedOption={tankId}
                                            isOpen={isOpenDropdownBreadcrumb}
                                            onToggle={toggleDropdownBreadcrumb}
                                        />
                                    ) : (
                                        <>
                                            <label
                                                className='text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer'
                                                onClick={
                                                    () =>
                                                        customLevel3Breadcrumb(customLevel3Details)
                                                }
                                            >
                                                {customLevel3Details?.groupName}
                                            </label>
                                            <label className='text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline'>
                                                &nbsp;\&nbsp;
                                            </label>
                                            <BreadcrumbDropdownSiteDetails
                                                options={customLevel1Tanks ?? []}
                                                onSelect={handleSelectOnBreadcrumbDropdown}
                                                bgColor='bg-transparent'
                                                selectedOption={tankId}
                                                isOpen={isOpenDropdownBreadcrumb}
                                                onToggle={toggleDropdownBreadcrumb}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </header>
            <hr className='w-full h-[2px] mx-auto my-0 bg-darkcolor-300 border-0 rounded md:my-0 dark:bg-darkcolor-300' />
            <Tabs
                tabs={tabs}
                className='bg-darkcolor-700'
                activeTab={activeTab}
                onChange={handleTabChange}
            />
            <div className='mb-[100px]' />
        </div>
    );
};

export default SiteDetails;
