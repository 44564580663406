import React, { useState } from "react";

interface MapsProps {
    size?: number | string;
    className?: string;
    onClick?: () => void;
    color?: string;
}

const Maps: React.FC<MapsProps> = ({
    size = 20,
    className,
    onClick,
    color,
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const style = size ? { width: size, height: size } : undefined;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21'
            height='22'
            viewBox='0 0 21 22'
            fill='none'
            className={className}
            style={style}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <g clipPath='url(#clip0_9065_4925)'>
                <path
                    d='M7.61351 16.0048L1.7866 19.3494L1.76093 6.01605L7.58784 2.67149M7.61351 16.0048L13.4532 19.3269M7.61351 16.0048L7.58784 2.67149M13.4532 19.3269L18.4468 15.9839L18.4212 2.65064L13.4276 5.99359M13.4532 19.3269L13.4276 5.99359M13.4276 5.99359L7.58784 2.67149'
                    stroke={isHovered ? "#0094BD" : color}
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_9065_4925'>
                    <rect
                        width='20'
                        height='20'
                        fill='white'
                        transform='translate(0.0845947 1.01927) rotate(-0.110297)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Maps;
