interface SignalProps {
    color?: string;
    onClick?: () => void;
    className?: string;
}

const Signal: React.FC<SignalProps> = ({ color, className, onClick }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            className={className}
            onClick={onClick}
        >
            <path
                d='M16.2427 6.01367C18.5858 8.35682 18.5858 12.1558 16.2427 14.499M7.75734 14.499C5.41419 12.1558 5.41419 8.35682 7.75734 6.01367M4.92869 17.3274C1.02345 13.4222 1.02345 7.09055 4.92869 3.1853M19.0713 3.1853C22.9765 7.09055 22.9765 13.4222 19.0713 17.3274M12 12.2563C13.1045 12.2563 14 11.3609 14 10.2563C14 9.15178 13.1045 8.25635 12 8.25635C10.8954 8.25635 9.99998 9.15178 9.99998 10.2563C9.99998 11.3609 10.8954 12.2563 12 12.2563ZM12 12.2563V21.2563'
                stroke={color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default Signal;
