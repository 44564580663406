export const injectionRateSetPointData = [
  {
    id: 1,
    value: "56.54 GDP set by SHasssan @ 9/28/2023 08:22 am",
  },
  {
    id: 2,
    value: "67 GDP set by Shasssan @ 10/17/2023 04:22 am",
  },
  {
    id: 3,
    value: "67 GDP set by Shasssan @ 10/17/2023 04:22 am",
  },
];

export const maxPumpOutput = [
  {
    id: 1,
    value: "56.54 GDP set by SHasssan @ 9/28/2023 08:22 am",
  },
  {
    id: 2,
    value: "67 GDP set by Shasssan @ 10/17/2023 04:22 am",
  },
  {
    id: 3,
    value: "67 GDP set by Shasssan @ 10/17/2023 04:22 am",
  },
  {
    id: 4,
    value: "67 GDP set by Shasssan @ 10/17/2023 04:22 am",
  },
];

export const timeBasedPrimePump = [
  {
    id: 1,
    value: "Prime set by John Doe @ 9/28/2023 08:22 am for 2 hours",
  },
  {
    id: 2,
    value: "Prime set by John Doe @ 9/28/2023 08:22 am for 2 hours",
  },
  {
    id: 3,
    value: "Prime set by John Doe @ 9/28/2023 08:22 am for 2 hours",
  },
  {
    id: 4,
    value: "Prime set by John Doe @ 9/28/2023 08:22 am for 2 hours",
  },
];
