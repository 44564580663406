import React, { createContext, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../services/authentication/auth.service";
import TokenService from "../services/authentication/token.service";
import Helpers from "../utilities/helpers";
import Loading from "../components/common/Loading/Loading";
import { useDispatch } from "react-redux";
import { setToken, setUserId } from "../store/groupSitesAssets/groupSitesAssets.store";

export interface PermissionsContextType {
  firstTimeLogin?: boolean;
  canAccessProvisionDevice?: boolean;
}

export const PermissionsContext = createContext<PermissionsContextType>(null!);

export const PermissionsProvider = (props: any) => {
  const [firstTimeLogin, setFirstTimeLogint] = useState(false);
  const [canAccessProvisionDevice, setCanAccessProvisionDevice] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const cleanup = async () => {
      if (searchParams.has("ref")) {
        const referenceID = searchParams.get("ref");
        if (referenceID) {
          const newParams: { [key: string]: string } = {};
          searchParams.forEach((value: string, key: string) => {
            newParams[key] = value;
          });
          await getAccessToken(referenceID);
          setSearchParams(newParams);
          navigate(
            {
              search: createSearchParams(newParams).toString(),
            },
            { replace: true }
          );

          const firstTimeLogin = TokenService.getFirstTimeLogin();
          if (firstTimeLogin === "true") {
            navigate("/onboarding");
            setIsLoading(false);
            const timerId = setInterval(() => {
              TokenService.setFirstTimeLogin("false");
            }, 10000);
            return () => {
              clearInterval(timerId);
            };
          } else {
            navigate("/");
            setIsLoading(false);
          }
        }
      } else {
        setIsLoading(false);
      }
    };
    cleanup();
  }, [searchParams]);

  const getAccessToken = async (referenceID: string) => {
    try {
      const response = await AuthService.accessTokenFromServer(referenceID);
      if (response?.statusCode === 200 && response?.success) {
        const data: any = response.data;
        TokenService.setFirstTimeLogin(data?.isFirstTimeLogin);
        TokenService.setUser(data);
        TokenService.addOrUpdateLocalRefreshToken(data?.token?.refresh_token);
        TokenService.addOrUpdateLocalAccessToken(data?.token?.access_token);
        dispatch(setToken(data?.token?.access_token));
        dispatch(setUserId(data?.user?._id));
        setIsLoading(false);
      }
    } catch (error) {
      Helpers.ConsoleLogError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      AuthService.logout();
    }, 86400000); //expires in 24 hours
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <PermissionsContext.Provider
          value={{
            firstTimeLogin,
            canAccessProvisionDevice,
          }}
        >
          {props.children}
        </PermissionsContext.Provider>
      )}
    </>
  );
};
