// import sites_table from "./data/groupSitesAssets.json";

// //const baseURL = process.env.REACT_APP_;

// const GroupSitesAssets = async () => {
//   return await new Promise((resolve, reject) => {
//     resolve(sites_table.data);
//   });
// };

// const GroupSitesService = {
//   GroupSitesAssets,
// };

// export default GroupSitesService;

import instance from "../api";
// import { GetPumpResponse } from "./siteDetails.interface";

export const getGroups = async () => {
  const response = await instance.get<any>("/Groups/GetAllGroups");
  return response.data;
};
