import Eye from "../Svg/Eye";
import Arrow from "../Svg/Arrow";
import React, { useEffect, ReactNode, useRef } from "react";

interface SiteSummaryDropdownProps {
    options: {
        id?: number;
        name: string;
        value: string;
        icon?: ReactNode;
    }[];
    onSelect: (selected: string) => void;
    optionHeading: string;
    dropdownIcon: boolean;
    bgColor: string;
    selectedOption: string | boolean | null;
    isOpen: boolean;
    onToggle: () => void;
    width?: string;
}

const SiteSummaryDropdown: React.FC<SiteSummaryDropdownProps> = ({
    options,
    onSelect,
    optionHeading,
    dropdownIcon,
    bgColor,
    selectedOption,
    isOpen,
    onToggle,
    width,
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            onToggle();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const getSelectedOptionName = () => {
        const selected = options.find(option => option.value === selectedOption || option.name === selectedOption);
        return selected ? selected.name : 'Select an option';
    };

    return (
        <div className='relative inline-block text-left' ref={dropdownRef}>
            <div style={{ width: width }}>
                <button
                    type='button'
                    className={`gap-[8px] outline-none inline-flex justify-between items-center w-full px-[14px] py-[8px] border-[1px] border-darkcolor-300 text-[16px] font-medium rounded-[8px] text-neutral-25 ${bgColor} focus:outline-none`}
                    onClick={onToggle}
                >
                    <div className='flex items-center text-[14px]'>
                        {dropdownIcon && <Eye size={18} className='mr-[10px]' />}
                        {getSelectedOptionName()}
                    </div>
                    <Arrow
                        size={20}
                        className={`transform transition-transform ${isOpen ? "rotate-90" : ""}`}
                    />
                </button>
            </div>

            {isOpen && (
                <div className='border-[1px] border-darkcolor-400 origin-top-right absolute z-10 right-0 mt-[3px] w-full rounded-[8px] shadow-lg bg-darkcolor-700'>
                    <div>
                        <div className='block px-4 py-2 text-[14px] cursor-auto border-b-[1px] border-darkcolor-400 text-neutral-25 bg-darkcolor-800 rounded-t-[8px]'>
                            {optionHeading}
                        </div>
                        {options.map((option, index) => (
                            <div
                                key={option?.id}
                                className={`text-[16px] p-[10px] text-sm hover:bg-darkcolor-600 hover:text-neutral-25 cursor-pointer text-neutral-25 flex justify-between bg-darkcolor-800 ${index === options.length - 1 ? "rounded-[8px]" : ""}`}
                                onClick={() => {
                                    onSelect(option.value);
                                    onToggle();
                                }}
                            >
                                <div className='flex'>
                                    {option?.icon}
                                    <p className='ml-[8px] text-neutral-25'>{option.name}</p>
                                </div>
                                <Arrow size={20} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SiteSummaryDropdown;
