import "./Layout.scss";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";

function Layout() {
  return (
    <div className="container w-full max-w-full bg-darkcolor-800 pl-[55px] 2xl:pl-[68px] cia-layout">
      <Sidebar />
      <Outlet />
    </div>
  );
}

export default Layout;
