import React from "react";

const DeviceTanks: React.FC<any> = ({deviceTanks }) => {
  
  return (
    <div className="border-darkcolor-400 border-[1px] rounded-[4px] flex flex-col items-start bg-gradient-to-b from-[#253240] via-[#253240] to-[rgba(37, 48, 64, 0.40)] mb-[12px] card">
      <div className="w-full grow px-6 pt-4 pb-4 cardHeader">
        <label className="text-neutral-25 text-[16px] font-bold leading-[24px] ">
          Tank details
        </label>
      </div>
      <div className="relative w-full h-full text-neutral-25 py-[6px] px-6 cardBody sitestable">
        <ul className="list-none">
        <li className="py-[10px] mb-1">
            <div className="text-base">
              Tank name:
              <label className="ml-2 text-base text-primary-400">
                {deviceTanks && deviceTanks?.name}
              </label>
            </div>
          </li>
          <li className="py-[10px] mb-1">
            <div className="text-base">
              Tank type:
              <label className="ml-2 text-base text-primary-400">
                {deviceTanks && deviceTanks?.type}
              </label>
            </div>
          </li>
          <li className="py-[10px] mb-1">
            <div className="text-base">
              Product name:
              <label className="ml-2 text-base text-primary-400">
                {deviceTanks && deviceTanks?.product}
              </label>
            </div>
          </li>
          <li className="py-[10px] mb-1">
            <div className="text-base">
              Product type:
              <label className="ml-2 text-base text-primary-400">
                {deviceTanks && deviceTanks?.productType}
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DeviceTanks;
