import React, { ChangeEvent } from 'react';

interface CheckboxListProps {
    data: any[];
    type: string;
    title: string;
    onCheckboxChange: (event: ChangeEvent<HTMLInputElement>, id: string, type: string,) => void;
}

const CheckboxList: React.FC<CheckboxListProps> = ({ data, type, onCheckboxChange, title }) => {
    return (
        <div className='border-[1px] border-darkcolor-400 py-[16px] flex flex-col items-start rounded-[8px]'>
            <label className='text-[16px] font-semibold text-neutral-25 px-[20px] w-full pb-[16px] border-b-darkcolor-400 border-b-[1px]'>
                {title}
            </label>
            <div className='py-[8px]'>
                {data.map((item: any, index: number) => (
                    <div key={index} className='py-[8px] px-[12px] pl-6 flex items-center relative'>
                        <input
                            type='checkbox'
                            className='accent-primary-600 w-4 h-4 absolute left-2 top-[11px] text-blue-600 bg-gray-100 border-gray-300 rounded focus:outline-none'
                            checked={item.isDefault}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                onCheckboxChange(e, item._id, type)
                            }
                        />
                        <label className='ms-2 text-neutral-25 text-[16px] font-semibold'>
                            {item.tagName}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CheckboxList;
