import "./RouteAssetManagement.scss";
import { FiEdit } from "react-icons/fi";
import Tabs from "../common/Tabs/Tabs1";
import { RootState } from "../../store/store";
import Loading from "../common/Loading/Loading";
import Table from "../common/Table/CustomTable";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import GroupAssets from "../common/GroupsAssets/GroupAssets";
import React, { useState, useEffect, ChangeEvent } from "react";
import { FiCheck, FiChevronRight, FiSearch, FiUser } from "react-icons/fi";
import CustomModal from "../common/Modal/Modal";
import { getGroups } from "../../services/groupSitesAssets/groupSitesAssets.service";
import {
  routeAndAssetManagmentCustomGroupsColumns,
  routeAndAssetManagmentCustomGroupsUserAccessColumns,
  routeAndAssetManagmentDefaultGroupsColumns,
} from "../../constants/columns/route&assetManagmentTable";
import {
  addCustomGroup,
  assignUnassignSites,
  deleteGroup,
  getAllSites,
  getAllUsers,
  getGroupAssets,
  getGroupsCustom,
  getUser,
  updateGroup,
} from "../../services/routeAssetManagement/routeAssetManagement.service";

import { toast } from "react-toastify";
import { set } from "lodash";
const RouteAssetManagement: React.FC<any> = () => {
  const [pageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [groupId, setGroupId] = useState<any>();
  const [assetData, setAssetData] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [filterText, setFilterText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [siteTitle, setSiteTitle] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(0);
  const [customGroups, setCustomGroups] = useState<any>([]);
  const [searchQueryUser, setSearchQueryUser] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchQueryGroup, setSearchQueryGroup] = useState("");
  const [GroupSitesData, setGroupSitesData] = useState<any>([]);
  const [siteTotalTank, setSiteTotalTank] = useState<number>(0);
  const [customUserData, setCustomUserData] = useState<any>([]);
  const [searchInputCustom, setSearchInputCustom] = useState("");
  const [filterUserData, setFilterUserData] = useState<any[]>([]);
  const [filterGroupData, setFilterGroupData] = useState<any>([]);
  const [showDropdownUser, setShowDropdownUser] = useState(false);
  const [customGroupData, setCustomGroupData] = useState<any>([]);
  const [duplicateAssetData, setDuplicateAssetData] = useState([]);
  const [customOption, setCustomOption] = useState<boolean>(false);
  const [showDropdownGroup, setShowDropdownGroup] = useState(false);
  const [filteredGroupsCustom, setFilteredGroupsCustom] = useState([]);
  const [filterGroupAssets, setFilterGroupAssets] = useState<any[]>([]);
  const [activeTabCustomGroup, setActiveTabCustomGroup] = useState<number>(0);
  const [isSubgroupButtonDisabled, setIsSubgroupButtonDisabled] = useState(false);
  const [prevCustomGroup, setPrevCustomGroup] = useState<any>([]);
  const [isNewSubGroup, setIsNewSubGroup] = useState(false);
  const [userData, setUserData] = useState<any[]>([]);
  const [filterGroup, setFilterGroup] = useState<any[]>([]);
  const [originalSiteData, setOriginalSiteData] = useState<any>([]);
  const [customCount, setCustomCount] = useState<number>(0);
  const [foundGroup, setFoundGroup] = useState<any>({});
  const [foundSubGroup, setFoundSubGroup] = useState<any>({});
  const [totalResultsCount, setTotalResultsCount] = useState<number>(0);
  const [previousPage, setPreviousPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [newGroupName, setNewGroupName] = useState<string>("");
  const [modalName, setModalName] = useState<string>("");
  const [isGroup, setIsGroup] = useState<boolean>(true);

  const { userId } = useSelector((state: RootState) => state.GroupSitesAssets);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        setSearchInputCustom("");
        filterGroups("");
        setCustomGroups([]);
        await getGroupCustom();
        setCustomOption(true);
        setCustomGroups((prevCustomGroups: string | any[]) => {
          if (prevCustomGroups.length > 0) {
            getAssetById(prevCustomGroups[0]?.id);
            getUserById(prevCustomGroups[0]?.id);
            setGroupName(prevCustomGroups[0]?.groupName);
            setGroupId(prevCustomGroups[0]?.id);
            // setActiveTabCustomGroup(0);
          }
          return prevCustomGroups;
        });
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchDefaultData = async () => {
      setIsLoading(true);
      try {
        setCustomOption(false);
        setFilterText("");
        await getGroupsApi();
        setSiteTitle("");
        setSiteTotalTank(0);
        setGroupId("");
        setAssetData([]);
        setDuplicateAssetData([]);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    }

    if (activeTab === 1) {

      //handleGroupClick(customGroups[0]?.id)
      if (searchInputCustom.length > 0) {
        fetchData();
      }
      else {
        getAssetById(customGroups[0]?.id);
        getUserById(customGroups[0]?.id);
        setGroupName(customGroups[0]?.groupName);
        setGroupId(customGroups[0]?.id);
        setCustomOption(true);
      }
    } else {
      if (filterText.length > 0) {
        fetchDefaultData();
      }
      else {
        setCustomOption(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    window.addEventListener("click", function (event) {
      setShowDropdownGroup(false);
      setShowDropdownUser(false);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getGroupsApi();
      await getGroupCustom();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (filterText) {
      setFilterGroupData(searchBytitle(GroupSitesData, filterText));
    } else if (filterText === "") {
      GroupSitesData?.forEach(resetShowAssets);
      setFilterGroupData(GroupSitesData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  useEffect(() => {
    if (searchInputCustom !== "") {
      filterGroups(searchInputCustom);
    }
  }, [filteredGroupsCustom]);

  useEffect(() => {
    if (groupId) {
      if (activeTabCustomGroup === 0) {
        getAssetById(groupId);
      } else {
        getUserById(groupId);
      }
    }
  }, [groupId, activeTabCustomGroup, sortBy, sortOrder]);


  useEffect(() => {
    const fetchData = async () => {
      if (activeTab === 1) {
        if (activeTabCustomGroup === 0) {
          await getAssetById(groupId);
        } else {
          await getUserById(groupId);
        }
      }
    };

    fetchData();
  }, [page]);


  const handleSearchInputChangeCustom = (e: any) => {
    if (typeof e === "string") {
      setSearchInputCustom(e);
      filterGroups(e);
    }
    else {
      setSearchInputCustom(e.target.value);
      filterGroups(e.target.value);
    }
  };

  const filterGroups = (searchQuery: any) => {
    const filterGroupRecursively = (group: any, query: string): boolean => {
      // Check if group name matches search query
      if (group.groupName.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }

      // Check if any site details name matches search query
      if (group.siteDetails && group.siteDetails.some((site: any) =>
        site.siteName.toLowerCase().includes(query.toLowerCase()))) {
        return true;
      }

      // Check if any child group name matches search query
      if (group.childGroups && group.childGroups.length > 0) {
        return group.childGroups.some((childGroup: any) => {
          return filterGroupRecursively(childGroup, query);
        });
      }

      return false;
    };

    const markAssetsVisibility = (group: any, query: string) => {
      // If group name or site details match, set showAssets to true
      if (filterGroupRecursively(group, query)) {
        group.showAssets = true;
      } else {
        group.showAssets = false;
      }

      // Recursively mark child groups
      if (group.childGroups && group.childGroups.length > 0) {
        group.childGroups.forEach((childGroup: any) => {
          markAssetsVisibility(childGroup, query);
        });
      }
    };

    const resetAssetsVisibility = (group: any) => {
      // Reset showAssets to false
      group.showAssets = false;

      // Recursively reset child groups
      if (group.childGroups && group.childGroups.length > 0) {
        group.childGroups.forEach((childGroup: any) => {
          resetAssetsVisibility(childGroup);
        });
      }
    };

    const filtered = filteredGroupsCustom.filter((group: any) => {
      // If search query is empty, return true for all groups
      if (!searchQuery) {
        return true;
      }
      // Check recursively for matches in group, site details, and its child groups
      return filterGroupRecursively(group, searchQuery);
    });

    if (searchQuery !== "") {
      filtered.forEach((item: any) => {
        markAssetsVisibility(item, searchQuery);
      });
      setCustomGroups(filtered as never[]);
    } else {
      filteredGroupsCustom.forEach((item: any) => {
        resetAssetsVisibility(item);
      });
      setCustomGroups(filteredGroupsCustom as never[]);
    }
  };



  const handleCustomTabChange = (index: number) => {
    setPage(1);
    setActiveTabCustomGroup(index);
  };

  const handlePageNumberChange = (pageNumber: number) => setPage(pageNumber);

  const handleSearchChangeUser = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setShowDropdownUser(true);
    const searchUser = e.target.value as string; // Changed from searchGroupUser to searchUser
    setSearchQueryUser(searchUser); // Update the search query state for users
    if (searchUser !== "") {
      setFilterUserData(
        customUserData.filter((user: any) =>
          user.userName.toLowerCase().includes(searchUser.toLowerCase())
        )
      );
    } else {
      setFilterUserData(customUserData);
    }
  };

  const handleSearchChangeGroup = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setShowDropdownGroup(true);
    const searchGroup = e.target.value as string;
    setSearchQueryGroup(searchGroup);

    if (searchGroup !== "") {
      const filteredGroups = customGroupData.filter((group: any) =>
        group.siteName.toLowerCase().includes(searchGroup.toLowerCase())
      );
      setFilterGroupAssets(filteredGroups);
    } else {
      setFilterGroupAssets(customGroupData);
    }
  };


  const handleSearchInputClick = async () => {
    setIsLoading(true);
    let group_id = "";
    const parent = foundSubGroup.length > 0 ? foundSubGroup.parent[0].id : foundGroup.id;
    if (parent !== undefined && parent !== "" && parent !== null) {
      group_id = parent;
    }
    else {
      group_id = customGroups[0]?.id;
    }
    try {
      const response: any = await getAllSites(group_id);
      if (response.statusCode === 200 && response.success) {
        setFilterGroupAssets(response.data);
        setCustomGroupData(response.data);
        setShowDropdownGroup(true);
        setSearchQueryGroup("");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchInputClickUser = async () => {
    setIsLoading(true);
    try {
      const response: any = await getAllUsers();
      if (response.statusCode === 200 && response.success) {
        setFilterUserData(response.data);
        setCustomUserData(response.data);
        setShowDropdownUser(true);
        setSearchQueryUser("");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSiteSelect = async (siteId: any) => {
    setIsLoading(true);
    try {
      const request: any = {
        id: groupId,
        assignedSites: [siteId],
        unAssignedSites: [],
        assignedUsers: [],
        unAssignedUsers: [],
      };
      const response: any = await assignUnassignSites(request);
      if (response.statusCode === 200 && response.success) {
        await getAssetById(groupId);
        setSearchQueryGroup(""); // Reset search query
        setShowDropdownGroup(false);
        await updateGroupState();
        toast.success("Site added successfully.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateGroupState = async () => {
    try {
      const response = await getGroupsCustom();
      if (response.statusCode === 200 && response.success) {
        const newGroups = response.data;
        const mergedGroups: any = mergeGroups(newGroups, customGroups);
        setCustomGroups(mergedGroups);
        setFilteredGroupsCustom(mergedGroups);
        if (searchInputCustom !== "" && isNewSubGroup) {
          handleSearchInputChangeCustom(searchInputCustom)
          setIsNewSubGroup(!isNewSubGroup);
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleUserSelect = async (user: string) => {
    setIsLoading(true);
    try {
      const request: any = {
        id: groupId,
        assignedSites: [],
        unAssignedSites: [],
        assignedUsers: [user],
        unAssignedUsers: [],
      };

      const response: any = await assignUnassignSites(request);
      if (response.statusCode === 200 && response.success) {
        setSearchQueryUser(""); // Reset search query
        setShowDropdownUser(false);
        getUserById(groupId);
        toast.success("User added successfully.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  };

  const handleDelete = async (id: any) => {
    setIsLoading(true);
    try {
      const request: any = {
        id: groupId,
        assignedSites: [],
        unAssignedSites: [id],
        assignedUsers: [],
        unAssignedUsers: [],
      };
      const response: any = await assignUnassignSites(request);
      if (response.statusCode === 200 && response.success) {
        //getGroupCustom();
        await getAssetById(groupId);
        if (totalResultsCount % 11 === 0) {
          setPage(previousPage)
        }
        if (searchInputCustom !== "") {
          await updateGroupState()
        }
        else {
          await getGroupCustom();
        }
        toast.success("Site deleted successfully.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async (id: any) => {
    setIsLoading(true);
    try {
      const request: any = {
        id: groupId,
        assignedSites: [],
        unAssignedSites: [],
        assignedUsers: [],
        unAssignedUsers: [id],
      };
      const response: any = await assignUnassignSites(request);
      if (response.statusCode === 200 && response.success) {
        await getUserById(groupId);
        if (totalResultsCount % 11 === 0) {
          setPage(previousPage)
        }
        toast.success("User Deleted successfully.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      const request: any = {
        id: groupId,
        groupName: groupName,
      };
      const response: any = await updateGroup(request);
      if (response.statusCode === 200 && response.success) {
        setIsEditing(false);
        await getGroupCustom();
        toast.success("Group Name updated succesfully")
      }
    } catch (error: any) {
      if (error.response.data.statusCode === 400) {
        toast.error("Duplicate record found")
        setGroupName(groupName);
        setIsLoading(false);
        setIsEditing(true)
      }
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    //}
    // else {
    //   toast.error("Group Name already exists. Please add different Group Name")
    //   setGroupName(groupName);
    //   setIsLoading(false);
    //   setIsEditing(true)
    // }

  };

  const handleDeleteGroup = async (id: any) => {
    setIsLoading(true);
    try {
      const response: any = await deleteGroup(id);
      if (response.statusCode === 200 && response.success) {
        await getGroupCustom();
        const nextGroup = customGroups[0]?.id === id ? customGroups[1] : customGroups[0];
        if (nextGroup) {
          setGroupId(nextGroup.id);
          getAssetById(nextGroup.id);
          getUserById(nextGroup.id);
          setGroupName(nextGroup.groupName);
        }

        toast.success("Group deleted successfully.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getGroupsApi = async () => {
    try {
      setIsLoading(true);
      const response = await getGroups();
      const filteredData = response.data.filter(
        (item: any) => item.isAmpGroup && item.groupName !== "All Sites"
      );
      setGroupSitesData(filteredData);
      setFilterGroup(filteredData);
      setOriginalSiteData(filteredData);
      setCustomOption(false);
    } catch (error) {
      console.error("error", error);
    }
    // finally {
    //   setIsLoading(false);
    // }
  };

  const getGroupCustom = async () => {
    try {
      setIsLoading(true);
      const response = await getGroupsCustom();
      if (response.statusCode === 200 && response.success) {
        setCustomCount(response.data.length);
        const newGroups = response.data;

        let prevGroups = prevCustomGroup.length === 0 ? prevCustomGroup : customGroups;

        const mergedGroups: any = mergeGroups(newGroups, prevGroups);
        setCustomGroups(mergedGroups);
        setFilteredGroupsCustom(mergedGroups);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (index: number) => {
    if (index === activeTab) {
      return;
    }
    setActiveTab(index);
  };

  function countChildGroups(groups: any) {
    let count = 0;

    function recursiveCount(groupList: any[]) {
      groupList.forEach(group => {
        if (group.childGroups && group.childGroups.length > 0) {
          count += group.childGroups.length;
          recursiveCount(group.childGroups);
        }
      });
    }

    recursiveCount(groups);
    return count;
  }



  const handleAddSubGroup = async () => {
    setIsLoading(true);
    if (isSubgroupButtonDisabled) {
      toast.error("More than 2 Sub-Groups cannot be created");
      setIsLoading(false);
      return;
    }
    try {
      const request: any = {
        groupName: newGroupName,
        cretaedByUserId: userId,
        siteIds: [],
        userIds: [],
        createdDate: new Date().toISOString(),
        parentGroupId: groupId,
      };
      const response: any = await addCustomGroup(request);
      if (response.statusCode === 200 && response.success) {
        toast.success("New Sub-Group added successfully.");
        setIsNewSubGroup(true);
        await updateGroupState();
        setModalVisible(false);
      }
      setCustomOption(true);
    } catch (error: any) {
      if (error.response.data.statusCode === 400) {
        toast.error("Duplicate record found")
        setGroupName(groupName);
        setIsLoading(false);
      }
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addGroup = () => {
    setModalVisible(true);
    setNewGroupName("");
    setIsGroup(true);
    setModalName("Add new group");
  };

  const addSubGroup = () => {
    setModalVisible(true);
    setNewGroupName("");
    setIsGroup(false);
    setModalName("Add new Sub-Group");
  };

  const handleGroupSave = async () => {
    setIsLoading(true);
    try {
      const request: any = {
        groupName: newGroupName,
        cretaedByUserId: userId,
        siteIds: [],
        userIds: [],
        createdDate: new Date().toISOString(),
      };
      const response: any = await addCustomGroup(request);
      if (response.statusCode === 200 && response.success) {
        const fetchData = async () => {
          const response = await getGroupsCustom();
          if (response.statusCode === 200 && response.success) {
            setCustomGroups(response.data);
            setFilteredGroupsCustom(response.data);
            setCustomOption(true);
            const totalCustomGroups = response.data.length - 1;
            getAssetById(response.data[totalCustomGroups]?.id);
            getUserById(response.data[totalCustomGroups]?.id);
            setGroupName(response.data[totalCustomGroups]?.groupName);
            setGroupId(response.data[totalCustomGroups]?.id);
            toast.success("New Group added successfully.");
          }
          else {
            console.error("error", response);
          }
        };
        fetchData();
        setModalVisible(false);
      }
      setCustomOption(true);
    } catch (error: any) {
      if (error.response.data.statusCode === 400) {
        toast.error("Duplicate record found")
        setGroupName(groupName);
        setIsLoading(false);
      }
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleGroupClick = async (assetId: any) => {
    setGroupId(assetId);
    getAssetById(assetId);
    getUserById(assetId);
    setPage(1);
    setIsLoading(true);
    const findGroupById = (
      groups: {
        id: string;
        groupName: string;
        createdDate: string;
        siteDetails: string[];
        showAssets: boolean;
        childGroups: any[];
      }[],
      id: any,
      parent: any[] = []
    ): { group: any; parent: any[] } | null => {
      for (const group of groups) {
        const currentparent = [...parent, group];
        if (group.id === id) {
          setFoundGroup(group)
          return { group, parent: currentparent };
        }
        if (group.childGroups.length) {
          const found = findGroupById(group.childGroups, id, currentparent);
          if (found) {
            setFoundSubGroup(found);
            return found;
          }
        }
      }
      return null;
    };

    const result = findGroupById(customGroups, assetId);
    if (result) {
      const { group, parent } = result;
      setGroupName(group.groupName);
      const numberOfParents = parent.length - 1;
      if (numberOfParents >= 2) {
        setIsSubgroupButtonDisabled(true);
      }
      else {
        setIsSubgroupButtonDisabled(false);
      }
    }
    setIsLoading(false);
  };


  const handleGroup = (assetId: any) => {
    const duplicateGroupSiteData = [...customGroups];

    const findAndToggleShowAssets = (groups: any[]): boolean => {
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].id === assetId) {
          groups[i].showAssets = !groups[i].showAssets;
          return true; // Return true when the group is found and updated
        }

        // Recurse into child groups if they exist
        if (groups[i].childGroups && groups[i].childGroups.length > 0) {
          const found = findAndToggleShowAssets(groups[i].childGroups);
          if (found) return true; // Propagate the found status up the call stack
        }
      }
      return false; // Return false if no matching group is found at this level
    };
    const found = findAndToggleShowAssets(duplicateGroupSiteData);
    if (found) {
      setCustomGroups(duplicateGroupSiteData); // Update the state only if a change was made
      setPrevCustomGroup(duplicateGroupSiteData);
    }
  };

  const handleDefaultGroup = (assetId: any) => {
    const duplicateFilterGroup = [...filterGroup];
    const findAndToggleShowAssets = (groups: any[]): boolean => {
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].id === assetId) {
          groups[i].showAssets = !groups[i].showAssets;
          return true; // Return true when the group is found and updated
        }

        // Recurse into child groups if they exist
        const childGroups = groups[i].childGroups || groups[i].childGroup || [];
        if (childGroups.length > 0) {
          const found = findAndToggleShowAssets(childGroups);
          if (found) return true; // Propagate the found status up the call stack
        }
      }
      return false; // Return false if no matching group is found at this level
    };

    const found = findAndToggleShowAssets(duplicateFilterGroup);
    if (found) {
      setFilterGroup(duplicateFilterGroup); // Update the state only if a change was made
    }
  };



  const getUserById = async (groupId?: any) => {
    setIsLoading(true);
    try {
      const response = await getUser(groupId, page, pageSize, sortOrder, sortBy,);
      if (response.statusCode === 200 && response.success)
        setFilterUserData(response.data.usersDetailId.results);
      setUserData(response.data.usersDetailId.results);
      setPage(response.data.usersDetailId.pageNumber);
      setTotalPages(response.data.usersDetailId.totalPages);
      setTotalResultsCount(response.data.usersDetailId.totalResultsCount)
      setPreviousPage(response.data.usersDetailId.prevPageNumber)
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getAssetById = async (groupId?: any) => {
    setIsLoading(true);
    try {
      const request: any = {
        pageNumber: page,
        pageSize: pageSize,
        groupId: groupId ? groupId : "",
        isAmpGroup: false,
        ampGroupType: "Route",
        sortOrder: sortOrder,
        sortBy: sortBy,
      };
      const response: any = await getGroupAssets(request);
      if (response.statusCode === 200 && response.success) {
        setFilteredData(response.data.results);
        setPage(response.data.pageNumber);
        setTotalPages(response.data.totalPages);
        setTotalResultsCount(response.data.totalResultsCount)
        setPreviousPage(response.data.prevPageNumber)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const findGroup: any = (groups: any, id: any) => {
    for (const group of groups) {
      if (group.childGroup) {
        const foundGroup = group.childGroup.find((child: { id: any; }) => child.id === id);
        if (foundGroup) return foundGroup;
      }
      if (group.childGroup) {
        const result = findGroup(group.childGroup, id);
        if (result) return result;
      }
    }
    return null;
  };
  const gettingSiteDefaultGroup = async (item: any) => {
    const group = findGroup(originalSiteData, item.id);

    setSiteTitle(item?.groupName);
    setSiteTotalTank(item?.totalTankCount);
    setGroupId(item?.id);
    setAssetData(group?.site ?? []);
    setDuplicateAssetData(group?.site ?? []);
  };

  const handleInputSearch = (e: any) => {
    const { value } = e.target;
    setFilterText(value);
    setOriginalSiteData(GroupSitesData as never[]);

    if (!value) {
      setFilterGroup(GroupSitesData as never[]);
      return;
    }

    const filtered = GroupSitesData.reduce((acc: any[], group: any) => {
      const groupNameMatch = group.groupName.toLowerCase().includes(value.toLowerCase());

      const matchingSites = (group.site || []).filter((site: any) =>
        site.siteName.toLowerCase().includes(value.toLowerCase())
      );

      if (groupNameMatch) {
        acc.push({
          ...group,
          showAssets: true,
          site: group.site,
          childGroup: group.childGroup,
        });
      } else if (matchingSites.length > 0) {
        acc.push({
          ...group,
          showAssets: true,
          site: matchingSites,
          childGroup: [],
        });
      } else {
        // Check child groups if they exist
        const matchingChildGroups = (group.childGroup || []).reduce(
          (childAcc: any[], childGroup: any) => {
            const childGroupNameMatch = childGroup.groupName.toLowerCase().includes(value.toLowerCase());
            const matchingChildSites = (childGroup.site || []).filter((site: any) =>
              site.siteName.toLowerCase().includes(value.toLowerCase())
            );

            if (childGroupNameMatch || matchingChildSites.length > 0) {
              childAcc.push({
                ...childGroup,
                showAssets: true,
                site: matchingChildSites,
              });
            }

            return childAcc;
          },
          []
        );

        if (matchingChildGroups.length > 0) {
          acc.push({
            ...group,
            showAssets: true,
            site: [],
            childGroup: matchingChildGroups,
          });
        }
      }

      return acc;
    }, []);

    setFilterGroup(filtered as never[]);
  };

  const resetShowAssets = (item: any) => {
    item.showAssets = false;
    if (item.childGroups) {
      item.childGroups.forEach(resetShowAssets);
    }
  };

  const hasMatch = (item: any, filterText: any) => {
    const match = item?.groupName
      ?.toLowerCase()
      .includes(filterText.toLowerCase());
    if (match) {
      item.showAssets = true;
    }

    const childMatch =
      item?.childGroups &&
      item?.childGroups.some((child: any) => hasMatch(child, filterText));

    if (childMatch) {
      item.showAssets = true;
    }

    return match || childMatch;
  };

  const searchBytitle = (GroupSitesData: any, filterText: any) => {
    return GroupSitesData.filter((item: any) => hasMatch(item, filterText));
  };

  const searchData = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const duplicateRecord = [...duplicateAssetData];
    if (duplicateRecord.length > 0) {
      const filterData = duplicateRecord.filter(
        (item: { siteName: string; product: string }) =>
          item.siteName.toLowerCase().includes(value.toLowerCase()) ||
          item.product.toLowerCase().includes(value.toLowerCase())
      );
      setPage(1);
      setAssetData(filterData);
    }
  };

  const mergeGroups = (newGroups: any[], prevGroups: any) => {
    const findAndMergeShowAssets = (newGroup: { id: any; showAssets: boolean; childGroups: any[]; }, prevGroups: string | any[]) => {
      for (let i = 0; i < prevGroups.length; i++) {
        if (prevGroups[i].id === newGroup.id) {
          if (prevGroups[i].showAssets) {
            newGroup.showAssets = true;
          }
          if (newGroup.childGroups && newGroup.childGroups.length > 0 && prevGroups[i].childGroups && prevGroups[i].childGroups.length > 0) {
            newGroup.childGroups.forEach((childGroup, index) => {
              findAndMergeShowAssets(childGroup, prevGroups[i].childGroups);
            });
          }
        }
      }
    };

    newGroups.forEach(newGroup => {
      findAndMergeShowAssets(newGroup, prevGroups);
    });
    return newGroups;
  };
  const handleSortChange = (order: string, title: string) => {
    setSortBy(title);
    setSortOrder(order);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };
  const handleSaveGroupInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewGroupName(e.target.value);
  };
  const tabs = [
    {
      label: "Default groups",
      badgeValue: GroupSitesData?.length,
      badge: true,
      content: (
        <>
          <div className='flex items-center justify-between gap-[8px] shrink-0 mb-[14px]'>
            <div className='grow input-with-lefticon sidebar-search-input-block'>
              <FiSearch className='input-icon' />
              <input
                className='input-outline-4'
                placeholder='Search'
                onChange={handleInputSearch}
                autoComplete='off'
                value={filterText}
              />
            </div>
          </div>
          {GroupSitesData.length > 0 && (
            <GroupAssets
              assetData={filterGroup ?? []}
              handleAssetGroupClick={handleDefaultGroup}
              handleDefaultGroupClick={gettingSiteDefaultGroup}
            />
          )}
        </>
      ),
    },
    {
      label: "Custom groups",
      badgeValue: customCount,
      badge: true,
      content: (
        <>
          <div className='flex items-center justify-between gap-[8px] shrink-0 mb-[14px]'>
            <div className='grow input-with-lefticon sidebar-search-input-block'>
              <FiSearch className='input-icon' />
              <input
                className='input-outline-4'
                placeholder='Search by group or site name'
                value={searchInputCustom}
                onChange={handleSearchInputChangeCustom}
                autoComplete='off'
              />
            </div>
          </div>
          {customGroups?.length === 0 && searchInputCustom.length === 0 ? (
            <div className='text-center'>
              <p className='text-[22px] xl:text-[28px] mt-11 mb-[30px]'>
                Start by adding your first custom group
              </p>
              <button className='btn btn-primary' onClick={() => addGroup()}>
                Add a group
              </button>
            </div>
          ) : (
            <GroupAssets
              assetData={customGroups}
              handleAssetGroupClick={handleGroup}
              handleAssetGroup={handleGroupClick}
            />
          )}
        </>
      ),
    },
  ];

  const customGroupTabs = [
    {
      label: "Group Assets",
      badge: true,
      content: (
        <>
          <div className='relative'>
            <div className='flex items-center justify-between gap-[8px] shrink-0 mb-[14px]'>
              <div className='grow input-with-lefticon sidebar-search-input-block'>
                <FiSearch className='input-icon' />
                <input
                  className='input-outline-4'
                  placeholder='Search & asset to the group'
                  autoComplete='off'
                  value={searchQueryGroup}
                  onChange={handleSearchChangeGroup}
                  onClick={handleSearchInputClick}
                />
              </div>
            </div>

            {searchQueryGroup !== null &&
              customGroupData.length > 0 &&
              showDropdownGroup && (
                <div className='absolute h-[350px] overflow-y-scroll z-50 bg-darkcolor-800 shadow-md mt-1 left-0 right-0'>
                  <div className='flex flex-col min-h-[350px] bg-darkcolor-800 w-full px-2'>
                    {filterGroupAssets.map((group) => (
                      <div
                        key={group.siteId}
                        className='flex  flex-col w-full gap-3 cursor-pointer p-2 mb-1 mt-2 items-start relative text-white'
                        onClick={() => handleSiteSelect(group.id)}
                      >
                        <div className='flex items-center justify-between w-full gap-2'>
                          <span>{group.siteName}</span>
                          <div className='navigation-content-frame-item-carrot'>
                            <FiChevronRight className='text-neutral-25' />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
          <Table
            data={filteredData.length > 0 ? filteredData : []}
            columns={[
              ...routeAndAssetManagmentCustomGroupsColumns({ handleDelete }),
            ]}
            handlePageNumberChange={handlePageNumberChange}
            page={page}
            pagniation={true}
            totalPages={totalPages}
            errorMessage='No assets have been added to the group so far'
            emptyIcon={false}
            pageSize={pageSize}
            handleSortChange={handleSortChange}
          />
        </>
      ),
    },
    {
      label: "User Access",
      badge: true,
      content: (
        <>
          <div className='relative'>
            <div className='flex items-center justify-between gap-[8px] shrink-0 mb-[14px]'>
              <div className='grow input-with-lefticon sidebar-search-input-block'>
                <FiSearch className='input-icon' />
                <input
                  className='input-outline-4'
                  placeholder='Search & add user to the group'
                  autoComplete='off'
                  value={searchQueryUser}
                  onChange={handleSearchChangeUser}
                  onClick={handleSearchInputClickUser}
                />
              </div>
            </div>
            {searchQueryUser !== null &&
              customUserData.length > 0 &&
              showDropdownUser && (
                <div className='absolute h-[350px] overflow-y-scroll z-50 bg-darkcolor-800 shadow-md mt-1 left-0 right-0'>
                  <div className='flex flex-col h-full bg-darkcolor-800 w-full px-2'>
                    {filterUserData.map((user) => (
                      <div
                        key={user.userId}
                        className='flex  flex-col w-full gap-3 cursor-pointer p-2 mb-1 mt-2 items-start relative text-white'
                        onClick={() => handleUserSelect(user.userId)}
                      >
                        <div className='flex items-center justify-between w-full gap-2'>
                          <div className="flex items-center gap-2">
                            <FiUser size={20} />
                            <span>{user.userName}</span>
                          </div>
                          <FiChevronRight className="text-neutral-25 ml-auto" />
                        </div>
                        <span className='text-gray-200 text-sm pl-8 mt-[-10px]'>
                          {user.userEmail}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
          <Table
            data={userData.length > 0 ? userData : []}
            columns={[
              ...routeAndAssetManagmentCustomGroupsUserAccessColumns({
                handleDeleteUser,
              }),
            ]}
            handlePageNumberChange={handlePageNumberChange}
            page={page}
            pagniation={true}
            totalPages={totalPages}
            errorMessage='No assets have been added to the group so far'
            emptyIcon={false}
            pageSize={pageSize}
            handleSortChange={handleSortChange}
          />
        </>
      ),
    },
  ];


  return (
    <div className='flex flex-wrap flex-col w-full h-full text-darkcolor-700 p-3 px-[15px] xl:px-[32px] relative'>
      <Loading isLoading={isLoading} text={""} />
      <div className='flex flex-wrap h-full mx-[-6px]'>
        <div className='flex max-h-[850px] w-[100%] lg:w-[50%] px-[6px] pb-4 lg:pb-0' style={{ "maxHeight": "850px" }}>
          <div className='w-[100%] max-h-[100%] h-full overflow-y-auto border-darkcolor-400 border-[1px] rounded-[4px] flex flex-col items-start bg-gradient-to-t from-[#253240] via-[#253240] to-[rgba(37, 48, 64, 0.40)] mb-[10px] card'>
            <div className='w-full grow px-6 pt-5 pb-3 cardHeader'>
              <label className='text-neutral-25 text-[16px] font-bold leading-[24px] mb-[10px] '>
                Route & asset managment
              </label>
            </div>
            <div className={`relative w-full h-full text-neutral-25 p-3 px-[15px] xl:px-6 cardBody sitestable ${activeTab === 1 ? 'routeAsset-tabs' : null}`}>
              <Tabs
                tabs={tabs}
                activeTab={activeTab}
                onChange={handleTabChange}
                className=''
              />
              {activeTab === 1 ? (
                <button
                  className='btn btn-primary absolute top-3 right-[15px] xl:right-6 addgrp-btn'
                  onClick={() => addGroup()}
                >
                  Add a group
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className='flex max-h-[850px] w-[100%] lg:w-[50%] px-[6px]' style={{ "maxHeight": "850px" }}>
          {activeTab === 0 ? (
            <div className='w-[100%] pb-[20px] max-h-[100%] min-h-[450px] h-full overflow-y-auto border-darkcolor-400 border-[1px] rounded-[4px] flex flex-col items-start bg-gradient-to-t from-[#253240] via-[#253240] to-[rgba(37, 48, 64, 0.40)] mb-[10px] relative card'>
              {siteTitle ? (
                <>
                  <div className='w-full grow px-6 pt-5 pb-3 cardHeader'>
                    {siteTitle && (
                      <label className='flex flex-wrap items-center text-neutral-25 text-[16px] font-bold leading-[24px] mb-[10px] '>
                        {siteTitle}
                        <span className='relative right-[-4px] rounded-sm max-h-6 p-1 px-2 bg-primary-300 text-primary-700 text-sm font-medium leading-4 ml-1'>
                          {siteTotalTank}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className='w-full h-full text-neutral-25 p-3 px-[15px] xl:px-6 cardBody sitestable'>
                    <p className='mb-3'>Group assets</p>
                    <div className='flex items-center justify-between gap-[8px] shrink-0 mb-[14px]'>
                      <div className='grow input-with-lefticon sidebar-search-input-block'>
                        <FiSearch className='input-icon' />
                        <input
                          className='input-outline-4'
                          placeholder='Search'
                          autoComplete='off'
                          onChange={searchData}
                        />
                      </div>
                    </div>
                    <Table
                      data={assetData}
                      columns={routeAndAssetManagmentDefaultGroupsColumns}
                      handlePageNumberChange={handlePageNumberChange}
                      page={page}
                      pagniation={true}
                      pageSize={10}
                      sortWithFrontend={true}
                    />
                  </div>
                </>
              ) : <div className="w-full h-full flex justify-center items-center"><h3 className="p-4 text-[16px]">Please select only group site to see related tanks.</h3></div>}
            </div>
          ) : null}
          {/* Custom Group Tabs section */}
          {customOption === true ? (
            <div className='w-[100%] xl:w-[100%] h-full px-[0] lg:px-[6px]'>
              {/* Custom Group Tabs */}
              <div className='border-darkcolor-400 border-[1px] rounded-[4px] overflow-y-auto flex flex-col h-full items-start bg-gradient-to-t from-[#253240] via-[#253240] to-[rgba(37, 48, 64, 0.40)] mb-[10px] card'>
                {isEditing ? (
                  <div className='w-[100%] flex items-center justify-between mt-4 ml-3 editblock'>
                    <div className='editbar'>
                      <input
                        type='text'
                        value={groupName}
                        onChange={handleInputChange}
                        className='flex-grow bg-darkcolor-700 p-2 rounded-md border border-darkcolor-300 text-white w-[139px] h-[40px] '
                      />
                      <button className='ml-2' onClick={handleSaveClick}>
                        <FiCheck style={{ color: "white" }} />
                      </button>
                    </div>
                    <div className='deletbar'>
                      <button
                        className='btn bg-darkcolor-300 text-white py-2 px-4 rounded-md m-[4px]'
                        onClick={() => handleDeleteGroup(groupId)}
                      >
                        Delete Group
                      </button>
                      <button
                        className='btn btn-primary m-[4px] mr-[8px]'
                        onClick={addSubGroup}
                      >
                        Create Subgroup
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='w-[100%] flex items-center justify-between  text-neutral-25 text-[16px] font-bold leading-[24px] mb-[10px] pl-4 pt-2 editblock'>
                    <div className='editbar'>
                      {groupName}
                      <button
                        className='cursor-pointer outline-none ml-3 text-lg'
                        onClick={handleEditClick}
                      >
                        <FiEdit />
                      </button>
                    </div>
                    <div className='deletbar'>
                      <button
                        className='btn bg-darkcolor-300 text-white py-2 px-4 rounded-md  m-[4px]'
                        onClick={() => handleDeleteGroup(groupId)}
                      >
                        Delete Group
                      </button>
                      <button
                        onClick={addSubGroup}
                        className='btn btn-primary py-2 px-4 rounded-md m-[4px] mr-[8px]'
                      >
                        Create Subgroup
                      </button>
                    </div>
                  </div>
                )}
                <Tabs
                  tabs={customGroupTabs}
                  activeTab={activeTabCustomGroup}
                  onChange={handleCustomTabChange}
                  className=''
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <CustomModal
        visible={modalVisible}
        title={modalName}
        onClose={handleModalClose}
        onSubmit={isGroup ? handleGroupSave : handleAddSubGroup}
        width="500px"
      >
        <p>Please specify a group name</p>
        <input
          type="text"
          className="input-outline-4 w-full p-2 mt-4 border rounded"
          placeholder="Enter here"
          value={newGroupName}
          onChange={handleSaveGroupInputChange}
        />
      </CustomModal>

    </div>
  );
};
export default RouteAssetManagement;