import { FC, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";

interface ModalProps {
    visible: boolean;
    title: string;
    onClose: () => void;
    onOk?: () => void;
    okText?: string;
    children: any;
    width?: string;
    onSubmit?: () => void;
}
const Modal: FC<ModalProps> = ({
    visible,
    title,
    onClose,
    onOk,
    okText,
    children,
    width,
    onSubmit,
}) => {
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose();
            }
        };

        if (visible) {
            document.addEventListener("keydown", handleEsc, false);
        } else {
            document.removeEventListener("keydown", handleEsc, false);
        }

        return () => {
            document.removeEventListener("keydown", handleEsc, false);
        };
    }, [onClose, visible]);

    if (!visible) return null;

    return (
        <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50'>
            <div
                className={`bg-darkblue-900 px-[40px] py-[20px] rounded-[10px] shadow-md relative`}
                style={{ width }}
            >
                <div className='flex justify-between items-center mb-4'>
                    <h2 className='text-xl font-semibold'>{title}</h2>
                    <IoCloseSharp
                        onClick={onClose}
                        className='text-neutral-25 absolute top-[10px] right-[10px] cursor-pointer hover:text-darkcolor-200'
                        size={24}
                    />
                </div>
                <div className='mb-[10px]'>{children}</div>
                {onOk && (
                    <button
                        className='float-right py-[10px] px-[16px] text-[14px] flex justify-center items-center rounded-[8px] border-solid bg-darkblue-600 hover:bg-darkblue-700 focus:outline-none text-neutral-10'
                        onClick={onOk}
                    >
                        {okText || "OK"}
                    </button>
                )}
                {onSubmit && (
                    <>
                        <button className="float-right py-[10px] px-[16px] text-[14px] flex justify-center items-center rounded-[8px] border-solid bg-darkblue-600 hover:bg-darkblue-700 focus:outline-none text-neutral-10"
                            onClick={onSubmit}>
                            Confirm
                        </button>
                        <button
                            onClick={onClose}
                            className='float-right px-4 py-2 text-neutral-25 text-[14px] rounded-[8px] mr-2 border border-neutral-25'
                        >
                            Cancel
                        </button>

                    </>
                )}
            </div>
        </div>
    );
};

export default Modal;
