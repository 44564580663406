import React from 'react';

interface BadgeProps {
    count: number | undefined;
    textColor: string;
    bgColor: string;
}

const Badge: React.FC<BadgeProps> = ({ count, textColor, bgColor }) => {
    return (
        <span className={`ml-[8px] py-0 px-[4px] rounded-[2px] font-semibold ${bgColor} ${textColor} text-[12px]`}>
            {count}
        </span>
    );
};

export default Badge;
