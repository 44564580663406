import "./Maps.scss";
import GoogleMapReact from "google-map-react";
import React, { useState, useEffect } from "react";
import Connected from "../../../assets/img/green.svg";
import Disconnected from "../../../assets/img/red.svg";
import {
    MarkerClusterer,
    SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";

interface Location {
    id: number;
    lat: number;
    lng: number;
    status: string;
    name: string;
}

interface MapProps {
    center: { lat: number; lng: number };
    zoom: number;
    locations: Location[];
}

const Map: React.FC<MapProps> = ({ center, zoom, locations }) => {
    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY || "";
    const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
    const [markerCluster, setMarkerCluster] = useState<MarkerClusterer | null>(
        null
    );

    useEffect(() => {
        if (mapInstance && markerCluster) {
            markerCluster.clearMarkers();

            const markers = locations.map((location) => {
                const marker = new google.maps.Marker({
                    position: { lat: location.lat, lng: location.lng },
                    icon: {
                        url:
                            location.status === "connected"
                                ? Connected
                                : Disconnected,
                        scaledSize: new google.maps.Size(45, 45),
                    },
                });

                const infoWindowContent = document.createElement("div");
                infoWindowContent.className = "custom-info-window";
                infoWindowContent.innerText = location.name;

                const infoWindow = new google.maps.InfoWindow({
                    content: infoWindowContent,
                });

                marker.addListener("mouseover", () => {
                    infoWindowContent.style.backgroundColor = "#131F2E"; // Change this color as needed
                    infoWindow.open(mapInstance, marker);
                });

                marker.addListener("mouseout", () => {
                    infoWindow.close();
                });

                return marker;
            });

            markerCluster.addMarkers(markers);
        }
    }, [mapInstance, markerCluster, locations]);

    const handleGoogleMapApi = (map: google.maps.Map) => {
        setMapInstance(map);

        const customRenderer = {
            render: ({ count, position }: any) => {
                const div = document.createElement("div");
                div.className = "cluster-icon";
                div.textContent = count.toString();
                return new google.maps.Marker({
                    position,
                    icon: {
                        url:
                            "data:image/svg+xml;charset=UTF-8," +
                            encodeURIComponent(`
                            <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="#0094BD" />
                                <text x="20" y="25" font-size="12" font-family="Arial" fill="white" text-anchor="middle">${count}</text>
                            </svg>
                        `),
                        scaledSize: new google.maps.Size(40, 40),
                    },
                });
            },
        };

        const markerClusterer = new MarkerClusterer({
            map,
            algorithm: new SuperClusterAlgorithm({ radius: 60 }),
            renderer: customRenderer,
        });

        setMarkerCluster(markerClusterer);
    };

    const defaultOptions = {
        fullscreenControl: true,
        mapTypeId: "hybrid", //other: terrain, satellite, hybrid, roadmap
        tilt: 45,
    }

    return (
        <div style={{ height: "80vh" }} className='w-full'>
            <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                defaultCenter={center}
                defaultZoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => handleGoogleMapApi(map)}
                options={defaultOptions}
            />
        </div>
    );
};

export default Map;
