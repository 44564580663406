import axios from "axios";
import instance from "../api";
import {
  AddUserPreference,
  SiteSummaryHeader,
  SiteSummaryResponse,
} from "./siteSummary.interface";

export const getFilterColumns = async (data: any) => {
  const response = await instance.post<SiteSummaryResponse>(
    "/Groups/GetAllSitesSummary",
    data
  );
  return response.data;
};

export const getHeaders = async (tableViewGroup: string) => {
  const params = {
    tableViewGroup: tableViewGroup,
  };
  const response = await instance.get<SiteSummaryHeader>("/LookUp/GetLookUp", {
    params,
  });
  return response.data;
};

export const addUserPreference = async (data: any) => {
  try {
    const response = await instance.post<AddUserPreference>(
      "/UserPreferences/AddUserPreference",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching pinned sites:", error);
    throw error;
  }
};

export const getSummaryPageFilterColumns = async (
  userId: string,
  tableName: string
) => {
  const params = {
    UserId: userId,
    TableName: tableName,
  };
  const response = await instance.get("/Groups/GetSummaryPageFilterColumns", {
    params,
  });
  return response.data;
};

export const getFilterColumnsValue = async (
  userId: string,
  columnKey: string,
  groupType: string,
  groupId: string
) => {
  const params = {
    UserId: userId,
    ColumnKey: columnKey,
    groupType: groupType,
    groupId: groupId,
  };
  const response = await instance.get("/Groups/GetFilterColumnsValues", {
    params,
  });
  return response.data;
};

export const getToken = async () => {
  const response = await instance.get<any>(
    "/Config/AmpSiteManagement"
  );
  return response.data;
}
export const updateToken = async (KEY_VAULT_URL: any, KEY_VAULT_EMAIL: any, KEY_VAULT_PASSWORD: any) => {
  const params = {
    grant_type: "external_user",
    refresh_token: "",
    reference: "",
    email: KEY_VAULT_EMAIL,
    password: KEY_VAULT_PASSWORD
  }
  const response = await axios.post(
    KEY_VAULT_URL + "/token",
    params
  );
  return response;
};

export const excelDownload = async (token: string, groupId: any) => {
  //export const excelDownload = async () => {
  const config = {
    headers: {
      "Content-Type": "FileUpload",
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob" as "json",
  };
  const response = await axios.get(
    "https://sitemanagementapp-api-001-s.azurewebsites.net/api/Asset/GetDownloadTemplate/" + groupId + "/2/3",
    config
  );
  return response.data;
};

export const excelErrorDownload = async (token: string, fileRefId: number) => {
  //export const excelDownload = async () => {

  const config = {
    headers: {
      "Content-Type": "FileUpload",
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob" as "json",
  };
  const response = await axios.get(
    `https://sitemanagementapp-api-001-s.azurewebsites.net/api/Ingestion/IngestionFileDownLoad?fileRefId=${fileRefId}`,
    config
  );
  return response.data;
};

export const excelUpload = async (file: any, token: string, groupId: any) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      "https://sitemanagementapp-api-001-s.azurewebsites.net/api/Ingestion/FileUploadIngestionTemplates/" + groupId + "/2/3",
      file,
      config
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error uploading Excel file:", error);
    throw error;
  }
};

export const getDefaultAmpGroupAndSites = async (
  isRequiredTopOneOnly?: boolean,
  groupId?: string,
  ampGroupType?: string
) => {
  try {
    const params = {
      ...(isRequiredTopOneOnly ? { isRequiredTopOneOnly: isRequiredTopOneOnly } : { isRequiredTopOneOnly: false }),
      ...(groupId ? { id: groupId } : {}),
      ...(ampGroupType ? { AmpGroupType: ampGroupType } : {}),

    };
    const response = await instance.get<any>(
      "/Groups/GetDefaultAmpGroupAndSites",
      { params }
    );
    return response.data;
  } catch (error) {
    console.error("error", error);
    throw error;
  }
};

export const getUserPreference = async (userId: string) => {
  const response = await instance.get<any>(
    `/UserPreferences/GetUserPreference/${userId}`
  );
  return response.data;
};

export const getSitesSummaryExportToExcel = async (data: any) => {
  const response = await instance.post<SiteSummaryResponse>(
    "/Groups/GetSitesSummaryExportToExcel",
    data
  );
  return response.data;
};

export const getTanksStatus = async (tankIdCsv: string) => {
  const params = {
    TankIdCsv: tankIdCsv,
  };
  const response = await instance.get<SiteSummaryHeader>("/Groups/GetTankStatus", {
    params,
  });
  return response.data;
};
