import React from "react";

interface AnalysisCardProps {
    title: string;
    dataPoints: {
        label: string;
        color: string;
    }[];
}

const AnalysisCard: React.FC<AnalysisCardProps> = ({ title, dataPoints }) => {
    return (
        <div className='border-darkcolor-400 border-[1px] rounded-[4px] gap-[10px] flex p-[16px] flex-col items-start bg-gradient-to-t from-[#253240] via-[#253240] to-[rgba(37, 48, 64, 0.40)] mb-[10px]'>
            <label className='text-neutral-25 text-[16px] font-bold leading-[24px] mb-[10px]'>
                {title}
            </label>
            {dataPoints.length > 0 &&
                dataPoints.map((point, index) => (
                    <div className='flex items-center' key={index}>
                        <span
                            className='w-[7px] h-[7px] rounded-full mr-[10px]'
                            style={{ background: point?.color }}
                        ></span>
                        <label className={`text-[16px] text-neutral-25 leading-[24px]`}>
                            {point.label}
                        </label>
                    </div>
                ))}
        </div>
    );
};

export default AnalysisCard;
