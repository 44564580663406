import noti_table from "../alarmNotifications/data/alarmNotifications.json";
import instance from "../api";

//const baseURL = process.env.REACT_APP_;

const AlarmNotifications = async () => {
  return await new Promise((resolve, reject) => {
    resolve(noti_table.data);
  });
};

const AlarmNotificationService = {
  AlarmNotifications,
};

export default AlarmNotificationService;


export const getSiteAlarms = async (
  priority: number | string,
  freeText: string,
  pageNumber: number,
  pageSize: number
) => {
  const params = {
    ...(priority ? { Priority: priority } : {}),
    ...(freeText ? { FreeText: freeText } : {}),
    ...(pageNumber ? { PageNumber: pageNumber } : {}),
    ...(pageSize ? { PageSize: pageSize } : {}),
  };
  const response = await instance.get<any>("/Alarms/SiteAlarms", {
    params,
  });
  return response.data;
};

