import React from "react";
import TimePickerComponent from "../../common/TimePicker/TimePicker";
import CustomDropdown from "../../common/Dropdown";
import { FaRegCircleCheck } from "react-icons/fa6";

interface timeBasedInterface {
    selectedDate: any;
    setSelectedDate: any;
    dropdownOptions: any;
    handleSelect: any;
    timeBasedPrimePump: any;
}

const TimeBased: React.FC<timeBasedInterface> = ({
    selectedDate,
    setSelectedDate,
    dropdownOptions,
    handleSelect,
    timeBasedPrimePump,
}) => {
    return (
        <div className=' mt-[16px]'>
            <div className='flex items-center justify-between gap-[8px] shrink-0  mb-[16px]'>
                <TimePickerComponent
                    selectedDate={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    backGround='transparent'
                />
                <CustomDropdown
                    options={dropdownOptions}
                    onSelect={handleSelect}
                    optionHeading='Views'
                    dropdownIcon={false}
                    defaultValue='Table1'
                    bgColor='bg-transparent'
                    width='180px'
                />
                <button className='py-[10px] px-[16px] text-[14px] flex justify-center items-center rounded-[8px] font-bold  border-primary-700 border-[1px] border-solid bg-primary-600 hover:bg-primary-700 focus:outline-none text-neutral-25'>
                    Schedule
                </button>
            </div>

            <div className='flex flex-col bg-darkcolor-700 rounded-[8px]  border border-darkcolor-400'>
                <div className='border-b py-[12px] px-[16px] border-b-darkcolor-400'>
                    <label className='text-[16px] font-semibold'>History</label>
                </div>
                {timeBasedPrimePump.map(
                    (item: { id: number; value: string }, index: number) => (
                        <div
                            className={`flex items-center py-[12px] px-[12px]  ${timeBasedPrimePump.length - 1 > index
                                    ? "border-b-[1px] border-b-darkcolor-400"
                                    : ""
                                }`}
                            key={item.id}
                        >
                            <div className='flex items-center gap-[8px] px-[12px] w-full whitespace-nowrap'>
                                <FaRegCircleCheck className='text-success-400' />
                                <label className='text-[14px]'>{item.value}</label>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default TimeBased;
