import { GrFormClose } from "react-icons/gr";
import React, { useEffect, useRef } from "react";

interface DrawerProps {
    title?: string;
    width?: string;
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({
    width,
    title,
    isOpen,
    onClose,
    children,
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("overflow-hidden");
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.body.classList.remove("overflow-hidden");
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.body.classList.remove("overflow-hidden");
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            onClose();
        }
    };

    return (
        <div
            className={`fixed inset-0 z-50 transition-transform ${isOpen ? "transform translate-x-0" : "transform translate-x-full"
                }`}
            ref={dropdownRef}
        >
            <div className='absolute inset-0'>
                <div
                    className='absolute inset-0 transition-opacity'
                    aria-hidden='true'
                    onClick={onClose}
                >
                    <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
                </div>

                <div
                    style={{ width: width }}
                    className='fixed inset-y-0 right-0 pl-16 xl:pl-10 max-w-full flex '
                >
                    <div
                        className={`relative w-screen bg-darkcolor-800 overflow-auto border-l-[1px] border-l-darkcolor-400`}
                    >
                        <div className='h-full flex flex-col space-y-6 py-6 shadow-xl'>
                            <div className='flex justify-between items-center px-[24px] sm:px-6 border-b-[1px] pb-[10px] border-b-darkcolor-400'>
                                <h2 className='text-lg font-medium text-neutral-25'>
                                    {title}
                                </h2>
                                <GrFormClose
                                    onClick={onClose}
                                    className='text-neutral-25 cursor-pointer hover:bg-darkcolor-600 hover:py-[2px] px-[2px] rounded-[4px]'
                                    size={22}
                                />
                            </div>
                            <div>{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Drawer;
