import React, { useEffect, useMemo, useState } from "react";
import ChartComponent from "../SiteDetails/ChartComponent";
import noDataIcon from "../../assets/img/icon-noData.svg";

interface ChartContainerProps {
    charts: any;
    seriesData: any;
}

const ChartContainer: React.FC<ChartContainerProps> = ({
    charts,
    seriesData,
}) => {

    const [noData, setNoData] = useState<boolean>(false);
    
    useEffect(()=>{
        const records = charts.filter((item: any) => item?.records.length !== 0);
        if (records.length === 0) {
            setNoData(true);
        } else {
            setNoData(false);
        }
    },[charts]);
    
    const chartElements = useMemo(() => {
       
        let numb = 1;
        return charts.length > 0 ? (
            <>
                {charts.map((chart: any, index: number) => {                    
                    const stackSeriesData: any = [];
                    let filterStack = seriesData.filter((item: any) => item?.stack === "Stack " + numb);

                    if (filterStack.length === 0) {
                        numb = numb + 1;
                        filterStack = seriesData.filter((item: any) => item?.stack === "Stack " + numb);

                        if (filterStack.length === 0) {
                            numb = numb + 1;
                            filterStack = seriesData.filter((item: any) => item?.stack === "Stack " + numb);
                        }
                    }

                    if (filterStack.length > 0) {
                        filterStack.forEach((rec: any) => {
                            stackSeriesData.push(rec);
                        });
                    }

                    numb = numb + 1;

                    return (
                        <React.Fragment key={index}>
                            {chart?.records.length > 0 && (
                                <ChartComponent
                                    chartId={chart?.chartId}
                                    data={chart}
                                    seriesData={stackSeriesData}
                                />
                            )}
                            {chart?.records.length > 0 && index < charts.length - 1 && <br />}
                        </React.Fragment>
                    );
                })}    
                {noData && (
                    <div className='flex flex-col items-center 1'>
                        <div className=' text-neutral-25 mb-4'>
                            <img src={noDataIcon} alt='SiteName' className='object-contain bg-img' />
                        </div>
                        <span className='text-neutral-25 title'> No data found</span>
                    </div>
                )}            
            </>
        ) : (
            <div className='flex flex-col items-center'>
              <div className=' text-neutral-25 mb-4'>
                <img src={noDataIcon} alt='SiteName' className='object-contain bg-img' />
              </div>
              <span className='text-neutral-25 title'> No data found</span>
            </div>
        );
    }, [charts, seriesData, noData]);

    return (<>
    {chartElements}
    </>);
}

export default React.memo(ChartContainer);
