import React, { useState } from "react";

interface PinnedProps {
    size?: number | string;
    className?: string;
    onClick?: () => void;
    color?: string;
}

const Pinned: React.FC<PinnedProps> = ({
    size = 20,
    className,
    onClick,
    color,
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const style = size ? { width: size, height: size } : undefined;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21'
            height='22'
            viewBox='0 0 21 22'
            fill='none'
            className={className}
            style={style}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <g clipPath='url(#clip0_9150_4898)'>
                <path
                    d='M9.97229 13.5002L9.98352 19.3335M6.62663 7.09673L6.63004 8.87224C6.63038 9.04559 6.63054 9.13226 6.61369 9.2152C6.59874 9.28878 6.57391 9.36 6.53987 9.42693C6.50152 9.50237 6.4475 9.57016 6.33947 9.70573L5.03447 11.3434C4.48103 12.038 4.20431 12.3852 4.20455 12.6771C4.20477 12.9308 4.32062 13.1707 4.51926 13.3286C4.74768 13.5102 5.19172 13.5094 6.0798 13.5077L13.8648 13.4927C14.7529 13.491 15.1969 13.4901 15.4246 13.3076C15.6227 13.1489 15.7376 12.9086 15.7368 12.6549C15.7359 12.363 15.4579 12.0168 14.9018 11.3244L13.5905 9.69177C13.4819 9.55662 13.4277 9.48904 13.389 9.41375C13.3547 9.34695 13.3296 9.27582 13.3144 9.2023C13.2972 9.11943 13.297 9.03276 13.2967 8.85941L13.2933 7.0839C13.2931 6.98796 13.293 6.93999 13.2983 6.89268C13.3031 6.85064 13.311 6.80903 13.3221 6.7682C13.3345 6.72224 13.3522 6.67767 13.3877 6.58853L14.2235 4.48718C14.4674 3.87414 14.5893 3.56761 14.5378 3.32184C14.4927 3.10691 14.3645 2.91847 14.1813 2.79749C13.9717 2.65914 13.6418 2.65977 12.9821 2.66104L6.92082 2.67271C6.26106 2.67398 5.93118 2.67462 5.72214 2.81377C5.53933 2.93546 5.41195 3.12439 5.36768 3.33949C5.31706 3.58546 5.44017 3.89151 5.68637 4.50361L6.53031 6.60173C6.56611 6.69073 6.58401 6.73524 6.59663 6.78115C6.60785 6.82194 6.61594 6.86352 6.62083 6.90553C6.62635 6.95282 6.62644 7.00079 6.62663 7.09673Z'
                    stroke={isHovered ? "#0094BD" : color}
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_9150_4898'>
                    <rect
                        width='20'
                        height='20'
                        fill='white'
                        transform='translate(0.0153809 1.01929) rotate(-0.110297)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Pinned;
