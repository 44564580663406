import "./SiteSummary.scss";
import FileSaver from "file-saver";
import Map from "../common/Maps/Maps";
import { toast } from "react-toastify";
import Tabs from "../common/Tabs/Tabs1";
import Alerts from "../common/Svg/Alerts";
import TableCheckbox from "./TableCheckbox";
import Drawer from "../common/Drawer/Drawer";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import Loading from "../common/Loading/Loading";
import { FaGreaterThan } from "react-icons/fa6";
import Table from "../common/Table/CustomTable";
import Tooltip from "../common/Tooltip/Tooltip";
import TableColumns from "../common/Svg/TableColumns";
import LineChart from "../common/Charts/SingleCharts";
import { useDispatch, useSelector } from "react-redux";
import UploadExcel from "../common/Upload/UploadExcel";
import noDataIcon from "../../assets/img/icon-noData.svg";
import IconsComponents from "./components/IconsComponents";
import SearchBar from "../common/Searchbar/AdvancedSearchbar";
import SiteNameComponent from "./components/SiteNameComponent";
import React, { ChangeEvent, useEffect, useState } from "react";
import SearchbarDrawer from "../common/Searchbar/SearchbarDrawer";
import BreadcrumbDropdown from "../common/Dropdown/BreadcrumbDropdown";
import TokenService from "../../services/authentication/token.service";
import IconDropdown from "../common/Dropdown/IconDropdown/IconDropdown";
import SiteSummaryDropdown from "../common/Dropdown/SiteSummaryTableDropdown";
import {
  downloadExcelFile,
  timeDifference,
} from "../../constants/globalFunctions";
import {
  dropdownOptions,
  iconDropdownOptions,
} from "../../constants/TableDropdownDummyData";
import {
  setSiteId,
  setGroupId,
  setGroupType,
  setIsAmpGroup,
  setTableRecords,
  setTabActive,
} from "../../store/groupSitesAssets/groupSitesAssets.store";
import {
  getHeaders,
  updateToken,
  excelDownload,
  getFilterColumns,
  addUserPreference,
  getUserPreference,
  getFilterColumnsValue,
  getDefaultAmpGroupAndSites,
  getSummaryPageFilterColumns,
  getSitesSummaryExportToExcel,
  getToken,
  getTanksStatus,
} from "../../services/siteSummary/siteSummary.service";
import {
  setTest,
  setTankId,
  clearSiteId,
  clearTankId,
  setCustomerId,
  clearCustomerId,
  setCustomLevel1Id,
  setCustomLevel2Id,
  clearCustomLevel1Id,
  clearCustomLevel2Id,
  clearCustomLevel3Id,
  clearSiteGroupDetails,
  clearTankGroupDetails,
  setRenderSiteSummaryAPI,
  setCustomerGroupDetails,
  clearCustomerGroupDetails,
  clearRenderSiteSummaryAPI,
  setCustomLevel1GroupDetails,
  setCustomLevel2GroupDetails,
  clearCustomLevel2GroupDetails,
  clearCustomLevel1GroupDetails,
  clearCustomLevel3GroupDetails,
} from "../../store/routesAndAssets/routesAndAssets.store";

const SiteSummary: React.FC = () => {
  const [pageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any>([]);
  const [sortBy, setSortBy] = useState<string>("");
  const [mapData, setMapData] = useState<any[]>([]);
  const [allColumn, setAllColumn] = useState<any>([]);
  const [mapCenter, setMapCenter] = useState<any>({});
  const [checkboxes, setCheckboxes] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [columnsFilter, setColumnsFilter] = useState<any[]>([]);
  const [searchFreeText, setSearchFreeText] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [totalSitesCount, setTotalSitesCount] = useState<number>(0);
  const [userID] = useState<string>(TokenService.getUser()?.user?._id);
  const [dynamicColumnsAll, setDynamicColumnsAll] = useState<any[]>([]);
  const [duplicateCheckboxes, setDuplicateCheckboxes] = useState<any>([]);
  const [totalPinResultsCount, setTotalPinnedCount] = useState<number>(0);
  const [searchParametersValue, setSearchParametersValue] = useState<any>({});
  const [isDrawerOpenUpload, setIsDrawerOpenUpload] = useState<boolean>(false);
  const [isOpenDropdownSiteSummary, setIsOpenDropdownSiteSummary] =
    useState(false);
  const [isAlarmsSitesRequired, setIsAlarmsSitesRequired] =
    useState<boolean>(false);
  const [selectTableOption, setSelectTableOption] =
    useState<string>("Table-view-1");
  const [isRequiredPinnedSitesOnly, setIsRequiredPinnedSitesOnly] =
    useState<boolean>(false);
  const [isOpenDropdownBreadcrumb, setIsOpenDropdownBreadcrumb] =
    useState<boolean>(false);
  const [excelToken, setExcelToken] = useState<any>("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  document.title = "CIA - Dashboard";

  const { groupId, groupType, isAmpGroup, tabActive } = useSelector(
    (state: RootState) => state.GroupSitesAssets
  );

  const {
    siteGroupDetails,
    customLevel1Details,
    customLevel2Details,
    customLevel3Details,
    renderSiteSummaryAPI,
    customerGroupDetails,
    noSiteClearForSearch,
  } = useSelector((state: RootState) => state.RoutesAndAssets);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await getUserPreferenceData();
        await fetchHeaderData(selectTableOption, userID);
        await getFilterColumnsPageSummary();
        if (!groupId) await getAmpDefaultGroupAndSites();
        dispatch(setRenderSiteSummaryAPI());
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    dispatch(setTabActive(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchAllSitesSummary();
      if (tabActive === 2) dispatch(setTabActive(0));
      dispatch(clearRenderSiteSummaryAPI());
    };
    if (renderSiteSummaryAPI) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderSiteSummaryAPI]);

  useEffect(() => {
    if (groupId && searchFreeText && searchFreeText.endsWith(",")) {
      fetchAllSitesSummary(selectTableOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFreeText]);

  useEffect(() => {
    defaultSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allColumn]);

  const getUserPreferenceData = async () => {
    setIsLoading(true);
    try {
      const response = await getUserPreference(userID);
      if (response.statusCode === 200 && response.success) {
        setSearchParametersValue(response?.data?.searchParams);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getFilterColumnsPageSummary = async (selected?: string) => {
    setIsLoading(true);
    try {
      const response = await getSummaryPageFilterColumns(
        userID,
        selected ?? selectTableOption
      );
      if (response.statusCode === 200 && response.success)
        setColumnsFilter(response.data);
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAmpDefaultGroupAndSites = async () => {
    setIsLoading(true);
    try {
      const response = await getDefaultAmpGroupAndSites(true);
      if (response.statusCode === 200 && response.success) {
        dispatch(clearCustomerId());
        dispatch(clearCustomerGroupDetails());

        dispatch(clearSiteId());
        dispatch(clearSiteGroupDetails());

        dispatch(clearTankId());
        dispatch(clearTankGroupDetails());

        dispatch(clearCustomLevel1Id());
        dispatch(clearCustomLevel1GroupDetails());

        dispatch(clearCustomLevel2Id());
        dispatch(clearCustomLevel2GroupDetails());

        dispatch(clearCustomLevel3Id());
        dispatch(clearCustomLevel3GroupDetails());

        dispatch(setGroupId(response?.data[1]?.id));
        dispatch(setCustomerId(response?.data[1]?.id));
        dispatch(setCustomerGroupDetails(response?.data[1]));
        dispatch(setIsAmpGroup(response?.data[1]?.isAmpGroup));
        dispatch(setGroupType(response?.data[1]?.ampGroupType));
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllSitesSummary = async (selectedTableOption?: string) => {
    setIsLoading(true);
    try {
      const request: any = {
        userId: userID,
        groupId: groupId,
        tableName: selectedTableOption
          ? selectedTableOption
          : selectTableOption,
        searchParameters: searchParametersValue,
        pageSize: pageSize,
        pageNumber: page,
        isFirstTimeLogin: false,
        dataType: "string",
        freeText: searchFreeText.replaceAll(",", ""),
        // freeText: selectedTableOption ? "" : searchFreeText.replaceAll(",", ""),
        isRequiredPinnedSitesOnly: isRequiredPinnedSitesOnly,
        isAlarmsSitesRequired: isAlarmsSitesRequired,
        IsExportToExcel: false,
        isAmpGroup: isAmpGroup,
        groupType: ["Custom1", "Custom2", "Custom3"].includes(groupType)
          ? "Custom"
          : groupType,
        sortOrder: sortOrder,
        sortBy: sortBy,
      };
      const response: any = await getFilterColumns(request);
      if (response.statusCode === 200 && response.success) {
        setData(response.data.results);
        setPage(response.data.pageNumber);
        setTotalPages(response.data.totalPages);
        setTotalSitesCount(response.data.totalResultsCount);
        setTotalPinnedCount(response.data.totalPinResultsCount);
        dispatch(setTableRecords(response.data.results));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveTableData = async (tableView: any) => {
    setIsLoading(true);
    try {
      const transformedData = tableView.map(
        (item: { id: any }, index: number) => ({
          lookUpId: String(item.id),
          sortOrder: String(index),
          tableViewGroup: selectTableOption,
        })
      );
      const request: any = {
        userId: userID,
        tableView: transformedData,
      };
      const response: any = await addUserPreference(request);
      if (response.statusCode === 200 && response.success) {
        await fetchHeaderData(selectTableOption, userID);
        await fetchAllSitesSummary(selectTableOption);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveTank = (siteId: string) => {
    dispatch(setTest());
    dispatch(setSiteId(siteId));
    dispatch(setTankId(siteId));
    if (!noSiteClearForSearch) {
      dispatch(clearSiteId());
      dispatch(clearSiteGroupDetails());
    }
    navigate("/site-details");
  };

  const handlePinnedTab = async (
    isPinned: boolean,
    tankId: string,
    groupId: string,
    records: any,
    tabActive: number
  ) => {
    setIsLoading(true);
    try {
      const duplicateRecords = JSON.parse(JSON.stringify(records));
      const request = {
        userId: userID,
        pinSitesId: [
          {
            siteId: tankId,
            groupId: groupId,
            isDefaultGroup: true,
            isPinned: isPinned,
          },
        ],
        selectedTable: String(selectTableOption),
      };
      const response: any = await addUserPreference(request);
      if (response.statusCode === 200 && response.success) {
        const findIndexRecord = duplicateRecords.findIndex(
          (item: { siteId: string }) => item?.siteId === tankId
        );
        if (findIndexRecord !== -1) {
          if (tabActive !== 1)
            duplicateRecords[findIndexRecord].isPinned = isPinned;
          else duplicateRecords.splice(findIndexRecord, 1);
          setData(duplicateRecords);
          dispatch(setTableRecords(duplicateRecords));
          setTotalPinnedCount(
            response?.data?.newPinSitesCount ?? totalPinResultsCount
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHeaderData = async (tableView: string, userId: string) => {
    setIsLoading(true);
    const columns: any = [];
    const allColumn: any = [];

    const renderers = {
      sitename: (record: {
        siteId: string;
        alarm: boolean;
        product: string;
        sitename: string;
      }) => (
        <SiteNameComponent
          tankId={record.siteId}
          alarm={record.alarm}
          tankName={record.sitename}
          product={record.product}
          handleSaveTank={handleSaveTank}
        />
      ),
      usage: (value: any) => <>{Number(value).toFixed(2)} QPD</>,
      currentVoltage: (value: any) => <>{Number(value).toFixed(2)} V</>,
      targetDosage: (value: any) => <>{Number(value).toFixed(2)} PPM</>,
      last30dTankLevel: (record: {
        last30dTankLevel: any[];
        siteId: string;
      }) => (
        <LineChart
          data={record?.last30dTankLevel || []}
          chartId={`chart_${record.siteId}`}
          strokeColor={"#fa6020"}
          fillColor={"#253240"}
          xAxisLabel={true}
          yAxisLabel={true}
          height={"80px"}
          width={"200px"}
        />
      ),
      alerts: (record: { alerts: { alert: string }[] }) => (
        <div className="flex justify-between">
          {record?.alerts?.length > 0 &&
            record.alerts.map((item: { alert: string }) => (
              <div className="badge badge-red">{item?.alert}</div>
            ))}
        </div>
      ),
      lastCommunication: (record: { lastCommunication: string; alarm: boolean }) => {
        const currentTime = new Date().getTime();
        const lastCommunicationTime = new Date(record?.lastCommunication).getTime();
        const timeDiff = currentTime - lastCommunicationTime;
      
        const getBadgeColor = () => {
          const oneHour = 60 * 60 * 1000; 
          const oneDay = 24 * oneHour;
      
          if (timeDiff < oneHour) {
            return "green";
          } else if (timeDiff >= oneHour && timeDiff < oneDay) {
            return "yellow"; 
          } else {
            return "red";
          }
        };
      
        return (
          <>
            {record?.lastCommunication && (
              <div
                className={`badge badge-${getBadgeColor()} flex items-center`}
              >
                {record?.alarm && (
                  <span className="w-[8px] h-[8px] bg-error-400 rounded-full mr-2" />
                )}
                <span>{timeDifference(record?.lastCommunication)}</span>
              </div>
            )}
          </>
        );
      },
      offlineSince: (record: { offlineSince: any; alarm: boolean }) => {
        const currentTime = new Date().getTime();
        const offlineSinceTime = new Date(record?.offlineSince).getTime();
        const timeDiff = currentTime - offlineSinceTime;
      
        const getBadgeColor = () => {
          const oneHour = 60 * 60 * 1000; 
          const oneDay = 24 * oneHour;
      
          if (timeDiff < oneHour) {
            return "badge-green";
          } else if (timeDiff >= oneHour && timeDiff < oneDay) {
            return "badge-yellow";
          } else {
            return "badge-red";
          }
        };
      
        return (
          <>
            {record?.offlineSince && record?.offlineSince !== 0 ? (
              <div className={`badge ${getBadgeColor()}`}>
                {timeDifference(record?.offlineSince)}
              </div>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    try {
      const response: any = await getHeaders(tableView);

      if (response.statusCode === 200 && response.success) {
        response.data.forEach((item: any) => {
          const commonProps = {
            title: item.displayLabel,
            dataIndex: item.key,
            sortable: true,
            key: item.key,
            id: item._id,
          };

          if (item.isSelected) {
            if (item.key === "sitename") {
              columns.push({
                ...commonProps,
                width: "20%",
                render: (
                  _: any,
                  record: {
                    siteId: string;
                    alarm: boolean;
                    product: string;
                    sitename: string;
                  }
                ) => renderers.sitename(record),
              });
            } else if (
              ["usage3Days", "usage7Days", "usageYesterday"].includes(item.key)
            ) {
              columns.push({
                ...commonProps,
                width: "10%",
                render: (value: any) => renderers.usage(value),
              });
            } else if (item.key === "currentVoltage") {
              columns.push({
                ...commonProps,
                width: "10%",
                render: (value: any) => renderers.currentVoltage(value),
              });
            }
            else if (item.key === "targetDosage") {
              columns.push({
                ...commonProps,
                width: "10%",
                render: (value: any) => renderers.targetDosage(value),
              });
            }
            else if (item.key === "last30dTankLevel") {
              columns.push({
                ...commonProps,
                width: "15%",
                render: (
                  _: any,
                  record: { last30dTankLevel: any[]; siteId: string }
                ) => renderers.last30dTankLevel(record),
              });
            } else if (item.key === "alerts") {
              columns.push({
                ...commonProps,
                width: "26%",
                render: (_: any, record: { alerts: { alert: string }[] }) =>
                  renderers.alerts(record),
              });
            } else if (item.key === "lastCommunication") {
              columns.push({
                ...commonProps,
                render: (
                  _: any,
                  record: { lastCommunication: string; alarm: boolean }
                ) => renderers.lastCommunication(record),
              });
            } else if (item.key === "offlineSince") {
              columns.push({
                ...commonProps,
                width: "18%",
                render: (
                  _: any,
                  record: { offlineSince: any; alarm: boolean }
                ) => renderers.offlineSince(record),
              });
            } else {
              columns.push(commonProps);
            }
          }
          allColumn.push({
            ...commonProps,
            isChecked: item.isSelected,
            order: item.order,
          });
        });

        columns.unshift({
          id: "",
          title: "",
          key: "icons",
          width: "5%",
          sortable: false,
          dataIndex: "icons",
          render: (
            _: any,
            record: {
              isPinned: boolean;
              latitude: number;
              longitude: number;
              siteId: string;
              sitename: string;
            }
          ) => (
            <IconsComponents
              isPinned={record.isPinned}
              latitude={record.latitude}
              longitude={record.longitude}
              tankId={record.siteId}
              tankName={record.sitename}
              handleOpenMapsTab={handleOpenMapsTab}
              handlePinnedTab={handlePinnedTab}
            />
          ),
        });
        setColumns(columns);
        setAllColumn(allColumn);
        setCheckboxes(allColumn);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const defaultSet = () => {
    if (isDrawerOpen) setIsDrawerOpen(false);
    const duplicateData: any[] = [];
    const dupliacteHeaderData: any[] = [];
    setDynamicColumnsAll(
      allColumn
        .filter((item: { isChecked: boolean }) => item.isChecked)
        .concat(
          allColumn.filter((item: { isChecked: boolean }) => !item.isChecked)
        )
    );
    allColumn.forEach(
      (
        item: {
          id: any;
          order: any;
          isChecked: boolean;
          title: string;
        },
        index: number
      ) => {
        dupliacteHeaderData.push({
          title: item.title,
          isChecked: item.isChecked,
          onChange: () => handleCheckboxChange(!item.isChecked, index),
          id: item.id,
          order: item.order,
        });
        if (item.isChecked) {
          duplicateData.push(item);
        }
      }
    );
    setCheckboxes(dupliacteHeaderData);
    setDuplicateCheckboxes(dupliacteHeaderData);
  };

  const handleOpenMapsTab = (
    latitude: number,
    longitude: number,
    tankName: string,
    tankId: string
  ) => {
    if (latitude && longitude) {
      const mapsTabIndex = tabs.findIndex((tab) => tab.label === "Maps");
      if (mapsTabIndex !== -1) {
        dispatch(setTabActive(mapsTabIndex));
        setMapData([
          {
            id: tankId,
            lat: Number(latitude),
            lng: Number(longitude),
            name: tankName,
            status: "connected",
          },
        ]);
        setMapCenter({ lat: Number(latitude), lng: Number(longitude) });
      }
    }
  };

  const handleCheckboxChange = (isChecked: boolean, index: number): void => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].isChecked = isChecked;
    setCheckboxes(newCheckboxes);
  };

  const updateHeaders = (values: any, dummyValues: any) => {
    values.forEach((item: any, index: number) => {
      item.order = index + 1;
      dummyValues[index].order = index + 1;
    });
    setCheckboxes(values);
    setDynamicColumnsAll(dummyValues);
  };

  const handleSerach = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value) {
      const duplicate = [...allColumn];
      const filterData = duplicate.filter((item: { title: string }) =>
        item.title.toLowerCase().startsWith(value.toLocaleLowerCase())
      );
      setCheckboxes(filterData);
    } else {
      setCheckboxes(
        duplicateCheckboxes
          .filter((item: { isDefault: boolean }) => item.isDefault)
          .concat(
            duplicateCheckboxes.filter(
              (item: { isDefault: boolean }) => !item.isDefault
            )
          )
      );
    }
    setSearchValue(value);
  };

  const updateIsChecked = (tableColumns: any[], configItems: any[]): any[] => {
    return tableColumns.map((column) => {
      const matchingConfigItem = configItems.find(
        (configItem) => configItem.title === column.title
      );
      return matchingConfigItem
        ? { ...column, isChecked: matchingConfigItem.isDefault }
        : column;
    });
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    const updatedTableColumns = updateIsChecked(dynamicColumnsAll, checkboxes);
    setDynamicColumnsAll(
      updatedTableColumns
        .filter((item: { isDefault: boolean }) => item.isDefault)
        .concat(
          updatedTableColumns.filter(
            (item: { isDefault: boolean }) => !item.isDefault
          )
        )
    );
    saveTableData(
      checkboxes.filter(
        (item: { isChecked: boolean }) => item.isChecked === true
      )
    );
    handleDrawer();
  };

  const handleDrawer = () => {
    if (!isDrawerOpen) {
      duplicateCheckboxes.sort(
        (a: { isChecked: any }, b: { isChecked: any }) => {
          if (a.isChecked && !b.isChecked) {
            return -1; // a comes before b
          } else if (!a.isChecked && b.isChecked) {
            return 1; // b comes before a
          } else {
            return 0; // no change in order
          }
        }
      );
      const duplicate = [...duplicateCheckboxes];
      setCheckboxes(
        duplicate
          .filter((item: { isDefault: boolean }) => item.isDefault)
          .concat(
            duplicate.filter((item: { isDefault: boolean }) => !item.isDefault)
          )
      );
      setSearchValue("");
    }
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleDrawerUpload = () => {
    if (isDrawerOpenUpload) {
      setIsDrawerOpenUpload(false);
    } else {
      setIsDrawerOpenUpload(true);
    }
  };

  const onChange = async (searchParameters: any, freeText?: string) => {
    if (freeText === "") setSearchFreeText("");
    setSearchParametersValue(searchParameters);
    dispatch(setRenderSiteSummaryAPI());
  };

  const handlePageNumberChange = (pageNumber: number) => {
    setPage(pageNumber);
    dispatch(setRenderSiteSummaryAPI());
  };

  const handleSortChange = (order: string, title: string) => {
    setSortBy(title);
    setSortOrder(order);
    dispatch(setRenderSiteSummaryAPI());
  };

  const handleTabChange = async (index: number) => {
    if (index === 1) {
      setIsRequiredPinnedSitesOnly(true);
    } else {
      setIsRequiredPinnedSitesOnly(false);
      if (index === 2 && data.length > 0) {
        let tanksIdCsv: string = "";
        const mapRecords: {
          id: number;
          lat: number;
          lng: number;
          name: string;
          status?: string;
        }[] = [];
        data.forEach(
          (record: {
            siteId: number;
            latitude: number;
            longitude: number;
            sitename: string;
          }) => {
            if (record.latitude && record.longitude) {
              tanksIdCsv = `${tanksIdCsv}${tanksIdCsv ? "," : ""}${
                record.siteId
              }`;
              mapRecords.push({
                id: record.siteId,
                lat: Number(record.latitude),
                lng: Number(record.longitude),
                name: record.sitename,
              });
            }
          }
        );
        if (tanksIdCsv) {
          setIsLoading(true);
          try {
            const response: any = await getTanksStatus(tanksIdCsv);
            if (response?.success && response?.statusCode === 200) {
              response?.data.forEach(
                (item: { tankId: string; connected: boolean }) => {
                  const findRecord = mapRecords.findIndex(
                    (record: any) => record?.id === item?.tankId
                  );
                  if (findRecord !== -1) {
                    mapRecords[findRecord].status = item.connected
                      ? "connected"
                      : "disconnected";
                  }
                }
              );
            }
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }
        setMapData(mapRecords);
        if (mapRecords.length > 0 && mapRecords[0]?.lat && mapRecords[0].lng) {
          setMapCenter({ lat: mapRecords[0].lat, lng: mapRecords[0].lng });
        }
      }
    }
    setPage(1);
    dispatch(setTabActive(index));
    if (index !== 2) dispatch(setRenderSiteSummaryAPI());
  };

  const handleViewAlaram = () => {
    setIsAlarmsSitesRequired(!isAlarmsSitesRequired);
    dispatch(setRenderSiteSummaryAPI());
  };

  const getValuesByKey = async (columnKey: any) => {
    try {
      const response = await getFilterColumnsValue(
        userID,
        columnKey,
        groupType,
        groupId
      );
      if (response.statusCode === 200 && response.success) return response.data;
    } catch (error) {}
  };

  // const handleSearchBarSave = async (e: any) => {
  //   e.preventDefault();
  //   try {
  //     const request: any = {
  //       userId: userID,
  //       selectedTable: selectTableOption,
  //       freeText: searchFreeText,
  //       searchParams: searchParametersValue,
  //     };
  //     const response: any = await addUserPreference(request);
  //     if (response.statusCode === 200 && response.success)
  //       fetchAllSitesSummary(selectTableOption);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleSelect = async (selectedOption: string) => {
    // await setSearchFreeText("");
    setSelectTableOption(selectedOption);
    await fetchHeaderData(selectedOption, userID);
    await getFilterColumnsPageSummary(selectedOption);
    await fetchAllSitesSummary(selectedOption);
  };

  const handleSelectIconDropdown = (selectedOption: string) => {
    if (selectedOption === "setPointOptimization") {
      navigate("/setpoint-optimization");
    } else if (selectedOption === "uploadProductionData") {
      setIsDrawerOpenUpload(true);
    } else if (selectedOption === "export") {
      getSitesSummaryExport();
    } else {
      setIsDrawerOpenUpload(false); // Close the drawer for other options
    }
  };

  const getSitesSummaryExport = async () => {
    try {
      setIsLoading(true);
      const request: any = {
        userId: userID,
        groupId: groupId,
        tableName: selectTableOption,
        searchParameters: searchParametersValue,
        pageSize: pageSize,
        pageNumber: page,
        isFirstTimeLogin: false,
        dataType: "string",
        freeText: searchFreeText.replaceAll(",", ""),
        isRequiredPinnedSitesOnly: false,
        isAlarmsSitesRequired: false,
        IsExportToExcel: true,
        isAmpGroup: isAmpGroup,
        groupType: ["Custom1", "Custom2", "Custom3"].includes(groupType)
          ? "Custom"
          : groupType,
        sortOrder: sortOrder,
        sortBy: sortBy,
      };
      const response: any = await getSitesSummaryExportToExcel(request);
      if (response.statusCode === 200 && response.success) {
        downloadExcelFile(response.data.base64String);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const tabs = [
    {
      label: "Sites",
      badgeValue: totalSitesCount,
      badge: true,
      content: (
        <>
          <div className="flex items-center justify-between gap-[8px] shrink-0 mb-[20px]">
            <SearchBar
              handleGet={onChange}
              headers={columnsFilter}
              getValuesByKey={getValuesByKey}
              setFreeText={setSearchFreeText}
              freeText={searchFreeText}
              handleSet={searchParametersValue}
            />
            {/* <div className='flex gap-[8px] items-center shrink-0'>
              <button
                className='py-[10px] px-[16px] text-[14px] flex justify-center items-center rounded-[8px] border-darkcolor-400 border-[1px] border-solid bg-darkcolor-700 focus:outline-none text-neutral-25'
                onClick={getUserPreferenceData}
              >
                Discard
              </button>
              <button
                className='py-[10px] px-[16px] text-[14px] flex justify-center items-center rounded-[8px] border-darkcolor-300 border-[1px] border-solid bg-primary-600 hover:bg-primary-700 focus:outline-none text-neutral-10'
                onClick={handleSearchBarSave}
              >
                Save
              </button>
            </div> */}
          </div>
          <Table
            data={data}
            columns={columns}
            handlePageNumberChange={handlePageNumberChange}
            page={page}
            pagniation={true}
            totalPages={totalPages}
            handleSortChange={handleSortChange}
          />
        </>
      ),
    },
    {
      label: "Pinned",
      badgeValue: totalPinResultsCount,
      badge: true,
      content: (
        <>
          <div className="flex items-center justify-between gap-[8px] shrink-0 mb-[20px]">
            <SearchBar
              handleGet={onChange}
              headers={columnsFilter}
              getValuesByKey={getValuesByKey}
              setFreeText={setSearchFreeText}
              freeText={searchFreeText}
            />
            {/* <div className='flex gap-[8px] items-center shrink-0'>
              <button
                className='py-[10px] px-[16px] text-[14px] flex justify-center items-center rounded-[8px] border-darkcolor-400 border-[1px] border-solid bg-darkcolor-700 focus:outline-none text-neutral-25'
                onClick={getUserPreferenceData}
              >
                Discard
              </button>
              <button className='py-[10px] px-[16px] text-[14px] flex justify-center items-center rounded-[8px] border-darkcolor-300 border-[1px] border-solid bg-primary-600 hover:bg-primary-700 focus:outline-none text-neutral-10 '>
                Save
              </button>
            </div> */}
          </div>
          <Table
            data={data}
            columns={columns}
            handlePageNumberChange={handlePageNumberChange}
            page={page}
            pagniation={true}
            totalPages={totalPages}
          />
        </>
      ),
    },
    {
      label: "Maps",
      badge: false,
      content: (
        <>
          {mapData.length > 0 ? (
            <Map center={mapCenter} zoom={10} locations={mapData} />
          ) : (
            <div className="flex flex-col items-center justify-center h-[500px] border-[1px] rounded-[4px] border-darkcolor-400">
              <div className="text-neutral-25 mb-4">
                <img
                  src={noDataIcon}
                  alt="SiteName"
                  className="object-contain bg-img"
                />
              </div>
              <span className="text-neutral-25 title">No data found</span>
            </div>
          )}
        </>
      ),
    },
  ];

  const convertToXLSX = (data: any) => {
    try {
      const blob = new Blob([data]);
      FileSaver.saveAs(blob, "Template.xlsx");
    } catch (error) {
      console.error("Error converting to Excel:", error);
    }
  };

  const handleExcelDownload = async () => {
    try {
      const response = await getToken();
      if (response.statusCode === 200 && response.success) {
        const KEY_VAULT_URL = response.data.ampSiteManagementApiSiteUrlBase;
        const KEY_VAULT_EMAIL = response.data.ampSiteManagementApiEmail;
        const KEY_VAULT_PASSWORD = response.data.ampSiteManagementApiPassword;
        getUpdateToken(KEY_VAULT_URL, KEY_VAULT_EMAIL, KEY_VAULT_PASSWORD);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUpdateToken = async (
    KEY_VAULT_URL: any,
    KEY_VAULT_EMAIL: any,
    KEY_VAULT_PASSWORD: any
  ) => {
    try {
      setIsLoading(true);
      const response = await updateToken(
        KEY_VAULT_URL,
        KEY_VAULT_EMAIL,
        KEY_VAULT_PASSWORD
      );
      if (response.status === 200 && response.statusText === "OK") {
        if (groupType === "Site") {
          setExcelToken(response.data.access_token);
          const responseExcelDownload = await excelDownload(
            response.data.access_token,
            groupId
          );
          convertToXLSX(responseExcelDownload);
        } else {
          toast.error("Please select a site to download the template");
        }
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectOnBreadcrumbDropdown = (option: any) => {
    dispatch(setGroupId(option?.id));
    dispatch(setIsAmpGroup(option?.isAmpGroup));
    dispatch(setGroupType(option?.ampGroupType));
    dispatch(setRenderSiteSummaryAPI());
  };

  const toggleDropdown = () =>
    setIsOpenDropdownSiteSummary(!isOpenDropdownSiteSummary);

  const toggleDropdownBreadcrumb = () =>
    setIsOpenDropdownBreadcrumb(!isOpenDropdownBreadcrumb);

  const handleOnClickCustomer = (customer: any) => {
    dispatch(clearSiteId());
    dispatch(clearSiteGroupDetails());

    dispatch(setGroupId(customer?.id));
    dispatch(setIsAmpGroup(customer.isAmpGroup));
    dispatch(setGroupType(customer.ampGroupType));

    dispatch(setCustomerId(customer?.id));
    dispatch(setCustomerGroupDetails(customer));

    dispatch(setRenderSiteSummaryAPI());
  };

  const handleOnClickCustomLevel1 = (custom: any) => {
    dispatch(clearCustomLevel2Id());
    dispatch(clearCustomLevel2GroupDetails());

    dispatch(setGroupId(custom?.id));
    dispatch(setIsAmpGroup(custom.isAmpGroup));
    dispatch(setGroupType(custom.ampGroupType));

    dispatch(setCustomLevel1Id(custom?.id));
    dispatch(setCustomLevel1GroupDetails(custom));

    dispatch(setRenderSiteSummaryAPI());
  };

  const handleOnClickCustomLevel2 = (custom: any) => {
    dispatch(clearCustomLevel3Id());
    dispatch(clearCustomLevel3GroupDetails());

    dispatch(setGroupId(custom?.id));
    dispatch(setIsAmpGroup(custom.isAmpGroup));
    dispatch(setGroupType(custom.ampGroupType));

    dispatch(setCustomLevel2Id(custom?.id));
    dispatch(setCustomLevel2GroupDetails(custom));

    dispatch(setRenderSiteSummaryAPI());
  };

  return (
    <div className="bg-darkcolor-800 content-wrapper">
      <Loading isLoading={isLoading} />
      <header
        className={`flex bg-darkcolor-700 text-white px-[15px] xl:px-[32px] py-[12px]`}
      >
        <div className="flex flex-wrap items-center breadcrumb-textblock">
          {customerGroupDetails?.isAmpGroup ? (
            <>
              <label
                className="text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer"
                onClick={() =>
                  Object.keys(siteGroupDetails).length > 0 &&
                  handleOnClickCustomer(customerGroupDetails)
                }
              >
                {customerGroupDetails?.groupName}
              </label>
              {Object.keys(siteGroupDetails).length > 0 && (
                <>
                  <label className="text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline">
                    &nbsp;\&nbsp;
                  </label>
                  <BreadcrumbDropdown
                    options={customerGroupDetails?.childGroup}
                    onSelect={handleSelectOnBreadcrumbDropdown}
                    bgColor="bg-transparent"
                    selectedOption={groupId}
                    isOpen={isOpenDropdownBreadcrumb}
                    onToggle={toggleDropdownBreadcrumb}
                    width="320px"
                  />
                </>
              )}
            </>
          ) : (
            <>
              <label
                className="text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer"
                onClick={() =>
                  Object.keys(customLevel2Details).length > 0 &&
                  handleOnClickCustomLevel1(customLevel1Details)
                }
              >
                {customLevel1Details?.groupName}
              </label>
              {Object.keys(customLevel2Details).length > 0 && (
                <>
                  <label className="text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline">
                    &nbsp;\&nbsp;
                  </label>
                  {Object.keys(customLevel3Details).length > 0 ? (
                    <>
                      <label
                        className="text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer"
                        onClick={() =>
                          Object.keys(customLevel3Details).length > 0 &&
                          handleOnClickCustomLevel2(customLevel2Details)
                        }
                      >
                        {customLevel2Details?.groupName}
                      </label>
                      <label className="text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline">
                        &nbsp;\&nbsp;
                      </label>
                      <BreadcrumbDropdown
                        options={customLevel2Details?.childGroup}
                        onSelect={handleSelectOnBreadcrumbDropdown}
                        bgColor="bg-transparent"
                        selectedOption={groupId}
                        isOpen={isOpenDropdownBreadcrumb}
                        onToggle={toggleDropdownBreadcrumb}
                        width="320px"
                      />
                    </>
                  ) : (
                    <BreadcrumbDropdown
                      options={customLevel1Details?.childGroup}
                      onSelect={handleSelectOnBreadcrumbDropdown}
                      bgColor="bg-transparent"
                      selectedOption={groupId}
                      isOpen={isOpenDropdownBreadcrumb}
                      onToggle={toggleDropdownBreadcrumb}
                      width="320px"
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </header>
      <hr className="w-full h-[2px] mx-auto my-0 bg-darkcolor-300 border-0 rounded md:my-0 dark:bg-darkcolor-300" />

      <div className="flex relative items-center justify-center summary-content">
        <Tabs
          tabs={tabs}
          activeTab={tabActive}
          onChange={handleTabChange}
          className="bg-darkcolor-700"
        />
        <div className="absolute top-[7px] right-0 summary-header-icons">
          <div className="flex justify-center items-center header-icons-inner">
            <div className="flex items-center">
              <div className="relative cursor-pointer mr-[16px]">
                <div className="absolute right-[-2px] top-[-1px] transform translate-x-1/2 -translate-y-1/2 w-[8px] h-[8px] bg-error-500 rounded-full" />
                <Tooltip content={"Alerts"} direction="top">
                  <Alerts
                    onClick={handleViewAlaram}
                    color={isAlarmsSitesRequired ? "#0094BD" : "#F7F9F9"}
                  />
                </Tooltip>
              </div>
              <div className="mr-[16px] cursor-pointer">
                <Tooltip content={"Site controls"} direction="top">
                  <IconDropdown
                    options={iconDropdownOptions}
                    onSelect={handleSelectIconDropdown}
                    optionHeading="Site controls"
                    dropdownIcon={true}
                    bgColor="bg-darkcolor-800"
                    width="320px"
                  />
                </Tooltip>
              </div>
              <div className="w-[1px] top-[-6px] h-[35px] bg-neutral-25 mr-[8px]" />
              <div className="mr-[16px] cursor-pointer">
                <Tooltip content={"Customize table"} direction="top">
                  <TableColumns
                    onClick={handleDrawer}
                    color={isDrawerOpen ? "#0094BD" : "#F7F9F9"}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="mr-[15px] xl:mr-[30px] siteSummaryDropdown">
              <SiteSummaryDropdown
                options={dropdownOptions}
                onSelect={handleSelect}
                optionHeading="Views"
                dropdownIcon={true}
                bgColor="bg-darkcolor-800"
                selectedOption={selectTableOption}
                isOpen={isOpenDropdownSiteSummary}
                onToggle={toggleDropdown}
                width="320px"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-[100px]" />
      <Drawer
        isOpen={isDrawerOpen}
        onClose={handleDrawer}
        title="Customize table"
        width="416px"
      >
        <div className="flex flex-col h-[90vh]">
          <div className="px-[16px]">
            <SearchbarDrawer
              onChange={handleSerach}
              placeholder={"search column name"}
              value={searchValue}
            />
            <div className="mb-[20px]" />
            <TableCheckbox
              checkboxes={checkboxes}
              updateHeaders={updateHeaders}
              onChange={handleCheckboxChange}
              dummyData={dynamicColumnsAll}
            />
          </div>
          <div className="border-t-darkcolor-400 border-t-[1px] py-[16px]">
            <div className="flex justify-between px-[16px]">
              <div className="flex items-center" onClick={defaultSet}>
                <label className="text-[14px] text-primary-500 font-bold cursor-pointer">
                  Set as default
                </label>
                <FaGreaterThan className="ml-[2px] text-primary-500" size={8} />
              </div>
              <div className="flex justify-end space-x-2 ">
                <button
                  onClick={handleDrawer}
                  className="px-4 py-2 text-neutral-25 text-[14px] rounded-[8px] mr-2 border border-neutral-25"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="px-4 py-2 text-neutral-25 text-[14px] border border-primary-700 rounded-[8px] bg-primary-600"
                  style={{
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        isOpen={isDrawerOpenUpload}
        onClose={handleDrawerUpload}
        title="Upload Data"
        width="416px"
      >
        <UploadExcel
          handleDrawer={handleDrawerUpload}
          handleExcelDownload={handleExcelDownload}
          convertXLSX={convertToXLSX}
          excelToken={excelToken}
        />
      </Drawer>
    </div>
  );
};

export default SiteSummary;
