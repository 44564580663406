import React from "react";

interface ArrowsToDotProps {
    size?: number | string;
    className?: string;
    onClick?: () => void;
}

const ArrowsToDot: React.FC<ArrowsToDotProps> = ({
    size = 24,
    className,
    onClick,
}) => {
    const style = size ? { width: size, height: size } : undefined;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className={className}
            style={style}
            onClick={onClick}
        >
            <g clipPath='url(#clip0_9109_5031)'>
                <path
                    d='M2.42605 6.67282L5.96159 10.2084M5.96159 10.2084L2.42605 13.7439M5.96159 10.2084L0.833415 10.2084M17.1574 13.7439L13.6219 10.2084M13.6219 10.2084L17.1574 6.67282M13.6219 10.2084L19.1667 10.2084'
                    stroke='#B8C5CC'
                    strokeWidth='1.67'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M13.3272 2.84268L9.79164 6.37821M9.79164 6.37821L6.25611 2.84268M9.79164 6.37821L9.79164 0.833374M6.25611 17.5741L9.79164 14.0385M9.79164 14.0385L13.3272 17.5741M9.79164 14.0385L9.79164 19.1667'
                    stroke='#F7F9F9'
                    strokeWidth='1.67'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <circle
                    cx='9.80225'
                    cy='10.2084'
                    r='1.46576'
                    transform='rotate(45 9.80225 10.2084)'
                    fill='#F7F9F9'
                />
            </g>
            <defs>
                <clipPath id='clip0_9109_5031'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ArrowsToDot;
