import "./Tabs.scss";
import React from "react";
import Badge from "../Badge/Badge";

interface Tab {
  badge: boolean;
  label: string;
  content: React.ReactNode;
  badgeValue?: number | undefined;
  class?: string;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: number;
  onChange: (index: number) => void;
  className?: string;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTab,
  onChange,
  className,
}) => (
  <div className='flex flex-col min-h-[400px] lg:min-h-[450px] 2xl:min-h-[635px] w-full relative'>
    <div
      className={`flex px-[15px] xl:px-[32px] ${className ? className : ""
        }  pt-[5px] tabs-text`}
    >
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`cursor-pointer mr-1 sm:mr-3 lg:mr-4 px-[4px] py-[10px] text-neutral-25 focus:outline-none border-b-2 tabs gap-[8px] ${activeTab === index
            ? "border-secondary-500 text-primary-400"
            : "border-transparent"
            }`}
          onClick={() => onChange(index)}
        >
          <div className='flex items-center'>
            <span className='text-[12px] sm:text-[14px] lg:text-[16px]'>{tab.label}</span>
            {tab.badge && (
              <Badge
                count={tab.badgeValue}
                textColor={"bg-secondary-500"}
                bgColor={"text-neutral-25"}
              />
            )}
          </div>
        </div>
      ))}
    </div>
    <hr className='w-full h-[1px] mx-auto my-0 bg-darkcolor-400 border-0 rounded md:my-0 dark:bg-darkcolor-400 hr-devider' />
    <div className='text-darkcolor-700 p-3 px-[15px] xl:px-[32px] tabs-content flex-grow'>
      {tabs[activeTab]?.content || <></>}
    </div>
  </div>
);

export default Tabs;
