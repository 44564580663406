import React from "react";
import Badge from "../Badge/Badge";
import noDataIcon from "../../../assets/img/icon-noData.svg";

interface DetailsProps {
    title: string;
    badge?: boolean;
    badgeCount?: number;
    details: {
        title: string;
        value: string;
    }[];
}

const Details: React.FC<DetailsProps> = ({
    title,
    badge,
    badgeCount,
    details,
}) => {
    return (
        <div>
            <div className='flex items-center justify-between gap-[16px] pt-5 pb-1 pt-20px px-[24px] border-b-[1px] border-darkcolor-400'>
                <label className='text-neutral-25 text-[18px] leading-[28px]'>{title}</label>
                {badge && (
                    <Badge
                        count={badgeCount}
                        textColor={"bg-secondary-500"}
                        bgColor={"text-neutral-25"}
                    />
                )}
            </div>
            {details.length > 0 ? (
                details.map((detail, index) => (
                    <div
                        key={index}
                        className={`flex justify-between items-center gap-[16px] py-[12px] px-[24px] ${index === details.length - 1
                            ? ""
                            : "border-b-[1px] border-darkcolor-400"
                            }`}
                    >
                        <p className='text-neutral-25 text-[16px] leading-[24px]'>{detail.title}</p>
                        <p className='text-neutral-25 text-[14px] leading-[24px]'>{detail.value}</p>
                    </div>
                ))
            ) : (
                <div className='flex flex-col items-center mb-[10px]'>
                    <div className=' text-neutral-25 mb-4'>
                        <img
                            src={noDataIcon}
                            alt='SiteName'
                            className='object-contain bg-img'
                        />
                    </div>
                    <span className='text-neutral-25 title'>No data found</span>
                </div>
            )}
        </div>
    );
};

export default Details;
