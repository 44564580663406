import React from "react";
import { HiRefresh } from "react-icons/hi";
import "./grid.scss";

interface LeftGridInterface {
    title: string;
    status: string;
    scan: string;
    serial: string;
    image: string;
    details: any;
    intermittent: any;
    pumpDetails: any;
    tankDetails: any;
    voltage: any;
    onClick: any
}

const SiteDetailsLeftGrid: React.FC<LeftGridInterface> = ({
    title,
    status,
    scan,
    serial,
    image,
    details,
    intermittent,
    pumpDetails,
    tankDetails,
    voltage,
    onClick
}) => {
    return (
        <div className='border-[2px] border-darkcolor-400 bg-darkcolor-700 rounded-[8px]'>
            <div className='flex justify-between items-center py-[20px] px-[24px]'>
                <p className='text-neutral-25 text-[18px] leading-[28px]'>{title}</p>
                <HiRefresh className='text-neutral-25 cursor-pointer' size={20} onClick={onClick} />
            </div>
            <hr className='w-full h-[1px] mx-auto my-0 bg-darkcolor-400 border-0 rounded md:my-0 dark:bg-darkcolor-400' />
            <div className='px-[20px] py-[20px] flex gap-[12px] items-center self-stretch info-connect'>
                <label className='bg-primary-400 text-neutral-800 text-size-[14px] font-medium flex px-[16px] py-[8px] rounded-[4px]'>
                    {status}
                </label>
                <label className='bg-primary-400 text-neutral-800 text-size-[14px] font-medium flex px-[16px] py-[8px] rounded-[4px]'>
                    Last scan: {scan}
                </label>
                <label className='bg-primary-400 text-neutral-800 text-size-[14px] font-medium flex px-[16px] py-[8px] rounded-[4px]'>
                    Serial number: {serial}
                </label>
            </div>
            <div className='px-[20px] flex gap-[12px] items-center self-stretch relative'>
                <div className='relative px-[20px] h-full w-full top-0 right-0 info-box'>
                    <img src={image} alt='SiteName' className='object-contain bg-img' />
                    <div className="flex justify-between info-detailBox">
                        <div className="w-1/3 text-left info-controllerpBox">
                            <div className='border-[1px] border-darkcolor-400 rounded-[8px]  py-[4px] px-[8px] bg-darkcolor-800 inline-block info-controllerblock'>
                                <div>
                                    <label className='text-neutral-25 text-[14px] leading-[20px]'>
                                        Details:
                                    </label>
                                </div>
                                <div>
                                    <label className='text-neutral-25 text-[14px] leading-[20px]'>
                                        Controller: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {details?.controller}
                                    </label>
                                </div>
                                {/* <div>
                                    <label className='text-[14px] font-medium leading-[20px] text-neutral-25'>
                                        Model type: &nbsp;
                                    </label>
                                    <label className='text-[14px] font-medium leading-[20px] text-primary-400'>
                                        [data]
                                    </label>
                                </div> */}
                                <div>
                                    <label className='text-neutral-25 text-[14px] leading-[20px]'>
                                        Pertinent mode registers: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {details?.injectionModeRegister}
                                    </label>
                                </div>
                                <div>
                                    <label className='text-neutral-25 text-[14px] leading-[20px]'>
                                        FW version: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {details?.fwVersion}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/3 relative top-[80px] left-[50px] info-setpointBox">
                            <div className='border-[1px] border-darkcolor-400 rounded-[8px]  py-[4px] px-[8px] bg-darkcolor-800 inline-block info-setpointblock'>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25 text-left'>
                                        Intermittent/Real time:
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25 text-left'>
                                        Mode: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {intermittent?.mode}
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Set-point: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {intermittent?.setPoint ? intermittent?.setPoint : "0"} QPD
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/3 relative left-[100px]">
                            {/* <div className='border-[1px] border-darkcolor-400 rounded-[8px]  py-[4px] px-[8px] bg-darkcolor-800 inline-block'>
                                <div>
                                    <label className='text-[14px] font-medium leading-[20px] text-neutral-25'>
                                        Power details: &nbsp;
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] font-medium leading-[20px] text-neutral-25'>
                                        Type: &nbsp;
                                    </label>
                                    <label className='text-[14px] font-medium leading-[20px] text-primary-400'>
                                        Solar
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] font-medium leading-[20px] text-neutral-25'>
                                        Size: &nbsp;
                                    </label>
                                    <label className='text-[14px] font-medium leading-[20px] text-primary-400'>
                                        110 watt
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] font-medium leading-[20px] text-neutral-25'>
                                        # of batteries: &nbsp;
                                    </label>
                                    <label className='text-[14px] font-medium leading-[20px] text-primary-400'>
                                        {" "}
                                        4
                                    </label>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex justify-between relative top-[100px] left-[10px] info-tankpBox">
                        <div className="w-1/3 text-left">
                            <div className='border-[1px] border-darkcolor-400 rounded-[8px]  py-[4px] px-[8px] bg-darkcolor-800 inline-block info-tankblock'>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Tank details:
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Tank fill: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {tankDetails?.tankFill ? tankDetails?.tankFill : "0"} GAL
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Tank type: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {tankDetails?.tankType}
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Chemical: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {tankDetails?.chemical}
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="w-1/3 relative left-[70px] top-[10px] info-voltBox">
                            <div className='border-[1px] border-darkcolor-400 rounded-[8px]  py-[4px] px-[8px] bg-darkcolor-800 inline-block info-voltblock'>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Voltage: &nbsp;
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Current: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {voltage?.current ? voltage.current : "0"} V
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        24hr minimum: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {voltage?.min24Hr} V
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        24hr maximum:  &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {voltage?.max24Hr} V
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className="w-1/3 text-left relative top-[170px] left-[40px] info-pumpBox">
                            <div className='border-[1px] border-darkcolor-400 rounded-[8px]  py-[4px] px-[8px] bg-darkcolor-800 inline-block info-pumpblock'>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Pump details:
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Pump type: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {pumpDetails?.pumpManufacture}
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Motor size: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {pumpDetails?.motorRPM ? pumpDetails?.motorRPM : "0"} RPM
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Plunger size: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {pumpDetails?.plungerSize1}
                                    </label>
                                </div>
                                <div>
                                    <label className='text-[14px] leading-[20px] text-neutral-25'>
                                        Pin position: &nbsp;
                                    </label>
                                    <label className='text-[14px] leading-[20px] text-primary-400'>
                                        {pumpDetails?.pinSettings1}
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SiteDetailsLeftGrid; 
