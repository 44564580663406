interface TreeProps {
    color?: string;
    onClick?: () => void;
    className?: string;
}

const Tree: React.FC<TreeProps> = ({ color, className, onClick }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            className={className}
            onClick={onClick}
        >
            <path
                d='M12 4.08459V15.2846C12 16.9648 12 17.8048 12.327 18.4466C12.6146 19.0111 13.0735 19.47 13.638 19.7576C14.2798 20.0846 15.1198 20.0846 16.8 20.0846H17M17 20.0846C17 21.1892 17.8954 22.0846 19 22.0846C20.1046 22.0846 21 21.1892 21 20.0846C21 18.98 20.1046 18.0846 19 18.0846C17.8954 18.0846 17 18.98 17 20.0846ZM7 4.08459L17 4.08459M7 4.08459C7 5.18916 6.10457 6.08459 5 6.08459C3.89543 6.08459 3 5.18916 3 4.08459C3 2.98003 3.89543 2.08459 5 2.08459C6.10457 2.08459 7 2.98003 7 4.08459ZM17 4.08459C17 5.18916 17.8954 6.08459 19 6.08459C20.1046 6.08459 21 5.18916 21 4.08459C21 2.98003 20.1046 2.08459 19 2.08459C17.8954 2.08459 17 2.98002 17 4.08459ZM12 12.0846H17M17 12.0846C17 13.1892 17.8954 14.0846 19 14.0846C20.1046 14.0846 21 13.1892 21 12.0846C21 10.98 20.1046 10.0846 19 10.0846C17.8954 10.0846 17 10.98 17 12.0846Z'
                stroke={color}
                strokeWidth='2'
                strokeLinecap="round"
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default Tree;
