import { BiChevronRight } from "react-icons/bi";
import Settings from "../../Svg/Settings";
import React, { useState, useEffect, ReactNode, useRef } from "react";

interface IconDropdownProps {
    options: {
        id: number;
        name: string;
        value: string;
        icon?: ReactNode;
    }[];
    onSelect: (selected: string) => void;
    optionHeading: string;
    dropdownIcon: boolean;
    bgColor: string;
    width?: string;
}

const IconDropdown: React.FC<IconDropdownProps> = ({
    width,
    options,
    onSelect,
    optionHeading,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className='relative w-full' ref={dropdownRef}>
            <Settings
                size={18}
                onClick={() => setIsOpen(!isOpen)}
                className='outline-none  justify-between items-center w-full'
                color="#F7F9F9"
            />
            {isOpen && (
                <div
                    className='border-[1px] border-darkcolor-400 origin-top-right absolute z-10 right-0 mt-[3px] w-full rounded-[8px] shadow-lg bg-darkcolor-700'
                    style={{ width: width }}
                >
                    <div className=''>
                        <div className='block px-4 py-2 text-[14px] cursor-auto border-b-[1px] border-darkcolor-400 text-neutral-25 bg-darkcolor-800 rounded-t-[8px]'>
                            {optionHeading}
                        </div>
                        {options.map((option, index) => (
                            <div
                                key={option?.id}
                                className={`text-[16px] p-[10px] text-sm  hover:bg-darkcolor-600 hover:text-neutral-25 cursor-pointer text-neutral-25 flex justify-between bg-darkcolor-800 ${index === options.length - 1 ? "rounded-b-[8px]" : ""
                                    }`}
                                onClick={() => {
                                    onSelect(option.value);
                                    setIsOpen(!isOpen);
                                }}
                            >
                                <div className='flex'>
                                    {option?.icon}
                                    <p className='ml-[8px]'>{option.name}</p>
                                </div>
                                <BiChevronRight size={20} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default IconDropdown;
