import React, { useState } from "react";
import { DateRangePicker as DateRangePickers, RangeKeyDict } from "react-date-range";
import { FaRegCalendar } from "react-icons/fa";
import { addDays } from "date-fns";
import "./DateRangePicker.scss";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRangePickerProps {
    value?: [Date | null, Date | null];
    onChange: (value: [Date | null, Date | null]) => void;
    dateFormat?: string;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const startDate = value?.[0] ?? null;
    const endDate = value?.[1] ?? null;
    const [tempRange, setTempRange] = useState({
        startDate: value?.[0] ?? new Date(),
        endDate: value?.[1] ?? addDays(new Date(), 7)
    });

    const handleSelect = (rangesByKey: RangeKeyDict) => {
        const { selection } = rangesByKey;
        setTempRange({
            startDate: selection.startDate ?? new Date(),
            endDate: selection.endDate ?? addDays(new Date(), 7)
        });
    };

    const applyDates = () => {
        setIsOpen(false);
        onChange([tempRange.startDate, tempRange.endDate]);
    };

    const cancelDates = () => {
        setIsOpen(false);
    };

    const toggleCalendar = () => setIsOpen(!isOpen);

    const CustomInput: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
        <div className="custom-input" onClick={toggleCalendar}>
            <span style={{ flex: "1" }}>
                {startDate && endDate
                    ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                    : "Select dates"}
            </span>
            {startDate !== null && endDate !== null ? (
                <>
                    <span className="inline-flex items-center">
                        <FaRegCalendar />
                    </span>
                </>
            ) : (
                <span className="inline-flex items-center">
                    <FaRegCalendar />
                </span>
            )}
        </div>
    );

    return (
        <div style={{ position: "relative" }}>
            <CustomInput />
            {isOpen && (
                <div className="calendar-container">
                    <DateRangePickers
                        ranges={[
                            {
                                startDate: tempRange.startDate ?? new Date(),
                                endDate: tempRange.endDate ?? addDays(new Date(), 7),
                                key: "selection",
                            },
                        ]}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        direction="horizontal"
                        className="custom-calendar"
                        showMonthAndYearPickers={false}
                    />
                    <div className="buttons">
                        <button onClick={cancelDates} className="px-4 py-2 text-neutral-25 text-[14px] rounded-[8px] mr-2 border border-neutral-25">Cancel</button>
                        <button onClick={applyDates} className="px-4 py-2 text-neutral-25 text-[14px] border border-primary-700 rounded-[8px] bg-primary-600">Apply</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateRangePicker;
