import "./App.scss";
import { useEffect } from "react";
import Login from "./components/Login/Login";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";
import Layout from "./components/common/Layout/Layout";
import { Route, Routes, useNavigate } from "react-router-dom";
import SiteSummary from "./components/SiteSummary/SiteSummary";
import SiteDetails from "./components/SiteDetails/SiteDetails";
import TokenService from "./services/authentication/token.service";
import AddEditDevice from "./components/DeviceManagement/AddEditDevice";
import PageNotFound from "./components/common/PageNotFound/PageNotFound";
import DeviceManagement from "./components/DeviceManagement/DeviceManagement";
import RouteAssetManagement from "./components/RouteAssetManagement/RouteAssetManagement";
import SetPointOptimization from "./components/SetPointOptimization/SetPointOptimization";

const siteLogoutUrl = process.env.REACT_APP_LOGOUT_URL;
const idleTimeInMinutes: number = parseInt(
  process.env.REACT_APP_IDLE_TIME_IN_MINUTES ?? "10"
);

const App = () => {
  const navigate = useNavigate();

  const onIdle = () => {
    TokenService.removeUser();
    if (siteLogoutUrl) window.location.assign(siteLogoutUrl);
    localStorage.clear();
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 480 * idleTimeInMinutes,
    throttle: 500,
  });

  useEffect(() => {
    // to hide the browser back button click
    const handlePopState = (event: any) => {
      event.preventDefault();
      navigate(1);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div>
        <Routes>
          <Route path='/onboarding' element={<Login />}></Route>
          <Route path='/' element={<Layout />}>
            <Route path='/' element={<SiteSummary />} />
            <Route path='/:id' element={<SiteSummary />} />
            <Route path='site-details/' element={<SiteDetails />} />
            <Route path='/device' element={<DeviceManagement />} />
            <Route path='/addeditdevice/:id' element={<AddEditDevice />} />
            <Route path='/addeditdevice' element={<AddEditDevice />} />
            <Route
              path='/setpoint-optimization'
              element={<SetPointOptimization />}
            />
            <Route path='/assetmanagement' element={<RouteAssetManagement />} />
            {/* Broken URL Route */}
            <Route path='/cia/pagenotfound' element={<PageNotFound />} />
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default App;
