import React from "react";

interface Tab {
    label: string;
    content: React.ReactNode;
}

interface TabsProps {
    tabs: Tab[];
    activeTab: number;
    onChange: (index: number) => void;
}

const TabsWithBg: React.FC<TabsProps> = ({ tabs, activeTab, onChange }) => (
    <div className='py-[16px] px-[24px]'>
        <div className='bg-darkcolor-500 border border-darkcolor-700 rounded-[8px] p-[8px] gap-[8px] flex justify-center'>
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`flex-1 text-[16px] font-semibold cursor-pointer flex justify-center px-[24px] py-[8px] text-neutral-25 focus:outline-none tabs gap-[8px] hover:bg-darkcolor-700 rounded-[8px] ${activeTab === index ? "bg-darkcolor-700 text-primary-400" : ""
                        } `}
                    onClick={() => onChange(index)}
                >
                    <span className='text-[16px]'>{tab.label}</span>
                </div>
            ))}
        </div>
        {tabs[activeTab].content}
    </div>
);

export default TabsWithBg;
