import "./Loading.scss";
import React, { useEffect } from 'react';

interface LoadingProps {
  text?: string;
  isLoading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ text, isLoading }) => {
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "";
    }
    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <div className='div-loading'>
          <div className='loader-inner'>
            <div className='loader'></div>
            <div className='div-loading-text'>Loading...</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
