import { RiDeleteBin6Line } from "react-icons/ri";

interface routeAndAssetManagmentCustomGroupsColumnsProps {
    handleDelete: any
}
interface routeAndAssetManagmentCustomGroupsUserAccessColumnsProps {
    handleDeleteUser: any
}

export const routeAndAssetManagmentDefaultGroupsColumns = [
    {
        title: "Site name",
        dataIndex: "siteName",
        key: "SiteName",
        sortable: true,
    },

    {
        title: "Product",
        dataIndex: "product",
        key: "Product",
        sortable: true,
    },
];

export const routeAndAssetManagmentCustomGroupsColumns = ({
    handleDelete,
}: routeAndAssetManagmentCustomGroupsColumnsProps) => [
        {
            title: "Site name",
            dataIndex: "siteName",
            key: "SiteName",
            sortable: true,
        },

        {
            title: "Product",
            dataIndex: "product",
            key: "product",
            sortable: true,
        },
        {
            title: "",
            dataIndex: "icons",
            key: "icons",
            sortable: false,
            width: "90px",
            id: "",
            render: (
                _: string,
                record: {
                    siteId: number;
                }
            ) => {
                return (
                    <div className='flex justify-between items-center'>
                        <RiDeleteBin6Line
                            className='cursor-pointer'
                            size={20}
                            onClick={() => handleDelete(record.siteId)}
                        />
                    </div>
                );
            },
        },
    ];

export const routeAndAssetManagmentCustomGroupsUserAccessColumns = ({
    handleDeleteUser,
}: routeAndAssetManagmentCustomGroupsUserAccessColumnsProps) => [
        {
            title: "User Name",
            dataIndex: "userName",
            key: "userName",
            render: (text: string, record: any) => (
                <div>
                    <div className='text-[16px] font-bold text-gray-25 cursor-pointer'>
                        {record.userName}
                    </div>
                    <div className='text-[14px] text-gray-200 text-sm'>
                        {record.userEmail}
                    </div>
                </div>
            ),
            sortable: true,
        },
        {
            title: "",
            dataIndex: "access",
            key: "access",
            sortable: false,
            render: (text: string, record: any) => (
                <div className='flex justify-between items-center'>
                    {record.access ? (
                        <div className='text-[12px] text-yellow-400 text-sm border border-yellow-400 p-2 rounded'>
                            Does not have access to {record.access} group assets
                        </div>
                    ) : null}
                </div>
            ),
        },

        {
            title: "",
            dataIndex: "icons",
            key: "icons",
            sortable: false,
            width: "90px",
            id: "",
            render: (
                _: string,
                record: {
                    userId: number;
                }
            ) => {
                return (
                    <div className='flex justify-between items-center'>
                        <RiDeleteBin6Line
                            className='cursor-pointer'
                            size={20}
                            onClick={() => handleDeleteUser(record.userId)}
                        />
                    </div>
                );
            },
        },
    ];
