import Arrow from "../Svg/Arrow";
import React, { useEffect, useRef } from "react";

interface BreadcrumbDropdownSiteDetailsProps {
    options: any[];
    onSelect: (selected: any) => void;
    bgColor: string;
    selectedOption: string | null;
    isOpen: boolean;
    onToggle: () => void;
}

const BreadcrumbDropdownSiteDetails: React.FC<
    BreadcrumbDropdownSiteDetailsProps
> = ({ options, onSelect, bgColor, selectedOption, isOpen, onToggle }) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            onToggle();
        }
    };

    const getSelectedOptionName = () => {
        const selected = options.find(
            (option) =>
                option.siteId === selectedOption ||
                option.id === selectedOption ||
                option.siteName === selectedOption
        );
        return selected ? selected.siteName : "Select an option";
    };

    return (
        <div className='relative inline-block text-left' ref={dropdownRef}>
            <div>
                <button
                    type='button'
                    className={`gap-[8px] outline-none inline-flex justify-between items-center w-full px-[14px] py-[8px] border-[1px] border-darkcolor-300 text-[16px] font-medium rounded-[8px] text-neutral-25 ${bgColor} focus:outline-none`}
                    onClick={onToggle}
                >
                    <div className='flex items-center text-[14px]'>
                        {getSelectedOptionName()}
                    </div>
                    <Arrow
                        size={20}
                        className={`transform transition-transform ${isOpen ? "rotate-90" : ""
                            }`}
                    />
                </button>
            </div>

            {isOpen && (
                <div
                    style={{ maxHeight: 300 }}
                    className='border-[1px] border-darkcolor-400 origin-top-right absolute z-10 right-0 mt-[3px] w-full rounded-[8px] shadow-lg bg-darkcolor-700 overflow-y-auto'
                >
                    <div>
                        {options.map((option, index) => (
                            <div
                                key={option?.siteId}
                                className={`text-[16px] p-[10px] text-sm hover:bg-darkcolor-600 hover:text-neutral-25 cursor-pointer text-neutral-25 flex justify-between bg-darkcolor-800 ${index === options.length - 1 ? "rounded-[8px]" : ""
                                    }`}
                                onClick={() => {
                                    onSelect(option);
                                    onToggle();
                                }}
                            >
                                <div className='flex'>
                                    <p className='ml-[8px] text-neutral-25'>{option.siteName}</p>
                                </div>
                                <Arrow size={20} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BreadcrumbDropdownSiteDetails;
