export const TankTableColumns = [
    {
        title: "Measurements",
        dataIndex: "Measurements",
        key: "Measurements",
    },
    {
        title: "Yesterday",
        dataIndex: "Yesterday",
        key: "Yesterday",
        render: (text: any, record: any) => {
            const value = record.Yesterday;
            return record.Measurements === "Pump variance percent" ? `${value} %` : `${value} gal`;
        },
    },
    {
        title: "3 days",
        dataIndex: "ThreeDays",
        key: "ThreeDays",
        render: (text: any, record: any) => {
            const value = record.ThreeDays;
            return record.Measurements === "Pump variance percent" ? `${value} %` : `${value} gal`;
        },

    },
    {
        title: "7 days",
        dataIndex: "SevenDays",
        key: "SevenDays",
        render: (text: any, record: any) => {
            const value = record.SevenDays;
            return record.Measurements === "Pump variance percent" ? `${value} %` : `${value} gal`;
        },
    },
];
