import { RiDownloadLine } from "react-icons/ri";
import Drawer from "../../common/Drawer/Drawer";
import Toggle from "../../common/Toggle/Toggle";
import Table from "../../common/Table/CustomTable";
import Loading from "../../common/Loading/Loading";
import React, { useEffect, useState } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosSearch, IoMdClose } from "react-icons/io";
import TextInput from "../../common/DeviceForm/TextInput";
import RadioButton from "../../common/RadioButton/RadioButton";
import { dropdownCoditionOptions } from "../../../constants/dropdowns";
import { convertBase64ToFile } from "../../../constants/globalFunctions";
import { alarmTableColumns } from "../../../constants/columns/alarmsTable";
import {
    exportAlarms,
    getSingleAlarmSetup,
    gettingAlarms,
    gettingAlarmsSetup,
    gettingDataPointsAlarms,
    updateAlarmSetup,
} from "../../../services/siteDetails/siteDetails.service";
import TokenService from "../../../services/authentication/token.service";
import Tooltip from "../../common/Tooltip/Tooltip";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
interface AlarmsInterface {
    tankId: string;
}
const Alarms: React.FC<AlarmsInterface> = ({ tankId }) => {
    const [pageSize] = useState<number>(20);
    const [page, setPage] = useState<number>(1);
    const [sortBy, setSortBy] = useState<string>("");
    const [alarmId, setAlarmId] = useState<string>("");
    const [alarmData, setAlarmData] = useState<any>([]);
    const [sortOrder, setSortOrder] = useState<string>("");
    const [totalPages, setTotalPages] = useState<number>(0);
    const [userData] = useState<any>(TokenService.getUser());
    const [alarmTitle, setAlarmTitle] = useState<string>("");
    const [searchValue, setSearchValue] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [alarmDrawerData, setAlarmDrawerData] = useState<any>([]);
    const [dataPointOptions, setDataPointOptions] = useState<any[]>([]);
    const [alarmIsDrawerOpen, setAlarmIsDrawerOpen] = useState<boolean>(false);
    const [enterpriseAlarmId, setEnterpriseAlarmId] = useState("")
    const [alarmPayload, setAlarmPayload] = useState<any>({
        name: "",
        conditions: [],
        priority: 0,
        recommendedAction: "",
        enabled: false,
        sendEmail: false,
    });
    const [resetAlarmValues, setResetAlarmValues] = useState<any>({
        name: "",
        conditions: [],
        priority: 0,
        recommendedAction: "",
        enabled: false,
        sendEmail: false,
    });

    const {
        tank
    } = useSelector((state: RootState) => state.RoutesAndAssets);

    useEffect(() => {
        if (searchValue) {
            const getData = setTimeout(() => {
                gettingListOfAlarms();
            }, 1000);

            return () => clearTimeout(getData);
        } else gettingListOfAlarms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchValue, tankId, sortBy, sortOrder]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(1);
        setSearchValue(event.target.value);
    };

    const fileDownload = async () => {
        try {
            setIsLoading(true);
            const response: any = await exportAlarms(
                searchValue,
                tankId,
                page,
                pageSize
            );
            if (response.statusCode === 200 && response.success) {
                convertBase64ToFile(response.data.split("data:text/csv;base64,")[1]);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const gettingListOfAlarmsSetup = async () => {
        try {
            setIsLoading(true);
            const response: any = await gettingAlarmsSetup(
                "",
                tankId ? tankId.toString() : "",
                page,
                pageSize
            );
            if (response.statusCode === 200 && response.success)
                setAlarmDrawerData(
                    response?.data?.results.filter(
                        (item: { enabled: boolean }) => item.enabled
                    )
                );
        } catch (error) {
            console.error("error", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAlarmDrawer = async () => {
        if (!alarmIsDrawerOpen) {
            setAlarmTitle("Add alarm");
            gettingListOfAlarmsSetup();
            gettingAlarmsDataPoints();
            setAlarmPayload({
                name: "",
                conditions: [],
                priority: 0,
                recommendedAction: "",
                enabled: false,
                sendEmail: false,
            });
            setResetAlarmValues({
                name: "",
                conditions: [],
                priority: 0,
                recommendedAction: "",
                enabled: false,
                sendEmail: false,
            });
            setAlarmId("");
        }
        setAlarmIsDrawerOpen(!alarmIsDrawerOpen);


    };

    const gettingAlarmsDataPoints = async () => {
        try {
            setIsLoading(true);
            const response: any = await gettingDataPointsAlarms();
            if (response.statusCode === 200 && response.success) {
                const record: any = [];
                response?.data.forEach((item: any) => {
                    record.push({
                        name: item.name,
                        value: item.key,
                    });
                });
                setDataPointOptions(record);
            }
        } catch (error) {
            console.error("error", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageNumberChange = (pageNumber: number) => {
        setPage(pageNumber);
    };

    const handleSortChange = (order: string, title: string) => {
        setSortOrder(order);
        setSortBy(title);
    };

    const gettingListOfAlarms = async () => {
        try {
            setIsLoading(true);
            const response = await gettingAlarms(
                searchValue,
                tankId,
                sortOrder,
                sortBy,
                page,
                pageSize
            );
            if (response.statusCode === 200 && response.success) {
                setAlarmData(response?.data?.results);
                setTotalPages(response.data.totalPages);
            }
        } catch (error) {
            console.error("error", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateAlarm = async (item: any) => {
        setIsLoading(true)
        try {
            setAlarmTitle("Modify alarm");
            setAlarmId(item?.siteId);
            setEnterpriseAlarmId(item?.enterpriseAlarmId);
            const response = await getSingleAlarmSetup(item?.enterpriseAlarmId, item?.siteId)
            if (response.statusCode === 200 && response.success) {
                setAlarmPayload({
                    name: item.name,
                    conditions: item.conditions,
                    priority: item.priority,
                    recommendedAction: item.recommendedAction,
                    enabled: item.enabled,
                    sendEmail: false,
                });
                setResetAlarmValues({
                    name: item.name,
                    conditions: item.condition,
                    priority: item.priority,
                    recommendedAction: item.recommendedAction,
                    enabled: item.enabled,
                    sendEmail: false,
                });
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    };

    const handleOnChange = (e: any) => {
        const { name, value } = e.target;
        setAlarmPayload((prev: any) => ({ ...prev, [name]: value }));
    };

    const handleEnabledAlarm = (checked: boolean) => {
        setAlarmPayload((prev: any) => ({ ...prev, enabled: checked }));
    };

    const handleResetValue = (e: any) => {
        setAlarmPayload(resetAlarmValues);
    };

    const handleOnSubmit = async (e: any) => {
        e.preventDefault();
        const request = {
            ...alarmPayload,
            priority: Number(alarmPayload.priority),
        };
        try {
            setIsLoading(true);
            const response = await updateAlarmSetup(enterpriseAlarmId, alarmId, request);
            if (response?.success && response?.statusCode === 200) {
                setAlarmPayload({
                    name: "",
                    conditions: [],
                    priority: 0,
                    recommendedAction: "",
                    enabled: false,
                    sendEmail: false,
                });
                setAlarmIsDrawerOpen(!alarmIsDrawerOpen);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const removeSearchValue = () => {
        setSearchValue("");
    };

    return (
        <>
            <Loading isLoading={isLoading} text={""} />
            <div className='flex items-center justify-between gap-[8px] shrink-0 mb-[20px]'>
                <div className='relative items-center pl-[12px] pr-[8px] max-w-full shrink grow min-h-[32px] min-w-[20px] border-darkcolor-300 border-[1px] border-solid rounded-[6px]'>
                    <div className='flex items-center justify-center'>
                        <IoIosSearch
                            size={20}
                            className='inline-block select-none align-text-bottom overflow-visible text-neutral-25'
                        />
                        <div className='h-full w-[100%] py-[10px] pl-[10px]'>
                            <input
                                onChange={handleInputChange}
                                name='filter'
                                className='w-[300px] text-neutral-25 border-0 p-0 shadow-none flex resize-none outline-none bg-transparent caret-white text-[14px] flex-1 h-full'
                                style={{
                                    overflow: "auto hidden",
                                    maxHeight: "unset",
                                    width: "100%",
                                    maxWidth: "100%",
                                }}
                                value={searchValue}
                                placeholder='Filter by keyword or by field'
                            />
                        </div>
                        {searchValue && searchValue.length > 0 && (
                            <div className='absolute z-10 right-[10px]'>
                                <IoMdClose
                                    size={20}
                                    onClick={removeSearchValue}
                                    className={`inline-block select-none align-text-bottom cursor-pointer text-neutral-25`}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className='flex gap-[8px] items-center shrink-0'>
                    <Tooltip content='Download' direction='left'>
                        <RiDownloadLine
                            className='text-neutral-25 cursor-pointer'
                            size={20}
                            onClick={fileDownload}
                        />
                    </Tooltip>
                    {userData?.user && userData?.user?.role !== 0 && (
                        <Tooltip content='Alarm Control' direction='left'>
                            <IoSettingsOutline
                                className='text-neutral-25 cursor-pointer'
                                size={20}
                                onClick={handleAlarmDrawer}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
            <Table
                data={alarmData}
                columns={alarmTableColumns}
                handlePageNumberChange={handlePageNumberChange}
                page={page}
                pagniation={true}
                totalPages={totalPages}
                handleSortChange={handleSortChange}
            />
            <Drawer
                isOpen={alarmIsDrawerOpen}
                onClose={handleAlarmDrawer}
                title={`Alarms setup - ${tank}`}
                width='1174px'
            >
                <div className='grid grid-cols-1 md:grid-cols-2 gap-7 px-[16px]'>
                    <div className='flex flex-col bg-darkcolor-700 rounded-[8px] py-[20px] overflow-x-auto'>
                        <div className="flex flex-col w-[450px] sm:w-[100%]">
                            <div className='border-b-[1px] pb-[10px] border-b-darkcolor-400'>
                                <label className='text-[16px] px-[16px] font-semibold'>
                                    Alarms
                                </label>
                            </div>
                            {alarmDrawerData &&
                                alarmDrawerData.length > 0 &&
                                alarmDrawerData.map((item: any, index: number) => {

                                    return (
                                        <div
                                            className="flex px-3 py-2 justify-start items-center border-b border-darkcolor-400"
                                            key={index}
                                        >
                                            <div className='flex items-center whitespace-nowrap min-w-[90px] max-w-[90px] pr-[15px]'>
                                                <div
                                                    className='w-[16px] h-[16px] rounded-full border'
                                                    style={{
                                                        borderColor:
                                                            item.priority === 4
                                                                ? "#F04438"
                                                                : item.priority === 3
                                                                    ? "#6BBBAE"
                                                                    : item.priority === 2
                                                                        ? "#FDB022"
                                                                        : item.priority === 1
                                                                            ? "#B8C5CC"
                                                                            : "",
                                                    }}
                                                />
                                                <label className='text-sm ml-1.5'>
                                                    {item.priority === 4
                                                        ? "Critical"
                                                        : item.priority === 3
                                                            ? "High"
                                                            : item.priority === 2
                                                                ? "Medium"
                                                                : item.priority === 1
                                                                    ? "Low"
                                                                    : "Cleared"}
                                                </label>
                                            </div>
                                            <div className='flex items-center w-[30%] max-w-[30%]'>
                                                <label
                                                    className='text-sm text-left break-words text-primary-400 cursor-pointer'
                                                    onClick={() => handleUpdateAlarm(item)}
                                                >
                                                    {item.name}
                                                </label>
                                            </div>
                                            <div className='px-3 flex flex-wrap w-[191px] max-w-[191px] break-words'>
                                                {item?.conditions.length > 0 && item?.conditions.map((rec: any, recIndex: number) => (
                                                    <label key={recIndex} className={`text-sm text-left max-w-[100%] ${item?.conditions.length - 1 > recIndex && 'mb-2'}`}>
                                                        {rec.datapointName} {rec.condition === 0 ? "=" : rec.condition === 1 ? ">" : rec.condition === 2 ? "<" : rec.condition === 3 ? ">=" : rec.condition === 4 ? "<=" : "!="} {rec.value}
                                                    </label>
                                                ))}
                                            </div>
                                            <div className='px-3 min-w-[73px] max-w-[73px]'>
                                                <label className='text-sm text-left'>
                                                    {item.enabled ? "Enabled" : "Disabled"}
                                                </label>
                                            </div>
                                        </div>

                                    );
                                })}
                        </div>
                    </div>
                    <div className='flex flex-col bg-darkcolor-700 rounded-[8px] py-[20px]'>
                        {alarmId ? (<>
                            <div className='border-b-[1px] pb-[10px] border-b-darkcolor-400'>
                                <label className='text-[16px] px-[16px] font-semibold'>
                                    {alarmTitle}
                                </label>
                            </div>
                            <div className='p-[16px]'>
                                <TextInput
                                    name={"name"}
                                    label={"Alarm name"}
                                    // onChange={handleOnChange}
                                    value={alarmPayload.name}
                                    errors={""}
                                    disabled={true}
                                />
                                {alarmPayload?.conditions && alarmPayload?.conditions.length > 0 && alarmPayload?.conditions.map((item: any, conditionNumber: number) => {
                                    return (
                                        <div key={conditionNumber}>
                                            <div className='flex flex-col mb-[16px]'>
                                                <label className="label--semibold mb-[6px]">
                                                    Data point
                                                </label>
                                                <select
                                                    className='mt-2 block w-full border-[1px] border-neutral-25 rounded-[8px] py-1.5 pl-3 pr-10 bg-transparent text-neutral-25 focus:outline-none'
                                                    value={item?.datapoint} disabled
                                                >
                                                    {dataPointOptions && dataPointOptions.length > 0 && dataPointOptions.map((data: any, recordNumber: any) => (
                                                        <option
                                                            className='bg-darkcolor-800 text-neutral-25 text-[16] border-b-[2px] border-neutral-25'
                                                            value={data?.value}
                                                            key={recordNumber}
                                                        >
                                                            {data?.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='flex flex-col mb-[16px]'>
                                                <label className="label--semibold mb-[6px]">
                                                    Condition
                                                </label>
                                                <select
                                                    className='mt-2 block w-full border-[1px] border-neutral-25 rounded-[8px] py-1.5 pl-3 pr-10 bg-transparent text-neutral-25 focus:outline-none'
                                                    value={item?.condition} disabled
                                                >
                                                    {dropdownCoditionOptions && dropdownCoditionOptions.length > 0 && dropdownCoditionOptions.map((data: any, test: any) => (
                                                        <option
                                                            className='bg-darkcolor-800 text-neutral-25 text-[16] border-b-[2px] border-neutral-25'
                                                            value={data?.value}
                                                            key={test}
                                                        >
                                                            {data?.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <TextInput
                                                name={"value"}
                                                label={"Value"}
                                                onChange={handleOnChange}
                                                value={item?.value}
                                                errors={""}
                                                disabled={true}
                                            />
                                        </div>
                                    )
                                })}


                                <div className='flex flex-col mb-[16px]'>
                                    <label className='text-[14px] font-semibold text-neutral-25 mb-[5px]'>
                                        Seniority
                                    </label>
                                    <div className='flex flex-wrap gap-[16px]'>
                                        <RadioButton
                                            name={"priority"}
                                            title={"Critical"}
                                            value={4}
                                            onChange={handleOnChange}
                                            checked={Number(alarmPayload?.priority) === 4}
                                        />
                                        <RadioButton
                                            name={"priority"}
                                            title={"High"}
                                            value={3}
                                            onChange={handleOnChange}
                                            checked={Number(alarmPayload?.priority) === 3}
                                        />
                                        <RadioButton
                                            name={"priority"}
                                            title={"Medium"}
                                            value={2}
                                            onChange={handleOnChange}
                                            checked={Number(alarmPayload?.priority) === 2}
                                        />
                                        <RadioButton
                                            name={"priority"}
                                            title={"Low"}
                                            value={1}
                                            onChange={handleOnChange}
                                            checked={Number(alarmPayload?.priority) === 1}
                                        />
                                    </div>
                                    {/* {errors?.priority && (
                                    <div className='text-error-600 my-1'>{errors?.priority}</div>
                                )} */}
                                </div>
                                <div className='flex flex-col mb-[16px]'>
                                    <label className='text-[14px] font-semibold text-neutral-25 mb-[5px]'>
                                        Recommended action
                                    </label>
                                    <textarea
                                        rows={4}
                                        className='block py-[12px] px-[14px] w-full text-[16px] text-neutral-400 bg-darkcolor-800 rounded-[8px] border-[1px] border-darkcolor-400'
                                        placeholder='Enter a description...'
                                        value={alarmPayload?.recommendedAction}
                                        name='recommendedAction'
                                        onChange={handleOnChange}
                                    ></textarea>
                                    {/* {errors?.recommendedAction && (
                                    <div className='text-error-600 my-1'>
                                        {errors?.recommendedAction}
                                    </div>
                                )} */}
                                </div>
                                <div className='flex mb-[16px]'>
                                    <Toggle
                                        checked={alarmPayload?.enabled}
                                        onChange={handleEnabledAlarm}
                                        label='Enabled alarm'
                                    />
                                </div>
                                <div className='flex justify-end gap-[12px]'>
                                    <button
                                        className='py-[10px] px-[16px] flex justify-center items-center text-neutral-25 rounded-[8px]  bg-darkcolor-700 focus:outline-none cursor-pointer'
                                        onClick={handleResetValue}
                                    >
                                        Reset
                                    </button>
                                    <button
                                        className='py-[10px] px-[16px] flex justify-center items-center rounded-[8px] text-primary-400 bg-darkcolor-700 focus:outline-none cursor-pointer'
                                        onClick={handleOnSubmit}
                                    >
                                        {"Save"}
                                    </button>
                                </div>
                            </div> </>) : <div className="w-full h-full flex justify-center items-center"><h3 className="p-4 text-[16px]">Please select the modify alarm.</h3></div>}
                    </div>
                </div>
            </Drawer >
        </>
    );
};

export default Alarms;
