import LocalStorageConstant from "../../constants/localStorage.constant";
import Helpers from "../../utilities/helpers";

const getLocalRefreshToken = () => {
  return localStorage.getItem(LocalStorageConstant.refreshToken);
};

const getLocalAccessToken = () => {
  return localStorage.getItem(LocalStorageConstant.accessToken);
};

const addOrUpdateLocalAccessToken = (token: string) => {
  localStorage.setItem(LocalStorageConstant.accessToken, token);
};

const addOrUpdateLocalRefreshToken = (token: string) => {
  localStorage.setItem(LocalStorageConstant.refreshToken, token);
};

const getUser = () => {
  let user: string | null = localStorage.getItem(LocalStorageConstant.user);
  return user ? JSON.parse(user) : null;
};

const setUser = (user: any) => {
  Helpers.ConsoleLog(JSON.stringify(user));
  localStorage.setItem(LocalStorageConstant.user, JSON.stringify(user) ?? "");
};

const removeUser = () => {
  localStorage.removeItem(LocalStorageConstant.user);
};

const getFirstTimeLogin = () => {
  return sessionStorage.getItem(LocalStorageConstant.firstTimeLogin);
};

const setFirstTimeLogin = (isFirstTimeLogin: string) => {
  sessionStorage.setItem(LocalStorageConstant.firstTimeLogin, isFirstTimeLogin);
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  addOrUpdateLocalAccessToken,
  addOrUpdateLocalRefreshToken,
  getUser,
  setUser,
  removeUser,
  getFirstTimeLogin,
  setFirstTimeLogin,
};

export default TokenService;
