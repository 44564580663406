import "./Table.scss";
import React, { useState } from "react";
import Arrow from "../Svg/Arrow";
import Tooltip from "../Tooltip/Tooltip";
import Pagination from "../Pagination/Pagination";
import noDataIcon from "../../../assets/img/icon-noData.svg";

interface TableColumn {
    title: string;
    dataIndex: string;
    key: string;
    render?: any;
    width?: string;
    sortable?: boolean;
    isChecked?: boolean;
}

interface TableProps {
    data: any[];
    columns: TableColumn[];
    page?: number;
    handlePageNumberChange?: (pageNumber: number) => void;
    pagniation?: boolean;
    totalPages?: number;
    handleSortChange?: (sortOrder: any, sortBy: any) => void;
    errorMessage?: string;
    emptyIcon?: boolean,
    tableClass?: string,
    pageSize?: number,
    sortWithFrontend?: boolean
    bgColor?: any;
}

const TableComponent: React.FC<TableProps> = ({
    data,
    columns,
    page,
    handlePageNumberChange,
    pagniation,
    totalPages,
    handleSortChange,
    errorMessage,
    emptyIcon = true,
    tableClass,
    pageSize,
    sortWithFrontend,
    bgColor,
}) => {

    const currentPage = page ?? 0;
    const total = totalPages ?? Math.ceil(data.length / (pageSize ?? 1));
    const defaultWidth = `${100 / columns.length}%`;

    const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(
        sortWithFrontend ? { key: columns[0].dataIndex, direction: "ascending" } : null
    );

    const requestSort = (key: string) => {
        let direction = "ascending";
        if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    const sortedData = () => {
        if (!sortConfig) return data;

        const sorted = [...data].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === "ascending" ? 1 : -1;
            }
            return 0;
        });

        return sorted;
    };

    const renderTableHeader = () => (
        <thead>
            <tr>
                {columns.map((column) => (
                    <th
                        key={column.key}
                        className='px-4 py-2 text-left cursor-pointer whitespace-nowrap'
                        style={{ width: column.width || defaultWidth }}
                        onClick={() => sortWithFrontend && column.sortable && requestSort(column.dataIndex)}
                    >
                        <div className='flex items-center'>
                            <div className='text-neutral-25 text-[12px] font-bold'>
                                {column.title}
                            </div>
                            {column.sortable && (
                                <>
                                    {sortWithFrontend ?
                                        <span className="ml-1">
                                            {(sortConfig && sortConfig.key === column.dataIndex) && (
                                                <>
                                                    <Arrow
                                                        size={12}
                                                        className='text-neutral-25 relative top-[2px] rotate-[-90deg]'
                                                    />
                                                    <Arrow
                                                        size={12}
                                                        className='text-neutral-25 relative bottom-[1px] rotate-90'
                                                    />
                                                </>

                                            )}
                                        </span> : <div className='ml-1'>
                                            <div className='flex flex-col'>
                                                <Tooltip content={"Ascending"} direction='right'>
                                                    <Arrow
                                                        size={12}
                                                        className='text-neutral-25 relative top-[2px] rotate-[-90deg]'
                                                        onClick={() => columnsSort("asc", column.dataIndex)}
                                                    />
                                                </Tooltip>
                                                <Tooltip content={"Descending"} direction='right'>
                                                    <Arrow
                                                        size={12}
                                                        className='text-neutral-25 relative bottom-[1px] rotate-90'
                                                        onClick={() => columnsSort("desc", column.dataIndex)}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>}
                                </>
                            )}
                        </div>
                    </th>
                ))}
            </tr>
        </thead >
    );


    const renderFrontendTableBody = () => {
        const start = (currentPage - 1) * (pageSize || 1);
        const end = start + (pageSize || 1);

        return (
            <tbody>
                {sortedData().slice(start, end).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {columns.map((column) => (
                            <td
                                key={column.key}
                                className="px-4 py-2 text-neutral-25 text-[14px] text-left"
                                style={{ width: column.width }}
                            >
                                {column.render
                                    ? column.render(row[column.dataIndex], row)
                                    : row[column.dataIndex]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        );
    };


    const renderTableBody = () => (
        <tbody>
            {data.length > 0 ? (
                data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {columns.map((column) => (
                            <td
                                key={column.key}
                                className={`px-4 py-2 text-neutral-25 text-[14px] text-left `}
                                style={{ width: column.width || defaultWidth }}
                            >
                                {column.render
                                    ? column.render(row[column.dataIndex], row)
                                    : row[column.dataIndex]}
                            </td>
                        ))}
                    </tr>
                ))
            ) : (
                <tr>
                    {emptyIcon ? <td colSpan={columns.length} className='text-center py-10'>
                        <div className='flex flex-col items-center'>
                            <div className=' text-neutral-25 mb-4'>
                                <img src={noDataIcon} alt='SiteName' className='object-contain bg-img' />
                            </div>
                            <span className='text-neutral-25 title'>{errorMessage ?? "No data found"}</span>
                        </div>
                    </td> : <td colSpan={columns.length} className='py-4'>
                        <span className='text-neutral-25 title'>{errorMessage ?? "No data found"}</span>
                    </td>}
                </tr>
            )}
        </tbody>
    );

    const columnsSort = (order: string, title: string) => {
        handleSortChange && handleSortChange(order, title);
    };

    const handlePageClick = (page: number) => {
        handlePageNumberChange && handlePageNumberChange(page);
    };

    return (
        <>
            <div style={{ overflow: "auto hidden" }}>
                <table className={`min-w-full rounded-[8px] bg-darkcolor-700 outline-[1px] ${tableClass} mb-[10px]`} style={bgColor ? bgColor : undefined}>
                    {renderTableHeader()}
                    {pageSize && pageSize > 0 && emptyIcon ? renderFrontendTableBody() : renderTableBody()}
                </table>
            </div>
            {pagniation && data.length > 0 && (
                <Pagination
                    currentPage={currentPage ?? 0}
                    totalPages={total}
                    onPageChange={handlePageClick}
                />
            )}
        </>
    );
};

export default TableComponent;
