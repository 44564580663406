import TimeBased from "./TimeBased";
import VolumeBased from "./VolumeBased";
import Drawer from "../../common/Drawer/Drawer";
import React, { useEffect, useState } from "react";
import CustomTable from "../../common/Table/CustomTable";
import DetailsCard from "../../common/Cards/DetailsCards";
import SetPoint from "./SetPoint";
import TabsWithBg from "../../common/Tabs/TabsWithBackground";
import { PumpTableColumns } from "../../../constants/columns/PumpTable";
import { timeBasedPrimePump } from "../../../constants/pumpControlData";
import { dropdownOptions } from "../../../constants/TableDropdownDummyData";
import {
    getHistroySetPoint,
    getPump,
    updateMaxPumpOutput,
    updateTankInjectionRate,
} from "../../../services/siteDetails/siteDetails.service";
import Loading from "../../common/Loading/Loading";

interface PumpInterface {
    groupId: string;
    siteId: string;
    groupType: string;
}

const Pump: React.FC<PumpInterface> = ({
    groupId,
    siteId,
    groupType,
}) => {
    const [pumpData, setPumpData] = useState<any>([]);
    const [pumpDetails, setPumpDetails] = useState<any>([]);
    const [maxPumpOutput, setMaxPumpOutput] = useState<any>([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [activeDrawerTab, setActiveDrawerTab] = useState<number>(0);
    const [isPrimeDrawer, setIsPrimeDrawer] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [maxPumpOutputValue, setMaxPumpOutputValue] = useState<number | string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] =
        useState<string>("configurationView");
    const [injectionRateSetPointData, setInjectionRateSetPointData] =
        useState<any>([]);
    const [injectionSetPointValue, setInjectionSetPointValue] =
        useState<number | string>("");
    const [currentInjectionSetPointValue, setCurrentInjectionSetPointValue] =
        useState<number>(0);
    const [currentMaxOutputValue, setCurrentMaxOutputValue] = useState<number>(0);

    useEffect(() => {
        gettingPumpData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId]);

    const gettingPumpData = async () => {
        setIsLoading(true);
        try {
            const response: any = await getPump(siteId);
            if (response.statusCode === 200 && response.success) {
                setCurrentInjectionSetPointValue(response?.data?.Injectionratesetpoint);
                setCurrentMaxOutputValue(
                    response?.data?.maxPumpOutput === "AMP"
                        ? 0
                        : response?.data?.maxPumpOutput
                );
                setPumpDetails([
                    {
                        label: "Control mode",
                        value: response?.data?.ControlMode,
                        editLabel: "Injection rate set-point",
                        editValue: response?.data?.Injectionratesetpoint ? `${response?.data?.Injectionratesetpoint} GPD` : " ",
                        editIcon: true,
                    },
                    {
                        label: "Power type",
                        value: response?.data?.powerType,
                        editLabel: "Max pump output",
                        editValue: response?.data?.maxPumpOutput ? `${response?.data?.maxPumpOutput} GPD` : "",
                        editIcon: true,
                    },
                    {
                        label: "Pump manufacturer",
                        value: response?.data?.pumpManufacturere,
                        editLabel: "Calibration mode ",
                        editValue: response?.data?.measured ? `${response?.data?.measured}` : "",
                        editIcon: false,
                    },
                    {
                        label: "Pump model",
                        value: response?.data?.PumpModel,
                        editLabel: "Single or dual head",
                        editValue: response?.data?.singleordualhead ? `${response?.data?.singleordualhead}` : "",
                        editIcon: false,
                    },
                    {
                        label: "Controller type",
                        value: response?.data?.controllerType,
                        editLabel: "Voltage scale index",
                        editValue: response?.data?.voltageScaleIndex ? `${response?.data?.voltageScaleIndex}` : "",
                        editIcon: false,
                    },
                ]);
                setPumpData(response?.data?.TrendData);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePrimeDrawer = () => {
        setIsPrimeDrawer(!isPrimeDrawer);
    };

    const handleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
        if (isDrawerOpen) {
            if (activeDrawerTab === 1) setInjectionSetPointValue("")
            else setMaxPumpOutputValue("")
        }
    };

    const handleTabDrawerChange = (index: number) => {
        setActiveDrawerTab(index);
        gettingHistory(index);
    };

    const handleSelect = (selectedOption: string) => {
        setSelectedOption(selectedOption);
    };

    const handleUpdateInjectionPoint = async (value: string) => {
        const request: any = {
            groupId: groupId,
            siteId: siteId,
            groupType: groupType,
            newSetPoint: Number(value),
        };
        try {
            const response = await updateTankInjectionRate(request);
            if (response.success && response.statusCode === 200) {
                setActiveDrawerTab(0);
                setInjectionSetPointValue("")
                setIsDrawerOpen(!isDrawerOpen);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdateMaxOutputPump = async (value: string) => {
        const request: any = {
            groupId: groupId,
            siteId: siteId,
            groupType: groupType,
            newSetPoint: Number(value),
        };
        try {
            const response = await updateMaxPumpOutput(request);
            if (response.success && response.statusCode === 200) {
                setActiveDrawerTab(0);
                setIsDrawerOpen(!isDrawerOpen);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const gettingHistory = async (channel: any) => {
        try {
            const response = await getHistroySetPoint(
                siteId,
                channel,
                groupId,
                groupType
            );
            if (response.success && response.statusCode === 200) {
                if (channel === 1) {
                    setMaxPumpOutput(response?.data?.results);
                    setActiveDrawerTab(1);
                } else {
                    setInjectionRateSetPointData(response?.data?.results);
                    setActiveDrawerTab(0);
                }
                if (!isDrawerOpen)
                    handleDrawer()
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnChange = (e: any) => {
        const { value } = e.target;
        const regex = /^\d+(\.\d{1,2})?$/;
        if (activeDrawerTab === 0 && regex.test(value))
            setInjectionSetPointValue(value);
        else if (activeDrawerTab === 1 && regex.test(value))
            setMaxPumpOutputValue(value)
    };

    const tabsPumpDetails = [
        {
            label: "Injection rate set point",
            content: (
                <SetPoint
                    data={injectionRateSetPointData}
                    onClick={handleUpdateInjectionPoint}
                    currentValue={currentInjectionSetPointValue}
                    onChange={handleOnChange}
                    value={injectionSetPointValue}
                />
            ),
        },
        {
            label: "Maximum pump output",
            content: (
                <SetPoint
                    data={maxPumpOutput}
                    onClick={handleUpdateMaxOutputPump}
                    currentValue={currentMaxOutputValue}
                    onChange={handleOnChange}
                    value={maxPumpOutputValue}

                />
            ),
        },
    ];

    const tabsPrimePump = [
        {
            label: "Time based",
            content: (
                <TimeBased
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    dropdownOptions={dropdownOptions}
                    handleSelect={handleSelect}
                    timeBasedPrimePump={timeBasedPrimePump}
                />
            ),
        },
        {
            label: "Volume based",
            content: (
                <VolumeBased
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    dropdownOptions={dropdownOptions}
                    handleSelect={handleSelect}
                    timeBasedPrimePump={timeBasedPrimePump}
                />
            ),
        },
    ];
    const bgGradientStyle = { background: "linear-gradient(181.1deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%)" };

    return (
        <>
            {/* <div className='absolute right-[20px] top-0'>
                <button
                    className='flex py-[10px] px-[16px] justify-center items-center gap-[8px] border-[1px] border-primary-600 bg-primary-600 rounded-[8px] text-neutral-25 text-[14px] font-bold hover:bg-primary-700'
                    onClick={handlePrimeDrawer}
                >
                    Prime
                </button>
            </div> */}
            <div className="pumpBlock">
                <Loading isLoading={isLoading} text={""} />
                <div className='grid gap-[12px] w-full mb-[20px]'>
                    <DetailsCard
                        title={"Pump details"}
                        data={pumpDetails}
                        onClick={gettingHistory}
                    />
                </div>
                <div className='grid gap-[12px] w-full'>
                    <CustomTable data={pumpData} columns={PumpTableColumns} bgColor={bgGradientStyle} />
                </div>
            </div>
            <Drawer
                isOpen={isDrawerOpen}
                onClose={handleDrawer}
                title='Pump control'
                width='565px'
            >
                <TabsWithBg
                    tabs={tabsPumpDetails}
                    activeTab={activeDrawerTab}
                    onChange={handleTabDrawerChange}
                />
            </Drawer>
            <Drawer
                isOpen={isPrimeDrawer}
                onClose={handlePrimeDrawer}
                title='Pump'
                width='565px'
            >
                <TabsWithBg
                    tabs={tabsPrimePump}
                    activeTab={activeDrawerTab}
                    onChange={handleTabDrawerChange}
                />
            </Drawer>
        </>
    );
};

export default Pump;
