import instance from "../api";


export const getSites = async (userId: string, groupId: string) => {
    const params = {
        UserId: userId,
        GroupId: groupId
    };
    const response = await instance.get<any>("/Groups/GetSites", { params }
    )
    return response.data;
}
export const getUser = async (userId: string, page: number, pageSize: number, sortOrder: any, sortBy: any) => {
    const params = {
        id: userId,
        PageNumber: page,
        PageSize: pageSize,
        sortOrder: sortOrder,
        sortBy: sortBy,
    };
    const response = await instance.get("Groups/GetUsersByGroupId", { params });
    return response.data;
}
export const getGroupAssets = async (data: any) => {
    const response = await instance.post<any>("Groups/GetAssetsByGroupId", data);
    return response.data;
}
export const getAllSites = async (groupId: string,) => {
    const params = {
        GroupId: groupId
    }
    const response = await instance.get("Groups/GetAllSites", { params });
    return response.data;
}
export const getAllUsers = async () => {
    const response = await instance.get("Groups/GetUsers");
    return response.data;
}
export const getGroupsCustom = async () => {
    const response = await instance.get("/Groups/GetCustomGroups");
    return response.data;
}
export const addCustomGroup = async (data: any) => {
    try {
        const response = await instance.post<any>("/Groups/AddCustomGroup", data);
        return response.data;
    } catch (error) {
        console.error("Error fetching pinned sites:", error);
        throw error;
    }
}

export const updateGroup = async (data: any) => {
    try {
        const response = await instance.put<any>("/Groups/UpdateGroup", data);
        return response.data;
    } catch (error) {
        // Handle error
        console.error("error", error);
        throw error;
    }
};

export const assignUnassignSites = async (data: any) => {
    try {
        const response = await instance.put<any>("/Groups/AssignUnassignGroup", data);
        return response.data;
    } catch (error) {
        // Handle error
        console.error("error", error);
        throw error;
    }
};

export const deleteGroup = async (id: any) => {
    const params = {
        id: id,
    };
    try {
        const response = await instance.delete<any>("/Groups/DeleteGroup", { params });
        return response.data;
    } catch (error) {
        // Handle error
        console.error("error", error);
        throw error;
    }
};