import React from "react";
import moment from "moment";
import noDataIcon from "../../../assets/img/icon-noData.svg";
import { FaRegCircleCheck } from "react-icons/fa6";

interface SetPointInterface {
    data: any;
    onClick: any;
    currentValue: any;
    value?: any;
    onChange?: any;
}

const SetPoint: React.FC<SetPointInterface> = ({
    data,
    onClick,
    currentValue,
    value,
    onChange,
}) => {
    return (
        <div className='mt-[16px]'>
            <div className='flex items-center justify-between gap-[8px] shrink-0  mb-[16px]'>
                <input
                    name='chartName'
                    type='text'
                    className='bg-darkcolor-800 border-[1px] border-darkcolor-400 rounded-[8px] py-[10px] px-[16px]  text-neutral-400 w-full focus:outline-none'
                    placeholder='Enter value here'
                    onChange={onChange}
                    value={value}
                />
                <button
                    className='py-[10px] px-[16px] text-[14px] flex justify-center items-center rounded-[8px] font-bold  border-primary-700 border-[1px] border-solid bg-primary-600 hover:bg-primary-700 focus:outline-none text-neutral-25'
                    onClick={() => onClick(value)}
                >
                    Update
                </button>
            </div>
            <div className='flex justify-start mb-[16px]'>
                <label className='text-success-400 text-[14px] font-medium'>
                    Current value: {currentValue && Number(currentValue).toFixed(2)} GPD
                </label>
            </div>
            <div className='flex flex-col bg-darkcolor-700 rounded-[8px]  border border-darkcolor-400'>
                <div className='border-b py-[12px] px-[16px] border-b-darkcolor-400'>
                    <label className='text-[16px] font-semibold'>History</label>
                </div>
                {data.length > 0 ? (
                    data.map((item: any, index: number) => (
                        <div
                            className={`flex items-center py-[12px] px-[12px]  ${data.length - 1 > index
                                ? "border-b-[1px] border-b-darkcolor-400"
                                : ""
                                }`}
                            key={item.id}
                        >
                            <div className='flex items-center gap-[8px] px-[12px] w-full whitespace-nowrap'>
                                <FaRegCircleCheck className='text-success-400' />
                                <label className='text-[14px]'>
                                    {item?.newSetPoint} GDP set by {item?.modifiedBy} @{" "}
                                    {moment(item.modifiedAt).format("MM/DD/yyyy hh:mm A")}
                                </label>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='flex flex-col items-center mb-[10px]'>
                        <div className=' text-neutral-25 mb-4'>
                            <img src={noDataIcon} alt='SiteName' className='object-contain bg-img' />
                        </div>
                        <span className='text-neutral-25 title'>No data found</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SetPoint;
