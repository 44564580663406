import { toast } from "react-toastify";
import Tabs from "../common/Tabs/Tabs1";
import { HiRefresh } from "react-icons/hi";
import { useEffect, useState } from "react";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import Table from "../common/Table/CustomTable";
import Loading from "../common/Loading/Loading";
import Tooltip from "../common/Tooltip/Tooltip";
import { FiSearch, FiSettings } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
    setPointOptimzationHistoryColumns,
    setPointOptimzationManualTable,
    setPointOptimzationRecommendationColumns,
} from "../../constants/columns/setPointOptimizationTable";
import {
    getManualSetPoint,
    getRecommendation,
    addHistorySetPoint,
    addAllRecommendationSetPoint,
    addManualRecommendationSetPoint,
} from "../../services/setPointOptimization/setPointOptimization.service";
import {
    setSiteId,
    setGroupId,
    setGroupType,
    setIsAmpGroup,
} from "../../store/groupSitesAssets/groupSitesAssets.store";
import {
    setTest,
    setTankId,
    clearSiteId,
    setCustomerId,
    setCustomLevel1Id,
    setCustomLevel2Id,
    setCustomLevel3Id,
    clearCustomLevel2Id,
    clearCustomLevel3Id,
    setSiteGroupDetails,
    clearSiteGroupDetails,
    setCustomerGroupDetails,
    setCustomLevel1GroupDetails,
    setCustomLevel2GroupDetails,
    setCustomLevel3GroupDetails,
    clearCustomLevel2GroupDetails,
    clearCustomLevel3GroupDetails,
} from "../../store/routesAndAssets/routesAndAssets.store";

const SetPointOptimization = () => {
    const [pageSize] = useState<number>(10);
    const [data, setData] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [index, setIndex] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState("");
    const [activeTab, setActiveTab] = useState<number>(0);
    const [sortOrder, setSortOrder] = useState<string>("");
    const [totalPages, setTotalPages] = useState<number>(0);
    const [manualValue, setManualValue] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [enableManualAllBtn, setEnableManualAllBtn] = useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { groupId, groupType } = useSelector(
        (state: RootState) => state.GroupSitesAssets
    );

    const {
        siteGroupDetails,
        customLevel1Details,
        customLevel2Details,
        customLevel3Details,
        customerGroupDetails,
        noSiteClearForSearch,
    } = useSelector((state: RootState) => state.RoutesAndAssets);

    useEffect(() => {
        if (searchQuery) {
            const getData = setTimeout(fetchData, 1000);
            return () => clearTimeout(getData);
        } else {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, index, page, sortBy, sortOrder]);

    const handleSortChange = (order: string, title: string) => {
        setSortBy(title);
        setSortOrder(order);
    };

    const handleTabChange = (index: number) => {
        setIndex(index);
        setActiveTab(index);
        setPage(1);
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            let response: any = {};
            if (activeTab === 0) {
                response = await getRecommendation(
                    groupId,
                    ["Custom1", "Custom2", "Custom3"].includes(groupType)
                        ? "Custom"
                        : groupType,
                    page,
                    pageSize,
                    searchQuery,
                    sortOrder,
                    sortBy
                );
            } else if (activeTab === 1) {
                response = await getManualSetPoint(
                    groupId,
                    ["Custom1", "Custom2", "Custom3"].includes(groupType)
                        ? "Custom"
                        : groupType,
                    page,
                    pageSize,
                    searchQuery,
                    sortOrder,
                    sortBy
                );
            } else if (activeTab === 2) {
                response = await addHistorySetPoint(
                    groupId,
                    ["Custom1", "Custom2", "Custom3"].includes(groupType)
                        ? "Custom"
                        : groupType,
                    searchQuery,
                    page,
                    pageSize,
                    sortOrder,
                    sortBy
                );
            }
            if (response.statusCode === 200 && response.success) {
                setData(response?.data?.results);
                setTotalPages(response?.data?.totalPages);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleManualNewSetPoint = (e: any, row: any) => {
        const { value } = e.target;
        const updatedData = data.map((item) =>
            item.siteId === row.siteId
                ? { ...item, newSetPointManual: value ? +value : undefined }
                : item
        );
        setData(updatedData);
        const hasManualValue = updatedData.some(
            (item) =>
                item.newSetPointManual !== undefined && item.newSetPointManual !== 0
        );
        setEnableManualAllBtn(hasManualValue);
        const duplicate = [...data];
        if (enableManualAllBtn) {
            setEnableManualAllBtn(hasManualValue);
        } else {
            setEnableManualAllBtn(hasManualValue);
        }
        const itemIndex = duplicate.findIndex((item) => item.siteId === row.siteId);
        duplicate[itemIndex].newSetPointManual = +value;
        setManualValue(+value);
        setData(duplicate);
    };

    const handleRecommendationManualSetPoint = async (
        newSetPoint: number,
        groupId: string,
        siteId: string
    ) => {
        setIsLoading(true);
        try {
            if (index === 0) {
                const request: any = [
                    {
                        newSetPoint: newSetPoint,
                        groupId: groupId,
                        groupType: ["Custom1", "Custom2", "Custom3"].includes(groupType)
                            ? "Custom"
                            : groupType,
                        siteId: siteId,
                    },
                ];
                const response: any = await addManualRecommendationSetPoint(request);
                if (
                    response.data[0].status === 1 &&
                    response.success &&
                    response.statusCode === 200
                ) {
                    fetchData();
                    toast.success("Setpoint Updated Succesfully");
                } else {
                    toast.error("Error Updating SetPoint");
                }
            } else {
                const request: any = [
                    {
                        newSetPoint: manualValue,
                        groupId: groupId,
                        groupType: ["Custom1", "Custom2", "Custom3"].includes(groupType)
                            ? "Custom"
                            : groupType,
                        siteId: siteId,
                    },
                ];
                const response: any = await addManualRecommendationSetPoint(request);
                if (
                    response.data[0].status === 1 &&
                    response.success &&
                    response.statusCode === 200
                ) {
                    fetchData();
                    toast.success("Setpoint Updated Succesfully");
                } else {
                    toast.error("Error Updating SetPoint");
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRecommendationAll = async () => {
        setIsLoading(true);
        try {
            const request: any = {
                groupId: groupId,
                groupType: ["Custom1", "Custom2", "Custom3"].includes(groupType)
                    ? "Custom"
                    : groupType,
                freeText: searchQuery,
            };
            const response: any = await addAllRecommendationSetPoint(request);
            if (response.statusCode === 200 && response.success) {
                fetchData();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRecommendationAllManual = async () => {
        setEnableManualAllBtn(false);
        try {
            setIsLoading(true);
            const request = data
                .filter((item) => item.newSetPointManual !== undefined) // Filter out objects with undefined newSetPointManual
                .map((item) => ({
                    newSetPoint: item.newSetPointManual,
                    siteId: item.siteId,
                    groupId: item.groupId,
                    groupType: ["Custom1", "Custom2", "Custom3"].includes(groupType)
                        ? "Custom"
                        : groupType,
                }));
            const response: any = await addManualRecommendationSetPoint(request);
            if (response.statusCode === 200 && response.success) {
                fetchData();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageNumberChange = (pageNumber: number) => setPage(pageNumber);

    const handleSearchChange = (e: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchQuery(e.target.value);
        setPage(1);
    };

    const saveSiteId = (siteId: any) => {
        dispatch(setSiteId(siteId));
        dispatch(setTankId(siteId));
        dispatch(setTest());
        if (!noSiteClearForSearch) {
            dispatch(clearSiteId());
            dispatch(clearSiteGroupDetails());
        }
        navigate("/site-details");
    };

    const tabs = [
        {
            label: "Recommendations",
            badge: false,
            content: (
                <>
                    <div className='flex items-center justify-between gap-[8px] shrink-0 mb-[14px]'>
                        <div className='grow input-with-lefticon sidebar-search-input-block'>
                            <FiSearch className='input-icon' />
                            <input
                                className='input-outline-4'
                                placeholder='Search'
                                autoComplete='off'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                    <Table
                        data={data}
                        columns={[
                            ...setPointOptimzationRecommendationColumns({
                                handleRecommendationManualSetPoint,
                                saveSiteId,
                            }),
                        ]}
                        handlePageNumberChange={handlePageNumberChange}
                        page={page}
                        pagniation={true}
                        totalPages={totalPages}
                        handleSortChange={handleSortChange}
                    />
                </>
            ),
        },
        {
            label: "Manual",
            badge: false,
            content: (
                <>
                    <div className='flex items-center justify-between gap-[8px] shrink-0 mb-[14px]'>
                        <div className='grow input-with-lefticon sidebar-search-input-block'>
                            <FiSearch className='input-icon' />
                            <input
                                className='input-outline-4'
                                placeholder='Search'
                                autoComplete='off'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                    <Table
                        data={data}
                        columns={[
                            ...setPointOptimzationManualTable({
                                handleRecommendationManualSetPoint,
                                handleManualNewSetPoint,
                                saveSiteId,
                            }),
                        ]}
                        handlePageNumberChange={handlePageNumberChange}
                        page={page}
                        pagniation={true}
                        totalPages={totalPages}
                        handleSortChange={handleSortChange}
                    />
                </>
            ),
        },
        {
            label: "History",
            badge: false,
            content: (
                <>
                    <div className='flex items-center justify-between gap-[8px] shrink-0 mb-[14px]'>
                        <div className='grow input-with-lefticon sidebar-search-input-block'>
                            <FiSearch className='input-icon' />
                            <input
                                className='input-outline-4'
                                placeholder='Search'
                                autoComplete='off'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                    <Table
                        data={data}
                        columns={[...setPointOptimzationHistoryColumns({ saveSiteId })]}
                        handlePageNumberChange={handlePageNumberChange}
                        page={page}
                        pagniation={true}
                        totalPages={totalPages}
                        handleSortChange={handleSortChange}
                    />
                </>
            ),
        },
    ];

    const handleOnClickCustomer = (customer: any) => {
        dispatch(clearSiteId());
        dispatch(clearSiteGroupDetails());

        dispatch(setGroupId(customer?.id));
        dispatch(setIsAmpGroup(customer.isAmpGroup));
        dispatch(setGroupType(customer.ampGroupType));

        dispatch(setCustomerId(customer?.id));
        dispatch(setCustomerGroupDetails(customer));

        navigate("/");
    };

    const handleOnClickSites = (site: any, customer: any) => {
        dispatch(setGroupId(site?.id));
        dispatch(setIsAmpGroup(site.isAmpGroup));
        dispatch(setGroupType(site.ampGroupType));

        dispatch(setCustomerId(customer?.id));
        dispatch(setCustomerGroupDetails(customer));

        dispatch(setSiteGroupDetails(site));
        dispatch(setSiteId(site?.id));

        navigate("/");
    };

    const handleOnClickCustomLevel1 = (custom: any) => {
        dispatch(clearCustomLevel2Id());
        dispatch(clearCustomLevel2GroupDetails());

        dispatch(setGroupId(custom?.id));
        dispatch(setIsAmpGroup(custom.isAmpGroup));
        dispatch(setGroupType(custom.ampGroupType));

        dispatch(setCustomLevel1Id(custom?.id));
        dispatch(setCustomLevel1GroupDetails(custom));

        navigate("/");
    };

    const handleOnClickCustomLevel2 = (custom: any) => {
        dispatch(clearCustomLevel3Id());
        dispatch(clearCustomLevel3GroupDetails());

        dispatch(setGroupId(custom?.id));
        dispatch(setIsAmpGroup(custom.isAmpGroup));
        dispatch(setGroupType(custom.ampGroupType));

        dispatch(setCustomLevel2Id(custom?.id));
        dispatch(setCustomLevel2GroupDetails(custom));

        navigate("/");
    };

    const handleOnClickCustomLevel3 = (custom: any) => {
        dispatch(setGroupId(custom?.id));
        dispatch(setIsAmpGroup(custom.isAmpGroup));
        dispatch(setGroupType(custom.ampGroupType));

        dispatch(setCustomLevel3Id(custom?.id));
        dispatch(setCustomLevel3GroupDetails(custom));

        navigate("/");
    };

    return (
        <div className='bg-darkcolor-800 content-wrapper'>
            <Loading isLoading={isLoading} text={""} />
            <header
                className={`flex bg-darkcolor-700 text-white px-[15px] xl:px-[32px] py-[12px]`}
            >
                <div className='flex flex-wrap items-center breadcrumb-textblock'>
                    {customerGroupDetails?.isAmpGroup ? (
                        <>
                            <label
                                className='text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer'
                                onClick={() =>
                                    Object.keys(customerGroupDetails).length > 0 &&
                                    handleOnClickCustomer(customerGroupDetails)
                                }
                            >
                                {customerGroupDetails?.groupName}
                            </label>
                            {Object.keys(siteGroupDetails).length > 0 && (
                                <>
                                    <label className='text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline'>
                                        &nbsp;\&nbsp;
                                    </label>
                                    <label
                                        className='text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer'
                                        onClick={() =>
                                            Object.keys(siteGroupDetails).length > 0 &&
                                            handleOnClickSites(siteGroupDetails, customerGroupDetails)
                                        }
                                    >
                                        {siteGroupDetails?.groupName}
                                    </label>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <label
                                className='text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer'
                                onClick={() =>
                                    Object.keys(customLevel2Details).length > 0 &&
                                    handleOnClickCustomLevel1(customLevel1Details)
                                }
                            >
                                {customLevel1Details?.groupName}
                            </label>
                            {Object.keys(customLevel2Details).length > 0 && (
                                <>
                                    <label className='text-neutral-25 text-[23px]'>
                                        &nbsp;\&nbsp;
                                    </label>
                                    <label
                                        className='text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer'
                                        onClick={() =>
                                            Object.keys(customLevel3Details).length > 0 &&
                                            handleOnClickCustomLevel2(customLevel2Details)
                                        }
                                    >
                                        {customLevel2Details?.groupName}
                                    </label>
                                    {Object.keys(customLevel3Details).length > 0 && (
                                        <>
                                            <label className='text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline'>
                                                &nbsp;\&nbsp;
                                            </label>
                                            <label
                                                className='text-primary-400 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] cursor-pointer'
                                                onClick={() =>
                                                    Object.keys(customLevel3Details).length > 0 &&
                                                    handleOnClickCustomLevel3(customLevel3Details)
                                                }
                                            >
                                                {customLevel3Details?.groupName}
                                            </label>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    <label className='text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0] bline'>
                        &nbsp;\&nbsp;
                    </label>
                    <label className='text-neutral-25 text-[1.2em] xl:text-[1.44em] py-[8px] lg:py-[0]'>
                        Setpoint optimization
                    </label>
                </div>
            </header>
            <hr className='w-full h-[2px] mx-auto my-0 bg-darkcolor-300 border-0 rounded md:my-0 dark:bg-darkcolor-300' />
            <div className={`flex relative items-center justify-center ${activeTab !== 2 ? 'setpoint-headBlock' : null}`}>
                <Tabs
                    activeTab={activeTab}
                    onChange={handleTabChange}
                    className='bg-darkcolor-700'
                    tabs={tabs}
                />
                <div className='absolute top-[5px] right-0 mr-[15px] xl:mr-[30px] setpoint-iconsBlock'>
                    <div className='flex justify-center items-center col-end-12'>
                        {activeTab === 0 ? (
                            <div className='relative cursor-pointer mr-[8px]'>
                                <Tooltip content='Refresh' direction='top'>
                                    <HiRefresh onClick={fetchData} size={18} />
                                </Tooltip>
                            </div>
                        ) : (
                            activeTab === 1 && (
                                <div className='relative cursor-pointer mr-[8px]'>
                                    {/* <Tooltip content="Upload" direction="top">
                                        <RiDownloadLine
                                            className="text-neutral-25 rotate-180"
                                            size={18}
                                        />
                                    </Tooltip> */}
                                </div>
                            )
                        )}
                        {activeTab !== 1 && activeTab <= 1 && (
                            <div className='relative w-[1px] h-[35px] bg-neutral-25 mr-[8px]' />
                        )}
                        {activeTab <= 1 && (
                            <div className='mr-[8px] cursor-pointer'>
                                {activeTab === 0 ? (
                                    <button
                                        onClick={handleRecommendationAll}
                                        type='button'
                                        className={`border-primary-700 rounded-[8px] bg-primary-600 hover:bg-primary-700 gap-[8px] outline-none inline-flex justify-between items-center w-full px-[14px] py-[8px] border-[1px]  text-[16px] font-medium rounded-[8px]focus:outline-none`}
                                    >
                                        <FiSettings />
                                        <div className='flex items-center text-[14px]'>
                                            Implement all recommendations
                                        </div>
                                    </button>
                                ) : (
                                    <>
                                        {enableManualAllBtn ? (
                                            <button
                                                onClick={handleRecommendationAllManual}
                                                type='button'
                                                className={`border-primary-700 rounded-[8px] bg-primary-600 hover:bg-primary-700 gap-[8px] outline-none inline-flex justify-between items-center w-full px-[14px] py-[8px] border-[1px]  text-[16px] font-medium rounded-[8px]focus:outline-none`}
                                            >
                                                <FiSettings />
                                                <div className='flex items-center text-[14px]'>
                                                    Implement all new
                                                </div>{" "}
                                            </button>
                                        ) : (
                                            <button
                                                disabled
                                                className={`border-white border-[1px] rounded-[8px] background: #131F2E opacity-50 cursor-not-allowed hover:bg-primary-600 gap-[8px] outline-none inline-flex items-center w-full px-[14px] py-[8px]  text-[16px] font-medium rounded-[8px]focus:outline-none`}
                                            >
                                                <FiSettings />
                                                <div className='flex items-center text-[14px]'>
                                                    Implement all new
                                                </div>{" "}
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetPointOptimization;
