import React from "react";
import moment from "moment";

interface Alarm {
    priority: number;
    alarmSetupId?: string;
    alert: string;
}

interface AlarmNotification {
    sitename: string;
    lastSetOn: string;
    alarms: Alarm[];
    groupsPath: string;
}

interface Props {
    alarmNotificationsData: AlarmNotification[];
    isLoading: boolean;
}

const AlarmList: React.FC<Props> = ({ alarmNotificationsData, isLoading }) => {
    return (
        <div className='alarm-list'>
            {alarmNotificationsData.length > 0 &&
                alarmNotificationsData.map((item, index) => (
                    <div
                        className='flex flex-col flex-wrap rounded-[4px] bg-darkcolor-700 border-darkcolor-300 mb-[10px] p-[8px] border'
                        key={index}
                    >
                        <div className='flex justify-between'>
                            <div className='flex pr-1 mb-1 grow'>
                                <p className='text-[16px] pr-1 font-semibold grow text-neutral-25'>
                                    {item.sitename}{" "}
                                </p>
                                <p className='text-[12px] text-neutral-200 min-w-[60px]'>
                                    {moment(item.lastSetOn).utc().format("hh:mm A")}{" "}
                                </p>
                            </div>
                            <div className='w-[8px] h-[8px] bg-primary-400 rounded-full mt-[5px]'></div>
                        </div>
                        <div className='flex flex-wrap'>
                            {item.alarms.length > 0 &&
                                item.alarms.map((element) => (
                                    <div
                                        className='mr-[4px] flex  px-[8px] py-[4px] text-[12px] justify-center items-center rounded-[4px] border mb-[10px]'
                                        style={{
                                            color:
                                                element.priority === 4
                                                    ? "#F04438"
                                                    : element.priority === 3
                                                        ? "#6BBBAE"
                                                        : element.priority === 2
                                                            ? "#FDB022"
                                                            : element.priority === 1
                                                                ? "#B8C5CC"
                                                                : "",
                                            borderColor:
                                                element.priority === 4
                                                    ? "#F04438"
                                                    : element.priority === 3
                                                        ? "#6BBBAE"
                                                        : element.priority === 2
                                                            ? "#FDB022"
                                                            : element.priority === 1
                                                                ? "#B8C5CC"
                                                                : "",
                                        }}
                                        key={element?.alarmSetupId}
                                    >
                                        {element.alert}
                                    </div>
                                ))}
                        </div>
                        <div className='text-[10px] text-neutral-25'>
                            {item?.groupsPath}
                        </div>
                    </div>
                ))}
            {isLoading && <p>Loading...</p>}
        </div>
    );
};

export default AlarmList;
