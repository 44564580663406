import "./Searchbar.scss";
import Search from "../Svg/Search";
import { IoMdClose } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa";
import React, {
    useState,
    useRef,
    useLayoutEffect,
    MutableRefObject,
    useEffect,
} from "react";

interface SearchBarProps {
    handleGet?: any;
    headers?: any;
    setFreeText?: any;
    freeText?: string;
    getValuesByKey: (value: any) => void;
    handleSet?: any;
}

const SearchBar: React.FC<SearchBarProps> = ({
    handleGet,
    headers,
    setFreeText,
    freeText,
    getValuesByKey,
    handleSet,
}) => {
    const [numbers] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);
    const [searchData, setSearchData] = useState<any>([]);
    const [searchKey, setSearchKey] = useState<string>("");
    const [duplicateSearchKey, setDuplicateSearchKey] = useState<string>("");
    const [columnsValues, setColumnsValues] = useState<{ value: string }[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [isKeyDropdownOpen, setKeyDropdownOpen] = useState<boolean>(false);
    const [isValueDropdownOpen, setValueDropdownOpen] = useState<boolean>(false);
    const [duplicateSearchData, setDuplicateSearchData] = useState({});
    const [filterKey, setFilterKey] = useState<{ key: string; value: string }[]>(
        []
    );
    const ref = useRef() as MutableRefObject<HTMLDivElement>;
    const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
    const dropdownKeyRef = useRef() as MutableRefObject<HTMLUListElement>;
    const dropdownValueRef = useRef() as MutableRefObject<HTMLUListElement>;

    useLayoutEffect(() => {
        setWidth(ref.current.clientWidth + 2);
    }, [numbers]);

    useEffect(() => {
        function handleWindowResize() {
            setWidth(ref.current.clientWidth + 2);
        }
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideDropdown);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideDropdown);
        };
    }, []);

    useEffect(() => {
        const duplicate: any = [];
        if (handleSet && Object.keys(handleSet).length > 0) {
            setDuplicateSearchData(handleSet);
            Object.keys(handleSet).forEach((item: any) => {
                duplicate.push({ [headers[item]]: handleSet[item] });
            });
            setSearchData(duplicate);
        }
        if (!freeText) {
            setSearchValue("");
        }
        if (freeText) {
            const duplicateValues: any = [...duplicate];
            duplicateValues.push({ "contains": freeText })
            setSearchData(duplicateValues)
        }
        if(freeText && searchValue) {
            setSearchValue("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headers, handleSet]);

    const handleClickOutsideDropdown = (event: MouseEvent) => {
        if (
            dropdownKeyRef.current &&
            !dropdownKeyRef.current.contains(event.target as Node)
        ) {
            setKeyDropdownOpen(false);
        } else if (
            dropdownValueRef.current &&
            !dropdownValueRef.current.contains(event.target as Node)
        ) {
            setValueDropdownOpen(false);
        }
    };

    const handleOnChange = (value: string) => {
        const duplicate: any = [...searchData];
        setSearchValue(value);
        if (value) {
            if (
                value.endsWith(" ") &&
                !value.endsWith(", ") &&
                duplicate.length > 0
            ) {
                setValueDropdownOpen(true);
            } else {
                setValueDropdownOpen(false);
                if (value.trim()) {
                    if (headers) {
                        const filterData: { key: string; value: string }[] = Object.entries(
                            headers
                        )
                            .filter(([key]) =>
                                key
                                    .toLowerCase()
                                    .startsWith(
                                        value.toLowerCase().replace(",", "").replace(" ", "")
                                    )
                            )
                            .map(([key, value]) => ({ key, value: value as string }));
                        if (filterData.length > 0) {
                            if (Object.entries(headers).length !== filterData.length) {
                                setFilterKey(filterData);
                                setKeyDropdownOpen(true);
                            }
                        } else {
                            setKeyDropdownOpen(false);
                            if (value) {
                                setFreeText(value.replaceAll(",", ""));
                                if (value.startsWith(",") && value.endsWith(",")) {
                                    const findIndexOfContainsKey = duplicate.findIndex((obj:any) => obj.hasOwnProperty('contains'))
                                    if(findIndexOfContainsKey !== -1) {
                                        duplicate.splice(findIndexOfContainsKey, 1);
                                    }
                                    duplicate.push({ contains: value.replaceAll(",", "") });
                                    setSearchData(duplicate);
                                    setSearchValue("");
                                    handleGet(duplicateSearchData, value.replaceAll(",", ""));
                                } else if (searchData.length === 0 && value.endsWith(",")) {
                                    duplicate.push({ contains: value.replaceAll(",", "") });
                                    setSearchData(duplicate);
                                    setSearchValue("");
                                    handleGet({}, value.replaceAll(",", ""));
                                }
                            }
                        }
                    }
                }
            }
        } else {
            setKeyDropdownOpen(false);
        }
    };

    const handleClick = async (key: { key: string; value: string }) => {
        let duplicate: any = [...searchData];
        let duplicateData: any = { ...duplicateSearchData };
        const filterData: any = await getValuesByKey(key.key);
        if (filterData.length > 0) setColumnsValues(filterData[0].values);
        else {
            setKeyDropdownOpen(false);
            setSearchValue("");
            return false;
        }
        duplicate.push({ [key.value]: [] });
        if (!duplicateData.hasOwnProperty(key.key)) {
            duplicateData[key.key] = [];
        }

        if (filterData.length > 0) {
            setSearchKey(key.key);
            setDuplicateSearchKey(key.value);
        }
        setKeyDropdownOpen(false);
        setValueDropdownOpen(true);
        setSearchValue("");
        setSearchData(duplicate);
        setDuplicateSearchData(duplicateData);
        inputRef?.current.focus();
    };

    const handleValueClick = (value: any) => {
        let duplicate: any = [...searchData];
        let duplicateData: any = { ...duplicateSearchData };
        const filtersValue = columnsValues.filter(
            (item: any) => item.value !== value
        );
        setColumnsValues(filtersValue);
        duplicate[searchData.length - 1][duplicateSearchKey].push({ value: value });
        duplicateData[searchKey].push({ value: value });
        setSearchData(duplicate);
        setDuplicateSearchData(duplicateData);
        setValueDropdownOpen(false);
        setSearchValue("");
        inputRef?.current.focus();
        handleGet(duplicateData, freeText);
    };

    const removeSearchBarInput = () => {
        setFreeText("");
        setSearchData([]);
        setSearchValue("");
        setKeyDropdownOpen(false);
        setValueDropdownOpen(false);
        setDuplicateSearchData({});
        handleGet({}, "");
    };

    const getKeyByValue = (object: any, value: any) => {
        return Object.keys(object).find((key) => object[key] === value);
    };

    const removeSearchbarValue = (
        key: string,
        keyIndex: number,
        index: number
    ) => {
        const duplicateData: any = JSON.parse(JSON.stringify(duplicateSearchData));
        const duplicate: any = [...searchData];
        if (duplicate[keyIndex][key].length > 1) {
            duplicate[keyIndex][key].splice(index, 1);
        } else {
            duplicate.splice(keyIndex, 1);
        }
        const keyCheck: any = getKeyByValue(headers, key);
        if (duplicateData[keyCheck].length > 1) {
            duplicateData[keyCheck].splice(index, 1);
            setDuplicateSearchData(duplicateData);
            handleGet(duplicateData, freeText);
        } else {
            const { [keyCheck]: removedProperty, ...duplicateNewObject } =
                duplicateData;
            setDuplicateSearchData(duplicateNewObject);
            handleGet(duplicateNewObject, freeText);
        }
        setSearchData(duplicate);
    };

    const freeTextRemove = (keyIndex: number) => {
        const duplicate = [...searchData];
        duplicate.splice(keyIndex, 1);
        setSearchData(duplicate);
        handleGet(duplicateSearchData, "");
    };

    const getKeysString = (obj: any): string => {
        return Object.keys(obj).join(", ");
    };

    const changeDesignInputFieldSearch = () => {
        return (
            <>
                {searchData.map((item: any, itemKey: number) => {
                    const keysString = getKeysString(item);
                    return (
                        <div
                            className='flex items-center relative'
                            key={itemKey}
                        >
                            <span className='relative inline-block whitespace-pre text-neutral-25'>
                                {keysString !== "contains" ? keysString + ":" : ""}
                            </span>
                            {keysString !== "contains" ? (
                                item[keysString].map(
                                    (val: { value: string }, index: number) => {
                                        return (
                                            <span
                                                className='flex py-[4px] px-[12px] items-center gap-[8px] bg-primary-50 text-darkcolor-800 rounded-[4px] mr-1 custom'
                                                key={index}
                                            >
                                                <span>{val.value}</span>
                                                <span>
                                                    <IoMdClose
                                                        className='text-darkcolor-800 cursor-pointer'
                                                        onClick={() =>
                                                            removeSearchbarValue(
                                                                Object.keys(item)[0],
                                                                itemKey,
                                                                index
                                                            )
                                                        }
                                                    />
                                                </span>
                                            </span>
                                        );
                                    }
                                )
                            ) : (
                                <span className='flex py-[4px] px-[12px] items-center gap-[8px] bg-primary-50 text-darkcolor-800 rounded-[4px] mr-1'>
                                    <span> Contains "{item[keysString].replace(",", "")}"</span>
                                    <IoMdClose
                                        className='text-darkcolor-800 cursor-pointer'
                                        onClick={() => {
                                            freeTextRemove(itemKey);
                                        }}
                                    />
                                </span>
                            )}
                        </div>
                    );
                })}
            </>
        );
    };

    const focusOnInput = () => {
        if (inputRef.current) inputRef.current.focus();
    };

    return (
        <div
            className='flex flex-1 max-w-full relative z-[4] gap-[8px] justify-between min-h-[32px]'
            style={{ flexFlow: "row", gridArea: 1 / 2 }}
        >
            <div
                ref={ref}
                onClick={focusOnInput}
                className={`${isKeyDropdownOpen || isValueDropdownOpen
                    ? "rounded-t-[6px]"
                    : "rounded-[6px]"
                    } relative grid items-center pl-[12px] pr-[8px] max-w-full shrink grow min-h-[32px] grid-cols-[auto_1fr] gap-[8px] min-w-[20px] border-darkcolor-300 border-[1px] border-solid`}
            >
                <div className='flex items-center justify-center'>
                    <Search
                        size={20}
                        className='inline-block select-none align-text-bottom overflow-visible text-neutral-25'
                    />
                </div>
                <div
                    className='relative flex items-center z-[2] h-full w-full overflow-x-auto overflow-y-hidden ant-table-content cursor-text'
                    style={{ maxHeight: "unset" }}
                >
                    <div
                        className='select-none whitespace-nowrap text-[14px] p-0 flex'
                        style={{ wordBreak: "break-word" }}
                    >
                        {changeDesignInputFieldSearch()}
                    </div>
                    <div className='h-full w-[10px] py-[10px]'>
                        <input
                            onChange={(e) => handleOnChange(e.target.value)}
                            name='filter'
                            className='w-[300px] text-neutral-25 border-0 p-0 shadow-none flex resize-none outline-none bg-transparent caret-white text-[14px] flex-1 h-full'
                            style={{
                                overflow: "auto hidden",
                                maxHeight: "unset",
                                minWidth: "15rem",
                            }}
                            value={searchValue}
                            placeholder={
                                searchData.length === 0 ? "Filter by keyword or by field" : ""
                            }
                            ref={inputRef}
                        />
                    </div>
                </div>
                {searchData && searchData.length > 0 && (
                    <div className='absolute z-10 right-[10px]'>
                        <IoMdClose
                            size={20}
                            onClick={removeSearchBarInput}
                            className={`inline-block select-none align-text-bottom cursor-pointer text-neutral-25`}
                        />
                    </div>
                )}
                {isKeyDropdownOpen && (
                    <ul
                        className={`absolute top-[44px] left-[-1px] min-w-[300px] border-darkcolor-400 border-[1px] rounded-b-lg`}
                        style={{ width: width }}
                        ref={dropdownKeyRef}
                    >
                        {filterKey &&
                            filterKey.map(
                                (key: { key: string; value: string }, index: number) => (
                                    <li
                                        key={index}
                                        onClick={() => handleClick(key)}
                                        className={`${index === filterKey.length - 1
                                            ? "rounded-b-lg"
                                            : "border-b-[1px]"
                                            }
                            cursor-pointer border-darkcolor-400 py-[5px] px-[8.5px] flex justify-between items-center leading-[24px] text-[16px] bg-darkcolor-800 hover:bg-darkcolor-600 hover:text-neutral-25`}
                                    >
                                        <span>{key.value}</span>
                                        <FaAngleRight />
                                    </li>
                                )
                            )}
                    </ul>
                )}
                {isValueDropdownOpen && (
                    <ul
                        className={`${columnsValues.length > 3 ? "h-[300px] overflow-y-auto" : ""
                            } absolute top-[44px] left-[-1px] min-w-[300px] border-darkcolor-400 border-[1px] rounded-b-lg`}
                        style={{ width: width }}
                    >
                        {columnsValues.length > 0 &&
                            columnsValues.map((item: { value: string }, index: number) => (
                                <li
                                    key={index}
                                    onClick={() => handleValueClick(item.value)}
                                    className={` ${index === columnsValues.length - 1
                                        ? "rounded-b-lg"
                                        : "border-b-[1px]"
                                        } cursor-pointer border-darkcolor-400 py-[5px] px-[8.5px] flex justify-between items-center leading-[24px] text-[16px] bg-darkcolor-800 hover:bg-darkcolor-600 hover:text-neutral-25`}
                                >
                                    <span>{item.value}</span>
                                    <FaAngleRight />
                                </li>
                            ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default SearchBar;
