import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface ActionPayload {
  groupId: string;
  groupType: string;
  isAmpGroup: boolean;
  selectedGroupData: any;
  parentGroupData: any;
  parentGroupFlag: boolean;
  siteGroupData: any;
  routeGroupData: any;
  isSiteDropdown: boolean;
  isRouteDropdown: boolean;
  token: string;
  userId: string;
  breadcrumbData: any;
  firstTimeLoggedIn: boolean;
  siteId: string;
  flag: boolean;
  custom1stLevel: any
  custom2ndLevel: { groupName: any; _id: any; }
  records: any[];
  tabActive: number;
}
const initialState: ActionPayload = {
  groupId: "",
  groupType: "",
  isAmpGroup: false,
  selectedGroupData: {},
  parentGroupData: {},
  parentGroupFlag: false,
  siteGroupData: {},
  routeGroupData: {},
  isSiteDropdown: false,
  isRouteDropdown: false,
  token: "",
  userId: "",
  breadcrumbData: {},
  firstTimeLoggedIn: false,
  siteId: "",
  flag: false,
  custom1stLevel: {},
  custom2ndLevel: { groupName: "", _id: "" },
  records: [],
  tabActive: 0
};

const GroupSitesAssets = createSlice({
  name: "GroupSitesAssets",
  initialState,
  reducers: {
    setGroupId: (state: any, action: PayloadAction<string>) => {
      state.groupId = action.payload;
    },
    clearGroupId: (state: any) => {
      state.groupId = "";
    },
    setGroupType: (state: any, action: PayloadAction<string>) => {
      state.groupType = action.payload;
    },
    clearGroupType: (state: any) => {
      state.groupType = "";
    },
    setIsAmpGroup: (state: any, action: PayloadAction<boolean>) => {
      state.isAmpGroup = action.payload;
    },
    clearIsAmpGroup: (state: any) => {
      state.isAmpGroup = false;
    },
    setGroupData: (state: any, action: PayloadAction<ActionPayload>) => {
      state.selectedGroupData = action.payload
    },
    clearGroupData: (state: any) => {
      state.selectedGroupData = {}
    },
    setParentGroupData: (state: any, action: PayloadAction<ActionPayload>) => {
      state.parentGroupData = action.payload;
    },
    clearParentGroupData: (state: any) => {
      state.parentGroupData = {}
    },
    setSiteGroupData: (state: any, action: PayloadAction<ActionPayload>) => {
      state.siteGroupData = action.payload;
    },
    clearSiteGroupData: (state: any) => {
      state.siteGroupData = {}
    },
    setRouteGroupData: (state: any, action: PayloadAction<ActionPayload>) => {
      state.routeGroupData = action.payload
    },
    clearRouteGroupData: (state: any) => {
      state.routeGroupData = {}
    },
    setSiteDropdownFlag: (state: any) => {
      state.isSiteDropdown = true
    },
    clearSiteDropdownFlag: (state: any) => {
      state.isSiteDropdown = false
    },
    setRouteDropdownFlag: (state: any) => {
      state.isRouteDropdown = true
    },
    clearRouteDropdownFlag: (state: any) => {
      state.isRouteDropdown = false
    },
    setToken: (state: any, action: PayloadAction<ActionPayload>) => {
      state.token = action.payload
    },
    setReference: (state: any, action: PayloadAction<ActionPayload>) => {
      state.reference = action.payload
    },
    setUserId: (state: any, action: PayloadAction<ActionPayload>) => {
      state.userId = action.payload
    },
    setBreadcrumbData: (state: any, action: PayloadAction<ActionPayload>) => {
      state.breadcrumbData = action.payload
    },
    setFirstTimeLoggedIn: (state: any) => {
      state.firstTimeLoggedIn = true
    },
    clearFirstTimeLoggedIn: (state: any) => {
      state.firstTimeLoggedIn = false;
    },
    setSiteId: (state: any, action: PayloadAction<string>) => {
      state.siteId = action.payload;
    },
    clearSiteId: (state: any) => {
      state.siteId = "";
    },
    setFirstTimeApiCall: (state: any) => {
      state.flag = true;
    },
    clearFirstTimeApiCall: (state: any) => {
      state.flag = false;
    },
    setCustom1stLevel: (state: any, action: any) => {
      state.custom1stLevel = action.payload
    },
    setCustom2ndLevel: (state: any, action: any) => {
      state.custom2ndLevel = action.payload
    },
    setTableRecords: (state: any, action: any) => {
      state.records = action.payload
    },
    setTabActive: (state, action: PayloadAction<number>) => {
      state.tabActive = action.payload;
    },
  },
});

export const {
  setGroupId,
  clearGroupId,
  setGroupType,
  clearGroupType,
  setIsAmpGroup,
  clearIsAmpGroup,
  setGroupData,
  clearGroupData,
  setParentGroupData,
  clearParentGroupData,
  setSiteGroupData,
  clearSiteGroupData,
  setRouteGroupData,
  clearRouteGroupData,
  setSiteDropdownFlag,
  clearSiteDropdownFlag,
  setRouteDropdownFlag,
  clearRouteDropdownFlag,
  setToken,
  setUserId,
  setBreadcrumbData,
  setFirstTimeLoggedIn,
  clearFirstTimeLoggedIn,
  setSiteId,
  clearSiteId,
  setFirstTimeApiCall,
  clearFirstTimeApiCall,
  setCustom1stLevel,
  setCustom2ndLevel,
  setTableRecords,
  setTabActive
} = GroupSitesAssets.actions;

export default GroupSitesAssets;
