import moment from "moment";
import html2canvas from "html2canvas";
import { FaEdit } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { FaListUl } from "react-icons/fa";
import { SketchPicker } from "react-color";
import CheckboxList from "../DrawerCheckbox";
import ChartContainer from "../ChartContainer";
import Drawer from "../../common/Drawer/Drawer";
import Toggle from "../../common/Toggle/Toggle";
import CustomModal from "../../common/Modal/Modal";
import Stepper from "../../common/Stepper/Stepper";
import Loading from "../../common/Loading/Loading";
import Tooltip from "../../common/Tooltip/Tooltip";
import { MdOutlineFileDownload } from "react-icons/md";
import AnalysisCard from "../../common/Cards/AnalysisCard";
import React, { ChangeEvent, useEffect, useState } from "react";
import DateRangePicker from "../../common/DatePicker/DateRangePicker";
import AnalysisDropdown from "../../common/Dropdown/AnalysisDropdown";
import { chartModeOption, stackOptions } from "../../../constants/dropdowns";
import SiteSummaryDropdown from "../../common/Dropdown/SiteSummaryTableDropdown";
import {
    createAddSiteUserPreference,
    getAnalysisTimeSeriesData,
    getChartsAnalysis,
    getDataPointsAnalysis,
    getDataPointsGroupsAnalysis,
    updateSiteUserPreference,
} from "../../../services/siteDetails/siteDetails.service";

interface AnalysisInterface {
    tankId: string;
}

type DataItem = {
    label: string;
    color: string;
};

const defaultStartDate = new Date();
const defaultEndDate = new Date();
defaultStartDate.setDate(defaultStartDate.getDate() - 7);

const Analysis: React.FC<AnalysisInterface> = ({ tankId }) => {
    const [stack1, setStack1] = useState<any>([]);
    const [stack2, setStack2] = useState<any>([]);
    const [stack3, setStack3] = useState<any>([]);
    const [stack4, setStack4] = useState<any>([]);
    const [title, setTitle] = useState<string>("");
    const [allStack, setAllStack] = useState<any>([]);
    const [chartId, setChartId] = useState<string>("");
    const [chartRecords, setChartRecords] = useState([]);
    const [chartName, setChartName] = useState<string>("");
    const [stackName, setStackName] = useState<string>("");
    const [activeStep, setActiveStep] = useState<number>(0);
    const [tankData, setTankData] = useState<DataItem[]>([]);
    const [pumpData, setPumpData] = useState<DataItem[]>([]);
    const [chartSeriesData, setChartSeriesData] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [statusData, setStatusData] = useState<DataItem[]>([]);
    const [statusRegister, setStatusRegister] = useState<any>([]);
    const [pumpProperties, setPumpProperties] = useState<any>([]);
    const [tankProperties, setTankProperties] = useState<any>([]);
    const [selectedChart, setSelectedChart] = useState<string>("");
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [openDropdown, setOpenDropdown] = useState<number | null>(null);
    const [configurationData, setConfigurationData] = useState<any[]>([]);
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
    const [selectedChartMode, setSelectedChartMode] = useState<boolean>(false);
    const [isOpenDropdownSiteSummary, setIsOpenDropdownSiteSummary] =
        useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState<
        [Date | null, Date | null]
    >([defaultStartDate, defaultEndDate]);
    const [isOpenChartModeDropdown, setIsOpenChartModeDropdown] =
        useState<boolean>(false);
    const [duplicateSelectedChartMode, setDuplicateSelectedChartMode] = useState<boolean>(false);

    const steps = ["Data points", "Style", "Plot"];

    useEffect(() => {
        gettingCharts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tankId]);

    useEffect(() => {
        const fetchData = async () => {
            await gettingDataPointsRecords(selectedChart);
            await gettingAnalysisTimeSeriesData(selectedChart);
        };
        if (selectedChart) fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChart]);

    useEffect(() => {
        const fetchApi = async () => {
            await gettingAnalysisTimeSeriesData(selectedChart)
            setStackName("");
        }
        if (stackName) fetchApi();
    }, [stackName]);

    const handleModalClose = () => {
        setModalVisible(false);
        setTitle("Add chart");
        setChartName("");
        handleDrawerOpen(true);
    };

    const gettingCharts = async () => {
        setIsLoading(true);
        try {
            const response = await getChartsAnalysis();
            if (response.success && response.statusCode === 200) {
                if (response?.data.length === 0) {
                    setModalVisible(true);
                } else {
                    const data: any = [];
                    response?.data.forEach((item: any) => {
                        data.push({
                            name: item.chartName,
                            value: item.id,
                            stacked: item.stocked,
                        });
                    });
                    setConfigurationData(data);
                    if (!selectedChart) {
                        setSelectedChart(response?.data[0].chartName);
                        setSelectedChartMode(response?.data[0].stocked);
                        setDuplicateSelectedChartMode(response?.data[0].stocked);
                        setChartId(response?.data[0].id);
                    }

                    if (title.includes("Add")) {
                        setSelectedChart(data[data.length - 1].name);
                        setSelectedChartMode(data[data.length - 1].stacked);
                        setDuplicateSelectedChartMode(data[data.length - 1].stacked);
                        setChartId(data[data.length - 1].value);
                    }

                    if (selectedChart && !title.includes("Add")) {
                        await gettingDataPointsRecords(selectedChart);
                        await gettingAnalysisTimeSeriesData(selectedChart);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const gettingDataPointsRecords = async (selectedValue: string = "") => {
        try {
            setIsLoading(true);
            const response = await getDataPointsGroupsAnalysis(
                tankId,
                selectedValue ? selectedValue : selectedChart
            );
            if (response.success && response.statusCode === 200) {
                setTankData(getUserSelectedGraphLabel(response?.data, "Tank"));
                setPumpData(getUserSelectedGraphLabel(response?.data, "Pump"));
                setStatusData(getUserSelectedGraphLabel(response?.data, "Status"));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const gettingAnalysisTimeSeriesData = async (
        chartNameOpt: string,
        startDate?: any,
        endDate?: any,
    ) => {
        try {
            setIsLoading(true);
            let isoStartDateString = startDate ? startDate + " 00:00:00" : moment(selectedDateRange[0]).format("YYYY-MM-DD") + " 00:00:00";
            let isoEndDateString = endDate ? endDate + " 23:59:59" : moment(selectedDateRange[1]).format("YYYY-MM-DD") + " 23:59:59";
            const response = await getAnalysisTimeSeriesData(
                chartNameOpt,
                tankId,
                isoStartDateString,
                isoEndDateString,
                selectedChartMode
            );

            if (response.statusCode === 200 && response.success) {
                const stack1Records = stackFilterRecords(
                    response?.data,
                    "Stack 1",
                    "stack_1"
                );
                const stack2Records = stackFilterRecords(
                    response?.data,
                    "Stack 2",
                    "stack_2"
                );
                const stack3Records = stackFilterRecords(
                    response?.data,
                    "Stack 3",
                    "stack_3"
                );
                const stack4Records = stackFilterRecords(
                    response?.data,
                    "Stack 4",
                    "stack_4"
                );

                const result: any = [
                    ...stack1Records,
                    ...stack2Records,
                    ...stack3Records,
                    ...stack4Records,
                ];
                result.forEach((item: any, index: number) => {
                    if (result.length - 1 !== index) {
                        item.xAxesLabelVisible = true
                    }
                })
                setChartRecords(result);
                const seriesDataStack1 = dataFounds(response?.data, "Stack 1");
                const seriesDataStack2 = dataFounds(response?.data, "Stack 2");
                const seriesDataStack3 = dataFounds(response?.data, "Stack 3");
                const seriesDataStack4 = dataFounds(response?.data, "Stack 4");

                const results: any = [
                    ...seriesDataStack1,
                    ...seriesDataStack2,
                    ...seriesDataStack3,
                    ...seriesDataStack4,
                ];
                setChartSeriesData(results);
            }
        } catch (error) {
            setChartSeriesData([]);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const stackFilterRecords = (records: any, groupName: string, id: string) => {
        const chartsData: any = [];
        const filterRecords = records.filter(
            (element: any) => element?.stack === groupName
        );
        if (filterRecords.length > 0) {
            const stackArray: any = [];
            filterRecords.forEach((element: any) => {
                stackArray.push({ values: element.values });
            });
            chartsData.push({
                chartId: id,
                xAxesLabelVisible: false,
                xAxesColor: "#91A4AA",
                yAxesLabelVisible: false,
                yAxesColor: filterRecords[0]?.color,
                records: transformData(stackArray),
            });
        }
        return chartsData;
    };

    const transformData = (inputData: any[]) => {
        const records: any[] = [];
        const dates: any = {};
        let maxValues = 0;
        inputData.forEach((item, index) => {
            const dateKey = `date${index}`;
            const valueKey = `value${index}`;
            item.values.forEach((value: any) => {
                if (!dates[dateKey]) {
                    dates[dateKey] = 0;
                }
                dates[dateKey]++;
                maxValues = Math.max(maxValues, dates[dateKey]);
                if (!records[dates[dateKey]]) {
                    records[dates[dateKey]] = {};
                }
                records[dates[dateKey]][dateKey] = new Date(value.time);
                records[dates[dateKey]][valueKey] = value.value;
            });
        });
        return records.filter((item) => item);
    };

    const dataFounds = (data: any, stack: any) => {
        const dummy: any = [];
        const filterRecords = data.filter(
            (element: any) => element?.stack === stack
        );
        if (filterRecords.length > 0) {
            filterRecords.forEach((item: any, index: number) => {
                dummy.push({
                    valueX: `date${index}`,
                    valueY: `value${index}`,
                    color: item.color,
                    strokeDasharray: item.chartType,
                    stack: item?.stack
                });
            });
        }
        return dummy;
    };

    const getUserSelectedGraphLabel = (groups: any, groupName: string) => {
        return (
            groups
                .find((item: { groupName: string }) => item.groupName === groupName)
                ?.datapoints.map((data: { tagName: string; color: string }) => {
                    return {
                        label: data.tagName,
                        color: data.color,
                    };
                }) || []
        );
    };

    const getDataPoints = (
        data: any[],
        groupName: string | boolean,
        key: string
    ): any[] => {
        return data.filter((item: any) => item[key] === groupName) || [];
    };

    const gettingDataPoints = async (isChartAvailable: boolean) => {
        try {
            setIsLoading(true);
            const response = await getDataPointsAnalysis(
                isChartAvailable ? "" : selectedChart
            );
            if (response.success && response.statusCode === 200) {
                setStatusRegister(
                    getDataPoints(response?.data, "Status", "datapointGroup")
                );
                setPumpProperties(
                    getDataPoints(response?.data, "Pump", "datapointGroup")
                );
                setTankProperties(
                    getDataPoints(response?.data, "Tank", "datapointGroup")
                );
                if (!isChartAvailable) {
                    setAllStack(getDataPoints(response?.data, true, "isDefault"));
                    setStack1(getDataPoints(response?.data, "Stack 1", "stack"));
                    setStack2(getDataPoints(response?.data, "Stack 2", "stack"));
                    setStack3(getDataPoints(response?.data, "Stack 3", "stack"));
                    setStack4(getDataPoints(response?.data, "Stack 4", "stack"));
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOnChange = (
        event: ChangeEvent<HTMLInputElement>,
        id: string,
        type: string
    ): void => {
        const stateMap: Record<
            string,
            [any[], React.Dispatch<React.SetStateAction<any[]>>]
        > = {
            Status: [statusRegister, setStatusRegister],
            Pump: [pumpProperties, setPumpProperties],
            Tank: [tankProperties, setTankProperties],
        };

        if (type in stateMap) {
            const [properties, setProperties] = stateMap[type];
            const updatedProperties = properties.map(
                (item: { _id: string; isDefault: boolean }) => {
                    if (item._id === id) {
                        nextCallStep2(item, event.target.checked);
                        return {
                            ...item,
                            isDefault: event.target.checked,
                        };
                    }
                    return item;
                }
            );
            setProperties(updatedProperties);
        }
    };

    const nextCallStep2 = (item: any, checked: boolean) => {
        let duplicateAllStack: any = [...allStack];
        let duplicate: any = [...stack1];
        if (checked) {
            item.stack = "Stack 1";
            item.color = "#fff";
            item.chartType = "Solid";
            duplicate.push(item);
            duplicateAllStack.push(item);
        } else {
            duplicate = duplicate.filter(
                (record: { _id: string }) => record._id !== item._id
            );
            duplicateAllStack = duplicateAllStack.filter(
                (record: { _id: string }) => record._id !== item._id
            );
        }
        setStack1(duplicate);
        setAllStack(duplicateAllStack);
    };

    const handleDateChange = (value: [Date | null, Date | null]) => {
        setSelectedDateRange(value);
        if (value[0] && value[1])
            gettingAnalysisTimeSeriesData(
                selectedChart,
                moment(value[0]).format("YYYY-MM-DD"),
                moment(value[1]).format("YYYY-MM-DD")
            );
    };

    const handleSelect = (selectedOption: string) => {
        setSelectedChartMode(Boolean(selectedOption));
        setDuplicateSelectedChartMode(Boolean(selectedOption));
    };

    const handleOutsideSelectChartName = async (selectedValue: any) => {
        const findRecord = configurationData.find(
            (item: any) => item.value === selectedValue
        );
        if (findRecord && (findRecord.name !== selectedChart)) {
            setChartName(findRecord.name);
            setSelectedChart(findRecord.name);
            setSelectedChartMode(findRecord?.stacked);
            setDuplicateSelectedChartMode(findRecord?.stacked);
            setChartId(findRecord.value);
        }
    };

    const handleNextStep = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handlePrevStep = () => setActiveStep((prevStep) => prevStep - 1);

    const handleDrawerOpen = async (isChartAvailable: boolean) => {
        setIsDrawerOpen(!isDrawerOpen);
        await gettingDataPoints(isChartAvailable);
    };

    const onFinishStep = async (e: any) => {
        e.preventDefault();
        const data: any = [];
        allStack.forEach((item: any) => {
            data.push({
                lookupId: item._id,
                stack: selectedChartMode ? item.stack : "Stack 1",
                color: item.color,
                chartType: item.chartType,
            });
        });
        let response: any = {};
        try {
            setIsLoading(true);
            const request: any = {
                view: data,
                chartName: chartName,
                stocked: selectedChartMode,
            };
            if (title.includes("Modify")) {
                request._id = chartId;
                response = await updateSiteUserPreference(request);
            } else response = await createAddSiteUserPreference(request);
            if (response.success && response.statusCode === 200) {
                await gettingCharts();
                handleDrawerClose();
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    };

    const handleDrawerClose = () => {
        setStack1([]);
        setStack2([]);
        setStack3([]);
        setStack4([]);
        setAllStack([]);
        setChartName("");
        setActiveStep(0);
        setIsDrawerOpen(!isDrawerOpen);
    };

    const moveItemBetweenStacks = (
        sourceStack: any,
        targetStack: any,
        item: any,
        sourceStackName: string,
        tagetStackName: string
    ) => {
        const duplicateSourceStack = [...sourceStack];
        const duplicateTargetStack = [...targetStack];

        const findIndex = duplicateSourceStack.findIndex(
            (record) => record._id === item._id
        );

        if (findIndex !== -1) {
            duplicateSourceStack[findIndex].stack = tagetStackName;

            if (tagetStackName === "Stack 1") {
                duplicateTargetStack.push(duplicateSourceStack[findIndex]);
            } else if (tagetStackName === "Stack 2") {
                duplicateTargetStack.push(duplicateSourceStack[findIndex]);
            } else if (tagetStackName === "Stack 3") {
                duplicateTargetStack.push(duplicateSourceStack[findIndex]);
            } else if (tagetStackName === "Stack 4") {
                duplicateTargetStack.push(duplicateSourceStack[findIndex]);
            }

            duplicateSourceStack.splice(findIndex, 1);

            switch (tagetStackName) {
                case "Stack 1":
                    setStack1(duplicateTargetStack);
                    break;
                case "Stack 2":
                    setStack2(duplicateTargetStack);
                    break;
                case "Stack 3":
                    setStack3(duplicateTargetStack);
                    break;
                case "Stack 4":
                    setStack4(duplicateTargetStack);
                    break;
                default:
                    break;
            }

            switch (sourceStackName) {
                case "Stack 1":
                    setStack1(duplicateSourceStack);
                    break;
                case "Stack 2":
                    setStack2(duplicateSourceStack);
                    break;
                case "Stack 3":
                    setStack3(duplicateSourceStack);
                    break;
                case "Stack 4":
                    setStack4(duplicateSourceStack);
                    break;
                default:
                    break;
            }
        }
    };

    const handleColorChange = (index: any, color: any, id: string) => {
        const updatedStack = [...allStack];
        updatedStack[index].color = color;
        setAllStack(updatedStack);
        const updatedStack1 = [...stack1];
        const findRecord = updatedStack1.findIndex((item: any) => item._id === id);
        if (findRecord !== -1) {
            updatedStack1[findRecord].color = color;
            setStack1(updatedStack1);
        }
    };

    const handleColorCancel = (index: any, id: string) => {
        const updatedStack = [...allStack];
        updatedStack[index].color = "#fff";
        setAllStack(updatedStack);
        const updatedStack1 = [...stack1];
        const findRecord = updatedStack1.findIndex((item: any) => item._id === id);
        if (findRecord !== -1) {
            updatedStack1[findRecord].color = "#fff";
            setStack1(updatedStack1);
        }
        setShowColorPicker(false);
    };

    const handlePlotSelect = (e: any, id: string) => {
        const { value } = e.target;
        const duplicateStack = [...stack1];
        const duplicateAllStack = [...allStack];
        const findIndexAllStack = duplicateAllStack.findIndex(
            (item: { _id: string }) => item._id === id
        );
        if (findIndexAllStack !== -1) {
            duplicateAllStack[findIndexAllStack].chartType = value;
            setAllStack(duplicateAllStack);
        }
        const findIndexStack = duplicateStack.findIndex(
            (item: { _id: string }) => item._id === id
        );
        if (findIndexStack !== -1) {
            duplicateStack[findIndexStack].chartType = value;
            setStack1(duplicateStack);
        }
    };

    const handleDropdownToggle = (id: number) => {
        setOpenDropdown(openDropdown === id ? null : id);
    };

    const renderStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <div className='w-full flex justify-between'>
                        <div className='grid grid-cols-1 md:grid-cols-3 gap-[15px] min-w-full'>
                            <CheckboxList
                                data={statusRegister}
                                title='Status register'
                                type={"Status"}
                                onCheckboxChange={handleOnChange}
                            />
                            <CheckboxList
                                data={pumpProperties}
                                title='Pump properties'
                                type={"Pump"}
                                onCheckboxChange={handleOnChange}
                            />
                            <CheckboxList
                                data={tankProperties}
                                title='Tank properties'
                                type={"Tank"}
                                onCheckboxChange={handleOnChange}
                            />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className='w-full flex justify-between'>
                        {duplicateSelectedChartMode ? (
                            <>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-[15px] min-w-full'>
                                    {/* Stack1 */}
                                    <div className='flex flex-col items-start justify-start p-[12px] rounded-[4px] border-[1px] border-darkcolor-300 bg-darkcolor-700 gap-[10px]'>
                                        <div className='flex items-center'>
                                            <FaListUl className='text-neutral-25 mr-[5px]' />
                                            <label className='text-[16px]'>Stack 1</label>
                                        </div>
                                        <div className='flex flex-wrap relative'>
                                            {stack1.length > 0 &&
                                                stack1.map((item: any, index: number) => (
                                                    <AnalysisDropdown
                                                        stack={item}
                                                        key={index}
                                                        isOpen={openDropdown === item._id}
                                                        onToggle={() => handleDropdownToggle(item._id)}
                                                    >
                                                        {stackOptions.map(
                                                            (option: any, optionIndex: number) => (
                                                                <>
                                                                    {option.name !== "Stack 1" && (
                                                                        <div
                                                                            key={optionIndex}
                                                                            className={`text-[16px] p-[10px] text-sm  hover:bg-darkcolor-600  cursor-pointer text-neutral-25 flex justify-start bg-darkcolor-800 ${optionIndex === stackOptions.length - 1
                                                                                ? "rounded-b-[8px]"
                                                                                : ""
                                                                                }`}
                                                                            onClick={() => {
                                                                                if (option.name === "Stack 2") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack1,
                                                                                        stack2,
                                                                                        item,
                                                                                        "Stack 1",
                                                                                        "Stack 2"
                                                                                    );
                                                                                } else if (option.name === "Stack 3") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack1,
                                                                                        stack3,
                                                                                        item,
                                                                                        "Stack 1",
                                                                                        "Stack 3"
                                                                                    );
                                                                                } else if (option.name === "Stack 4") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack1,
                                                                                        stack4,
                                                                                        item,
                                                                                        "Stack 1",
                                                                                        "Stack 4"
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className='flex'>
                                                                                <p className='ml-[8px]'>
                                                                                    {option.name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )
                                                        )}
                                                    </AnalysisDropdown>
                                                ))}
                                        </div>
                                    </div>
                                    {/* Stack2 */}
                                    <div className='flex flex-col items-start justify-start p-[12px] rounded-[4px] border-[1px] border-darkcolor-300 bg-darkcolor-700 gap-[10px]'>
                                        <div className='flex items-center'>
                                            <FaListUl className='text-neutral-25 mr-[5px]' />
                                            <label className='text-[16px]'>Stack 2</label>
                                        </div>
                                        <div className='flex flex-wrap relative'>
                                            {stack2.length > 0 &&
                                                stack2.map((item: any, index: number) => (
                                                    <AnalysisDropdown
                                                        stack={item}
                                                        key={index}
                                                        isOpen={openDropdown === item._id}
                                                        onToggle={() => handleDropdownToggle(item._id)}
                                                    >
                                                        {stackOptions.map(
                                                            (option: any, optionIndex: number) => (
                                                                <>
                                                                    {option.name !== "Stack 2" && (
                                                                        <div
                                                                            key={optionIndex}
                                                                            className={`text-[16px] p-[10px] text-sm  hover:bg-darkcolor-600 cursor-pointer text-neutral-25 flex justify-start bg-darkcolor-800 ${optionIndex === stackOptions.length - 1
                                                                                ? "rounded-b-[8px]"
                                                                                : ""
                                                                                }`}
                                                                            onClick={() => {
                                                                                if (option.name === "Stack 1") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack2,
                                                                                        stack1,
                                                                                        item,
                                                                                        "Stack 2",
                                                                                        "Stack 1"
                                                                                    );
                                                                                } else if (option.name === "Stack 3") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack2,
                                                                                        stack3,
                                                                                        item,
                                                                                        "Stack 2",
                                                                                        "Stack 3"
                                                                                    );
                                                                                } else if (option.name === "Stack 4") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack2,
                                                                                        stack4,
                                                                                        item,
                                                                                        "Stack 2",
                                                                                        "Stack 4"
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className='flex'>
                                                                                <p className='ml-[8px]'>
                                                                                    {option.name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )
                                                        )}
                                                    </AnalysisDropdown>
                                                ))}
                                        </div>
                                    </div>
                                    {/* Stack3 */}
                                    <div className='flex flex-col items-start justify-start p-[12px] rounded-[4px] border-[1px] border-darkcolor-300 bg-darkcolor-700 gap-[10px]'>
                                        <div className='flex items-center'>
                                            <FaListUl className='text-neutral-25 mr-[5px]' />
                                            <label className='text-[16px]'>Stack 3</label>
                                        </div>
                                        <div className='flex flex-wrap relative'>
                                            {stack3.length > 0 &&
                                                stack3.map((item: any, index: number) => (
                                                    <AnalysisDropdown
                                                        stack={item}
                                                        key={index}
                                                        isOpen={openDropdown === item._id}
                                                        onToggle={() => handleDropdownToggle(item._id)}
                                                    >
                                                        {stackOptions.map(
                                                            (option: any, optionIndex: number) => (
                                                                <>
                                                                    {option.name !== "Stack 3" && (
                                                                        <div
                                                                            key={optionIndex}
                                                                            className={`text-[16px] p-[10px] text-sm  hover:bg-darkcolor-600 cursor-pointer text-neutral-25 flex justify-start bg-darkcolor-800 ${optionIndex === stackOptions.length - 1
                                                                                ? "rounded-b-[8px]"
                                                                                : ""
                                                                                }`}
                                                                            onClick={() => {
                                                                                if (option.name === "Stack 1") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack3,
                                                                                        stack1,
                                                                                        item,
                                                                                        "Stack 3",
                                                                                        "Stack 1"
                                                                                    );
                                                                                } else if (option.name === "Stack 2") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack3,
                                                                                        stack2,
                                                                                        item,
                                                                                        "Stack 3",
                                                                                        "Stack 2"
                                                                                    );
                                                                                } else if (option.name === "Stack 4") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack3,
                                                                                        stack4,
                                                                                        item,
                                                                                        "Stack 3",
                                                                                        "Stack 4"
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className='flex'>
                                                                                <p className='ml-[8px]'>
                                                                                    {option.name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )
                                                        )}
                                                    </AnalysisDropdown>
                                                ))}
                                        </div>
                                    </div>
                                    {/* Stack4 */}
                                    <div className='flex flex-col items-start justify-start p-[12px] rounded-[4px] border-[1px] border-darkcolor-300 bg-darkcolor-700 gap-[10px]'>
                                        <div className='flex items-center'>
                                            <FaListUl className='text-neutral-25 mr-[5px]' />
                                            <label className='text-[16px]'>Stack 4</label>
                                        </div>
                                        <div className='flex flex-wrap relative'>
                                            {stack4.length > 0 &&
                                                stack4.map((item: any, index: number) => (
                                                    <AnalysisDropdown
                                                        stack={item}
                                                        key={index}
                                                        isOpen={openDropdown === item._id}
                                                        onToggle={() => handleDropdownToggle(item._id)}
                                                    >
                                                        {stackOptions.map(
                                                            (option: any, optionIndex: number) => (
                                                                <>
                                                                    {option.name !== "Stack 4" && (
                                                                        <div
                                                                            key={optionIndex}
                                                                            className={`text-[16px] p-[10px] text-sm  hover:bg-darkcolor-600 cursor-pointer text-neutral-25 flex justify-start bg-darkcolor-800 ${optionIndex === stackOptions.length - 1
                                                                                ? "rounded-b-[8px]"
                                                                                : ""
                                                                                }`}
                                                                            onClick={() => {
                                                                                if (option.name === "Stack 1") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack4,
                                                                                        stack1,
                                                                                        item,
                                                                                        "Stack 4",
                                                                                        "Stack 1"
                                                                                    );
                                                                                } else if (option.name === "Stack 2") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack4,
                                                                                        stack2,
                                                                                        item,
                                                                                        "Stack 4",
                                                                                        "Stack 2"
                                                                                    );
                                                                                } else if (option.name === "Stack 3") {
                                                                                    moveItemBetweenStacks(
                                                                                        stack4,
                                                                                        stack3,
                                                                                        item,
                                                                                        "Stack 4",
                                                                                        "Stack 3"
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className='flex'>
                                                                                <p className='ml-[8px]'>
                                                                                    {option.name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )
                                                        )}
                                                    </AnalysisDropdown>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='grid min-w-full'>
                                    <div className='flex flex-col items-start justify-start p-[12px] rounded-[4px] border-[1px] border-darkcolor-300 bg-darkcolor-700 gap-[10px]'>
                                        <div className='flex items-center'>
                                            <FaListUl className='text-neutral-25 mr-[5px]' />
                                            <label className='text-[16px]'>Stack</label>
                                        </div>
                                        <div className='flex flex-wrap relative'>
                                            {allStack.length > 0 &&
                                                allStack.map((item: any, index: number) => (
                                                    <AnalysisDropdown stack={item} key={index} />
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                );
            case 1:
                return (
                    <div className='w-full'>
                        <div className='grid mb-[32px]'>
                            <label className='text-neutral-25 text-[16px]'>
                                Style your data points according to significance
                            </label>
                        </div>
                        <div className='grid rounded-[8px]'>
                            <div className='overflow-x-auto'>
                                <table className='min-w-full custom-table'>
                                    <tbody className='border-t border-darkblue-500'>
                                        {allStack.map((item: any, index: any) => (
                                            <tr
                                                key={index}
                                                className='first:border-t-[1px] first:border-t-darkblue-700'
                                            >
                                                <td className='px-6 py-4 text-neutral-25 text-[16]'>
                                                    {item.tagName}
                                                </td>
                                                <td className='px-6 py-4 text-center'>
                                                    <div className='flex items-center justify-center'>
                                                        <div className='flex items-center justify-center w-[16px] h-[16px] rounded-full bg-darkcolor-700 border-[1px]'>
                                                            <div
                                                                className={`w-[8px] h-[8px] rounded-full text-[12px] cursor-pointer ${item.color !== "" ? item.color : "bg-black"
                                                                    }`}
                                                                style={{
                                                                    backgroundColor:
                                                                        item.color !== "" ? item.color : "bg-black",
                                                                }}
                                                                onClick={() => setShowColorPicker(index)}
                                                            />
                                                        </div>
                                                        {showColorPicker === index && (
                                                            <div className='absolute z-[2px]'>
                                                                <div className='pt-[0] bg-gray-100 rounded-lg shadow-lg bg-darkcolor-700 pb-[10px]'>
                                                                    <SketchPicker
                                                                        color={item.color}
                                                                        onChangeComplete={(color: any) =>
                                                                            handleColorChange(
                                                                                index,
                                                                                color.hex,
                                                                                item._id
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className='mt-4 flex justify-around'>
                                                                        <button
                                                                            className='px-[16px] py-[10px] border-[1px] rounded-[8px] flex justify-center items-center font-bold text-[14px] text-neutral-25 focus:outline-none hover:bg-darkcolor-600'
                                                                            onClick={() =>
                                                                                handleColorCancel(index, item._id)
                                                                            }
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                        <button
                                                                            className={`px-[16px] py-[10px] border-[1px] border-primary-600 rounded-[8px] flex justify-center items-center font-bold text-[14px] text-neutral-25 bg-primary-600 focus:outline-none hover:bg-primary-700`}
                                                                            onClick={() => setShowColorPicker(false)}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className=''>
                                                    <select
                                                        className='mt-2 block w-full border-[1px] border-neutral-25 rounded-[8px] py-1.5 pl-3 pr-10 bg-transparent text-neutral-25 focus:outline-none'
                                                        value={item.chartType ? item.chartType : "Solid"}
                                                        onChange={(e) => handlePlotSelect(e, item._id)}
                                                    >
                                                        <option
                                                            className='bg-darkcolor-800 text-neutral-25 text-[16] border-b-[2px] border-neutral-25'
                                                            value={"Dotted"}
                                                        >
                                                            Dotted
                                                        </option>
                                                        <option
                                                            className='bg-darkcolor-800 text-neutral-25 text-[16] border-b-[2px] border-neutral-25'
                                                            value={"Solid"}
                                                        >
                                                            Solid
                                                        </option>
                                                    </select>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const takeScreenshot = () => {
        setIsLoading(true);
        try {
            const element: any = document.querySelector(".graph-download");
            element.style.setProperty(
                "--Gradient-2",
                "linear-gradient(181deg, #253240, #253240)"
            );
            if (element) {
                html2canvas(element).then((canvas) => {
                    const screenshotUrl = canvas.toDataURL();
                    const link = document.createElement("a");
                    link.href = screenshotUrl;
                    link.download = "screenshot.png";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            }
            element.style.setProperty(
                "--Gradient-2",
                "linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.40) 35.14%)"
            );
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleDropdown = () => {
        setIsOpenDropdownSiteSummary(!isOpenDropdownSiteSummary);
    };

    const toggleDrawerChartModeDropdown = () => {
        setIsOpenChartModeDropdown(!isOpenChartModeDropdown);
    };

    const handleToggleChange = async (e: any) => {
        setSelectedChartMode(e.target.checked);
        setStackName(e.target.checked ? "Normal" : "Stacked")
    };

    return (
        <>
            <Loading isLoading={isLoading} text={""} />
            <div className='flex flex-wrap items-center justify-start lg:justify-end'>
                <div className='flex items-center justify-center mr-[8px] mb-2 lg:mb-0'>
                    <Toggle
                        checked={selectedChartMode}
                        onChange={handleToggleChange}
                        label={selectedChartMode ? "Normal" : "Stacked"}
                    />
                    <Tooltip content='Add Chart' direction='top'>
                        <FaPlus
                            size={20}
                            className='cursor-pointer text-neutral-25 hover:bg-darkcolor-600 hover:py-[2px] px-[2px] rounded-[4px]'
                            onClick={() => {
                                setTitle("Add chart");
                                setChartName("");
                                handleDrawerOpen(true);
                            }}
                        />
                    </Tooltip>
                </div>
                <div className='flex items-center justify-center mr-[8px] mb-2 lg:mb-0'>
                    <Tooltip content='Download Chart' direction='top'>
                        <MdOutlineFileDownload
                            size={24}
                            className='cursor-pointer text-neutral-25 hover:bg-darkcolor-600 hover:py-[2px] px-[2px] rounded-[4px]'
                            onClick={takeScreenshot}
                        />
                    </Tooltip>
                </div>
                <div className='w-[1px] top-[-6px] h-[35px] bg-neutral-25 mr-[8px] mb-2 lg:mb-0' />
                <div className='flex items-center justify-center mr-[8px]  mb-2 lg:mb-0'>
                    <Tooltip content='Edit Chart' direction='top'>
                        <FaEdit
                            size={20}
                            className='cursor-pointer text-neutral-25'
                            onClick={() => {
                                if (configurationData.length > 0) {
                                    setTitle("Modify Chart");
                                    setChartName(selectedChart);
                                    handleDrawerOpen(false);
                                } else { setModalVisible(true); }
                            }}
                        />
                    </Tooltip>
                </div>
                <div className='mr-[8px] mb-2 lg:mb-0'>
                    <DateRangePicker
                        value={selectedDateRange}
                        onChange={handleDateChange}
                    />
                </div>
                <div className='mb-2 lg:mb-0'>
                    <SiteSummaryDropdown
                        options={configurationData}
                        onSelect={handleOutsideSelectChartName}
                        optionHeading='Views'
                        dropdownIcon={true}
                        bgColor='bg-darkcolor-800'
                        selectedOption={selectedChart}
                        isOpen={isOpenDropdownSiteSummary}
                        onToggle={toggleDropdown}
                        width='270px'
                    />
                </div>
            </div>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 lg:col-span-8'>
                    <div
                        className='mt-[20px] min-h-[385px] p-[24px] flex flex-col justify-center items-center rounded-[4px] border-[1px] border-darkcolor-400 graph-download'
                        style={{
                            background:
                                "var(--Gradient-2, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.40) 35.14%))",
                        }}
                    >
                        <ChartContainer
                            charts={chartRecords}
                            seriesData={chartSeriesData}
                        />
                    </div>
                </div>
                <div className='col-span-12 lg:col-span-4 mt-[20px]'>
                    <AnalysisCard title={"Tank"} dataPoints={tankData} />
                    <AnalysisCard
                        title={"Key status registers"}
                        dataPoints={statusData}
                    />
                    <AnalysisCard title={"Pump"} dataPoints={pumpData} />
                </div>
            </div>
            <Drawer
                isOpen={isDrawerOpen}
                onClose={handleDrawerClose}
                title={title}
                width='1174px'
            >
                <div className='grid grid-cols-2 gap-7 px-[16px]'>
                    <div className='flex flex-col'>
                        <label className='text-[14px] font-semibold text-neutral-25 mb-[5px]'>
                            Chart name
                        </label>
                        <input
                            name='chartName'
                            type='text'
                            onChange={(e) => setChartName(e.target.value)}
                            className='bg-darkcolor-700 border-[1px] border-darkcolor-300 rounded-[8px] py-[6px] px-[14px] text-neutral-25'
                            value={chartName}
                            autoCapitalize={"off"}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-[14px] font-semibold text-neutral-25 mb-[5px]'>
                            Chart mode
                        </label>
                        <SiteSummaryDropdown
                            options={chartModeOption}
                            onSelect={handleSelect}
                            optionHeading='Views'
                            dropdownIcon={false}
                            bgColor='bg-darkcolor-800'
                            selectedOption={duplicateSelectedChartMode}
                            isOpen={isOpenChartModeDropdown}
                            onToggle={toggleDrawerChartModeDropdown}
                        />
                    </div>
                </div>
                <div className='px-[16px] my-[10px]'>
                    <Stepper
                        steps={steps}
                        activeStep={activeStep}
                        onChangeStep={setActiveStep}
                        onNextStep={handleNextStep}
                        onPrevStep={handlePrevStep}
                        isLastStep={activeStep === steps.length - 1}
                        onCancel={handleDrawerClose}
                        onFinishStep={onFinishStep}
                        content={renderStepContent()}
                    />
                </div>
            </Drawer>
            <CustomModal
                visible={modalVisible}
                title='Warning'
                onClose={handleModalClose}
                onOk={handleModalClose}
            >
                <p className='text-neutral-25'>
                    There is no default graph available. Please create a configuration for
                    the graph.
                </p>
            </CustomModal>
        </>
    );
};

export default Analysis;
