import React from 'react'
import Loading from '../../common/Loading/Loading';
import CustomTable from '../../common/Table/CustomTable';
import DetailsCard from "../../common/Cards/DetailsCards";
import { TankTableColumns } from '../../../constants/columns/TankPumpTable';


interface TankInterface {
    tankDetails: any;
    tankData: any;
    isLoading: any;
}
const bgGradientStyle = { background: "linear-gradient(181.1deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%)" };
const Tank: React.FC<TankInterface> = ({ tankDetails, tankData, isLoading }) => {
    return (
        <div className='tankBlock'>
            <Loading isLoading={isLoading} text={""} />
            <div className='grid gap-[12px] w-full mb-[20px]'>
                <DetailsCard title={"Tank Details"} data={tankDetails} />
            </div>
            <div className='grid gap-[12px] w-full'>
                <CustomTable data={tankData} columns={TankTableColumns} bgColor={bgGradientStyle} />
            </div>
        </div>
    )
}

export default Tank