import instance from "../api";
import { GetPumpResponse } from "./siteDetails.interface";

export const getPump = async (siteId: string) => {
    const params = {
        SiteId: siteId,
    };
    const response = await instance.get<any>("/Site/GetPump", {
        params,
    });
    return response.data;
};

export const getStatus = async (siteId: string) => {
    const params = {
        SiteId: siteId,
    };
    const response = await instance.get<any>("/Site/GetStatus", { params });
    return response.data;
};

export const getTank = async (siteId: string) => {
    const params = {
        SiteId: siteId,
    };
    const response = await instance.get<GetPumpResponse>("/Site/GetTank", {
        params,
    });
    return response.data;
};

export const gettingAlarms = async (
    freeText: string,
    siteId: string,
    sortOrder: string,
    sortBy: string,
    pageNumber: number,
    pageSize: number
) => {
    const params = {
        ...(freeText ? { FreeText: freeText } : {}),
        ...(siteId ? { SiteId: siteId } : {}),
        ...(sortOrder ? { SortOrder: sortOrder } : {}),
        ...(sortBy ? { SortBy: sortBy } : {}),
        ...(pageNumber ? { PageNumber: pageNumber } : {}),
        ...(pageSize ? { PageSize: pageSize } : {}),
    };
    const response = await instance.get<any>("/Alarms", {
        params,
    });
    return response.data;
};

export const gettingAlarmsSetup = async (
    freeText: string,
    siteId: string,
    pageNumber: number,
    pageSize: number
) => {
    const params = {
        ...(freeText ? { FreeText: freeText } : {}),
        ...(siteId ? { SiteId: siteId } : {}),
        ...(pageNumber ? { PageNumber: pageNumber } : {}),
        ...(pageSize ? { PageSize: pageSize } : {}),
    };
    const response = await instance.get<any>("/AlarmSetups", {
        params,
    });
    return response.data;
};

export const gettingDataPointsAlarms = async () => {
    const response = await instance.get<any>("/AlarmSetups/DataPoints");
    return response.data;
};

export const exportAlarms = async (
    freeText: string,
    siteId: string,
    pageNumber: number,
    pageSize: number
) => {
    const params = {
        ...(freeText ? { FreeText: freeText } : {}),
        ...(siteId ? { SiteId: siteId } : {}),
        ...(pageNumber ? { PageNumber: pageNumber } : {}),
        ...(pageSize ? { PageSize: pageSize } : {}),
    };
    const response = await instance.get<any>("/Alarms/Export", {
        params,
    });
    return response.data;
};

export const createAlarmSetup = async (data: any) => {
    const response = await instance.post<any>("/AlarmSetups", data);
    return response.data;
};

export const updateAlarmSetup = async (enterpriseAlarmId: string, siteId: any, data: any) => {
    const response = await instance.put<any>(`/AlarmSetups/${enterpriseAlarmId}/${siteId}`, data);
    return response.data;
};

export const getDataPointsAnalysis = async (
    chartName: string
) => {
    const params = {
        ...(chartName ? { ChartName: chartName } : {}),
    };
    const response = await instance.get<any>("/Site/GetDatapoints", {
        params,
    });
    return response.data;
};

export const getChartsAnalysis = async () => {
    const response = await instance.get<any>("/Site/GetCharts");
    return response.data;
};

export const getAnalysisTimeSeriesData = async (
    chartName: string,
    siteId: string,
    dtFrom: any,
    dtTo: any,
    stacked: boolean
) => {
    const params = {
        ChartName: chartName,
        SiteId: siteId,
        DtFrom: dtFrom,
        DtTo: dtTo,
        Stacked: stacked
    };
    const response = await instance.get<any>("/Site/GetAnalysisTimeseriesData", {
        params,
    });
    return response.data;
};

export const createAddSiteUserPreference = async (data: any) => {
    const response = await instance.post<any>(
        "/Site/AddSiteUserPreference",
        data
    );
    return response.data;
};

export const updateSiteUserPreference = async (data: any) => {
    const response = await instance.put<any>(
        "/Site/UpdateSiteUserPreference",
        data
    );
    return response.data;
};

export const getDataPointsGroupsAnalysis = async (
    siteId: string,
    chartName: string
) => {
    const params = {
        ...(siteId ? { SiteId: siteId } : {}),
        ...(chartName ? { ChartName: chartName } : {}),
    };
    const response = await instance.get<any>("/Site/GetDatapointGroups", {
        params,
    });
    return response.data;
};

export const getSiteInformationImageData = async (
    customParentGroupId: string,
    siteId: string
) => {
    const params = {
        ...(customParentGroupId ? { customParentGroupId: customParentGroupId } : {}),
        ...(siteId ? { SiteId: siteId } : {}),
    };
    const response = await instance.get<any>(
        "/Site/GetSiteInformationImageData",
        {
            params,
        }
    );
    return response.data;
};

export const getHistroySetPoint = async (
    siteId: string,
    channel: number,
    groupId: string,
    groupType: string
) => {
    const params = {
        ...(siteId ? { SiteId: siteId } : {}),
        ...(groupId ? { GroupId: groupId } : {}),
        ...(groupType ? { GroupType: groupType } : {}),
        ...(channel !== undefined && channel !== null ? { Channel: channel } : {}),
    };
    const response = await instance.get<any>("/SetPoints/History", {
        params,
    });
    return response.data;
};

export const updateTankInjectionRate = async (data: any) => {
    const response = await instance.put<any>(
        "/SetPoints/TankInjectionRate",
        data
    );
    return response.data;
};

export const updateMaxPumpOutput = async (data: any) => {
    const response = await instance.put<any>("/SetPoints/MaxPumpOutput", data);
    return response.data;
};

export const getSingleAlarmSetup = async (enterpriseAlarmId: string, siteId: string) => {

    const response = await instance.get<any>(`/AlarmSetups/${enterpriseAlarmId}/${siteId}`);
    return response.data;
}
