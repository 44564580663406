import GroupSitesAssets from "./groupSitesAssets/groupSitesAssets.store";
import AlarmNotifications from "./alarmNotifications/alarmNotifications.store";
import RouteAssetManagment from "./routeAssetManagement/routeAssetManagement.store";
import ProvisionDevice from "./provisionDevice/provisionDevice.store";
import CiaAuthentication from "./authentication/authentication.store";

import storage from "redux-persist/lib/storage";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import RoutesAndAssets from "./routesAndAssets/routesAndAssets.store";

const rootReducer = combineReducers({
  RoutesAndAssets: RoutesAndAssets.reducer,
  GroupSitesAssets: GroupSitesAssets.reducer,
  AlarmNotifications: AlarmNotifications.reducer,
  RouteAssetManagment: RouteAssetManagment.reducer,
  ProvisionDevice: ProvisionDevice.reducer,
  CiaAuthentication: CiaAuthentication.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
export const persistor = persistStore(Store);
export default Store;
