import React from "react";

interface ArrowLongProps {
    size?: number | string;
    className?: string;
    onClick?: () => void;
}

const ArrowLong: React.FC<ArrowLongProps> = ({
    size = 24,
    className,
    onClick,
}) => {
    const style = size ? { width: size, height: size } : undefined;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21'
            height='21'
            viewBox='0 0 21 21'
            fill='none'
            className={className}
            style={style}
            onClick={onClick}
        >
            <path
                d='M5.13211 10.9727L16.7988 10.9503M16.7988 10.9503L10.9542 5.12817M16.7988 10.9503L10.9767 16.7948'
                stroke='#F7F9F9'
                strokeWidth='1.67'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ArrowLong;
