import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/img/login-logo.svg";
import Loginimg2 from "../../../assets/img/login-img2.png";
import Loginimg3 from "../../../assets/img/login-img3.png";
import Loginimg4 from "../../../assets/img/login-img4.png";
import Loginimg5 from "../../../assets/img/login-img5.png";
import { FaTableColumns } from "react-icons/fa6";
import "../Login.scss";
import TokenService from "../../../services/authentication/token.service";

const DefaultSetup: React.FC<any> = ({ page, setPage }) => {
  const navigate = useNavigate();

  const handlePageCount = () => {
    setPage((currPage: any) => currPage + 1);
  };

  const handleSkip = () => {
    navigate("/");
    TokenService.setFirstTimeLogin("false");
  };

  return (
    <div className="flex w-full max-w-full fixed left-0 right-0 top-0 bottom-0 login-wrapper">
      <div className="flex w-full flex-col md:flex-row max-w-full overflow-y-auto login-wrapper__inner">
        <div className="flex w-[100%] md:w-[50%] flex-col grow pl-3 pr-3 pt-12 2xl:pt-20 pb-12 2xl:pb-20 items-center relative justify-center z-20 login-colleft">
          <div className="w-full max-w-[408px] onboarding-steps">
            <img
              src={Logo}
              alt="Logo"
              className="w-full max-w-[242px] mb-8 xxl:mb-10 login-logo"
            />
            <h3 className="h3 h3--bold tracking-[-1.56px] mb-2 xl:mb-3">
              {page === 1
                ? "Default summary view"
                : page === 2
                ? "What’s new?"
                : page === 3
                ? "Awesome analysis"
                : "Beyond status"}
            </h3>
            {page === 1 ? (
              <>
                <p className="p-mid mb-3 2xl:mb-8">
                  The summary view comes with a predefined set of columns that
                  automatically load when you start CIA. Not to worry, you can
                  always personalize this view later on using the customize
                  table option.
                </p>
                <div className="flex justify-between mb-3 2xl:mb-8">
                  <div className="w-[46px] h-[38px] rounded-[150px] bg-darkcolor-600 flex items-center justify-center">
                    <FaTableColumns size={26} className="max-w-[100%]" />
                  </div>
                  <div className="">
                    <span className="inline-block w-[50px] h-[2px] relative bg-primary-300 line-point"></span>
                  </div>
                  <div className="text-primary-300 max-w-[70%]">
                    Use customize feature to modify the default view
                  </div>
                </div>
                <div className="flex flex-col mb-5 form-group">
                  <div className="flex flex-wrap flex-col min-h-5 max-h-[300px] md:max-h-[150px] lg:max-h-[200px] 2xl:max-h-[250px] overflow-y-auto headerList">
                    <div className="mb-2 2xl:mb-5 px-3 innerBlock">
                      <div className="flex flex-wrap justify-between mb-3 2xl:mb-3">
                        <label className="text-base">Site name</label>
                        <FaTableColumns
                          size={20}
                          className="text-darkcolor-400"
                        />
                      </div>
                      <div className="flex flex-wrap justify-between mb-3 2xl:mb-3">
                        <label className="text-base">Alerts</label>
                        <FaTableColumns
                          size={20}
                          className="text-darkcolor-400"
                        />
                      </div>
                      <div className="flex flex-wrap justify-between mb-3 2xl:mb-3">
                        <label className="text-base">Lift type</label>
                        <FaTableColumns
                          size={20}
                          className="text-darkcolor-400"
                        />
                      </div>
                      <div className="flex flex-wrap justify-between mb-3 2xl:mb-3">
                        <label className="text-base">Product volume</label>
                        <FaTableColumns
                          size={20}
                          className="text-darkcolor-400"
                        />
                      </div>
                      <div className="flex flex-wrap justify-between mb-3 2xl:mb-3">
                        <label className="text-base">Offline since</label>
                        <FaTableColumns
                          size={20}
                          className="text-darkcolor-400"
                        />
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <label className="text-base">Voltage</label>
                        <FaTableColumns
                          size={20}
                          className="text-darkcolor-400"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : page === 2 ? (
              <p className="p-mid mb-7 xl:mb-8 leading-6 xl:leading-9">
                Fresh new look and layout  <br className="hidden md:block" />
                Cleaner, more organized, and new features{" "}
                <br className="hidden md:block" />
                New data visualizations and interactions{" "}
                <br className="hidden md:block" />
                Holistic asset analysis
              </p>
            ) : page === 3 ? (
              <p className="p-mid mb-7 xl:mb-8 leading-6 xl:leading-9">
                Analyze your assets with side-by-side viewing
                <br className="hidden md:block" />
                Interact with large data sets in Card viewer
                <br className="hidden md:block" />
                Rich chart interactions with History Graph{" "}
                <br className="hidden md:block" />
                New sparkline trend groups
                <br className="hidden md:block" />
              </p>
            ) : (
              <>
                <p className="p-mid mb-4 xl:mb-5">
                  Alarm-focused workflows to acknowledge and support
                </p>
                <p className="p-mid mb-7 xl:mb-8">
                  Get a new perspective on the field and proactively address
                  potential issues
                </p>
              </>
            )}

            <div className="flex items-center justify-between">
              <button
                className={`btn btn-outline-white-1 ${
                  page === 0 ? "hidden" : page === 1 ? "hidden" : ""
                }`}
                onClick={() => setPage((currPage: any) => currPage - 1)}
              >
                <FaArrowLeft className="mr-2 icon" />
                Back
              </button>
              <button
                className={`btn btn-link ${page === 4 ? "hidden" : ""}`}
                onClick={handleSkip}
              >
                Skip intro
              </button>
              {page === 4 ? (
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/")}
                >
                  Go to my dashboard <FaArrowRight className="ml-2 icon" />
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={page === 1 ? handlePageCount : handlePageCount}
                >
                  Continue <FaArrowRight className="ml-2 icon" />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-[100%] md:w-[50%] grow items-center justify-center p-4 pt-12 2xl:pt-20 pb-12 2xl:pb-20 login-colright">
          <div className="flex flex-col text-center items-center justify-center w-full max-w-[625px] login-form-logo">
            <img
              src={
                page === 1
                  ? Loginimg2
                  : page === 2
                  ? Loginimg3
                  : page === 3
                  ? Loginimg4
                  : Loginimg5
              }
              alt="Loginlogo"
              className="w-full max-w-[625px] mb-9 login-img"
            />
            <h5 className="h5 h5--semibold mb-0">
              Welcome to your new dashboard
            </h5>

            {page === 1 ? (
              <p className="p-mid">
                Super charging group views with dashboards
              </p>
            ) : page === 2 ? (
              <p className="p-mid">
                Super charging group views with analysis and
                <br className="hidden md:block" />
                schematics
              </p>
            ) : page === 3 ? (
              <p className="p-mid">
                Deep analysis production and controller data points
              </p>
            ) : (
              <p className="p-mid">Alerts. alarms and optimizing setpoints</p>
            )}
          </div>
        </div>
        <div className="text-xs xl:text-base text-neutral-200 w-full p-4 xl:p-8 static md:absolute left-0 right-0 bottom-0 login-footer">
          © XSPOC 2023
        </div>
      </div>
    </div>
  );
};

export default DefaultSetup;
