import { useNavigate } from "react-router-dom";
import CustomDropdown from "../Dropdown";
import { FaArrowLeft } from "react-icons/fa";

interface HeaderInterface {
  home?: string;
  isDropdown1?: boolean;
  dropdownOptions?: any[];
  handleSelect?: any;
  isDropdown2?: boolean;
  defaultValueDropdown1?: string;
  breadcrumbLevel2?: string;
  className?: string;
  deviceBreadcrumb?: string;
  handleReduxCleanup?: any;
  deviceHeader?:boolean;
}
const Header: React.FC<HeaderInterface> = ({
  home,
  isDropdown1,
  dropdownOptions = [],
  handleSelect,
  isDropdown2,
  defaultValueDropdown1,
  breadcrumbLevel2,
  className,
  deviceBreadcrumb,
  handleReduxCleanup,
  deviceHeader,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <header
        className={`flex bg-darkcolor-700 text-white px-[15px] xl:px-[32px] py-[12px] ${className}`}
      >
        <div className="flex flex-wrap items-center">
          <label
            className="text-primary-400 text-[1.3em] lg:text-[23px] cursor-pointer flex items-center"
            onClick={deviceHeader ? () => {navigate("/device"); handleReduxCleanup();} : () => navigate("/")}
          >
            {deviceBreadcrumb === "" && <FaArrowLeft className="mr-2" />} {home}
          </label>

          {isDropdown1 ? (
            <>
              <label className="text-[23px]">&nbsp;\&nbsp;</label>
              <CustomDropdown
                options={dropdownOptions}
                onSelect={handleSelect}
                optionHeading="Views"
                dropdownIcon={false}
                bgColor="bg-transparent"
                defaultValue={defaultValueDropdown1}
                width="320px"
              />
            </>
          ) : (
            <>
              {breadcrumbLevel2 && (
                <label className="text-[23px]">&nbsp;\&nbsp;</label>
              )}
              <p className="text-primary-400 text-[23px] cursor-pointer">
                {breadcrumbLevel2 || ""}
              </p>
            </>
          )}
          {isDropdown2 ? (
            <>
              <label className="text-[23px]">&nbsp;\&nbsp;</label>
              <CustomDropdown
                options={dropdownOptions}
                onSelect={handleSelect}
                optionHeading="Views"
                dropdownIcon={false}
                bgColor="bg-transparent"
                defaultValue={defaultValueDropdown1}
                width="320px"
              />
            </>
          ) : (
            ""
          )}
          {deviceBreadcrumb && (
            <label className="text-[1.3em] lg:text-[23px]">&nbsp;\&nbsp;</label>
          )}
          <p className="text-white text-[1.3em] lg:text-[23px] cursor-pointer">
            {deviceBreadcrumb || ""}
          </p>
        </div>
      </header>
      <hr className="w-full h-[2px] mx-auto my-0 bg-darkcolor-300 border-0 rounded md:my-0 dark:bg-darkcolor-300" />
    </>
  );
};

export default Header;
