import React from "react";
import Maps from "../../common/Svg/Maps";
import { useSelector } from "react-redux";
import Pinned from "../../common/Svg/Pinned";
import { RootState } from "../../../store/store";
import Tooltip from "../../common/Tooltip/Tooltip";

interface IconsComponentsI {
    isPinned: boolean;
    latitude: number;
    longitude: number;
    tankId: string;
    tankName: string;
    handleOpenMapsTab: (
        latitude: number,
        longitude: number,
        tankName: string,
        tankId: string
    ) => void;
    handlePinnedTab: (
        isPinned: boolean,
        tankId: string,
        groupId: string,
        records: any[],
        tabActive: number
    ) => void;
}

const IconsComponents: React.FC<IconsComponentsI> = ({
    isPinned,
    latitude,
    longitude,
    tankId,
    tankName,
    handleOpenMapsTab,
    handlePinnedTab,
}) => {
    const { groupId, records, tabActive } = useSelector(
        (state: RootState) => state.GroupSitesAssets
    );
    return (
        <div className='flex items-center'>
            <Tooltip content={isPinned ? "Pinned" : "Unpinned"} direction='right'>
                <Pinned
                    size={20}
                    className={`mr-2.5 cursor-pointer hover:text-secondary-500 ${isPinned
                        ? "rotate-0 text-secondary-500"
                        : "rotate-45 text-neutral-25"
                        }`}
                    onClick={() => handlePinnedTab(!isPinned, tankId, groupId, records, tabActive)}
                    color={isPinned ? "#0094BD" : "#F7F9F9"}
                />
            </Tooltip>
            <Tooltip content='Map' direction='right'>
                <Maps
                    size={22}
                    className='cursor-pointer'
                    color='#F7F9F9'
                    onClick={() =>
                        handleOpenMapsTab(latitude, longitude, tankName, tankId)
                    }
                />
            </Tooltip>
        </div>
    );
};

export default IconsComponents;
