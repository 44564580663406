import React from "react";
import Tree from "../Svg/Tree";
import Arrow from "../Svg/Arrow";

interface RoutesAndAssetsInterface {
    defaultGroupData: any[];
    handleCustomerGroupClick: any;
    handleCustomerDropdown: any;
    customerGroup: any;
    handleSiteDropdown: any;
    handleSiteGroupClick: any;
    siteGroup: any;
    handleTankGroupClick: any;
    customGroupData: any[];
    handleCustom1Dropdown: any;
    handleCustom1stGroupClick: any;
    handleCustom1Level1Tank: any
    custom1Group: any;
    handleCustom2Dropdown: any;
    handleCustom2ndGroupClick: any;
    handleCustom2Level2Tank: any
    custom2Group: any;
    handleCustom3Level3Tank: any;
    handleCustom3Dropdown: any;
    custom3Group: any;
    handleCustom3rdGroupClick: any;
    toggleShowAssets?: any;
    handleAllSitesTanks?: any
}

const RoutesAndAssets: React.FC<RoutesAndAssetsInterface> = ({
    defaultGroupData,
    handleCustomerGroupClick,
    handleCustomerDropdown,
    customerGroup,
    handleSiteDropdown,
    handleSiteGroupClick,
    siteGroup,
    handleTankGroupClick,

    customGroupData,
    handleCustom1Dropdown,
    handleCustom1stGroupClick,
    custom1Group,
    handleCustom2Dropdown,
    handleCustom2ndGroupClick,
    handleCustom1Level1Tank,
    handleCustom2Level2Tank,
    handleCustom3Level3Tank,
    custom2Group,

    handleCustom3Dropdown,
    custom3Group,
    handleCustom3rdGroupClick,
    toggleShowAssets,
    handleAllSitesTanks
}) => {
    return (
        <>
            <div className='flex flex-col max-h-full h-[85vh] overflow-y-auto pb-11'>
                {defaultGroupData?.length > 0 &&
                    defaultGroupData?.map((itemCustomer: any) => (
                        <div key={itemCustomer.id} className='w-full'>
                            {/* Default Customer (1st Level)*/}
                            <div
                                className={`flex w-full pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemCustomer.showAssets && "bg-darkcolor-600 rounded-[6px]"
                                    }`}
                                style={{ paddingLeft: 12 }}
                            >
                                <div className={`flex items-center gap-[12px]`}>
                                    <Tree
                                        color={itemCustomer.showAssets ? "#A6DBEA" : "#F7F9F9"}
                                    />
                                    <label
                                        className='text-[16px] cursor-pointer'
                                        onClick={() => handleCustomerGroupClick(itemCustomer)}
                                    >
                                        {itemCustomer?.groupName}
                                    </label>
                                </div>
                                <div className='flex gap-[12px] items-center'>
                                    <div className='flex items-center rounded-[4px] text-primary-700 bg-primary-300 px-[10px] py-[2px]'>
                                        {itemCustomer?.totalTankCount}
                                    </div>
                                    <Arrow
                                        size={20}
                                        className={`transform transition-transform cursor-pointer ${itemCustomer.showAssets ? "-rotate-90" : "rotate-90"
                                            }`}
                                        color={itemCustomer?.showAssets ? "#0094BD" : "#fff"}
                                        onClick={() => handleCustomerDropdown(itemCustomer)}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${itemCustomer?.showAssets &&
                                    "transition-all duration-300 ease-out transform opacity-100 scale-100"
                                    }`}
                            >
                                {itemCustomer?.groupName === "All Sites" ?

                                    itemCustomer?.showAssets &&
                                    itemCustomer?.site?.length > 0 &&
                                    itemCustomer?.site.map((itemAllSite: any) => (

                                        <div className={`${itemAllSite?.showAssets &&
                                            "transition-all duration-300 ease-out transform opacity-100 scale-100"
                                            }`} >

                                            <div
                                                className={`flex w-full pl-[48px] pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemAllSite.showAssets &&
                                                    "bg-darkcolor-600 rounded-[6px]"
                                                    }`}
                                                key={itemAllSite?.siteId}
                                            >
                                                <div className={`flex items-center`}>
                                                    <label
                                                        className='text-[16px] cursor-pointer'
                                                        onClick={() =>
                                                            handleAllSitesTanks(
                                                                itemAllSite,
                                                                itemCustomer
                                                            )
                                                        }
                                                    >
                                                        {itemAllSite?.siteName}
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    ))
                                    :
                                    itemCustomer?.showAssets &&
                                    itemCustomer?.childGroup?.length > 0 &&
                                    itemCustomer?.childGroup.map((itemSite: any) => (
                                        <div key={itemSite?.id}>
                                            <div
                                                className={`flex w-full pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemSite.showAssets &&
                                                    "bg-darkcolor-600 rounded-[6px]"
                                                    }`}
                                                style={{ paddingLeft: 48 }}

                                            >
                                                <div className={`flex items-center`}>
                                                    <label
                                                        className='text-[16px] cursor-pointer'
                                                        onClick={() =>
                                                            handleSiteGroupClick(itemSite, itemCustomer)
                                                        }
                                                    >
                                                        {itemSite?.groupName}
                                                    </label>
                                                </div>
                                                <div className='flex gap-[12px] items-center'>
                                                    <div className='flex items-center rounded-[4px] text-primary-700 bg-primary-300 px-[10px] py-[2px]'>
                                                        {itemSite?.totalTankCount}
                                                    </div>
                                                    <Arrow
                                                        size={20}
                                                        className={`transform transition-transform cursor-pointer ${itemSite.showAssets ? "-rotate-90" : "rotate-90"
                                                            }`}
                                                        color={itemSite?.showAssets ? "#0094BD" : "#fff"}
                                                        onClick={() =>
                                                            handleSiteDropdown(itemSite, itemCustomer.id)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={`${itemSite?.showAssets &&
                                                    "transition-all duration-300 ease-out transform opacity-100 scale-100"
                                                    }`}
                                            >
                                                {
                                                    itemSite?.showAssets &&
                                                    itemSite?.childGroup?.length > 0 &&
                                                    itemSite?.childGroup.map((itemRoute: any) => (
                                                        <>
                                                            <div
                                                                className={`flex w-full pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemRoute.showAssets &&
                                                                    "bg-darkcolor-600 rounded-[6px]"
                                                                    }`}
                                                                style={{ paddingLeft: 72 }}
                                                                key={itemRoute?.id}
                                                            >
                                                                <div className={`flex items-center`}>
                                                                    <label
                                                                        className='text-[16px] cursor-pointer'
                                                                        onClick={() =>
                                                                            handleCustomerGroupClick(itemRoute)
                                                                        } // yaha pe change karna hai
                                                                    >
                                                                        {itemRoute?.groupName}
                                                                    </label>
                                                                </div>
                                                                <div className='flex gap-[12px] items-center'>
                                                                    <div className='flex items-center rounded-[4px] text-primary-700 bg-primary-300 px-[10px] py-[2px]'>
                                                                        {itemRoute?.totalTankCount}
                                                                    </div>
                                                                    <Arrow
                                                                        size={20}
                                                                        className={`transform transition-transform cursor-pointer ${itemRoute.showAssets
                                                                            ? "-rotate-90"
                                                                            : "rotate-90"
                                                                            }`}
                                                                        color={
                                                                            itemRoute?.showAssets ? "#0094BD" : "#fff"
                                                                        }
                                                                        onClick={() =>
                                                                            handleSiteDropdown(itemRoute)
                                                                        } // yaha pe handleRouteDropdown
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                }
                                            </div>
                                            <div
                                                className={`${itemSite?.showAssets &&
                                                    "transition-all duration-300 ease-out transform opacity-100 scale-100"
                                                    }`}
                                            >
                                                {
                                                    itemSite?.showAssets &&
                                                    itemSite?.site?.length > 0 &&
                                                    itemSite?.site?.map((itemTank: any) => (
                                                        <div
                                                            className={`flex w-full pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemTank.showAssets &&
                                                                "bg-darkcolor-600 rounded-[6px]"
                                                                }`}
                                                            style={{ paddingLeft: 72 }}
                                                            key={itemTank?.siteId}
                                                        >
                                                            <div className={`flex items-center`}>
                                                                <label
                                                                    className='text-[16px] cursor-pointer'
                                                                    onClick={() =>
                                                                        handleTankGroupClick(
                                                                            itemTank,
                                                                            itemSite,
                                                                            itemCustomer
                                                                        )
                                                                    } // yaha pe change karna hai
                                                                >
                                                                    {itemTank?.siteName}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ))}

                {customGroupData &&
                    customGroupData?.length > 0 &&
                    customGroupData?.map((itemCustomLevel1: any) => (
                        <div key={itemCustomLevel1.id} className='w-full'>
                            {/* Level 1 Groups */}
                            <div
                                className={`flex w-full pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemCustomLevel1.showAssets &&
                                    "bg-darkcolor-600 rounded-[6px]"
                                    }`}
                                style={{ paddingLeft: 12 }}
                            >
                                <div className={`flex items-center gap-[12px]`}>
                                    <Tree
                                        color={itemCustomLevel1.showAssets ? "#A6DBEA" : "#F7F9F9"}
                                    />
                                    <label
                                        className='text-[16px] cursor-pointer'
                                        onClick={() => handleCustom1stGroupClick(itemCustomLevel1)}
                                    >
                                        {itemCustomLevel1?.groupName}
                                    </label>
                                </div>
                                <div className='flex gap-[12px] items-center'>
                                    <div className='flex items-center rounded-[4px] text-primary-700 bg-primary-300 px-[10px] py-[2px]'>
                                        {itemCustomLevel1?.totalTankCount}
                                    </div>
                                    <Arrow
                                        size={20}
                                        className={`transform transition-transform cursor-pointer ${itemCustomLevel1.showAssets ? "-rotate-90" : "rotate-90"
                                            }`}
                                        color={itemCustomLevel1?.showAssets ? "#0094BD" : "#fff"}
                                        onClick={() => handleCustom1Dropdown(itemCustomLevel1)}
                                    />
                                </div>
                            </div>
                            {/* Level 1 Groups */}
                            <div
                                className={`${itemCustomLevel1?.showAssets &&
                                    "transition-all duration-300 ease-out transform opacity-100 scale-100"
                                    }`}
                            >
                                {/* Level 1 Child Group */}
                                {
                                    itemCustomLevel1?.showAssets &&
                                    itemCustomLevel1?.childGroup?.length > 0 &&
                                    itemCustomLevel1?.childGroup?.map((itemCustomLevel2: any) => {
                                        return (
                                            <div key={itemCustomLevel2?.id}>
                                                <div
                                                    className={`flex w-full pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemCustomLevel2.showAssets &&
                                                        "bg-darkcolor-600 rounded-[6px]"
                                                        }`}
                                                    style={{ paddingLeft: 48 }}
                                                >
                                                    <div className={`flex items-center`}>
                                                        <label
                                                            className='text-[16px] cursor-pointer'
                                                            onClick={() =>
                                                                handleCustom2ndGroupClick(itemCustomLevel2, itemCustomLevel1)
                                                            }
                                                        >
                                                            {itemCustomLevel2?.groupName}
                                                        </label>
                                                    </div>
                                                    <div className='flex gap-[12px] items-center'>
                                                        <div className='flex items-center rounded-[4px] text-primary-700 bg-primary-300 px-[10px] py-[2px]'>
                                                            {itemCustomLevel2?.totalTankCount}
                                                        </div>
                                                        <Arrow
                                                            size={20}
                                                            className={`transform transition-transform cursor-pointer ${itemCustomLevel2.showAssets
                                                                ? "-rotate-90"
                                                                : "rotate-90"
                                                                }`}
                                                            color={
                                                                itemCustomLevel2?.showAssets ? "#0094BD" : "#fff"
                                                            }
                                                            onClick={() => handleCustom2Dropdown(itemCustomLevel2, itemCustomLevel1.id)}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${itemCustomLevel2?.showAssets &&
                                                        "transition-all duration-300 ease-out transform opacity-100 scale-100"
                                                        }`}
                                                >
                                                    {
                                                        itemCustomLevel2?.showAssets &&
                                                        itemCustomLevel2?.childGroup?.length > 0 &&
                                                        itemCustomLevel2?.childGroup?.map((itemCustomLevel3: any) => (
                                                            <div key={itemCustomLevel3?.id}>
                                                                <div
                                                                    className={`flex w-full pl-[65px] pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemCustomLevel3.showAssets &&
                                                                        "bg-darkcolor-600 rounded-[6px]"
                                                                        }`}
                                                                >
                                                                    <div className={`flex items-center`}>
                                                                        <label
                                                                            className='text-[16px] cursor-pointer'
                                                                            onClick={() =>
                                                                                handleCustom3rdGroupClick(itemCustomLevel3, itemCustomLevel2, itemCustomLevel1)
                                                                            }
                                                                        >
                                                                            {itemCustomLevel3?.groupName}
                                                                        </label>
                                                                    </div>
                                                                    <div className='flex gap-[12px] items-center'>
                                                                        <div className='flex items-center rounded-[4px] text-primary-700 bg-primary-300 px-[10px] py-[2px]'>
                                                                            {itemCustomLevel3?.totalTankCount}
                                                                        </div>
                                                                        <Arrow
                                                                            size={20}
                                                                            className={`transform transition-transform cursor-pointer ${itemCustomLevel3.showAssets
                                                                                ? "-rotate-90"
                                                                                : "rotate-90"
                                                                                }`}
                                                                            color={
                                                                                itemCustomLevel3?.showAssets ? "#0094BD" : "#fff"
                                                                            }
                                                                            onClick={() => handleCustom3Dropdown(itemCustomLevel3, itemCustomLevel2, itemCustomLevel1)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {
                                                                    itemCustomLevel3?.showAssets &&
                                                                    itemCustomLevel3?.site?.length > 0 &&
                                                                    itemCustomLevel3?.site?.map((itemCustomLevel3Sites: any) => (
                                                                        <div key={itemCustomLevel3Sites?.siteId}>
                                                                            <div
                                                                                className={`flex w-full  pl-[84px] pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemCustomLevel3Sites.showAssets &&
                                                                                    "bg-darkcolor-600 rounded-[6px]"
                                                                                    }`}

                                                                            >
                                                                                <div className={`flex items-center`}>
                                                                                    <label
                                                                                        className='text-[16px] cursor-pointer'
                                                                                        onClick={() =>
                                                                                            handleCustom3Level3Tank(itemCustomLevel3Sites, itemCustomLevel3, itemCustomLevel2, itemCustomLevel1)
                                                                                        }
                                                                                    >
                                                                                        {itemCustomLevel3Sites?.siteName}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    )}
                                                            </div>
                                                        ))}
                                                </div>
                                                {/* Level 1 Site */}
                                                {
                                                    itemCustomLevel2?.showAssets &&
                                                    itemCustomLevel2?.site?.length > 0 &&
                                                    itemCustomLevel2?.site?.map((itemCustomLevel2Sites: any) => (
                                                        <div key={itemCustomLevel2Sites?.siteId}>
                                                            <div
                                                                className={`flex w-full pl-[65px] pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemCustomLevel2Sites.showAssets &&
                                                                    "bg-darkcolor-600 rounded-[6px]"
                                                                    }`}

                                                            >
                                                                <div className={`flex items-center`}>
                                                                    <label
                                                                        className='text-[16px] cursor-pointer'
                                                                        onClick={() =>
                                                                            handleCustom2Level2Tank(itemCustomLevel2Sites, itemCustomLevel2, itemCustomLevel1)
                                                                        }
                                                                    >
                                                                        {itemCustomLevel2Sites?.siteName}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    )}
                                                {/* Level 1 Site */}
                                            </div>
                                        )
                                    })}
                                {/* Level 1 Child Group */}
                                {/* level 1 Sites */}
                                {
                                    itemCustomLevel1?.showAssets &&
                                    itemCustomLevel1?.site?.length > 0 &&
                                    itemCustomLevel1?.site?.map((itemCustomLevel1Sites: any) => (
                                        <>
                                            <div
                                                className={`flex w-full pl-[48px] pr-[12px] py-[8px] mb-[4px] justify-between items-center ${itemCustomLevel1Sites.showAssets &&
                                                    "bg-darkcolor-600 rounded-[6px]"
                                                    }`}
                                                key={itemCustomLevel1Sites?.siteId}
                                            >
                                                <div className={`flex items-center`}>
                                                    <label
                                                        className='text-[16px] cursor-pointer'
                                                        onClick={() =>
                                                            handleCustom1Level1Tank(itemCustomLevel1Sites, itemCustomLevel1)
                                                        }
                                                    >
                                                        {itemCustomLevel1Sites?.siteName}
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                {/* Level 1 Sites */}
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default RoutesAndAssets;
