import React from "react";
import ArrowLong from "../Svg/ArrowLong";

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    displayRange?: number;
}

const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    onPageChange,
    displayRange = 4,
}) => {
    let startPage = Math.max(1, currentPage - Math.floor(displayRange / 2));
    let endPage = Math.min(totalPages, startPage + displayRange - 1);

    if (endPage - startPage + 1 < displayRange) {
        startPage = Math.max(1, endPage - displayRange + 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className='flex justify-between items-center mt-4'>
            <button
                className={`flex items-center text-neutral-25 justify-center rounded-[8px] border-[1px] border-neutral-25 py-[8px] px-[14px] pageButton ${currentPage === 1
                    ? "bg-darkcolor-300 cursor-not-allowed"
                    : "bg-transparent text-white"
                    }`}
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                <ArrowLong className='mr-[8px] rotate-180' />
                Previous
            </button>

            <div className='flex'>
                {startPage > 1 && (
                    <button
                        className={`mx-1 w-[40px] h-[40px] text-neutral-25 pageCircle ${currentPage === 1 ? "rounded-full bg-darkcolor-500" : ""
                            }`}
                        onClick={() => onPageChange(1)} 
                    >
                        {1}
                    </button>
                )}
                {startPage > 2 && (
                    <button className='mx-1 w-[40px] h-[40px] text-neutral-25 pageDots'>
                        ...
                    </button>
                )}
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        className={`mx-1 w-[40px] h-[40px] text-neutral-25 pageCircle ${currentPage === number ? "rounded-full bg-darkcolor-500" : ""
                            }`}
                        onClick={() => onPageChange(number)}
                    >
                        {number}
                    </button>
                ))}
                {endPage < totalPages - 1 && (
                    <button className='mx-1 w-[40px] h-[40px] text-neutral-25 pageDots'>
                        ...
                    </button>
                )}
                {endPage < totalPages && (
                    <button
                        className={`mx-1 w-[40px] h-[40px] text-neutral-25 pageCircle ${totalPages === currentPage ? "rounded-full bg-darkcolor-500" : ""
                            }`}
                        onClick={() => onPageChange(totalPages)}
                    >
                        {totalPages}
                    </button>
                )}
            </div>
            <div>
                <button
                    className={`flex items-center text-neutral-25 justify-center rounded-[8px] border-[1px] border-neutral-25 py-[8px] px-[14px] pageButton ${currentPage === totalPages
                        ? "bg-darkcolor-300 cursor-not-allowed"
                        : "bg-transparent text-white"
                        }`}
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                    <ArrowLong className='ml-[8px]' />
                </button>
            </div>
        </div>
    );
};

export default Pagination;
