interface BellProps {
    color?: string;
    onClick?: () => void;
    className?: string;
}

const Bell: React.FC<BellProps> = ({ color, className, onClick }) => {
    return (
        <>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
                className={className}
                onClick={onClick}
            >
                <path
                    d='M9.35419 21.2563C10.0593 21.8787 10.9856 22.2563 12 22.2563C13.0145 22.2563 13.9407 21.8787 14.6458 21.2563M18 8.25635C18 6.66505 17.3679 5.13893 16.2427 4.01371C15.1174 2.88849 13.5913 2.25635 12 2.25635C10.4087 2.25635 8.8826 2.88849 7.75738 4.01371C6.63216 5.13893 6.00002 6.66505 6.00002 8.25635C6.00002 11.3465 5.22049 13.4623 4.34968 14.8618C3.61515 16.0422 3.24788 16.6325 3.26134 16.7971C3.27626 16.9794 3.31488 17.0489 3.46179 17.1579C3.59448 17.2563 4.19261 17.2563 5.38887 17.2563H18.6112C19.8074 17.2563 20.4056 17.2563 20.5382 17.1579C20.6852 17.0489 20.7238 16.9794 20.7387 16.7971C20.7522 16.6325 20.3849 16.0422 19.6504 14.8618C18.7795 13.4623 18 11.3465 18 8.25635Z'
                    stroke={color}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </>
    );
};

export default Bell;
