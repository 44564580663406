import React from "react";
import TextInput from "./TextInput";
import DropdownSerial from "../Dropdown/DropdownSerial";
import "./DeviceForm.scss";

interface DeviceFormData {
  formLabel?: string;
  handleFormInputChange: any;
  handleDeviceStatus: any;
  deviceField: any;
  errors: any;
  showValidate: boolean;
  handleSelect: (selected: string) => void;
  deviceStatus: any;
  deviceData?: any;
  deviceList: any;
  id?: any;
}

const DeviceForm: React.FC<DeviceFormData> = ({
  formLabel,
  deviceField,
  errors,
  handleFormInputChange,
  handleSelect,
  handleDeviceStatus,
  showValidate,
  deviceStatus,
  deviceData,
  deviceList,
  id,
}) => {
  return (
    <div className="border-darkcolor-400 border-[1px] rounded-[4px] flex flex-col h-full items-start bg-gradient-to-b from-[#253240] via-[#253240] to-[rgba(37, 48, 64, 0.40)] mb-[10px] card">
      <div className="w-full grow px-6 pt-4 pb-4 cardHeader">
        <label className="text-neutral-25 text-[16px] font-bold leading-[24px] ">
          {formLabel}
        </label>
      </div>
      <div className="relative w-full h-full text-neutral-25 p-4 cardBody sitestable">
        <TextInput
          placeholder="Select Value"
          label="Type"
          name="type"
          onChange={handleFormInputChange}
          value={deviceField?.type}
          disabled={true}
          errors={errors.type}
        />
        <div className="flex flex-col mb-4 xl:mb-5 form-group">
          <label className="label--semibold mb-[6px]">Serial #</label>
          {id && deviceData !== null && deviceData !== undefined ? (
            <DropdownSerial
              options={deviceList}
              onSelect={handleSelect}
              optionHeading="Serial List"
              dropdownIcon={false}
              bgColor="input-outline-4 bg-darkcolor-800"
              defaultValue={`
              ${
                id && deviceData?.serialNo !== undefined
                  ? deviceData?.serialNo
                  : "Select Serial"
              }
            `}
              width="100%"
              handleDeviceStatus={handleDeviceStatus}
              showValidate={showValidate}
              deviceStatus={deviceStatus}
              errors={errors.serial}
              id={id}
            />
          ) : null}
          {!id && (
            <DropdownSerial
              options={deviceList}
              onSelect={handleSelect}
              optionHeading="Serial List"
              dropdownIcon={false}
              bgColor="input-outline-4 bg-darkcolor-800"
              defaultValue={`
              ${
                id && deviceData?.serialNo !== undefined
                  ? deviceData?.serialNo
                  : "Select Serial"
              }
            `}
              width="100%"
              handleDeviceStatus={handleDeviceStatus}
              showValidate={showValidate}
              deviceStatus={deviceStatus}
              errors={errors.serial}
            />
          )}
        </div>
        <TextInput
          placeholder="Select Value"
          label="Make"
          name="make"
          onChange={handleFormInputChange}
          value={deviceField?.make}
          errors={errors.make}
          disabled={true}
        />
        <TextInput
          placeholder="Select Value"
          label="Hardware version #"
          name="hversion"
          onChange={handleFormInputChange}
          value={deviceField?.hardwareVersion}
          disabled={true}
        />
        <TextInput
          placeholder="Select Value"
          label="Firmware version #"
          name="fversion"
          onChange={handleFormInputChange}
          value={deviceField?.firmwareVersion}
          disabled={true}
          errors={errors.firmwareVersion}
        />
        <TextInput
          placeholder="Select Value"
          label="IMEI #"
          name="imei"
          onChange={handleFormInputChange}
          value={deviceField?.imei}
          disabled={true}
          errors={errors.imei}
        />
        <TextInput
          placeholder="Select Value"
          label="Status"
          name="status"
          onChange={handleFormInputChange}
          value={deviceField?.status}
          disabled={true}
          errors={errors.status}
        />
      </div>
    </div>
  );
};

export default React.memo(DeviceForm);
