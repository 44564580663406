import "./Sidebar.scss";
import Tabs from "../Tabs/Tabs1";
import { FiSearch } from "react-icons/fi";
import AlarmList from "../Cards/AlarmListCard";
import React, { useEffect, useState } from "react";
import { getSiteAlarms } from "../../../services/alarmNotifications/alarmNotifications.service";

const NotificationPanel: React.FC = () => {
  const [pageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [freeText, setFreeText] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(0);
  // const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alarmNotificationsData, setAlarmNotificationsData] = useState<any>([]);


  useEffect(() => {
    if (freeText) {
      const getData = setTimeout(() => {
        getNotificationAlarmsBasedOnTab(activeTab);
      }, 1000);

      return () => clearTimeout(getData);
    }
    else
      getNotificationAlarmsBasedOnTab(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeText, activeTab]);

  const getNotificationAlarmsBasedOnTab = (activeTab: number) => {
    if (activeTab === 0) getNotificationAlarms(1, 0);
    else if (activeTab === 1) getNotificationAlarms(1, 4);
    else if (activeTab === 2) getNotificationAlarms(1, 3);
    else if (activeTab === 3) getNotificationAlarms(1, 2);
    else if (activeTab === 4) getNotificationAlarms(1, 1);
    else getNotificationAlarms(1);
  };

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    setFreeText("");
  };

  const getNotificationAlarms = async (
    pageNumber?: number,
    priority?: number
  ) => {
    setIsLoading(true);
    try {
      const response = await getSiteAlarms(
        priority || "",
        freeText,
        pageNumber ? pageNumber : page,
        pageSize
      );
      if (response?.success && response?.statusCode === 200) {
        if (pageNumber) {
          setAlarmNotificationsData(response?.data?.results);
        } else {
          setAlarmNotificationsData((prevData: any) => [
            ...prevData,
            ...response?.data?.results,
          ]);
        }
        setPage((prevPage) => prevPage + 1);
        // setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchData = () => {
    if (!isLoading) getNotificationAlarms();
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      handleFetchData();
    }
  };

  const tabs = [
    {
      label: "All",
      badge: false,
      content: (
        <AlarmList
          alarmNotificationsData={alarmNotificationsData}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: "Critical",
      badge: false,
      content: (
        <AlarmList
          alarmNotificationsData={alarmNotificationsData}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: "High",
      badge: false,
      content: (
        <AlarmList
          alarmNotificationsData={alarmNotificationsData}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: "Medium",
      badge: false,
      content: (
        <AlarmList
          alarmNotificationsData={alarmNotificationsData}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: "Low",
      badge: false,
      content: (
        <AlarmList
          alarmNotificationsData={alarmNotificationsData}
          isLoading={isLoading}
        />
      ),
    },
  ];

  return (
    <div
      className={`${isLoading ? "pb-[100px]" : "pb-[20px]"
        } fixed left-70 bottom-0 top-0 flex flex-col bg-darkcolor-800 w-[410px] 2xl:w-[510px] py-[36px] pl-[36px] pr-[16px] z-50 sidebar-overlay`}
    >
      <div className='w-full h-full sidebar-overlay-content'>
        <div className='flex flex-wrap justify-between items-center mb-4 sidebar-overlay-headingblock'>
          <h6>Alerts</h6>
        </div>
        <div
          className='flex flex-col h-full overflow-y-auto relative sidebar-alarm-block notification-content'
          onScroll={handleScroll}
        >
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onChange={handleTabChange}
            className='tabs-block'
          />
          <div className='flex flex-wrap justify-between mb-4 absolute top-0 left-0 right-0 sidebar-overlay-search'>
            <div className='grow mr-[9px] input-with-lefticon sidebar-search-input-block'>
              <FiSearch className='input-icon' />
              <input
                className='input-outline-4'
                placeholder='Search by site name or alarms'
                onChange={(e) => setFreeText(e.target.value)}
                value={freeText}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPanel;
