import React from "react";
import Tooltip from "../../common/Tooltip/Tooltip";

interface SiteNameComponentsI {
    tankId: string;
    alarm: boolean;
    tankName: string;
    product: string;
    handleSaveTank: (tankId: string) => void;
}

const SiteNameComponent: React.FC<SiteNameComponentsI> = ({
    tankId,
    alarm,
    product,
    tankName,
    handleSaveTank
}) => {
    return (
        <div className='flex justify-between'>
            <div className='flex flex-col'>
                <Tooltip content={tankName} direction='top'>
                    <p
                        className='text-[14px] font-bold text-primary-400 cursor-pointer  truncate'
                        onClick={() => handleSaveTank(tankId)}
                    >
                        {tankName}
                    </p>
                </Tooltip>
                <p className='text-[14px] text-neutral-25'>{product}</p>
            </div>
            <div className='pt-[7px]'>
                {alarm && (
                    <div className='w-[8px] h-[8px] rounded-[50%] bg-error-500' />
                )}
            </div>
        </div>
    );
};

export default SiteNameComponent;
