import moment from "moment";

export const alarmTableColumns = [
    {
        title: "",
        dataIndex: "icons",
        key: "icons",
        width: "90px",
        isChecked: true,
        render: (_: any, record: any) => (
            <div
                className={"w-[16px] h-[16px] border-[1px] rounded-full"}
                style={{
                    borderColor:
                        record.priority === 4
                            ? "#F04438"
                            : record.priority === 3
                                ? "#6BBBAE"
                                : record.priority === 2
                                    ? "#FDB022"
                                    : record.priority === 1
                                        ? "#B8C5CC"
                                        : "",
                }}
            />
        ),
    },
    {
        title: "Alert",
        dataIndex: "alert",
        key: "alert",
        sortable: true,
    },
    {
        title: "Set on",
        dataIndex: "setOn",
        key: "setOn",
        sortable: true,
        render: (text: string, record: any) => (
            <div>{text !== null ? moment(text).utc().format('MM/DD/yyyy hh:mm'): ''}</div>
        )
    },
    {
        title: "Cleared on",
        dataIndex: "clearedOn",
        key: "clearedOn",
        sortable: true,
        render: (text: string, record: any) => (            
            <div>{text !== null ? moment(text).utc().format('MM/DD/yyyy hh:mm'): ''}</div>
        )
    },
    // {
    //     title: "Cleared by",
    //     dataIndex: "clearedBy",
    //     key: "clearedBy",
    //     sortable: true,
    // },
    {
        title: "State",
        dataIndex: "state",
        key: "state",
        sortable: true,
        render: (_: string, record: any) => (
            <div className={"flex items-center"}>
                <span
                    className={
                        "py-[2px] items-center px-[8px] text-[12px] font-semibold capitalize rounded-[4px] border-[1px]"
                    }
                    style={{
                        borderColor:
                            record.priority === 4
                                ? "#F04438"
                                : record.priority === 3
                                    ? "#6BBBAE"
                                    : record.priority === 2
                                        ? "#FDB022"
                                        : record.priority === 1
                                            ? "#B8C5CC"
                                            : "",
                        color:
                            record.priority === 4
                                ? "#F04438"
                                : record.priority === 3
                                    ? "#6BBBAE"
                                    : record.priority === 2
                                        ? "#FDB022"
                                        : record.priority === 1
                                            ? "#B8C5CC"
                                            : "",
                    }}
                >
                    {record.priorityName}
                </span>
            </div>
        ),
    },
    {
        title: "Recommendation action",
        dataIndex: "recommendedAction",
        key: "recommendedAction",
        sortable: true,
    },
];
