import moment from "moment";
import { RootState } from "../../../store/store";
import React, { useEffect, useState } from "react";
import Loading from "../../common/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import TankDiagram from "../../../assets/img/tank-diagram.png";
import TankDiagram100 from "../../../assets/img/tank-diagram-100.png"
import TankDiagram75 from "../../../assets/img/tank-diagram-75.png"
import TankDiagram50 from "../../../assets/img/tank-diagram-50.png"
import TankDiagram25 from "../../../assets/img/tank-diagram-25.png"
import SiteDetailsLeftGrid from "../../common/SiteDetailsGrid/LeftGrid";
import SiteDetailsRightGrid from "../../common/SiteDetailsGrid/RightGrid";
import { getSiteInformationImageData } from "../../../services/siteDetails/siteDetails.service";
import {
    clearTest,
    setCustomLevel1Tanks,
    setCustomLevel2GroupDetails,
    setCustomLevel2Id,
    setCustomLevel3GroupDetails,
    setCustomLevel3Id,
    setTank,
} from "../../../store/routesAndAssets/routesAndAssets.store";

interface InformationInterface {
    groupId: string;
    tankId: string;
    groupType: string;
}

const Information: React.FC<InformationInterface> = ({
    groupId,
    tankId,
    groupType,
}) => {
    const [details, setDetails] = useState<any>({});
    const [voltage, setVoltage] = useState<any>({});
    const [tankName, setTankName] = useState<string>("");
    const [lastScan, setLastScan] = useState<string>("");
    const [pumpDetails, setPumpDetails] = useState<any>({});
    const [tankDetails, setTankDetails] = useState<any>({});
    const [tankStatus, setTankStatus] = useState<string>("");
    const [intermittent, setIntermittent] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [serialNumber, setSerialNumber] = useState<string>("");
    const [tankDiagramImage, setTankDiagramImage] = useState<string>("");

    const dispatch = useDispatch();
    const { test } = useSelector((state: RootState) => state.RoutesAndAssets);

    useEffect(() => {
        informationImageData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tankId]);

    const informationImageData = async () => {
        try {
            setIsLoading(true);
            const response = await getSiteInformationImageData(groupId, tankId);
            if (response?.success && response?.statusCode === 200) {
                setDetails(response?.data?.details);
                setIntermittent(response?.data?.intermittent);
                setPumpDetails(response?.data?.pumpDetails);
                setTankDetails(response?.data?.tankDetails);
                setVoltage(response?.data?.voltage);
                setTankName(response?.data?.tankName);
                setTankStatus(response?.data?.status);
                setLastScan(response?.data?.lastScan);
                setSerialNumber(response?.data?.serialNumber);
                const tankFillPercentage = (response?.data?.tankDetails.tankFill / response?.data?.tankDetails.tankSize) * 100;
                if (tankFillPercentage >= 75) {
                    setTankDiagramImage(TankDiagram100);
                }
                else if (tankFillPercentage >= 50 && tankFillPercentage < 75) {
                    setTankDiagramImage(TankDiagram75);
                }
                else if (tankFillPercentage >= 25 && tankFillPercentage < 50) {
                    setTankDiagramImage(TankDiagram50);
                }
                else if (tankFillPercentage > 0 && tankFillPercentage < 25) {
                    setTankDiagramImage(TankDiagram25);
                }
                else {
                    setTankDiagramImage(TankDiagram);
                }

                dispatch(setTank((response?.data?.tankName)))
                if (test) {
                    if (response?.data?.ciaGroupDetails?.groupSiblings.length > 0) {
                        if (response?.data?.ciaGroupDetails?.parents?.length > 1) {
                            dispatch(setCustomLevel2Id(response?.data?.ciaGroupDetails?.parents[1]?.id));
                            dispatch(setCustomLevel2GroupDetails(response?.data?.ciaGroupDetails?.parents[1]));
                            const find3ndLevelRecord =
                                response?.data?.ciaGroupDetails?.groupSiblings.find(
                                    (item: any) =>
                                        item?.id === response?.data?.ciaGroupDetails?.id
                                );
                            if (find3ndLevelRecord) {
                                dispatch(setCustomLevel3Id(find3ndLevelRecord?.id));
                                dispatch(setCustomLevel3GroupDetails(find3ndLevelRecord));
                            }
                        }
                        else {
                            const find2ndLevelRecord =
                                response?.data?.ciaGroupDetails?.groupSiblings.find(
                                    (item: any) =>
                                        item?.id === response?.data?.ciaGroupDetails?.id
                                );
                            if (find2ndLevelRecord) {
                                dispatch(setCustomLevel2Id(find2ndLevelRecord?.id));
                                dispatch(setCustomLevel2GroupDetails(find2ndLevelRecord));
                            }
                        }
                    }
                    dispatch(
                        setCustomLevel1Tanks(response?.data?.ciaGroupDetails?.groupTanks)
                    );
                    dispatch(clearTest());
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Loading isLoading={isLoading} text={""} />
            <div className='grid xl:grid-cols-2 gap-[12px] w-full'>
                <SiteDetailsLeftGrid
                    title={tankName}
                    status={tankStatus}
                    scan={moment(lastScan).format("MM/DD/yyyy hh:mm A")}
                    serial={serialNumber}
                    image={tankDiagramImage}
                    details={details}
                    intermittent={intermittent}
                    pumpDetails={pumpDetails}
                    tankDetails={tankDetails}
                    voltage={voltage}
                    onClick={informationImageData}
                />
                <SiteDetailsRightGrid
                    groupId={groupId}
                    siteId={tankId}
                    groupType={groupType}
                />
            </div>
        </>
    );
};

export default Information;
