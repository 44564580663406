import ApiConstant from "../../constants/api.constants";
import Helpers from "../../utilities/helpers";
import instance from "../api";
import TokenService from "./token.service";

const siteLogoutUrl = process.env.REACT_APP_LOGOUT_URL;

const logout = () => {
  TokenService.removeUser();
  if (siteLogoutUrl) window.location.assign(siteLogoutUrl);
  localStorage.clear();
};

const accessTokenFromServer = async (refreshToken: any) => {
  try {
    const params = {
      ReferenceToken: refreshToken,
    };
    const response = await instance.get(ApiConstant.getToken, {
      params,
    });
    return response.data;
  } catch (error) {
    Helpers.ConsoleLogError(error);
  }
};

const refreshTokenFromServer = async (refreshToken: any) => {
  try {
    const params = {
      RefreshToken: refreshToken,
    };
    const response = await instance.get(ApiConstant.getRefreshToken, {
      params,
    });
    if (response) {
      let data: any = response?.data?.data;
      TokenService.addOrUpdateLocalRefreshToken(data?.token?.refresh_token);
      TokenService.addOrUpdateLocalAccessToken(data?.token?.access_token);
    }
  } catch (error) {
    Helpers.ConsoleLogError(error);
  }
};

const isAuth = async (authStatus: boolean) => {
  if (authStatus) {
    if (siteLogoutUrl) window.location.assign(siteLogoutUrl);
    localStorage.clear();
  }
};

const AuthService = {
  logout,
  isAuth,
  accessTokenFromServer,
  refreshTokenFromServer,
};

export default AuthService;
