import Download from "../components/common/Svg/Download";
import ArrowsToDot from "../components/common/Svg/ArrowsToDot";

export const dropdownOptions = [
  {
    id: 1,
    name: "Table view 1",
    value: "Table-view-1",
  },
  {
    id: 2,
    name: "Table view 2",
    value: "Table-view-2",
  },
  {
    id: 3,
    name: "Table view 3",
    value: "Table-view-3",
  },
];

export const iconDropdownOptions = [
  {
    id: 1,
    value: "setPointOptimization",
    name: "Set point optimization",
    icon: <ArrowsToDot size={20} />,
  },
  // {
  //   id: 2,
  //   value: "consumption",
  //   name: "Consumption",
  //   icon: <Bullhorn size={20} />,
  // },
  {
    id: 3,
    value: "export",
    name: "Export",
    icon: <Download size={20} />,
  },
  // {
  //   id: 4,
  //   value: "uploadProductionData",
  //   name: "Upload production data",
  //   icon: <Download size={20} className="rotate-180" />,
  // },
]

export const styleDropdownOptions = [
  {
    id: 1,
    name: "Dotted",
    value: "Dotted",
  },
  {
    id: 2,
    name: "Solid",
    value: "Solid",
  }
]
