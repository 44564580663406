import React from "react";

interface EyeProps {
    size?: number | string;
    className?: string;
    onClick?: () => void;
}

const Eye: React.FC<EyeProps> = ({ size = 24, className, onClick }) => {
    const style = size ? { width: size, height: size } : undefined;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className={className}
            style={style}
            onClick={onClick}
        >
            <path
                d='M2.01677 9.76102C1.90328 9.58132 1.84654 9.49147 1.81477 9.35289C1.79091 9.24879 1.79091 9.08462 1.81477 8.98053C1.84654 8.84194 1.90328 8.75209 2.01677 8.57239C2.95461 7.08741 5.74617 3.33337 10.0003 3.33337C14.2545 3.33337 17.0461 7.08741 17.9839 8.57239C18.0974 8.75209 18.1541 8.84194 18.1859 8.98053C18.2098 9.08462 18.2098 9.24879 18.1859 9.35289C18.1541 9.49147 18.0974 9.58132 17.9839 9.76102C17.0461 11.246 14.2545 15 10.0003 15C5.74617 15 2.95461 11.246 2.01677 9.76102Z'
                stroke='#E7ECEE'
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.0003 11.6667C11.381 11.6667 12.5003 10.5474 12.5003 9.16671C12.5003 7.786 11.381 6.66671 10.0003 6.66671C8.61962 6.66671 7.50034 7.786 7.50034 9.16671C7.50034 10.5474 8.61962 11.6667 10.0003 11.6667Z'
                stroke='#E7ECEE'
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default Eye;
