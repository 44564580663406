import React from "react";
import Tree from "../Svg/Tree";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

interface GroupAssetsInterface {
  assetData: any[];
  handleAssetGroupClick: (assetId: string) => void;
  handleAssetGroup?: (assetId: string) => void;
  handleDefaultGroupClick?: (assetId: string) => void;
}

const GroupAssets: React.FC<GroupAssetsInterface> = ({
  assetData,
  handleAssetGroupClick,
  handleAssetGroup,
  handleDefaultGroupClick
}) => {
  return (
    <>
      <div className="flex flex-col max-h-full h-[100%] overflow-y-auto">
        {assetData &&
          assetData.length > 0 &&
          assetData.map((item: any) => {
            const siteDetails = item?.siteDetails || item?.site || [];
            const childGroups = item?.childGroups || item?.childGroup || [];
            const siteCount = item?.totalTankCount
            return (
              <div className='w-full' key={item.id}>
                <div
                  className={`flex w-full gap-3 cursor-pointer p-2 mb-1 items-start relative ${item?.showAssets ? "bg-darkcolor-600 rounded" : ""
                    }`}
                >
                  <div className='flex grow gap-2 navigation-content-frame-item-content'>
                    <div className='pt-1 navigation-content-frame-item-dataflow'>
                      <Tree color={item?.showAssets ? "#A6DBEA" : "#F7F9F9"} />
                    </div>
                    <div
                      data-testid={`navbar-${item.groupName}`}
                      className='grow navigation-content-frame-item-text flex items-center text-neutral-25'
                      onClick={() => handleAssetGroup ? handleAssetGroup(item.id) : handleDefaultGroupClick && handleDefaultGroupClick("")}
                    >
                      {item?.groupName}
                    </div>
                    {(childGroups.length > 0 || siteDetails.length > 0) && (
                      <span className='relative right-[-4px] rounded-sm max-h-6 p-1 px-2 bg-primary-300 text-primary-700 text-sm font-medium leading-4'>
                        {handleDefaultGroupClick ? siteCount : siteDetails.length + childGroups.length}
                      </span>
                    )}
                  </div>
                  <div
                    className='navigation-content-frame-item-carrot'
                    onClick={() => {
                      handleAssetGroupClick(item.id);
                    }}
                  >
                    {item?.showAssets ? (
                      <FiChevronUp className='text-primary-500' />
                    ) : (
                      <FiChevronDown className='text-neutral-25' />
                    )}
                  </div>
                </div>
                {item?.showAssets && (childGroups.length > 0 || siteDetails.length > 0) ? (
                  <>
                    <div className='w-full'>
                      {siteDetails.map((detail: any) => (
                        <div
                          key={detail.id}
                          className='flex w-full gap-3 mb-1 cursor-pointer p-2 pl-8 rounded navigation-content-frame-asset'
                        >
                          <span className='text-neutral-200'>
                            {detail.siteName}
                          </span>
                        </div>
                      ))}
                      {childGroups.length > 0 &&
                        childGroups.map((gitem: any) => {
                          const gitemChildGroups = gitem?.childGroups || gitem?.childGroup || [];
                          const gitemSiteDetails = gitem?.siteDetails || gitem?.site || [];
                          const tankCount = gitem?.site
                          return (
                            <div className='w-full' key={gitem?.id}>
                              <div
                                data-testid={`navbar-${gitem?.groupName}`}
                                className={`flex w-full gap-3 mb-1 cursor-pointer p-2 pl-8 ${gitem?.showAssets ? "bg-darkcolor-600 rounded" : ""
                                  } navigation-content-frame-asset`}
                              >
                                <div
                                  className='flex gap-1 justify-between grow items-start navigation-content-frame-item-text ml-[12px] text-neutral-25'
                                  onClick={() => handleAssetGroup ? handleAssetGroup(gitem?.id) : handleDefaultGroupClick && handleDefaultGroupClick(gitem)}
                                >
                                  {gitem?.groupName}
                                  {(gitemChildGroups.length > 0 || gitemSiteDetails.length > 0) && (
                                    <span className='relative right-[-4px] rounded-sm max-h-6 p-1 px-2 bg-primary-300 text-primary-700 text-sm font-medium leading-4'>
                                      {handleDefaultGroupClick ? tankCount.length : (gitemChildGroups.length + gitemSiteDetails.length)}
                                    </span>
                                  )}
                                </div>
                                <div
                                  className='navigation-content-frame-item-carrot'
                                  onClick={() => {
                                    handleAssetGroupClick(gitem?.id);
                                  }}
                                >
                                  {gitem?.showAssets ? (
                                    <FiChevronUp className='text-primary-500' />
                                  ) : (
                                    <FiChevronDown className='text-neutral-25' />
                                  )}
                                </div>
                              </div>
                              {gitem?.showAssets && (gitemChildGroups.length > 0 || gitemSiteDetails.length > 0) ? (
                                <>
                                  <div className='w-full'>
                                    {gitemSiteDetails.map((detail: any) => (
                                      <div
                                        key={detail.id}
                                        className='flex w-full gap-3 mb-1 cursor-pointer p-2 pl-14 rounded navigation-content-frame-asset'
                                      >
                                        <span className='text-neutral-200'
                                          onClick={() => handleAssetGroup ? handleAssetGroup("") : handleDefaultGroupClick && handleDefaultGroupClick("")}>
                                          {detail.siteName}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                  {gitemChildGroups.length > 0 &&
                                    gitemChildGroups.map((sgitem: any) => {
                                      const sgitemChildGroups = sgitem?.childGroups || sgitem?.childGroup || [];
                                      const sgitemSiteDetails = sgitem?.siteDetails || sgitem?.site || [];
                                      return (
                                        <div key={sgitem.groupName}>
                                          <div
                                            data-testid={`navbar-${sgitem.groupName}`}
                                            className={`flex w-full gap-2 mb-1 cursor-pointer p-2 pl-8 ${sgitem.showAssets ? "bg-darkcolor-600 rounded" : ""
                                              } navigation-content-frame-asset`}
                                          >
                                            <div
                                              className='flex gap-1 justify-between grow text-neutral-200 items-start navigation-content-frame-item-text ml-[24px]'
                                              onClick={() => {
                                                handleAssetGroup && handleAssetGroup(sgitem.id);
                                              }}
                                            >
                                              {sgitem.groupName}
                                              {sgitemSiteDetails.length > 0 && (
                                                <span className='relative rounded-sm max-h-6 p-1 px-2 bg-primary-300 text-primary-700 text-sm font-medium leading-4'>
                                                  {handleDefaultGroupClick ? "" : (sgitemSiteDetails.length + sgitemChildGroups.length)}
                                                </span>
                                              )}
                                            </div>
                                            <div
                                              className='navigation-content-frame-item-carrot'
                                              onClick={() => {
                                                handleAssetGroupClick(sgitem?.id);
                                              }}
                                            >
                                              {sgitem?.showAssets ? (
                                                <FiChevronUp className='text-primary-500' />
                                              ) : (
                                                <FiChevronDown className='text-neutral-25' />
                                              )}
                                            </div>
                                          </div>
                                          {sgitem?.showAssets && sgitemSiteDetails.length > 0 && (
                                            <>
                                              <div className='w-full'>
                                                {sgitemSiteDetails.map(
                                                  (detail: any) => (
                                                    <div
                                                      key={detail.id}
                                                      className='flex w-full gap-3 mb-1 cursor-pointer p-2 pl-20 rounded navigation-content-frame-asset'
                                                    >
                                                      <span className='text-neutral-200 '>
                                                        {detail.siteName}
                                                      </span>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                              {sgitemChildGroups.length > 0 &&
                                                sgitemChildGroups.map(
                                                  (ssgitem: any) => (
                                                    <div key={ssgitem.groupName}>
                                                      <div
                                                        data-testid={`navbar-${ssgitem.groupName}`}
                                                        className={`flex w-full gap-2 mb-1 cursor-pointer p-2 pl-10 ${ssgitem.showAssets ? "bg-darkcolor-600 rounded" : ""
                                                          } navigation-content-frame-asset`}
                                                      >
                                                        <div
                                                          className='flex gap-1 justify-between grow text-neutral-200 items-start navigation-content-frame-item-text ml-[24px]'
                                                          onClick={() => {
                                                            handleAssetGroup && handleAssetGroup(ssgitem.id);
                                                          }}
                                                        >
                                                          {ssgitem.groupName}
                                                          {ssgitem?.siteDetails.length > 0 && (
                                                            <span className='relative rounded-sm max-h-6 p-1 px-2 bg-primary-300 text-primary-700 text-sm font-medium leading-4'>
                                                              {ssgitem.siteDetails.length + (ssgitem?.childGroups?.length || 0)}
                                                            </span>
                                                          )}
                                                        </div>
                                                        <div
                                                          className='navigation-content-frame-item-carrot'
                                                          onClick={() => {
                                                            handleAssetGroupClick(ssgitem?.id);
                                                          }}
                                                        >
                                                          {ssgitem?.showAssets ? (
                                                            <FiChevronUp className='text-primary-500' />
                                                          ) : (
                                                            <FiChevronDown className='text-neutral-25' />
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                            </>
                                          )}
                                        </div>
                                      );
                                    })}
                                </>
                              ) : null}
                            </div>
                          );
                        })}
                    </div>
                  </>
                ) : null}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default GroupAssets;
