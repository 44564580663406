import React from "react";

const DeviceSites: React.FC<any> = ({deviceSites }) => {
 
  return (
    <div className="border-darkcolor-400 border-[1px] rounded-[4px] flex flex-col items-start bg-gradient-to-b from-[#253240] via-[#253240] to-[rgba(37, 48, 64, 0.40)] mb-[12px] card">
      <div className="w-full grow px-6 pt-4 pb-4 cardHeader">
        <label className="text-neutral-25 text-[16px] font-bold leading-[24px] ">
          Assigned account
        </label>
      </div>
      <div className="relative w-full h-full text-neutral-25 py-[6px] px-6 cardBody sitestable">
        <ul className="list-none">
          <li className="py-[10px] mb-1">
            <div className="text-base">
              Site:
              <label className="ml-2 text-base text-primary-400">
                {deviceSites && deviceSites?.siteName}
              </label>
            </div>
          </li>
          <li className="py-[10px] mb-1">
            <div className="text-base">
              Customer:
              <label className="ml-2 text-base text-primary-400">
                {deviceSites && deviceSites?.customers}
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DeviceSites;
