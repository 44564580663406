import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../common/Header/Header";
import { FiSearch } from "react-icons/fi";
import TableComponent from "../common/Table/CustomTable";
import Drawer from "../common/Drawer/Drawer";
import Stepper from "../common/Stepper/Stepper";
import "./DeviceManagement.scss";
import DeviceForm from "../common/DeviceForm/DeviceForm";
import ProvisionDeviceService from "../../services/provisionDevice/provisionDevice.service";
import "./DeviceManagement.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setDevicePumps,
  setDeviceSites,
  setDeviceTanks,
} from "../../store/provisionDevice/provisionDevice.store";
import Helpers from "../../utilities/helpers";
import DeviceSites from "./SubComponents/DeviceSites";
import DevicePumps from "./SubComponents/DevicePumps";
import DeviceTanks from "./SubComponents/DeviceTanks";
import { toast } from "react-toastify";
import Loading from "../common/Loading/Loading";
import CustomModal from "../common/Modal/Modal";
import ProvisioningModal from "../common/Modal/ProvisioningModal";
import Dropdown2 from "../common/Dropdown/Dropdown2";

const AddEditDevice = () => {
  document.title = "CIA - Provision Device";
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(8);
  const [pumpPage, setPumpPage] = useState<number>(1);
  const [pumpPageSize] = useState<number>(8);
  const [pumpTotalPages, setPumpTotalPages] = useState<number>(0);
  const [tankPage, setTankPage] = useState<number>(1);
  const [tankPageSize] = useState<number>(8);
  const [tankTotalPages, setTankTotalPages] = useState<number>(0);
  const [sitePage, setSitePage] = useState<number>(1);
  const [sitePageSize] = useState<number>(8);
  const [siteTotalPages, setSiteTotalPages] = useState<number>(0);
  const [deviceSitesData, setDeviceSitesData] = useState<any>([]);
  const [deviceSiteSingleData, setDeviceSiteSingleData] = useState<any>({});
  const [deviceTanksData, setDeviceTanksData] = useState<any>([]);
  const [deviceTankSingleData, setDeviceTankSingleData] = useState<any>({});
  const [devicePumpsData, setDevicePumpsData] = useState<any>([]);
  const [powerType , setPowerType] = useState<any>([]);
  const [controllerType, setControllerType] = useState("Select");
  const [devicePumpSingleData, setDevicePumpSingleData] = useState<any>({});
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [deviceData, setDeviceData] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const [showValidate, setShowValidate] = useState<any>(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [deviceStatus, setDeviceStatus] = useState<any>(null);
  const [deviceList, setDeviceList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deviceProvisioned, setDeviceProvisioned] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchSiteText, setSearchSiteText] = useState<string>("");
  const [searchTankText, setSearchTankText] = useState<string>("");
  const [searchPumpText, setSearchPumpText] = useState<string>("");
  const [isDisableContinueBtn, setIsDisableContinueBtn] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [tankSortBy, setTankSortBy] = useState<string>("");
  const [tankSortOrder, setTankSortOrder] = useState<string>("");
  const [assignUnassignModal, setAssignUnassignModal] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<string | null>(null);
  const [modaltext, setModalText] = useState<string>("");
  const [isUnassignConfirmed, setIsUnassignConfirmed] = useState<boolean>(false);
  const [isOpenManufacturerDropdown, setIsOpenManufacturerDropdown] = useState<boolean>(false);
  const [isOpenPowerDropdown, setIsOpenPowerDropdown] = useState<boolean>(false);
  const [isOpenControllerDropdown, setIsOpenControllerDropdown] = useState<boolean>(false);
  const [deviceField, setDeviceField] = useState({
    type: "",
    serial: "",
    make: "",
    hardwareVersion: "",
    firmwareVersion: "",
    imei: 0,
    status: "",
  });

  const dispatch = useDispatch();
  const { deviceSites, devicePumps, deviceTanks, allAssignData } = useSelector(
    (state: any) => state.ProvisionDevice
  );

  const navigate = useNavigate();
  //edit device
  const { id } = useParams();

  const getDeviceData = async () => {
    setIsLoading(true);
    try {
      const request: any = {
        id: id,
        pageSize: pageSize,
        pageNumber: page,
        searchText: "",
      };
      const response = await ProvisionDeviceService.getProvisionDeviceSummary(
        request
      );
      if (response?.statusCode === 200 && response?.success) {
        setDeviceData(response?.data?.provisionData[0]);
        handleSelect(response?.data?.provisionData[0]?.serialNo);
        handleDeviceSites(response?.data?.provisionData[0]?.sites[0]);
        handleDevicePumps(response?.data?.provisionData[0]?.pumps[0]);
        handleDeviceTanks(response?.data?.provisionData[0]?.tanks[0]);       
        setSitePage(response?.data?.provisionData[0]?.sites[0]?.pageNumber !== 0 ? response?.data?.provisionData[0]?.sites[0]?.pageNumber : 1);
        setPumpPage(response?.data?.provisionData[0]?.pumps[0]?.pageNumber !== 0 ? response?.data?.provisionData[0]?.pumps[0]?.pageNumber : 1);
        setTankPage(response?.data?.provisionData[0]?.tanks[0]?.pageNumber !== 0 ? response?.data?.provisionData[0]?.tanks[0]?.pageNumber : 1);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

 
  const getDeviceApi = async () => {
    setIsLoading(true);
    try {
      const deviceSerialData: {
        id: string;
        productType: string;
        deviceId: string;
        make: string;
        type: string;
        model: string;
        value: string;
        name: string;
        hardwareVersion: string;
        firmwareVersion: string;
        imei: number;
        status: string;
        isDeviceProvisioned: boolean;
      }[] = [];
      const request = {
        skip: 0,
        limit: 0,
      };
      const response = await ProvisionDeviceService.getDevices(request);
      if (response?.statusCode === 200 && response?.success) {
        //setDeviceStatus(responseData?.data);
        response?.data?.forEach((item: any) => {
          deviceSerialData.push({
            id: item?.id,
            productType: item?.productType,
            deviceId: item?.serialNo,
            make: item?.make,
            model: item?.model,
            value: item?.serialNo,
            name: item?.serialNo,
            type: item?.productType,
            hardwareVersion: item?.hardwareVersion,
            firmwareVersion: item?.firmwareVersion,
            imei: item?.imei,
            status: item?.active ? "Active" : "Inactive",
            isDeviceProvisioned: item?.isDeviceProvisioned,
          });
        });
        setDeviceList(deviceSerialData);
      }
    } catch (error) {
      Helpers.ConsoleLogError(error);
     // throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect (() =>{
    if (isUnassignConfirmed){
    handleSave();
    setIsUnassignConfirmed(false);
    }
  },[isUnassignConfirmed])

  useEffect(() => {  
    getDeviceApi(); 
    if(!id) {
      handleReduxCleanup();
    }; 
  }, []);

  useEffect(() => {
    if (id && deviceList) {
      const getSData = setTimeout(async () => {
        await getDeviceData();
      }, 1000);
      return () => clearTimeout(getSData);
    }
  }, [id, deviceList]);

const ControllerType = [
  {
      "key": "DigiUltra",
      "value": "DigiUltra",
      "displayLabel": "DigiUltra",
      "order": 1,
      "isSelected": true,
      "_id": 1
  }
]

  const getDeviceSitesApi = async (sitePageSize: number, sitePage: number, searchText: string = "") => {   
      try { 
        setIsLoading(true); 
        const request: any = {
          PageSize: sitePageSize,
          PageNumber: sitePage,
          SearchText: searchText,
          SortOrder: sortOrder,
          SortBy: sortBy,
        };
        const response = await ProvisionDeviceService.getDeviceSites(request);
        if (response?.statusCode === 200 && response?.success) {         
          setDeviceSitesData(response?.data?.sites);
          setSiteTotalPages(response?.data?.totalPages);            
          if(!id){
            setDeviceSiteSingleData(response?.data?.sites[0]);
          }
          if(!searchSiteText && response?.data?.sites?.length === 0){
            setIsDisableContinueBtn(true);   
          }              
        }
      } catch (error) {
        console.error("error", error);
      } finally {
        setIsLoading(false);
      }    
  }

  const getDeviceTanksApi =  async (siteId:string, tankPageSize: number, tankPage: number, searchText: string = "") => {     
      try {
        setIsLoading(true); 
        const request: any = {
          SiteId: siteId,
          PageSize: tankPageSize,
          PageNumber: tankPage,
          SearchText: searchText,
          SortOrder: tankSortOrder,
          SortBy: tankSortBy,
        };
        const response = await ProvisionDeviceService.getDeviceTanks(request);
        if (response?.statusCode === 200 && response?.success) {          
          setDeviceTanksData(response?.data?.tank);
          setTankTotalPages(response?.data?.totalPages);
          if(!id){
            setDeviceTankSingleData(response?.data?.tank[0]);
          }
          if(!searchSiteText && response?.data?.tank?.length === 0){
            setIsDisableContinueBtn(true);   
          } 
        }
      } catch (error) {
        console.error("error", error);
      } finally {
        setIsLoading(false);
      }  
  }

  const getDevicePumpsApi = async () => { 
    try {  
      setIsLoading(true); 
      const response = await ProvisionDeviceService.getDevicePumps();
      if (response?.statusCode === 200 && response?.success) {
        setDevicePumpsData(response?.data);
        setPumpTotalPages(response?.data?.totalPages);
        if(!id){
          setDevicePumpSingleData(response?.data?.pump[0]);
        }      
        if(!searchPumpText && response?.data?.pump?.length === 0){
          setIsDisableContinueBtn(true);   
        }    
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }  
} 

const getDevicePowerType = async () => {    
  try {  
    setIsLoading(true); 
    const response = await ProvisionDeviceService.getDevicePowerType();
    if (response?.statusCode === 200 && response?.success) {
      setPowerType(response?.data);
    }
  } catch (error) {
    console.error("error", error);
  } finally {
    setIsLoading(false);
  }  
} 
  
  //form handlers
  const handleFormInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    setDeviceField({ ...deviceField, [target.name]: target.value });
  };


  const handleSelect = (selectedOption: string) => {
    setDeviceStatus(null);
    setShowValidate(selectedOption !== "" ? true : false);
    const filterOption = deviceList && deviceList?.length && deviceList?.filter(
      (item: any) => item?.name === selectedOption
    );
    if (filterOption?.length > 0) {
      const { type, make, hardwareVersion, firmwareVersion, imei, status, isDeviceProvisioned } =
        filterOption[0];
      if (!id && isDeviceProvisioned) setModalVisible(true);
      setDeviceField({
        type,
        serial: selectedOption,
        make,
        hardwareVersion,
        firmwareVersion,
        imei,
        status,
      });
      setSelectedValue(selectedOption);
      setDeviceProvisioned(isDeviceProvisioned);
    }
  };


  const handleDeviceStatus = async () => {
    try {
      const responseData = await ProvisionDeviceService.getDecviceStatusCheck(
        selectedValue
      );
      if (responseData?.statusCode === 200 && responseData?.success) {
        setDeviceStatus(responseData?.data);
      }
    } catch (error) {
      Helpers.ConsoleLogError(error);
      throw error;
    } 
  };

  useEffect(() => {
    if (id && deviceData) {
        const getEditStatus = setTimeout(async () => {
          await handleDeviceStatus();
        }, 1000);  
        return () => clearTimeout(getEditStatus);
    }
  }, [id,deviceData]);


  const handleErrors = () => {
    const _errors: any = {};
    if (!deviceField.type) _errors.type = "Type is required";
    if (!deviceField.serial) _errors.serial = "Serial is required";
    if (!deviceField.make) _errors.make = "Make is required";
    if (!deviceField.firmwareVersion)
      _errors.firmwareVersion = "Firmware Version is required";
    if (!deviceField.imei) _errors.imei = "imei is required";
    if (!deviceField.status) _errors.status = "Status is required";

    setErrors(_errors);

    return Object.keys(_errors).length === 0;
  };

  const handleDeviceSites = (record: any) => {
    setDeviceSiteSingleData(record);
    dispatch(setDeviceSites(record));
  };
  const handleDeviceTanks = (record: any) => {
    setDeviceTankSingleData(record);
    dispatch(setDeviceTanks(record));
  };

  const handleDevicePumps = (record: any) => {
    setDevicePumpSingleData(record);
    dispatch(setDevicePumps(record));
  };

 
  const handleReduxCleanup = () => {    
      dispatch(setDeviceSites({}));
      dispatch(setDevicePumps({}));
      dispatch(setDeviceTanks({}));
  };

  const handleCancelClick = () => {
    setModalMode("cancel");
    setModalText("Are you sure you want to cancel the changes you have made? ");
    setAssignUnassignModal(true);
  };

  const handleReassignClick = () => {
    setModalMode("reassign");
    setModalText("Are you sure you want to reassign another account to the device?")
    setAssignUnassignModal(true);
  };

  const handleUnassignClick = () => {
    setModalMode("unassign");
    setModalText("Are you sure you want to unassign the account from the device?")
    setAssignUnassignModal(true);
  };

  const handleModalConfirm = () => {
    if (modalMode === "cancel") {
      navigate("/device");
    } else if (modalMode === "reassign") {
      handleDrawer();
    } else if (modalMode === "unassign") {
      dispatch(setDeviceSites({}));
      dispatch(setDevicePumps({}));
      dispatch(setDeviceTanks({}));
      setIsUnassignConfirmed(true)
    }
    setAssignUnassignModal(false);
  };

const handleProvisionClose = () => {
  setAssignUnassignModal(false);
}  
const handleModalClose = () => {
    setModalVisible(false);
  };

  //table
  const columns1 = [
    {
      title: "",
      dataIndex: `icon`,
      sortable: false,
      key: `icon`,
      width: "60px",
      render: (_: any, record: { siteId: any; productCode: any }) => (
        <>
          <div className="radio-block">
            <input
              type="radio"
              onChange={() => (setDeviceSiteSingleData(record))}
              name="site"
              checked={(record?.siteId && record.productCode) === (deviceSiteSingleData?.siteId && deviceSiteSingleData?.productCode)}
            />
            <span className="checkmark"></span>
          </div>
        </>
      ),
    },  
    {
      title: "District",
      dataIndex: `productCode`,
      sortable: true,
      key: `productCode`,
      width: "28%",
    },  
    {
      title: "Customer / Account",
      dataIndex: `customers`,
      sortable: true,
      key: `customers`,
      width: "auto",
    },
    {
      title: "Site name",
      dataIndex: `siteName`,
      sortable: true,
      key: `siteName`,
      width: "auto",
    },    
  ];

  const columns2 = [
    {
      title: "",
      dataIndex: `icon`,
      sortable: false,
      key: `icon`,
      width: "60px",
      render: (_: any, record: { name: string; tankId: number }) => (
        <>
          <div className="radio-block">
            <input
              type="radio"
              name="tank"
              onChange={() => (setDeviceTankSingleData(record))}
              checked={record.tankId === deviceTankSingleData?.tankId}
            />
            <span className="checkmark"></span>
          </div>
        </>
      ),
    },
    {
      title: "Tank name",
      dataIndex: `name`,
      sortable: true,
      key: `name`,
      width: "40%",
    },
    {
      title: "Product name",
      dataIndex: `product`,
      sortable: true,
      key: `product`,
      width: "auto",
    },
    {
      title: "Product type",
      dataIndex: `productType`,
      sortable: true,
      key: `type`,
      width: "auto",
    },
  ];

const columns3 = [
    {
      title: "",
      dataIndex: `icon`,
      sortable: false,
      key: `icon`,
      width: "60px",
      render: (_: any, record: { serialNumber: string }) => (
        <>
          <div className="radio-block">
            <input
              type="radio"
              name={`pump`}
              onChange={() => (setDevicePumpSingleData(record))}
              checked={record.serialNumber === devicePumpSingleData?.serialNumber}
            />
            <span className="checkmark"></span>
          </div>
        </>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: `manufacturer`,
      sortable: true,
      key: `manufacturer`,
      width: "auto",
    },
    {
      title: "Model",
      dataIndex: `model`,
      sortable: true,
      key: `model`,
      width: "auto",
    },
    {
      title: "Control Type",
      dataIndex: `controlType`,
      sortable: true,
      key: `controlType`,
      width: "auto",
    },    
    {
      title: "Power Type",
      dataIndex: `powerType`,
      sortable: true,
      key: `powerType`,
      width: "auto",
    },    
  ]; 

 
  useEffect(() => {
    if (searchSiteText) {
      const getSiteData = setTimeout(async () => {
        await getDeviceSitesApi(sitePageSize, sitePage, searchSiteText);
      }, 1000);
      return () => clearTimeout(getSiteData);
    } else {
      if(isDrawerOpen) getDeviceSitesApi(sitePageSize, sitePage, searchSiteText);
    }
  }, [sitePage, searchSiteText, isDrawerOpen, sortBy, sortOrder]);
  useEffect(() => {
    if (searchTankText) {
      const getTankData = setTimeout(async () => {
        await getDeviceTanksApi(deviceSiteSingleData?.siteId, tankPageSize, tankPage, searchTankText);
      }, 1000);
      return () => clearTimeout(getTankData);
    } else {
      if(isDrawerOpen) getDeviceTanksApi(deviceSiteSingleData?.siteId, tankPageSize, tankPage, searchTankText);
    }
  }, [tankPage, searchTankText, tankSortBy, tankSortOrder]);
  useEffect(() => {
    if (searchPumpText) {
      const getPumpData = setTimeout(async () => {
        await getDevicePumpsApi();
        await getDevicePowerType();
      }, 1000);
      return () => clearTimeout(getPumpData);
    } else {
      if(isDrawerOpen) getDevicePumpsApi(); getDevicePowerType();
    }
  }, [pumpPage, searchPumpText]);

  const handleSiteInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchSiteText(event.target.value);
    setSitePage(1);
  };

  const handleTanksInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTankText(event.target.value);
    setTankPage(1);
  };

  const handlePumpsInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchPumpText(event.target.value);
    setPumpPage(1);
  };

  const handlePageNumberChange = (pageNumber: number) => setPage(pageNumber);
  const handlePumpPageNumberChange = (pageNumber: number) => setPumpPage(pageNumber);
  const handleTankPageNumberChange = (pageNumber: number) => setTankPage(pageNumber);
  const handleSitePageNumberChange = (pageNumber: number) => setSitePage(pageNumber);

  const handleSortChange = (order: string, title: string) => {
    setSortBy(title);
    setSortOrder(order);
  };

  const handleTankSortChange = (order: string, title: string) => {
    setTankSortBy(title);
    setTankSortOrder(order);
  };

  //stepper
  const steps = ["Select a site", "Select tank", "Enter pump details"];
  const handleNextStep = () => {    
    setSearchSiteText("");
    setSearchTankText("");
    setSearchPumpText("");
    setActiveStep((prevStep) => prevStep + 1);    
  };
  const handlePrevStep = () => {
    setIsDisableContinueBtn(false);
    setSearchSiteText("");
    setSearchTankText("");
    setSearchPumpText("");
    setActiveStep((prevStep) => prevStep - 1);
  };

  useEffect(()=>{
    if(deviceSiteSingleData?.siteId && activeStep === 1 && isDrawerOpen) {
      getDeviceTanksApi(deviceSiteSingleData?.siteId, tankPageSize, tankPage, searchTankText);
    } else if (deviceTankSingleData?.tankId && activeStep === 2 && isDrawerOpen) {
      getDevicePumpsApi(); 
      getDevicePowerType();
    }
  },[activeStep]);

  const handleSelectManufacturerDropdown = (option: any) => {
    //dispatch(setGroupId(option?.key));
    setDevicePumpSingleData((prevState: any) => ({
      ...prevState,
      manufacturer: option[0]?.value,
    }));
  };
  const toggleManufacturerDropdown = () => {
  setIsOpenManufacturerDropdown(!isOpenManufacturerDropdown);
}

const handleSelectOnPowerDropdown = (option: any) => {
  //dispatch(setDevicePumps(option?.key));
  setDevicePumpSingleData((prevState: any) => ({
    ...prevState,
    powerType: option[0]?.value,
  }));
};
const togglePowerDropdown = () => {
  setIsOpenPowerDropdown(!isOpenPowerDropdown);
}

const handleControllerDropdown = (selectedOption:any) => {
  setControllerType(selectedOption[0].value);
  setDevicePumpSingleData((prevState: any) => ({
    ...prevState,
    controlType: selectedOption[0]?.value,
  }));
};

const toggleControllerDropdown = () => {
  setIsOpenControllerDropdown(!isOpenControllerDropdown);
}
  const renderStepContent = () => {    
    switch (activeStep) {
      case 0:
        return (
          <div className="w-full flex justify-between min-h-[100%]">
            <div className="flex flex-col w-full min-h-[400px]">
              <label htmlFor="">
                Select a site from the list below to assign to the device.
              </label>
              <div className="my-2 input-with-lefticon sidebar-search-input-block">
                <FiSearch className="input-icon" />
                <input
                  className="input-outline-4"
                  name="siteSearch"
                  value={searchSiteText}
                  placeholder="Search"
                  onChange={handleSiteInputSearch}
                  autoComplete="off"
                />
              </div>
              <div className="py-2 mb-[80px]">
                <TableComponent
                  data={deviceSitesData}
                  columns={columns1}
                  handlePageNumberChange={handleSitePageNumberChange}
                  page={sitePage}
                  pagniation={true}
                  totalPages={siteTotalPages}
                  tableClass={"assign-table"}
                  handleSortChange={handleSortChange}
                />
              </div>
            </div>
          </div>
        );
      case 1:        
        return (
          <div className="w-full flex justify-between min-h-[100%]">
            <div className="flex flex-col w-full min-h-[400px]">
              <label htmlFor="">Select a tank from the list below.</label>
              <div className="my-2 input-with-lefticon sidebar-search-input-block">
                <FiSearch className="input-icon" />
                <input
                  className="input-outline-4"
                  name="tankSearch"
                  value={searchTankText}
                  placeholder="Search"
                  onChange={handleTanksInputSearch}
                  autoComplete="off"
                />
              </div>
              <div className="py-2 mb-[80px]">
                <TableComponent
                  data={deviceTanksData}
                  columns={columns2}
                  handlePageNumberChange={handleTankPageNumberChange}
                  page={tankPage}
                  pagniation={true}
                  totalPages={tankTotalPages}
                  tableClass={"assign-table"}
                  handleSortChange={handleTankSortChange}
                />
              </div>
            </div>
          </div>
        );
        case 2:
          return (
            <div className="w-full min-h-[100%]">
              <div className="flex w-full min-h-[450px]">
                <div className="flex flex-col w-[50%] px-2 mb-4">
                  <label htmlFor="" className="mb-1">Manufacturer</label>
                  <Dropdown2
                    options={devicePumpsData}
                    onSelect={handleSelectManufacturerDropdown}
                    bgColor='bg-transparent'
                    defaultValue={`
                      ${
                        devicePumps?.powerType?.groupName !== undefined
                          ? devicePumps?.powerType?.groupName
                          : "Select"
                      }
                    `}
                    isOpen={isOpenManufacturerDropdown}
                    onToggle={toggleManufacturerDropdown}
                    width='100%'
                  ></Dropdown2>
                </div>
                 <div className="flex flex-col w-[50%] px-2 mb-4">
                  <label htmlFor="" className="mb-1">Controller Type</label>
                  <Dropdown2
                    options={ControllerType} 
                    onSelect={handleControllerDropdown}
                    bgColor='bg-transparent'
                    defaultValue={controllerType}
                    isOpen={isOpenControllerDropdown}
                    onToggle={toggleControllerDropdown}
                    width='100%'
                  ></Dropdown2>
                </div>

               {/* <div className="flex flex-col w-[50%] px-2 mb-4">
                  <label htmlFor="" className="mb-1">Controller type</label>
                  <Dropdown2
                    options={powerType}
                    onSelect={null}
                    bgColor='bg-transparent'
                    defaultValue={`
                    ${
                        devicePumps?.powerType?.groupName !== undefined
                          ? devicePumps?.powerType?.groupName
                          : "Select"
                      }
                    `}
                    isOpen={isOpenPowerDropdown}
                    onToggle={togglePowerDropdown}
                    width='100%'
                  ></Dropdown2>
                </div> */}

                <div className="flex flex-col w-[50%] px-2 mb-4">
                  <label htmlFor="" className="mb-1">Power type</label>
                  <Dropdown2
                    options={powerType}
                    onSelect={handleSelectOnPowerDropdown}
                    bgColor='bg-transparent'
                    defaultValue={`
                    ${
                        devicePumps?.powerType?.groupName !== undefined
                          ? devicePumps?.powerType?.groupName
                          : "Select"
                      }
                    `}
                    isOpen={isOpenPowerDropdown}
                    onToggle={togglePowerDropdown}
                    width='100%'
                  ></Dropdown2>
                </div>

              </div>
            </div>
          );
      default:
        return null;
    }
  };
  const handleDrawer = async () => {

    setSearchSiteText("");
    setSearchTankText("");
    setSearchPumpText("");

    setIsDrawerOpen(!isDrawerOpen);
    if (!isDrawerOpen) setActiveStep(0);   
    
  };

  const handleDrawerSubmit = async () => {
    dispatch(setDeviceSites(deviceSiteSingleData));
    dispatch(setDeviceTanks(deviceTankSingleData));
    dispatch(setDevicePumps(devicePumpSingleData));

    setIsDrawerOpen(!isDrawerOpen);
    if (!isDrawerOpen) setActiveStep(0);   
  }

  const handleSave = async () => {
    try {
      if (id) {
        const data: any = {
          id: id,
          serialNo: deviceField?.serial,
          sites: deviceSites?.siteId ? [{ id: deviceSites?.siteId, name: deviceSites?.siteName, productCode: deviceSites?.productCode }] : [],
          pumps: devicePumps?.serialNumber ? [{ id: devicePumps?.serialNumber, name: devicePumps?.serialNumber, manufacturer:devicePumps?.manufacturer, model: devicePumps?.model, controlType: devicePumps?.controlType, powerType: devicePumps?.powerType}] : [],
          tanks: deviceTanks?.tankId ? [{ id: deviceTanks?.tankId, name: deviceTanks?.name,productType:deviceTanks?.productType, productCode: deviceTanks?.product, tankType: deviceTanks?.type}] : [],
          isActive: !deviceSites?.siteId && !deviceTanks?.tankId && !devicePumps?.serialNumber ? false : true
        };

        const response = await ProvisionDeviceService.addProvisionDeviceData(
          data
        );
        if (response?.statusCode === 200 && response?.success) {
          handleReduxCleanup();
          navigate("/device");
          toast.success("Data submitted successfully.");
        } else {
          toast.error("Something went wrong.");
        }
      } else {
        const data: any = {
          serialNo: deviceField?.serial,
          sites: [{ id: deviceSites?.siteId, name: deviceSites?.siteName, productCode: deviceSites?.productCode }],
          pumps: [{ id: devicePumps?.manufacturer, name: devicePumps?.manufacturer, manufacturer:devicePumps?.manufacturer, model: devicePumps?.model, controlType: devicePumps?.controlType, powerType: devicePumps?.powerType}],
          tanks: [{ id: deviceTanks?.tankId, name: deviceTanks?.name,productType:deviceTanks?.productType, productCode: deviceTanks?.product, tankType: deviceTanks?.type}],
          isActive: !deviceSites?.siteId && !deviceTanks?.tankId && !devicePumps?.serialNumber ? false : true
        };

        const response = await ProvisionDeviceService.addProvisionDeviceData(
          data
        );
        if (response?.statusCode === 200 && response?.success) {
          handleReduxCleanup();
          navigate("/device");
          toast.success("Data submitted successfully.");
        } else {
          toast.error("Something went wrong.");
        }
      }
    } catch (error) {
      Helpers.ConsoleLogError(error);
      toast.error("Something went wrong.");
      throw error;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!handleErrors()) {
      toast.error("Please enter the required field details.");
      return;
    }
    if (!deviceProvisioned || deviceStatus?.isDeviceExists) {
      handleSave();
    }
  };
  return (
    <>
      <Loading isLoading={isLoading} text={""} />
      <div className="content-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="relative deviceEdit-header">
            <Header
              home={"Device management"}
              className="device-header"
              deviceBreadcrumb={"Provision"}
              handleReduxCleanup={handleReduxCleanup}
              deviceHeader={true}
            />
            <div className="flex flex-wrap absolute right-4 xl:right-8 top-3 deviceEdit-btns">
              <button
                onClick={handleCancelClick}
                type="button"
                className="py-[8px] px-[16px] mr-3 text-[14px] flex justify-center items-center rounded-[8px] border-darkcolor-400 border-[1px] border-solid bg-darkcolor-700 focus:outline-none text-neutral-25"
              >
                Cancel
              </button>
              {id ? (
                <button
                  type="submit"
                  disabled={!deviceStatus?.isDeviceExists}
                  className="py-[8px] px-[16px] text-[14px] flex justify-center items-center rounded-[8px] border-darkcolor-300 border-[1px] border-solid bg-primary-600 hover:bg-primary-700 focus:outline-none text-neutral-10 disabled:opacity-60 disabled:cursor-not-allowed"
                >
                  Save
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={deviceProvisioned || !deviceStatus?.isDeviceExists}
                  className="py-[8px] px-[16px] text-[14px] flex justify-center items-center rounded-[8px] border-darkcolor-300 border-[1px] border-solid bg-primary-600 hover:bg-primary-700 focus:outline-none text-neutral-10 disabled:opacity-60 disabled:cursor-not-allowed"
                >
                  Save
                </button>
              )}
            </div>
          </div>
          <div className="flex flex-wrap py-3 pl-[10px] xl:pl-[20px] pr-[10px] xl:pr-[20px]">
            <div className="w-[100%] lg:w-[50%] h-full px-[6px]">
              <DeviceForm
                errors={errors}
                deviceField={deviceField}
                handleFormInputChange={handleFormInputChange}
                formLabel="Device details"
                handleSelect={handleSelect}
                handleDeviceStatus={handleDeviceStatus}
                showValidate={showValidate}
                deviceStatus={deviceStatus}
                deviceData={deviceData}
                deviceList={deviceList}
                id={id}
              />
            </div>
            <div className="w-[100%] lg:w-[50%] h-full px-[6px]">
              <DeviceSites deviceSites={deviceSites} />
              <DeviceTanks deviceTanks={deviceTanks} />
              <DevicePumps devicePumps={devicePumps} />
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleUnassignClick}
                  className="font-bold py-[10px] px-4 mr-3 cursor-pointer disabled:cursor-default disabled:text-darkcolor-400"
                  disabled={id ? false : true
                    // deviceSites || devicePumps || deviceTanks ? false : true
                  }
                >
                  Unassign
                </button>
                <button
                  type="button"
                  className="font-bold py-[10px] cursor-pointer px-4 text-primary-400"
                  onClick={id ? handleReassignClick : handleDrawer}
                >
                  {id ? "Reassign" : "Assign"}
                </button>
              </div>
            </div>
          </div>
        </form>
        <Drawer
          isOpen={isDrawerOpen}
          onClose={handleDrawer}
          title="Assign site to device"
          width="1174px"
        >
          {/* Content of the drawer */}
          <div className="flex flex-col px-[16px] pb-5 mt-[10px]">
            <Stepper
              steps={steps}
              activeStep={activeStep}
              onChangeStep={setActiveStep}
              onNextStep={handleNextStep}
              onPrevStep={handlePrevStep}
              isLastStep={activeStep === steps.length - 1}
              content={renderStepContent()}
              onCancel={handleDrawer}
              onFinishStep={handleDrawerSubmit}
              prevBtnText={"Go Back"}
              nextBtnText={"Continue"}
              disabled={isDisableContinueBtn}
            />
          </div>
        </Drawer>
      </div>

      <ProvisioningModal
        visible={assignUnassignModal}
        title={
          modalMode === "cancel"
            ? "Cancel changes"
            : modalMode === "reassign"
            ? "Confirm account reassigning"
            : "Confirm account unassigning"
        }
        onClose={handleProvisionClose}
        onOk={handleModalConfirm}
      >
        <p className="text-neutral-25">{modaltext}</p>
      </ProvisioningModal>

      <CustomModal
        visible={modalVisible}
        title="Not allowed"
        onClose={handleModalClose}
        onOk={handleModalClose}
      >
        <p className="text-neutral-25">This device is already provisioned.</p>
      </CustomModal>
    </>
  );
};

export default AddEditDevice;
