import "./Sidebar.scss";
import Bell from "../Svg/Bell";
import Tree from "../Svg/Tree";
import Signal from "../Svg/Signal";
import Logout from "../Svg/Logout";
import { useDispatch } from "react-redux";
import GroupSitesPanel from "./GroupSitesPanel";
import Tooltip from "../../common/Tooltip/Tooltip";
import NotificationPanel from "./NotificationPanel";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../../services/authentication/auth.service";
import TokenService from "../../../services/authentication/token.service";
import {
  clearGroupId,
  clearGroupType,
  clearIsAmpGroup,
} from "../../../store/groupSitesAssets/groupSitesAssets.store";
import {
  setDevicePumps,
  setDeviceSites,
  setDeviceTanks,
} from "../../../store/provisionDevice/provisionDevice.store";
import {
  clearSiteId,
  clearTankId,
  clearCustomerId,
  clearCustomLevel1Id,
  clearCustomLevel2Id,
  clearSiteGroupDetails,
  clearTankGroupDetails,
  clearCustomerGroupDetails,
  clearCustomLevel1GroupDetails,
  clearCustomLevel2GroupDetails,
} from "../../../store/routesAndAssets/routesAndAssets.store";

import logo from "../../../assets/img/logo.svg";

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState<any>(null);
  const [userData, setUserData] = useState<any>({});
  const [isDatabaseActive, setIsDatabaseActive] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarRef = useRef<any>(null);
  const _access_token = TokenService.getLocalAccessToken();

  const ToggleSidebar = (val: any) => {
    setIsOpen(val);
  };

  useEffect(() => {
    const handleOutside = (e: any) => {
      if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        setIsOpen(null);
      }
    };
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, [sidebarRef]);

  useEffect(() => {
    // Check if the current path is "/prod" and update isDatabaseActive
    setIsDatabaseActive(location.pathname === "/prod");
  }, [location.pathname]);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_access_token]);

  const getUserData = () => {
    if (_access_token) {
      const _userData = TokenService.getUser();
      setUserData(_userData);
    }
  };

  const breadcrumbReset = () => {
    dispatch(clearGroupId());
    dispatch(clearGroupType());
    dispatch(clearIsAmpGroup());

    dispatch(clearCustomLevel1GroupDetails());
    dispatch(clearCustomLevel1Id());

    dispatch(clearCustomLevel2GroupDetails());
    dispatch(clearCustomLevel2Id());
    dispatch(clearCustomerId());
    dispatch(clearCustomerGroupDetails());
    dispatch(clearSiteId());
    dispatch(clearSiteGroupDetails());
    dispatch(clearTankId());
    dispatch(clearTankGroupDetails());

    const pathName = location?.pathname.split("/")[1];
    if (pathName !== "") navigate("/");
    else navigate(0);
  };

  const handleDeviceReduxCleanup = () => {
    dispatch(setDeviceSites(null));
    dispatch(setDevicePumps(null));
    dispatch(setDeviceTanks(null));
  };

  return (
    <>
      <div ref={sidebarRef}>
        <div className='fixed left-0 bottom-0 top-0 flex flex-col items-start justify-between bg-darkcolor-600 py-[16px] 2xl:py-[24px] w-[55px] 2xl:w-[68px] min-w-[55px] 2xl:min-w-[68px] z-[60] sidebar'>
          <div className='flex flex-col justify-center items-center w-full max-w-full sidebar-toplinks'>
            <div className='flex flex-col justify-center w-full max-w-full text-center px-[6px] pt-0 pb-[15px] 2xl:pb-[25px] sidebar-logo'>
              <Link
                to='/'
                className='w-full max-w-full bg-darkcolor-700 inline-block min-h-[20px] rounded-[5px] p-1'
                onClick={breadcrumbReset}
              >
                <img src={logo} alt='logo' className='object-contain bg-img logo' />
              </Link>
            </div>

            <div className='flex flex-col justify-center w-full max-w-full px-2 2xl:px-3 gap-1 sidebar-toplinks'>
              <Tooltip content='Routes & assets' direction='right'>
                <Link to='#' onClick={() => ToggleSidebar("siteGroups")}>
                  <div
                    data-testid='SitesGroupIcon'
                    className={`p-1 2xl:p-2 rounded-md cursor-pointer text-center hover:bg-primary-700 border-solid border-2 border-transparent hover:border-primary-500 iconbtn ${isOpen === "siteGroups" ? "activeMenu" : ""
                      }`}
                  >
                    <Tree color='#F7F9F9' />
                  </div>
                </Link>
              </Tooltip>
              <Tooltip content='Alerts' direction='right'>
                <Link
                  to='#'
                  onClick={() => ToggleSidebar("alarmsNotification")}
                >
                  <div
                    className={`p-1 2xl:p-2 rounded-md cursor-pointer text-center hover:bg-primary-700 border-solid border-2 border-transparent hover:border-primary-500 iconbtn ${isOpen === "alarmsNotification" ? "activeMenu" : ""
                      }`}
                  >
                    <Bell color='#F7F9F9' />
                  </div>
                </Link>
              </Tooltip>
              {userData?.user && userData?.user?.role === 2 ? (
                <Tooltip content='Device' direction='right'>
                  <Link
                    to='/device'
                    onClick={() => (
                      ToggleSidebar(null), handleDeviceReduxCleanup()
                    )}
                  >
                    <div
                      className={`p-1 2xl:p-2 rounded-md cursor-pointer text-center hover:bg-primary-700 border-solid border-2 border-transparent hover:border-primary-500 iconbtn ${isDatabaseActive ? "activeMenu" : ""
                        }`}
                    >
                      <Signal color='#F7F9F9' />
                    </div>
                  </Link>
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className='flex flex-col justify-center w-full max-w-full px-2 2xl:px-3 gap-2 sidebar-bottomlinks'>
            <Tooltip content='Logout' direction='right'>
              <div
                onClick={AuthService.logout}
                className={`p-1 2xl:p-2 rounded-md cursor-pointer text-center hover:bg-primary-700 border-solid border-2 border-transparent hover:border-primary-500 iconbtn`}
              >
                <Logout color='#F7F9F9' />
              </div>
            </Tooltip>
          </div>
        </div>
        <div>
          {isOpen === "siteGroups" ? (
            <GroupSitesPanel
              userData={userData}
              ToggleSidebar={ToggleSidebar}
            />
          ) : null}
          {isOpen === "alarmsNotification" ? <NotificationPanel /> : null}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
