import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ActionPayload {
  loading: boolean;
  alarmNotificationsData: Array<string> | null | undefined;
  error: string | undefined;
  selectedAssetId: string | undefined;
  selectedGroup: string | undefined;
}

const initialState: ActionPayload = {
  loading: false,
  alarmNotificationsData: null,
  error: undefined,
  selectedAssetId: undefined,
  selectedGroup: undefined,
};

const AlarmNotifications = createSlice({
  name: "AlarmNotifications",
  initialState,
  reducers: {
    ReducerAlarmNotiData: (state: any, action: PayloadAction<ActionPayload>) => {
      state.alarmNotificationsData = action.payload;
      return state;
    },
  },
});

export const { ReducerAlarmNotiData } = AlarmNotifications.actions;

export default AlarmNotifications;
