import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

interface ChartInterface {
    data: any;
    seriesData: any;
    chartId: any;
}

const Chart: React.FC<ChartInterface> = ({ data, seriesData, chartId }) => {
    useEffect(() => {
        am4core.options.commercialLicense = true;
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create(chartId, am4charts.XYChart);
        chart.data = data?.records

        const xAxis = chart.xAxes.push(new am4charts.DateAxis());
        xAxis.renderer.labels.template.disabled = data.xAxesLabelVisible;
        xAxis.renderer.labels.template.fill = am4core.color(data.xAxesColor);

        xAxis.dateFormatter = new am4core.DateFormatter();
        xAxis.dateFormatter.dateFormat = "MMM dd, yyyy";
        chart.dateFormatter.inputDateFormat = "MMM/dd/yyyy";

        const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.renderer.labels.template.disabled = data.yAxesLabelVisible;
        yAxis.renderer.labels.template.fill = am4core.color(data.yAxesColor);
        yAxis.renderer.labels.template.fontSize = "14";

        const seriesArray = [];
        for (let i = 0; i < seriesData.length; i++) {
            const series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.dateX = seriesData[i].valueX;
            series.dataFields.valueY = seriesData[i].valueY;
            series.fillOpacity = 0.01;
            series.strokeWidth = 1;
            series.stroke = am4core.color(seriesData[i].color);
            series.fill = am4core.color(seriesData[i].color);
            series.tooltipText = `{dateX.formatDate('MMM/dd/yyyy, HH:mm')} value:{valueY}`;
            if (seriesData[i]?.strokeDasharray === "Dotted")
                series.strokeDasharray = "10,10";
            seriesArray.push(series);
        }
        seriesArray.forEach((series) => {
            series.appear();
        });

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.snapToSeries = seriesArray;
        chart.exporting.menu = new am4core.ExportMenu();

        return () => {
            chart.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, seriesData, chartId]);

    return (
        <div id={chartId} style={{ width: "1000px", height: "500px" }} />
    )

};

export default Chart;
