import React from "react";
import { RxDragHandleDots2 } from "react-icons/rx";

interface AnalysisDropdownProps {
    stack: any;
    children?: React.ReactNode;
    isOpen?: boolean;
    onToggle?: () => void;
}

const AnalysisDropdown: React.FC<AnalysisDropdownProps> = ({
    stack,
    children,
    isOpen,
    onToggle,
}) => {
    return (
        <div className='relative inline-block mr-[10px] last:mr-0 mb-[10px]'>
            <div
                className={`p-[8px] flex items-center border-[1px] rounded-[4px] `}
                style={{
                    borderColor: stack.color ? stack.color : "#006dfa",
                    borderStyle: stack.chartType ? stack.chartType : "solid",
                }}
            >
                <RxDragHandleDots2
                    className='mr-[5px]'
                    style={{ color: stack.color ? stack.color : "#006dfa" }}
                    onClick={onToggle}
                />
                <label
                    className='text-[12px]'
                    onClick={onToggle}
                    style={{ color: stack.color ? stack.color : "#006dfa" }}
                >
                    {stack.tagName}
                </label>
            </div>
            {isOpen && children && (
                <div className='absolute border rounded-b-[8px] z-[999]'>
                    {React.Children.map(children, (child, index) => (
                        <div key={index} onClick={onToggle}>
                            {child}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AnalysisDropdown;
