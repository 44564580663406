import { FiEye } from "react-icons/fi";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { GoDotFill } from "react-icons/go";
import React, { useState, useEffect, ReactNode, useRef } from "react";

interface CustomDropdownProps {
  options: {
    id?: number;
    name: string;
    value: string;
    icon?: ReactNode;
  }[];
  onSelect: (selected: string) => void;
  optionHeading?: string;
  dropdownIcon: boolean;
  bgColor: string;
  defaultValue?: string;
  width?: string;
  handleDeviceStatus?: any;
  showValidate?: any;
  deviceStatus?: any;
  errors?: any;
  id?:any;
}

const DropdownSerial: React.FC<CustomDropdownProps> = ({
  options,
  onSelect,
  optionHeading,
  dropdownIcon,
  bgColor,
  defaultValue,
  width,
  handleDeviceStatus,
  showValidate,
  deviceStatus,
  errors,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(
    defaultValue || ""
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      {/* Dropdown Button */}
      <div style={{ width: width }}>
        <button
          type="button"
          className={`gap-[8px] outline-none inline-flex justify-between items-center w-full px-[14px] py-[8px] border-[1px] border-darkcolor-300 text-[16px] font-medium rounded-[8px] text-neutral-25 ${bgColor} focus:outline-none`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center text-[14px]">
            {dropdownIcon && <FiEye className="mr-2" />}
            {selectedOption || defaultValue}
          </div>
          {showValidate === false ? (
            <BiChevronDown className="ml-2" />
          ) : (
            <div className="flex">
              {deviceStatus?.isDeviceCommunicating === true ? (
                <span
                  className={`btn-outline-green-1 flex items-center cursor-default`}
                >
                  <GoDotFill /> Connected
                </span>
              ) : deviceStatus?.isDeviceCommunicating === false ? (
                <span
                  className={`btn-outline-red-1 flex items-center cursor-default`}
                >
                  <GoDotFill /> Not Found
                </span>
              ) : (
                <span
                  className="verify py-2 text-primary-400 cursor-pointer flex items-center"
                  onClick={(e: any) => (
                    e.stopPropagation(), handleDeviceStatus()
                  )}
                >
                  Verify Device{" "}
                  <BiChevronRight className="ml-1 text-[20px] relative bottom-[-1px]" />
                </span>
              )}
            </div>
          )}
        </button>
      </div>
      {errors && <div className="text-error-600 my-1">{errors}</div>}
      {/* Dropdown Options */}
      {!id && isOpen ? (
        <div className="border-[1px] max-h-[250px] border-darkcolor-400 origin-top-right absolute z-10 right-0 mt-[3px] w-full rounded-[8px] shadow-lg bg-darkcolor-700 overflow-y-auto">
          <div className="">
            <div className="block px-4 py-2 text-[14px] cursor-auto border-b-[1px] border-darkcolor-400 text-neutral-25 bg-darkcolor-800 rounded-t-[8px]">
              {optionHeading}
            </div>
            {options.map((option, index) => (
              <div
                key={option?.id}
                className={`text-[16px] p-[10px] text-sm  hover:bg-darkcolor-600 hover:text-neutral-25 cursor-pointer text-neutral-25 flex justify-between bg-darkcolor-800 ${
                  index === options.length - 1 ? "rounded-b-[8px]" : ""
                }`}
                onClick={() => {
                  setSelectedOption(option.name);
                  onSelect(option.value);
                  setIsOpen(!isOpen);
                }}
              >
                <div className="flex">
                  {option?.icon}
                  <p className="ml-[8px] text-neutral-25">{option.name}</p>
                </div>
                <BiChevronRight size={20} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DropdownSerial;
