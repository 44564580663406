export const PumpTableColumns = [
    {
        title: "",
        dataIndex: "Measurements",
        key: "Measurements",
    },
    {
        title: "Yesterday",
        dataIndex: "Yesterday",
        key: "Yesterday",
        render: (text: any, record: any) => {
            const value = record.Yesterday;
            if (value === "" || value === null || value === undefined) {
                return "";
            }
            if (record.Measurements === "Pump variance %") {
                return `${value} %`;
            }
            return value === "Over" || value === "Under" || value === "On target" ? value : `${value} gal`;
        },
    },
    {
        title: "3 days",
        dataIndex: "ThreeDays",
        key: "ThreeDays",
        render: (text: any, record: any) => {
            const value = record.ThreeDays;
            if (value === "" || value === null || value === undefined) {
                return "";
            }
            if (record.Measurements === "Pump variance %") {
                return `${value} %`;
            }
            return value === "Over" || value === "Under" || value === "On target" ? value : `${value} gal`;
        },
    },
    {
        title: "7 days",
        dataIndex: "SevenDays",
        key: "SevenDays",
        render: (text: any, record: any) => {
            const value = record.SevenDays;
            if (value === "" || value === null || value === undefined) {
                return "";
            }
            if (record.Measurements === "Pump variance %") {
                return `${value} %`;
            }
            return value === "Over" || value === "Under" || value === "On target" ? value : `${value} gal`;
        },
    },
];

export const AnalysisColumns = [
    {
        title: "",
        dataIndex: "tagName",
        key: "tagName",
    },
    {
        title: "",
        dataIndex: "color",
        key: "color",
    },
    {
        title: "",
        dataIndex: "dropdown",
        key: "dropdown",
    },
]