import React from "react";

interface SearchProps {
    size?: number | string;
    className?: string;
    onClick?: () => void;
}

const Search: React.FC<SearchProps> = ({ size = 24, className, onClick }) => {
    const style = size ? { width: size, height: size } : undefined;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className={className}
            style={style}
            onClick={onClick}
        >
            <path
                d='M17.5 16.6666L13.875 13.0416M15.8333 8.33329C15.8333 12.0152 12.8486 15 9.16667 15C5.48477 15 2.5 12.0152 2.5 8.33329C2.5 4.65139 5.48477 1.66663 9.16667 1.66663C12.8486 1.66663 15.8333 4.65139 15.8333 8.33329Z'
                stroke='#F7F9F9'
                strokeWidth='1.67'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default Search;
