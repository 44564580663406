import { ChangeEvent, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegFileExcel } from "react-icons/fa6";
import {
  excelErrorDownload,
  excelUpload,
  getToken,
  updateToken,
} from "../../../services/siteSummary/siteSummary.service";
import Loading from "../Loading/Loading";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface UploadExcelProps {
  handleDrawer: any;
  handleExcelDownload: any;
  convertXLSX?: any;
  excelToken: any
}

const UploadExcel: React.FC<UploadExcelProps> = ({
  handleDrawer,
  handleExcelDownload,
  convertXLSX,
  excelToken
}) => {
  const {
    groupId,
    groupType,
  } = useSelector((state: RootState) => state.GroupSitesAssets);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<"success" | "failed" | null>(
    null
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [file, setFile] = useState<any>([]);
  const [errFile, setErrFile] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {

    const file = event.target.files?.[0];
    if (file) {
      setUploadStatus("success");
      setFile(file);
      uploadFile(file);
      setUploadProgress(0);
      try {
        setIsLoading(true);
        //const response = await updateToken();
        let accessToken = excelToken;
        if (!accessToken) {
          const response = await getToken();
          if (response.statusCode === 200 && response.success) {
            const KEY_VAULT_URL = response.data.ampSiteManagementApiSiteUrlBase;
            const KEY_VAULT_EMAIL = response.data.ampSiteManagementApiEmail;
            const KEY_VAULT_PASSWORD = response.data.ampSiteManagementApiPassword;
            const tokenResponse = await updateToken(KEY_VAULT_URL, KEY_VAULT_EMAIL, KEY_VAULT_PASSWORD);
            if (tokenResponse.status === 200 && tokenResponse.statusText === "OK") {
              accessToken = tokenResponse.data.access_token;
            }
          }
        }
        if (excelToken || accessToken) {
          if (groupType === "Site") {
            const formData = new FormData();
            formData.append("file", file, file.name);
            const responseExcelUpload = await excelUpload(formData, accessToken, groupId);
            setUploadStatus("success");
            setUploadedFile(responseExcelUpload);
          }
          else {
            toast.error("Please select a site to upload the file");
          }
        }
      }
      catch (error) {
        const typedError = error as any;

        const accessToken = excelToken;
        console.error("Error uploading Excel file:", error);
        const errorData = typedError.response.data;
        // Handle the error data here
        console.log("Error data:", errorData);
        // Proceed with error handling, for example, download error file
        const fileId = errorData.fileId;
        if (fileId !== undefined && fileId !== null && fileId !== 0) {
          const errorFile = await excelErrorDownload(accessToken, fileId);
          setUploadedFile(errorFile);
          setErrFile(errorData);
          console.log("Error file:", errorFile);
          setUploadStatus("failed");
        }
        else {
          toast.error("Invalid File Format or Unsupported File");
        }

      }
      finally {
        setIsLoading(false);
      }
      event.target.value = "";

    }
  };
  const handleErrDownload = () => {
    getErrDownload();
  }

  const getErrDownload = async () => {
    try {
      setIsLoading(true);
      if (excelToken) {
        const responseExcelDownload = await excelErrorDownload(
          excelToken, errFile.fileId
        );
        convertXLSX(responseExcelDownload);
      }
      //setReferenceId(response.access_token);
    } catch (error) {
      console.error("error", error);
    }
    finally {
      setIsLoading(false);
    }
  }
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      if (file && isValidFile(file)) {
        setSelectedFile(file);
        uploadFile(file);
      }
    } else {
      setUploadStatus("failed");
      toast.error("Invalid file format");
    }
  };

  const resetUpload = () => {
    setSelectedFile(null);
    setUploadStatus(null);
    setUploadProgress(0);
    setUploadedFile(null);
    setFile([]);
  };
  const formatFileSize = (sizeInBytes: number) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    return `${Math.round(sizeInBytes / Math.pow(1024, i))} ${sizes[i]}`;
  };

  const confirmBtn = () => {
    setFile([]);
    toast.success("Records have been updated successfully");
    setSelectedFile(null);
    setUploadedFile(null);
  }

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const percent = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(percent);
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        console.log("File upload completed!");
        if (xhr.status === 200) {
          setUploadStatus("success");
        } else {
          setUploadStatus("failed");
        }
        setUploadProgress(100); // Set progress to 100% after completion
      }
    };

    xhr.open("POST", "your-upload-endpoint", true);
    xhr.send(formData);
  };

  const isValidFile = (file: File) => {
    const allowedExtensions = [".xls", ".xlsx", ".xlsm", ".xlsb", ".csv"];
    const extension = file.name.split(".").pop()?.toLowerCase();
    return allowedExtensions.includes(`.${extension}`);
  };

  return (
    <>
      <Loading isLoading={isLoading} text={""} />
      <div className="flex flex-col h-[90vh]">
        <div className="px-[16px]">
          {/* <SearchbarDrawer onChange={handleSerach} placeholder={"search colums name"} value={searchValue} /> */}
          <div
            className="border-dashed border-[4px] border-gray-100 rounded-md bg-[#131F2E] p-[10px] flex flex-col justify-center items-center h-[200px] gap-2 self-stretch"
            style={{ borderColor: "var(--Dark-mode-400, #4A5463)" }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <label
              htmlFor="file-input"
              className="cursor-pointer flex flex-col justify-center items-center"
            >
              <FaRegFileExcel size={20} />
              <div className="text-gray font-mulish text-base font-semibold leading-6">
                Select an Excel file to upload
              </div>
              <div className="text-[#647980] font-mulish text-sm font-normal leading-5">
                or drag and drop here
              </div>
            </label>
          </div>
          <input
            type="file"
            id="file-input"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {uploadedFile && uploadStatus === "success" ? (
            <>
              <div
                className="mt-[16px] justify-between border border-neutral-25 rounded p-[8px] text-gray-200"
                style={{ fontSize: "14px" }}
              >
                <div className="flex items-center">
                  <div className="mr-[20px]">{file.name}</div>
                  <div className="ml-auto flex items-center">
                    {/* <div className="h-3 w-3 bg-yellow-500 rounded-full mr-2"></div> */}
                    {/* <div className="text-yellow-500">Load</div> */}
                    <FaCheckCircle className="text-primary-400" />
                  </div>
                </div>
                <div>
                  {formatFileSize(file.size)} - {uploadProgress}%
                  uploaded
                </div>
              </div>

              <div className="text-center text-success-500 mt-[16px] text-base font-semibold leading-6">
                {uploadedFile.recordCount}
              </div>
              <div
                className="text-neutral-grey-500 font-mulish font-normal leading-5 text-center"
                style={{ fontSize: "14px" }}
              >
                Records have been updated please confirm to continue
              </div>
              <button onClick={confirmBtn} className="w-full px-4 py-2 mt-[16px] text-neutral-25 flex flex-col justify-center items-center bg-primary-indigo-600 hover:bg-primary-indigo-700 border border-primary-indigo-700 rounded btn btn-primary">
                Confirm
              </button>
            </>
          ) : uploadedFile && uploadStatus === "failed" ? (
            <>
              <div
                className="mt-[16px] justify-between border border-neutral-25 rounded p-[8px] text-gray-200"
                style={{ fontSize: "14px" }}
              >
                <div className="flex items-center">
                  <div className="mr-[20px]">{file.name}</div>
                  <div className="ml-auto flex items-center">
                    <div
                      className="h-3 w-3 rounded-full mr-2"
                      style={{ backgroundColor: "rgb(239 68 68)" }}
                    ></div>
                    <div className="text-error-500">Failed</div>
                  </div>
                </div>
                <div>
                  {formatFileSize(file.size)} - {uploadProgress}%
                  uploaded
                </div>
              </div>
              <div
                className="text-center mt-[16px] text-red-500 font-mulish text-base font-semibold leading-6"
                style={{ color: "rgb(239 68 68)" }}
              >
                {errFile.errorCount}
              </div>
              <div
                className="text-neutral-grey-500 font-mulish font-normal leading-5 text-center"
                style={{ fontSize: "14px" }}
              >
                Please download the file containing details for the errors.
              </div>
              <button onClick={handleErrDownload} className="w-full px-4 py-2 mt-[16px] text-neutral-25 flex flex-col justify-center items-center bg-primary-indigo-600 hover:bg-primary-indigo-700 border border-primary-indigo-700 rounded btn btn-primary">
                Download
              </button>
            </>
          ) : null}
        </div>
        {/* for button */}
        {uploadedFile ? null : (
          <button
            className=" px-4 py-2 mt-[16px] ml-[14px] mr-[14px] text-neutral-25 flex flex-col justify-center items-center bg-primary-indigo-600 hover:bg-primary-indigo-700 border border-primary-indigo-700 rounded btn btn-primary"
            onClick={handleExcelDownload}
          >
            Download template
          </button>
        )}

        <div className="mt-auto border-t-[1px] border-t-neutral-25 py-[20px]">
          {" "}
          <div className="flex justify-between px-[16px]">
            <div className="flex items-center"></div>
            {selectedFile && uploadStatus === "success" ? (
              <div className="flex justify-end space-x-2 ">
                <button
                  onClick={handleDrawer}
                  className="px-4 py-2 text-neutral-25 bg-gray-500 hover:bg-gray-600 rounded mr-2 btn btn-outline-1"
                >
                  Cancel
                </button>
                <button
                  onClick={resetUpload}
                  className="px-4 py-2 text-neutral-25 bg-primary-indigo-600 hover:bg-primary-indigo-700 border border-primary-indigo-700 rounded btn btn-primary"
                >
                  Start again
                </button>
              </div>
            ) : (
              <div className="flex justify-end space-x-2 ">
                <button
                  onClick={handleDrawer}
                  className="px-4 py-2 text-neutral-25 bg-gray-500 hover:bg-gray-600 rounded mr-2 btn btn-outline-1"
                >
                  Cancel
                </button>
                <button
                  onClick={resetUpload}
                  className="px-4 py-2 text-neutral-25 bg-primary-indigo-600 hover:bg-primary-indigo-700 border border-primary-indigo-700 rounded btn btn-primary"
                >
                  Start again
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadExcel;
