export const assignSitesData = [
  {
    id: 1,
    siteName: "Adams consignment station BIOC I",
    liftType: "GAS_LIFT",
    date: "11/04/2023",
    product: "BIOC11139A",
  },
  {
    id: 2,
    siteName: "Adams CTB EMBR",
    liftType: "GAS_LIFT",
    date: "11/04/2023",
    product: "EMBR11161B",
  },
  {
    id: 3,
    siteName: "Adams CTB HSCV",
    liftType: "GAS_LIFT",
    date: "11/04/2023",
    product: "HSV30164A",
  },
  {
    id: 4,
    siteName: "Adams D 3143Dp 1H",
    liftType: "GAS_LIFT",
    date: "11/04/2023",
    product: "SICI32540A",
  },
  {
    id: 5,
    siteName: "Adams h 3111MP",
    liftType: "GAS_LIFT",
    date: "11/04/2023",
    product: "SICI32540A",
  },
  {
    id: 6,
    siteName: "Adams H 3123SP",
    liftType: "GAS_LIFT",
    date: "11/04/2023",
    product: "SICI32540A",
  },
  // {
  //   id: 7,
  //   siteName: "Adams H 3141DF",
  //   liftType: "GAS_LIFT",
  //   date: "11/04/2023",
  //   product: "SICI32540A",
  // },
  // {
  //   id: 8,
  //   siteName: "Akins tidwell 4403 1WA",
  //   liftType: "GAS_LIFT",
  //   date: "11/04/2023",
  //   product: "HSCV30164A",
  // },
  // {
  //   id: 9,
  //   siteName: "AICFU-219",
  //   liftType: "GAS_LIFT",
  //   date: "11/04/2023",
  //   product: "ACPC12261B",
  // },
  // {
  //   id: 10,
  //   siteName: "Adams h 3111MP",
  //   liftType: "GAS_LIFT",
  //   date: "11/04/2023",
  //   product: "SICI32540A",
  // },
  // {
  //   id: 11,
  //   siteName: "Adams H 3123SP",
  //   liftType: "GAS_LIFT",
  //   date: "11/04/2023",
  //   product: "SICI32540A",
  // },
  // {
  //   id: 12,
  //   siteName: "Adams H 3141DF",
  //   liftType: "GAS_LIFT",
  //   date: "11/04/2023",
  //   product: "SICI32540A",
  // },
  // {
  //   id: 13,
  //   siteName: "Akins tidwell 4403 1WA",
  //   liftType: "GAS_LIFT",
  //   date: "11/04/2023",
  //   product: "HSCV30164A",
  // },
  // {
  //   id: 14,
  //   siteName: "AICFU-219",
  //   liftType: "GAS_LIFT",
  //   date: "11/04/2023",
  //   product: "ACPC12261B",
  // },
];

export const CommonConstant: any = {
  localEnvironemt: "local",
};

export const serialDropdownOptions = [
  {
    id: 1,
    value: "CIARyanTest",
    name: "CIARyanTest",
    //icon: <CiBullhorn size={20} />,
    type: "type test",
    make: "make test",
    hardwareVersion: "Hardware version test",
    firmwareVersion: "Firmware version test",
    imei: 1111,
    status: "Active",
  },
  {
    id: 2,
    value: "CIASample",
    name: "CIASample",
    type: "type test",
    make: "make test",
    hardwareVersion: "Hardware version test",
    firmwareVersion: "Firmware version test",
    imei: 2222,
    status: "Active",
  },
  {
    id: 3,
    value: "CIACloud",
    name: "CIACloud",
    type: "type test",
    make: "make test",
    hardwareVersion: "Hardware version test",
    firmwareVersion: "Firmware version test",
    imei: 3333,
    status: "Not Active",
  },
];

export const userData = [
  {
    id: 1,
    userName: "Emelia Cline | District Manager",
    email: "e.eline@example.com",
    access: "",
  },
  {
    id: 2,
    userName: "Cullen Woods | Area Manager",
    email: "cullenwoods@example.com",
    access: "4",
  },
  {
    id: 3,
    userName: "Resse Parish | Technical Sales Representative",
    email: "resseparish@exapmle.com",
    access: "",
  },
  {
    id: 4,
    userName: "Kasyn Muno | Technical Sales Repreentative",
    email: "kasynmuno@exapmle.com",
    access: "6",
  },
  {
    id: 5,
    userName: "Kehlani Watkins | Technical Sales Represntative",
    email: "K.watkins@example.com",
    access: "",
  },
  {
    id: 6,
    userName: "Nash Castillo | Sr. Application Specialist",
    email: "e.eline@example.com",
    access: "5",
  },
  {
    id: 7,
    userName: "Eva leach | Sr. Application Specialist",
    email: "e.leach@example",
    access: "",
  },
];

export const GroupsAssets1 = [
  {
    id: "1",
    groupName: "New Group 1",
    createdDate: "2024-02-01T13:16:03.502Z",
    siteDetails: ["1", "2"],
    showAssets: false,
    childGroups: [],
  },
];
