import React from "react";

interface ArrowProps {
    size?: number | string;
    className?: string;
    onClick?: () => void;
    color?: string
}

const Arrow: React.FC<ArrowProps> = ({ size = 24, className, onClick, color = "#F7F9F9" }) => {
    const style = size ? { width: size, height: size } : undefined;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className={className}
            style={style}
            onClick={onClick}
        >
            <path
                d='M7.5 15L12.5 10L7.5 5'
                stroke={color}
                strokeWidth='1.67'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default Arrow;
