import moment from "moment";
import FileSaver from "file-saver";

export const downloadExcelFile = (base64String: string) => {
    const byteCharacters = atob(base64String);
    const fileName = `site_summary_details_${getFormattedDateTime()}.xlsx`;
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const convertBase64ToFile = (fileData: string) => {
    try {
        const decodedString = atob(fileData);
        const rows = decodedString.split("\n");
        const csvContent = rows
            .map((row) =>
                row
                    .split(",")
                    .map((cell) => `"${cell}"`)
                    .join(",")
            )
            .join("\n");
        const csvData = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        FileSaver.saveAs(csvData, `site_details_alarm_${getFormattedDateTime()}.csv`);
    } catch (error) {
        console.error("Error converting base64 to file:", error);
    }
};

export const getFormattedDateTime = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
};

export const convertDateTime = (value: string) => {
    const date = new Date(value);
    const formattedTime = date.toLocaleTimeString([], {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
    return formattedTime;
};


export const convertDateTimeUTC = (value: string | number | Date) => {
    const date = new Date(value);
    //const localDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));

    const formattedDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });

    const formattedTime = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    });

    return `${formattedDate} ${formattedTime}`;
};

export const convertUTCToLocalDateTime = (utcDate: string | number | Date): any => {
    const date = new Date(utcDate + 'Z'); 
    
    const localDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });

    const localTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });

    return `${localDate} ${localTime}`;
};

export const timeDifference = (date: any): string => {
    const localDateObject = convertUTCToLocalDateTime(date);

    const dateObject = moment(localDateObject, "MM/DD/YYYY HH:mm:ss");
    if (!dateObject.isValid()) {
        return "Invalid date";
    }

    const now = moment();
    const differenceInHours = now.diff(dateObject, "hours");
    const differenceInMinutes = now.diff(dateObject, "minutes");

    let displayText: string;

    if (differenceInHours >= 24) {
        displayText = dateObject.format("MM/DD/YYYY HH:mm");
    } else if (differenceInHours > 0) {
        displayText = `${differenceInHours} hour${differenceInHours > 1 ? "s" : ""} ago`;
    } else if (differenceInMinutes > 0) {
        displayText = `${differenceInMinutes} min${differenceInMinutes > 1 ? "s" : ""} ago`;
    } else {
        displayText = dateObject.format("MM/DD/YYYY HH:mm");
    }

    return displayText;
};
