import React, { useState } from "react";
import LoginForm from "./Loginscreens/LoginForm";
import DefaultSetup from "./Loginscreens/DefaultSetup";

interface formdata {
  useName: string;
  passWord: string | null | undefined;
}

const Login = () => {
  const [page, setPage] = useState<number>(1);
  const [formData, setFormData] = useState<formdata>({
    useName: "",
    passWord: "",
  });

  const handleLoginscreens = () => {
    /* if (page === 0) {
      return (
        <LoginForm
          formData={formData}
          setFormData={setFormData}
          setPage={setPage}
        />
      );
    } else {
      return <DefaultSetup page={page} setPage={setPage} />;
    } */
    return <DefaultSetup page={page} setPage={setPage} />;
  };

  return <>{handleLoginscreens()}</>;
};

export default Login;
