import Settings from "../Svg/Settings";
import Loading from "../Loading/Loading";
import Tooltip from "../Tooltip/Tooltip";
import { FiSearch } from "react-icons/fi";
import { RootState } from "../../../store/store";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import RoutesAndAssets from "../GroupsAssets/RoutesAndAssets";
import { getGroups } from "../../../services/groupSitesAssets/groupSitesAssets.service";
import { setRouteAssetManagmentGroupIdValue } from "../../../store/routeAssetManagement/routeAssetManagement.store";
import {
  setGroupId,
  setGroupType,
  setIsAmpGroup,
} from "../../../store/groupSitesAssets/groupSitesAssets.store";
import {
  setCustomerGroupDetails,
  setCustomerId,
  setSiteId,
  setSiteGroupDetails,
  setTankGroupDetails,
  setTankId,
  setCustomLevel1Id,
  setCustomLevel1GroupDetails,
  clearCustomerGroupDetails,
  clearSiteGroupDetails,
  clearTankId,
  clearTankGroupDetails,
  clearCustomerId,
  clearCustomLevel1GroupDetails,
  clearCustomLevel1Id,
  setRenderSiteSummaryAPI,
  setCustomLevel2Id,
  setCustomLevel2GroupDetails,
  clearSiteId,
  setCustomLevel1Tanks,
  clearCustomLevel2Id,
  clearCustomLevel2GroupDetails,
  setCustomLevel3GroupDetails,
  setCustomLevel3Id,
  clearCustomLevel3Id,
  clearCustomLevel3GroupDetails,
  setNoSiteClearForSearch,
  clearNoSiteClearForSearch,
  setDefaultGroupData,
  setAllSitesData,
  setGroupDataCustom,
  setTest,
} from "../../../store/routesAndAssets/routesAndAssets.store";

interface TrackingProps {
  onClick: () => void;
}

const GroupSitesPanel: React.FC<TrackingProps | any> = ({
  userData,
  ToggleSidebar,
}) => {
  const [data, setData] = useState<any>([]);
  const [siteGroup, setSiteGroup] = useState<any>([]);
  const [filterText, setFilterText] = useState<string>("");
  const [custom1Group, setCustom1Group] = useState<any>([]);
  const [custom2Group, setCustom2Group] = useState<any>([]);
  const [custom3Group, setCustom3Group] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerGroup, setCustomerGroup] = useState<any>([]);
  const [filterGroupData, setFilterGroupData] = useState<any>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { defaultGroupData, customGroupData, customLevel1Id, customLevel2Id, customLevel3Id } = useSelector(
    (state: RootState) => state.RoutesAndAssets
  );

  useEffect(() => {
    gettingAllGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gettingAllGroups = async () => {
    setIsLoading(true);
    try {
      const response = await getGroups();
      if (response?.statusCode && response.success) {
        setData(response.data);
        setFilterGroupData(response.data);
        dispatch(
          setDefaultGroupData(
            response.data.filter(
              (item: any) => item.isAmpGroup && item.groupName !== "All Sites"
            )
          )
        );
        dispatch(
          setGroupDataCustom(
            response.data.filter(
              (item: any) => !item.isAmpGroup && item.groupName !== "All Sites"
            )
          )
        );
        dispatch(
          setAllSitesData(
            response.data.filter((item: any) => item.groupName === "All Sites")[0]
          )
        );
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterDataByName = (items: any, searchText: any) => {
    if (searchText === "") {
      return items.map((item: any) => ({
        ...item,
        showAssets: false,
      }));
    }
    return items
      .map((item: any) => {
        const groupNameMatches =
          item?.groupName &&
          item?.groupName?.toLowerCase().includes(searchText.toLowerCase());
        let filteredItem = { ...item, showAssets: true };

        if (item?.childGroup) {
          filteredItem.childGroup = filterDataByName(
            item.childGroup,
            searchText
          );
          filteredItem.showAssets = true;
        }
        if (item?.site) {
          filteredItem.site =
            item?.site &&
            item?.site.filter((val: any) =>
              val?.siteName?.toLowerCase().includes(searchText.toLowerCase())
            );
        }
        if (
          groupNameMatches ||
          (filteredItem.childGroup && filteredItem.childGroup.length > 0) ||
          (filteredItem.site && filteredItem.site.length > 0)
        ) {
          return filteredItem;
        }
        return null;
      })
      .filter((item: any) => item != null);
  };

  const handleInputSearch = (e: any) => {
    const inputValue = e.target.value;
    setFilterText(inputValue);
    setFilterGroupData(filterDataByName(data, inputValue));
    if (inputValue) {
      dispatch(setNoSiteClearForSearch());
    } else {
      dispatch(clearNoSiteClearForSearch());
    }
  };

  const handleToggleClick = (id: any, items: any) => {
    return items.map((item: any) => {
      if (item.id === id) {
        return { ...item, showAssets: !item.showAssets };
      }
      if (item.childGroup) {
        return { ...item, childGroup: handleToggleClick(id, item.childGroup) };
      }
      return item;
    });
  };

  const toggleShowAssets = (id: any) => {
    setFilterGroupData(handleToggleClick(id, filterGroupData));
  };

  const closeSidebar = () => {
    ToggleSidebar(null);
    const pathName = location?.pathname.split("/")[1];
    if (pathName !== "") navigate("/");
  };

  const clearTank = () => {
    dispatch(clearTankId());
    dispatch(clearTankGroupDetails());
  };

  const clearSite = () => {
    dispatch(clearSiteId());
    dispatch(clearSiteGroupDetails());
  };

  const clearCustomer = () => {
    dispatch(clearCustomerId());
    dispatch(clearCustomerGroupDetails());
  };

  const clearDefaultGroup = () => {
    clearTank();
    clearSite();
    clearCustomer();
  };

  const clearCustomGroup = () => {
    clearCustomLevel1();
  };

  const clearCustomLevel1 = () => {
    dispatch(clearCustomLevel1Id());
    dispatch(clearCustomLevel1GroupDetails());
  };

  const clearCustomLevel2 = () => {
    dispatch(clearCustomLevel2Id());
    dispatch(clearCustomLevel2GroupDetails());
  };

  const clearCustomLevel3 = () => {
    dispatch(clearCustomLevel3Id());
    dispatch(clearCustomLevel3GroupDetails());
  };

  const handleCustomerDropdown = (customerItem: { id: string }) => {
    const duplicateCustomerRecords = [...filterGroupData];
    const findIndexCustomerRecord = duplicateCustomerRecords?.findIndex(
      (record: { id: string }) => record?.id === customerItem?.id
    );
    if (duplicateCustomerRecords && findIndexCustomerRecord !== -1) {
      const updatedRecord = {
        ...duplicateCustomerRecords[findIndexCustomerRecord],
      };
      updatedRecord.showAssets = !updatedRecord.showAssets;
      duplicateCustomerRecords[findIndexCustomerRecord] = updatedRecord;
      setCustomerGroup(duplicateCustomerRecords[findIndexCustomerRecord]);
      setFilterGroupData(duplicateCustomerRecords);
    }
  };

  const handleCustomerGroupClick = (customerItem: any) => {
    clearSite();
    clearTank();
    clearCustomGroup();
    clearCustomLevel3();
    clearCustomLevel2();
    clearCustomLevel1();

    dispatch(setGroupId(customerItem?.id));
    dispatch(setIsAmpGroup(customerItem.isAmpGroup));
    dispatch(setGroupType(customerItem.ampGroupType));
    dispatch(setCustomerId(customerItem?.id));
    if (
      Array.isArray(customerItem?.childGroup) &&
      customerItem?.childGroup.length === 0
    ) {
      const findCustomerRecord = defaultGroupData.find(
        (item: any) => item?.id === customerItem?.id
      );
      if (findCustomerRecord)
        customerItem.childGroup = findCustomerRecord.childGroup;
    }
    dispatch(setCustomerGroupDetails(customerItem));
    dispatch(setRenderSiteSummaryAPI());
    closeSidebar();
  };

  const handleSiteDropdown = (siteItem: any, customerId: string) => {
    const duplicateCustomerRecords = [...filterGroupData];
    const duplicateSiteRecord = { ...customerGroup };
    if (
      duplicateSiteRecord?.childGroup &&
      duplicateSiteRecord?.childGroup.length > 0
    ) {
      const findIndexSiteRecord = duplicateSiteRecord?.childGroup.findIndex(
        (record: { id: string }) => record?.id === siteItem?.id
      );

      if (findIndexSiteRecord !== -1) {
        const updatedChildGroup = [...duplicateSiteRecord.childGroup];
        updatedChildGroup[findIndexSiteRecord] = {
          ...updatedChildGroup[findIndexSiteRecord],
          showAssets: !updatedChildGroup[findIndexSiteRecord].showAssets,
        };

        duplicateSiteRecord.childGroup = updatedChildGroup;
        setSiteGroup(updatedChildGroup[findIndexSiteRecord]);
        setCustomerGroup(duplicateSiteRecord);
        const findIndexCustomerRecord = duplicateCustomerRecords?.findIndex(
          (record: { id: string }) => record?.id === customerId
        );

        if (findIndexCustomerRecord !== -1) {
          const updatedCustomerGroup = [
            ...duplicateCustomerRecords[findIndexCustomerRecord].childGroup,
          ];
          updatedCustomerGroup[findIndexSiteRecord] =
            updatedChildGroup[findIndexSiteRecord];
          duplicateCustomerRecords[findIndexCustomerRecord] = {
            ...duplicateCustomerRecords[findIndexCustomerRecord],
            childGroup: updatedCustomerGroup,
          };
          setFilterGroupData(duplicateCustomerRecords);
        }
      }
    }
  };

  const handleSiteGroupClick = (siteItem: any, customerItem: any) => {
    clearTank();
    clearCustomGroup();

    dispatch(setGroupId(siteItem?.id));
    dispatch(setIsAmpGroup(siteItem.isAmpGroup));
    dispatch(setGroupType(siteItem.ampGroupType));

    dispatch(setCustomerId(customerItem?.id));
    dispatch(setSiteId(siteItem?.id));
    dispatch(setSiteGroupDetails(siteItem));
    dispatch(setCustomerGroupDetails(customerItem));
    dispatch(setRenderSiteSummaryAPI());
    closeSidebar();
  };

  const handleTankGroupClick = (
    tankItem: any,
    siteItem: any,
    customerItem: any
  ) => {
    dispatch(setGroupId(siteItem?.id));
    dispatch(setIsAmpGroup(siteItem.isAmpGroup));
    dispatch(setGroupType(siteItem.ampGroupType));

    dispatch(setTankGroupDetails(tankItem));
    dispatch(setSiteId(siteItem?.id));
    dispatch(setSiteGroupDetails(siteItem));
    dispatch(setCustomerId(customerItem?.id));
    dispatch(setTankId(tankItem?.siteId));
    dispatch(setCustomerGroupDetails(customerItem));
    ToggleSidebar(null);
    navigate(`/site-details`);
    // navigate(`/site-details/${tankItem?.siteId}`);
  };

  const handleCustom1Dropdown = (customItem: { id: string }) => {
    const duplicateCustomRecords = [...filterGroupData];
    const findIndexCustomRecord = duplicateCustomRecords?.findIndex(
      (record: { id: string }) => record?.id === customItem?.id
    );
    if (findIndexCustomRecord !== -1) {
      const updatedRecord = {
        ...duplicateCustomRecords[findIndexCustomRecord],
        showAssets: !duplicateCustomRecords[findIndexCustomRecord].showAssets,
      };
      duplicateCustomRecords[findIndexCustomRecord] = updatedRecord;
      setCustom1Group(updatedRecord);
      setFilterGroupData(duplicateCustomRecords);
    }
  };

  const handleCustom1stGroupClick = (customItem: any) => {
    const customItemCopy = { ...customItem };
    if (Array.isArray(customItemCopy.childGroup) && customItemCopy.childGroup.length === 0) {
      const findCustomerRecord = customGroupData?.find((item: any) => item?.id === customItemCopy?.id);
      if (findCustomerRecord) {
        customItemCopy.childGroup = [...findCustomerRecord.childGroup];
      }
    }
    if (Array.isArray(customItemCopy.site) && customItemCopy.site.length === 0) {
      const findCustomerRecord = customGroupData?.find((item: any) => item?.id === customItemCopy?.id);
      if (findCustomerRecord) {
        customItemCopy.site = [...findCustomerRecord.site];
      }
    }

    clearDefaultGroup();
    dispatch(setGroupId(customItemCopy?.id));
    dispatch(setIsAmpGroup(customItemCopy.isAmpGroup));
    dispatch(setGroupType(customItemCopy.ampGroupType));
    dispatch(setCustomLevel1Id(customItemCopy?.id));
    clearCustomLevel2();
    clearCustomLevel3();
    dispatch(setCustomLevel1GroupDetails(customItemCopy));
    dispatch(setRenderSiteSummaryAPI());
    closeSidebar();
  };

  const handleCustom1Level1Tank = (tankItem: any, customItem: any) => {
    clearDefaultGroup();
    dispatch(clearCustomLevel2Id());
    dispatch(clearCustomLevel2GroupDetails());
    dispatch(setGroupId(customItem?.id));
    dispatch(setIsAmpGroup(customItem.isAmpGroup));
    dispatch(setGroupType(customItem.ampGroupType));
    dispatch(setTankId(tankItem?.siteId));
    dispatch(setTankGroupDetails(tankItem));
    dispatch(setCustomLevel1Id(customItem?.id));
    dispatch(setCustomLevel1GroupDetails(customItem));
    dispatch(setTest())
    if (customLevel1Id !== customItem.id) {
      dispatch(setCustomLevel1Tanks(customItem?.site));
    }
    ToggleSidebar(null);
    navigate("/site-details");
  };

  const handleCustom2Dropdown = (siteItem: any, customerId: string) => {
    const duplicateCustomerRecords = JSON.parse(
      JSON.stringify(filterGroupData)
    );
    const duplicateSiteRecord = JSON.parse(JSON.stringify(custom1Group));

    if (
      duplicateSiteRecord?.childGroup &&
      duplicateSiteRecord?.childGroup.length > 0
    ) {
      const findIndexSiteRecord = duplicateSiteRecord.childGroup.findIndex(
        (record: { id: string }) => record?.id === siteItem?.id
      );
      if (findIndexSiteRecord !== -1) {
        duplicateSiteRecord.childGroup[findIndexSiteRecord].showAssets =
          !duplicateSiteRecord.childGroup[findIndexSiteRecord].showAssets;

        setCustom2Group(duplicateSiteRecord.childGroup[findIndexSiteRecord]);

        const findIndexCustomerRecord = duplicateCustomerRecords.findIndex(
          (record: { id: string }) => record?.id === customerId
        );
        if (findIndexCustomerRecord !== -1) {
          duplicateCustomerRecords[findIndexCustomerRecord].childGroup[
            findIndexSiteRecord
          ] = duplicateSiteRecord.childGroup[findIndexSiteRecord];
          setFilterGroupData(duplicateCustomerRecords);

          setCustom1Group(duplicateSiteRecord);
        }
      }
    }
  };

  const handleCustom3Dropdown = (
    itemLevel3: any,
    itemLevel2: any,
    itemLevel1: any
  ) => {
    const duplicateCustomRecords = [...filterGroupData];
    const duplicateCustomGroupLevel2 = { ...custom2Group };

    if (
      duplicateCustomGroupLevel2?.childGroup &&
      duplicateCustomGroupLevel2?.childGroup.length > 0
    ) {
      const findIndexCustomGroupLevel2Record =
        duplicateCustomGroupLevel2?.childGroup.findIndex(
          (record: { id: string }) => record?.id === itemLevel3?.id
        );
      if (findIndexCustomGroupLevel2Record !== -1) {
        duplicateCustomGroupLevel2.childGroup[
          findIndexCustomGroupLevel2Record
        ].showAssets =
          !duplicateCustomGroupLevel2?.childGroup[
            findIndexCustomGroupLevel2Record
          ].showAssets;
        setCustom3Group(
          duplicateCustomGroupLevel2.childGroup[
          findIndexCustomGroupLevel2Record
          ]
        );
        const findIndexCustomRecord = duplicateCustomRecords?.findIndex(
          (item: { id: string }) => item?.id === itemLevel1.id
        );
        if (findIndexCustomRecord !== -1) {
          const findIndexCustomGroupLevel1Record = duplicateCustomRecords[
            findIndexCustomRecord
          ]?.childGroup.findIndex(
            (item: { id: string }) => item?.id === itemLevel2.id
          );
          if (findIndexCustomGroupLevel1Record !== -1) {
            setFilterGroupData(duplicateCustomRecords);
          }
        }
      }
    }
  };

  const handleCustom2ndGroupClick = (
    itemCustomLevel2: any,
    itemCustomLevel1: any
  ) => {
    clearCustomer();
    clearSite();
    clearTank();

    clearCustomLevel3();

    dispatch(setGroupId(itemCustomLevel2?.id));
    dispatch(setIsAmpGroup(itemCustomLevel2.isAmpGroup));
    dispatch(setGroupType(itemCustomLevel2.ampGroupType));

    dispatch(setCustomLevel1Id(itemCustomLevel1?.id));
    dispatch(setCustomLevel1GroupDetails(itemCustomLevel1));

    dispatch(setCustomLevel2Id(itemCustomLevel2?.id));
    dispatch(setCustomLevel2GroupDetails(itemCustomLevel2));

    dispatch(setRenderSiteSummaryAPI());
    closeSidebar();
  };

  const handleCustom3rdGroupClick = (
    itemCustomLevel3: any,
    itemCustomLevel2: any,
    itemCustomLevel1: any
  ) => {
    clearCustomer();
    clearSite();
    clearTank();

    dispatch(setGroupId(itemCustomLevel3?.id));
    dispatch(setIsAmpGroup(itemCustomLevel3.isAmpGroup));
    dispatch(setGroupType(itemCustomLevel3.ampGroupType));

    dispatch(setCustomLevel1Id(itemCustomLevel1?.id));
    dispatch(setCustomLevel1GroupDetails(itemCustomLevel1));

    dispatch(setCustomLevel2Id(itemCustomLevel2?.id));
    dispatch(setCustomLevel2GroupDetails(itemCustomLevel2));

    dispatch(setCustomLevel3Id(itemCustomLevel3?.id));
    dispatch(setCustomLevel3GroupDetails(itemCustomLevel3));

    dispatch(setRenderSiteSummaryAPI());
    closeSidebar();
  };

  const handleCustom2Level2Tank = (
    tankItem: any,
    itemCustomLevel2: any,
    itemCustomLevel1: any
  ) => {
    clearDefaultGroup();
    dispatch(setGroupId(itemCustomLevel1?.id));
    dispatch(setIsAmpGroup(itemCustomLevel1.isAmpGroup));
    dispatch(setGroupType(itemCustomLevel1.ampGroupType));

    dispatch(setCustomLevel1Id(itemCustomLevel1?.id));
    dispatch(setCustomLevel1GroupDetails(itemCustomLevel1));
    dispatch(setCustomLevel2Id(itemCustomLevel2?.id));
    dispatch(setCustomLevel2GroupDetails(itemCustomLevel2));
    dispatch(setTankId(tankItem?.siteId));
    dispatch(setTankGroupDetails(tankItem));
    dispatch(setCustomLevel1Tanks(itemCustomLevel2?.site));

    if (customLevel2Id !== itemCustomLevel2.id) {
      dispatch(setCustomLevel1Tanks(itemCustomLevel2?.site));
    }

    ToggleSidebar(null);
    navigate("/site-details");
  };

  const handleCustom3Level3Tank = (
    tankItem: any,
    itemCustomeLevel3: any,
    itemCustomLevel2: any,
    itemCustomLevel1: any
  ) => {
    clearDefaultGroup(); // clear default group
    dispatch(setGroupId(itemCustomLevel1?.id));
    dispatch(setIsAmpGroup(itemCustomLevel1.isAmpGroup));
    dispatch(setGroupType(itemCustomLevel1.ampGroupType));
    dispatch(setCustomLevel1Id(itemCustomLevel1?.id));
    dispatch(setCustomLevel1GroupDetails(itemCustomLevel1));
    dispatch(setCustomLevel2Id(itemCustomLevel2?.id));
    dispatch(setCustomLevel2GroupDetails(itemCustomLevel2));
    dispatch(setCustomLevel3GroupDetails(itemCustomeLevel3));
    dispatch(setCustomLevel3Id(itemCustomeLevel3?.id));
    dispatch(setTankId(tankItem?.siteId));
    dispatch(setTankGroupDetails(tankItem));
    dispatch(setCustomLevel1Tanks(itemCustomeLevel3?.site));

    if (customLevel3Id !== itemCustomeLevel3.id) {
      dispatch(setCustomLevel1Tanks(itemCustomeLevel3?.site));
    }
    ToggleSidebar(null);
    navigate("/site-details");
  };
  const handleAllSitesTanks = (tankItem: any, customerItem: any) => {
    dispatch(setGroupId(customerItem?.id));
    dispatch(setIsAmpGroup(customerItem.isAmpGroup));
    dispatch(setGroupType(customerItem.ampGroupType));

    dispatch(clearSiteId());
    dispatch(clearSiteGroupDetails());
    clearCustomLevel1();
    clearCustomLevel2();
    clearCustomLevel3();

    dispatch(setTankId(tankItem?.siteId));
    dispatch(setTankGroupDetails(tankItem));
    dispatch(setCustomerId(customerItem?.id));
    dispatch(setCustomerGroupDetails(customerItem));
    ToggleSidebar(null);
    navigate(`/site-details`);
    // navigate(`/site-details/${tankItem?.siteId}`);
  };

  return (
    <>
      <Loading isLoading={isLoading} text={""} />
      <div className='fixed left-70 bottom-0 top-0 flex flex-col h-full bg-darkcolor-800 w-[410px] 2xl:w-[510px] min-w-[410px] p-3 2xl:p-4 py-9 pb-16 z-50'>
        <div className='w-full h-full'>
          <div className='flex flex-wrap justify-between items-center mb-[16px]'>
            <h6>Routes & assets</h6>
            {userData?.user && userData?.user?.role !== 0 && (
              <Tooltip content={"Customize groups"} direction='left'>
                <Settings
                  color='#F7F9F9'
                  className='cursor-pointer'
                  onClick={() => {
                    navigate("/assetmanagement");
                    ToggleSidebar(null);
                    dispatch(setRouteAssetManagmentGroupIdValue(""));
                  }}
                />
              </Tooltip>
            )}
          </div>
          <div className='flex flex-wrap justify-between mb-[16px]'>
            <div className='grow mr-[9px] input-with-lefticon'>
              <FiSearch className='input-icon' />
              <input
                className='input-outline-4'
                placeholder='Search'
                onChange={handleInputSearch}
                autoComplete='off'
                value={filterText}
              />
            </div>
          </div>
          {data.length > 0 && (
            <RoutesAndAssets
              defaultGroupData={filterGroupData.filter(
                (item: any) => item.isAmpGroup
              )}
              customerGroup={customerGroup}
              handleCustomerGroupClick={handleCustomerGroupClick}
              handleCustomerDropdown={handleCustomerDropdown}
              siteGroup={siteGroup}
              handleSiteDropdown={handleSiteDropdown}
              handleSiteGroupClick={handleSiteGroupClick}
              handleTankGroupClick={handleTankGroupClick}
              customGroupData={filterGroupData.filter(
                (item: any) => !item.isAmpGroup
              )}
              custom1Group={custom1Group}
              handleCustom1Dropdown={handleCustom1Dropdown}
              handleCustom1stGroupClick={handleCustom1stGroupClick}
              handleCustom1Level1Tank={handleCustom1Level1Tank}
              handleCustom2Dropdown={handleCustom2Dropdown}
              handleCustom2ndGroupClick={handleCustom2ndGroupClick}
              handleCustom2Level2Tank={handleCustom2Level2Tank}
              custom2Group={custom2Group}
              handleCustom3Level3Tank={handleCustom3Level3Tank}
              handleCustom3Dropdown={handleCustom3Dropdown}
              custom3Group={custom3Group}
              handleCustom3rdGroupClick={handleCustom3rdGroupClick}
              toggleShowAssets={toggleShowAssets}
              handleAllSitesTanks={handleAllSitesTanks}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default GroupSitesPanel;
