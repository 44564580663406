

import React from 'react';
import './Toggle.scss';

interface ToggleProps {
    checked: boolean;
    onChange: any;// (checked: boolean) => void;
    label: string
}

const Toggle: React.FC<ToggleProps> = ({ checked, onChange, label }) => {
    // const handleToggle = () => {
    //     onChange(!checked);
    // };

    return (
        <>
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={onChange} />
                <span className="slider"></span>
            </label>
            <label className='text-neutral-25 text-[14px] font-semibold mx-[8px]'>
                {label}
            </label>
        </>

    );
};

export default Toggle;
