import { CommonConstant } from "../constants/common.constant";
import * as XLSX from "xlsx";
import { utils, writeFileXLSX } from "xlsx";

const ConsoleLog = (apiMessage: any, data?: string) => {
  if (`${process.env.ENVIRONMENT}` === CommonConstant.localEnvironemt) {
    if (data) console.log(apiMessage, data);
    else console.log(apiMessage, data);
  }
};

const ConsoleLogError = (apiMessage: any, data?: string) => {
  if (`${process.env.ENVIRONMENT}` === CommonConstant.localEnvironemt) {
    if (data) console.error(apiMessage, data);
    else console.error(apiMessage, data);
  }
};

const exportToCSV = (
  jsonData: any,
  fileName: string,
  sheetName?: string,
  headersRow?: any,
  keysWhichNeedsTobeFilteredFromJSON?: any
) => {
  const fileExtension = ".xlsx";
  var jsonObjectForExcelCreation = jsonData;
  var excelOptions = {};
  var skipHeaderRow = false;

  if (headersRow) {
    skipHeaderRow = true;
  }

  if (keysWhichNeedsTobeFilteredFromJSON?.length > 0) {
    jsonObjectForExcelCreation = jsonObjectForExcelCreation.map((obj: any) =>
      Object.fromEntries(
        Object.entries(obj).filter(
          (r: any) => keysWhichNeedsTobeFilteredFromJSON?.indexOf(r[0]) > -1
        )
      )
    );
    excelOptions = {
      header: keysWhichNeedsTobeFilteredFromJSON,
      skipHeader: skipHeaderRow,
    };
  }

  if (headersRow) {
    jsonObjectForExcelCreation.unshift(headersRow);
  }

  var ws: XLSX.WorkSheet = utils.json_to_sheet(
    jsonObjectForExcelCreation,
    excelOptions
  );

  var wb: XLSX.WorkBook = utils.book_new();
  utils.book_append_sheet(wb, ws, sheetName ?? "Data");

  writeFileXLSX(wb, fileName + "-" + new Date().toISOString() + fileExtension);
};

const Helpers = {
  exportToCSV,
  ConsoleLog,
  ConsoleLogError,
};

export default Helpers;
