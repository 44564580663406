import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { convertDateTime, convertDateTimeUTC } from "../globalFunctions";
import Tooltip from "../../components/common/Tooltip/Tooltip";

interface setPointOptimzationRecommendationColumnsProps {
    handleRecommendationManualSetPoint: (newSetPoint: any, groupId: any, siteId: any) => void;
    saveSiteId: (siteId: number) => void;
}

interface setPointOptimzationManualColumnsProps {
    handleManualNewSetPoint: (e: any, row: any) => void;
    handleRecommendationManualSetPoint: (newSetPoint: any, groupId: any, siteId: any) => void;
    saveSiteId: (siteId: number) => void;
}
interface setPointOptimzationHistoryColumnsProps {
    saveSiteId: (siteId: number) => void;
}

export const setPointOptimzationRecommendationColumns = ({ handleRecommendationManualSetPoint, saveSiteId }: setPointOptimzationRecommendationColumnsProps) => [
    {
        title: "Site name",
        dataIndex: "siteName",
        key: "siteName",
        width: "200px",
        sortable: true,
        render: (
            value:
                | string
                | number
                | boolean
                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                | Iterable<React.ReactNode>
                | React.ReactPortal
                | null
                | undefined,
            row: { siteName: string; product: string; siteId: number }
        ) => (
            <div className="flex flex-col">
                <Tooltip content={value} direction='right'>
                    <p className='text-[14px] font-bold text-primary-400 cursor-pointer w-[250px] truncate' onClick={() => saveSiteId(row.siteId)}>
                        {value}
                    </p>
                </Tooltip>
                <p className="text-[14px] font-bold text-neutral-25 cursor-pointer">
                    {row.product}
                </p>
            </div>
        ),
    },
    {
        title: "API number", dataIndex: "apiNumber", key: "apiNumber", sortable: true,
    },
    {
        title: "Device ID",
        dataIndex: "deviceId",
        key: "deviceId",
        sortable: true,
    },
    {
        title: "Current setpoint",
        dataIndex: "currentSetPoint",
        key: "currentSetPoint",
        sortable: true,
        render: (value: any) => (<>
        {value ? value + " QPD": ""} 
        </>)
    },
    {
        title: "Recommended setpoint",
        dataIndex: "recommendationSetPoint",
        key: "recommendationSetPoint",
        width: "200px",
        sortable: true,
        render: (value: any, row: any) => (
            <div className="flex justify-between items-center">
                {row.isNew ? (
                    <div className="border border-success-400 px-[10px] py-[5px] text-success-400 rounded-[6px] font-semibold text-[14px]">
                        New
                    </div>
                ) : (
                    <div style={{ width: "50px" }} />
                )}
                {value ? value + " QPD" : ""} 
            </div>
        ),
    },
    {
        title: "",
        dataIndex: "isNew",
        key: "isNew",
        width: "150px",
        render: (_: any, row: any) => (
            <>
                {row.isNew ? (
                    <button
                        onClick={() =>
                            handleRecommendationManualSetPoint(
                                row.recommendationSetPoint,
                                row.groupId,
                                row.siteId
                            )
                        }
                        type="button"
                        className={`border-primary-700 rounded-[8px] bg-primary-600 hover:bg-primary-700 gap-[8px] outline-none inline-flex items-center w-full px-[14px] py-[8px] border-[1px]  text-[16px] font-medium rounded-[8px]focus:outline-none`}
                    >
                        <FiSettings />
                        <div className="flex items-center text-[14px] capitalize">
                            Implement
                        </div>
                    </button>
                ) : (
                    <div className="text-right text-neutral-25 text-[14px]">
                        {row.implement ? convertDateTime(row.implement) : "N/A"}
                    </div>
                )}
            </>
        ),
    },
];

export const setPointOptimzationManualTable = ({ handleManualNewSetPoint, handleRecommendationManualSetPoint, saveSiteId }: setPointOptimzationManualColumnsProps) => [
    {
        title: "Site name",
        dataIndex: "siteName",
        key: "siteName",
        width: "200px",
        sortable: true,
        render: (
            value:
                | string
                | number
                | boolean
                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                | Iterable<React.ReactNode>
                | React.ReactPortal
                | null
                | undefined,
            row: { siteName: string; product: string; siteId: number }
        ) => (
            <div className="flex flex-col">
                <Tooltip content={value} direction='right'>
                    <p className='text-[14px] font-bold text-primary-400 cursor-pointer w-[250px] truncate' onClick={() => saveSiteId(row.siteId)}>
                        {value}
                    </p>
                </Tooltip>
                <p className="text-[14px] font-bold text-neutral-25 cursor-pointer">
                    {row.product}
                </p>
            </div>
        ),
    },
    { title: "API number", dataIndex: "apiNumber", key: "apiNumber", sortable: true, },
    {
        title: "Device ID",
        dataIndex: "deviceId",
        key: "deviceId",
        sortable: true,
    },
    {
        title: "Current setpoint",
        dataIndex: "currentSetPoint",
        key: "currentSetPoint",
        sortable: true,
        render: (value: any) => (<>
            {value ? value + " QPD": ""} 
            </>)
    },
    {
        title: "Recommended Setpoint",
        dataIndex: "recommendationSetPoint",
        key: "recommendationSetPoint",
        width: "200px",
        sortable: true,
        render: (value: any, row: any) => (
            <div className="flex justify-between items-center">{value ? value + "QPD" : ""}</div>
        ),
    },
    {
        title: "New setpoint",
        dataIndex: "newSetPointManual", // Update to newSetPointManual
        key: "newSetPoint",
        width: "207.14px",
        sortable: true,
        render: (value: any, row: any, index: any) => (
            <div className="flex items-center">
                <input
                    type="number"
                    value={value || ""}
                    placeholder="Enter here"
                    onChange={(e) => handleManualNewSetPoint(e, row)} // handleNewSet function should directly update newSetPointManual
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                    style={{ backgroundColor: "#001023", width: "207.14px" }}
                />
                 <span className="ml-2 text-white"> QPD</span>
            </div>
        ),
    },

    {
        title: "",
        dataIndex: "isNew",
        key: "isNew",
        width: "150px",
        render: (_: any, row: any) => (
            <>
                {row.newSetPointManual ? (
                    <button
                        onClick={() => {
                            handleRecommendationManualSetPoint(
                                row.recommendationSetPoint,
                                row.groupId,
                                row.siteId,
                            );
                        }}
                        type="button"
                        className={`border-primary-700 rounded-[8px] bg-primary-600 hover:bg-primary-700 gap-[8px] outline-none inline-flex items-center w-full px-[14px] py-[8px] border-[1px]  text-[16px] font-medium rounded-[8px]focus:outline-none`}
                    >
                        <FiSettings />
                        <div className="flex items-center text-[14px] capitalize">
                            Implement
                        </div>
                    </button>
                ) : (
                    <button
                        disabled
                        className={`border-white border-[1px] rounded-[8px] background: #131F2E opacity-50 cursor-not-allowed hover:bg-primary-600 gap-[8px] outline-none inline-flex items-center w-full px-[14px] py-[8px]  text-[16px] font-medium rounded-[8px]focus:outline-none`}
                    >
                        <FiSettings />
                        <div className="flex items-center text-[14px] capitalize">
                            Implement
                        </div>
                    </button>
                )}
            </>
        ),
    },
];

export const setPointOptimzationHistoryColumns = ({ saveSiteId }: setPointOptimzationHistoryColumnsProps) => [
    {
        title: "Site name",
        dataIndex: "siteName",
        key: "siteName",
        sortable: true,
        width: "200px",
        render: (
            value:
                | string
                | number
                | boolean
                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                | Iterable<React.ReactNode>
                | React.ReactPortal
                | null
                | undefined,
            row: { siteName: string; product: string; siteId: number }
        ) => (
            <div className="flex flex-col">
                <p className="text-[14px] font-bold text-primary-400 cursor-pointer" onClick={() => saveSiteId(row.siteId)}>
                    {value}
                </p>
                <p className="text-[14px] font-bold text-neutral-25 cursor-pointer">
                    {row.product}
                </p>
            </div>
        ),
    },
    {
        title: "API number",
        dataIndex: "apiNumber",
        key: "apiNumber",
        sortable: true,
    },
    {
        title: "Device ID",
        dataIndex: "deviceId",
        key: "deviceId",
        sortable: true,
    },
    {
        title: "Previous setpoint",
        dataIndex: "previousSetPoint",
        key: "previousSetPoint",
        sortable: true,
        render: (value: any) => (<>
            {value ? value + " QPD": ""} 
            </>)
    },
    {
        title: "New setpoint",
        dataIndex: "newSetPoint",
        key: "newSetPoint",
        sortable: true,
        render: (value: any) => (<>
            {value ? value + " QPD": ""} 
            </>)
    },
    {
        title: "Modified by",
        dataIndex: "modifiedBy",
        key: "modifiedBy",
        sortable: true,
    },
    {
        title: "Modified at",
        dataIndex: "modifiedAt",
        key: "modifiedAt",
        sortable: true,
        render: (value: string) => {
            let formattedTime = "";
            if (value !== "" && value !== null && value !== undefined) {
                formattedTime = convertDateTimeUTC(value);
            }
            return formattedTime;
        },
    },
];
