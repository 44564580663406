import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface ActionPayload {
  deviceSites: any,  
  deviceTanks: any,
  devicePumps: any,
}
const initialState: ActionPayload = {
  deviceSites: {},  
  deviceTanks: {},
  devicePumps: {},
};

const ProvisionDevice = createSlice({
  name: "ProvisionDevice",
  initialState,
  reducers: {
    setDeviceSites: (state: any, action: PayloadAction<any>) => {
      return { ...state, deviceSites: action.payload };
    },
    setDevicePumps: (state: any, action: PayloadAction<any>) => {
      return { ...state, devicePumps: action.payload };
    },
    setDeviceTanks: (state: any, action: PayloadAction<any>) => {
      return { ...state, deviceTanks: action.payload };
    },
  },
});

export const { setDeviceSites, setDevicePumps, setDeviceTanks } =
  ProvisionDevice.actions;

export default ProvisionDevice;
