import Tabs from "../Tabs/Tabs1";
import Pump from "../../SiteDetails/Tabs/Pump";
import Tank from "../../SiteDetails/Tabs/Tank";
import React, { useEffect, useState } from "react";
import Status from "../../SiteDetails/Tabs/Status";
import {
    getStatus,
    getTank,
} from "../../../services/siteDetails/siteDetails.service";

interface SiteDetailsRightGridInterface {
    groupId: string;
    siteId: string;
    groupType: string
}

const SiteDetailsRightGrid: React.FC<SiteDetailsRightGridInterface> = ({
    groupId,
    siteId,
    groupType
}) => {
    const [tankData, setTankData] = useState<any>([]);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [tankDetails, setTankDetails] = useState<any>([]);
    const [alertsDetails, setAlertsDetails] = useState<any>([]);
    const [communicationDetails, setCommunicationDetails] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [keyStatusRegistersDetails, setKeyStatusRegistersDetails] =
        useState<any>([]);

    useEffect(() => {
        if (activeTab === 0) gettingStatusData();
        else if (activeTab === 2) gettingTankData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, siteId]);

    const gettingStatusData = async () => {
        try {
            const response: any = await getStatus(
                siteId
            );
            if (response.statusCode === 200 && response.success) {
                const tankLevel = response?.data?.tankLevel;
                const voltage = response?.data?.voltage
                setCommunicationDetails([
                    { title: "Last comm", value: response?.data?.LastComm },
                    { title: "Comm %", value: response?.data["comm%"] },
                    { title: "Device type", value: response?.data.deviceType },
                    { title: "IP address", value: response?.data.IPAddress },
                    { title: "Port", value: response?.data?.Port },
                    { title: "Protocol", value: response?.data?.Protocol },
                ]);
                setKeyStatusRegistersDetails([
                    { title: "Tank level", value:`${tankLevel} GAL`},
                    { title: "Target QPD", value: response?.data?.targetQPD},
                    // { title: "PPM if entered", value: response?.data["PPM"] },
                    { title: "3 days variance", value: response?.data["3daysVariance"] },
                    { title: "7 days variance", value: response?.data["7daysvariance"] },
                    { title: "Voltage", value: voltage ? `${voltage} V` : voltage },
                ]);
                setAlertsDetails([
                    { title: "Low voltage", value: response?.data?.lowVoltage },
                    { title: "Low tank level", value: response?.data?.lowTankLevel },
                    {
                        title: "High variance 3 days",
                        value: response?.data?.highVariance3days,
                    },
                    {
                        title: "High variance 7 days",
                        value: response?.data?.highVariance7days,
                    },
                    {
                        title: "Pump under / over sized",
                        value: response?.data?.pumpsized,
                    },
                ]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const gettingTankData = async () => {
        setIsLoading(true);
        try {
            const response: any = await getTank(
                siteId
            );
            if (response.statusCode === 200 && response.success) {
                setTankDetails([
                    {
                        label: "Product name",
                        value: response?.data?.ProductName,
                        editLabel: "Tank ID",
                        editValue: response?.data?.tankID,
                        editIcon: false,
                    },
                    {
                        label: "Product type",
                        value: response?.data?.ProductType,
                        editLabel: "Estimated days to low",
                        editValue: response?.data?.estimatedDaysLow,
                        editIcon: false,
                    },
                    {
                        label: "Tank type",
                        value: response?.data?.tankType,
                        editLabel: "Percent full",
                        editValue: response?.data?.percentFull + " %",
                        editIcon: false,
                    },
                    {
                        label: "Low calibration value",
                        value: response?.data?.lowCalibrationValue,
                        editLabel: "Required volume to full",
                        editValue: response?.data?.reqVolToFull + " GAL",
                        editIcon: false,
                    },
                    {
                        label: "High calibration value",
                        value: response?.data?.highCalibrationValue,
                        editLabel: "Tank size",
                        editValue: response?.data?.tankSize + " GAL",
                        editIcon: false,
                    },
                ]);
                setTankData(response?.data?.TrendData);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTabChange = (index: number) => {
        setActiveTab(index);
    };

    const tabs = [
        {
            label: "Status",
            badge: false,
            content: (
                <Status
                    keyStatusRegistersDetails={keyStatusRegistersDetails}
                    communicationDetails={communicationDetails}
                    alertsDetails={alertsDetails}
                />
            ),
        },
        {
            label: "Pump",
            badge: false,
            content: <Pump groupId={groupId} siteId={siteId} groupType={groupType} />,
        },
        {
            label: "Tank",
            badge: false,
            content: (
                <Tank
                    tankData={tankData ?? []}
                    tankDetails={tankDetails ?? []}
                    isLoading={isLoading}
                />
            ),
        },
    ];

    return (
        <div className='border-[2px] border-darkcolor-400 bg-darkcolor-700 rounded-[8px]'>
            <div className='flex justify-between items-center pt-[20px] px-[24px] rounded-[8px]' />
            <div className='relative'>
                <Tabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} />
            </div>
        </div>
    );
};

export default SiteDetailsRightGrid;
