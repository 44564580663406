export const dropdownOptions = [
  {
    id: 1,
    name: "Confiuration view",
    value: "confiuratioView",
  },
  {
    id: 2,
    name: "Confiuration view",
    value: "confiuratioView",
  },
  {
    id: 3,
    name: "Confiuration view",
    value: "confiuratioView",
  },
];

export const dropdownCoditionOptions = [
  {
    id: 1,
    name: "Equal To",
    value: "0",
  },
  {
    id: 2,
    name: "Greater Than",
    value: "1",
  },
  {
    id: 3,
    name: "Less Than",
    value: "2",
  },

  {
    id: 4,
    name: "Greater Than Equal to",
    value: "3",
  },
  {
    id: 5,
    name: "Less Than Equal to",
    value: "4",
  },
  {
    id: 6,
    name: "Not Equal To",
    value: "5",
  },
];

export const stackOptions: any = [
  {
    name: "Stack 1",
    value: "stack1",
  },
  {
    name: "Stack 2",
    value: "stack2",
  },
  {
    name: "Stack 3",
    value: "stack3",
  },
  {
    name: "Stack 4",
    value: "stack4",
  },
];

export const chartModeOption: any = [
  {
    name: "Normal",
    value: true,
  },
  {
    name: "Stacked",
    value: false,
  },
];
