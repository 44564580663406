import React, { useEffect, ChangeEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../common/Header/Header";
import TableComponent from "../common/Table/CustomTable";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { MdOutlineFileDownload } from "react-icons/md";
import ProvisionDeviceService from "../../services/provisionDevice/provisionDevice.service";
import "./DeviceManagement.scss";
import { useDispatch } from "react-redux";
import {
  setDevicePumps,
  setDeviceSites,
  setDeviceTanks,
} from "../../store/provisionDevice/provisionDevice.store";
import Loading from "../common/Loading/Loading";
import Helpers from "../../utilities/helpers";
import Tooltip from "../common/Tooltip/Tooltip";

const DeviceManagement: React.FC = () => {
  document.title = "CIA - Device Management";
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(8);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [proDeviceData, setProDeviceData] = useState<any>([]);
  const [searchFreeText, setSearchFreeText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchFreeText) {
      const getData = setTimeout(async () => {
        await getProvisionDeviceApi(pageSize, page, searchFreeText);
      }, 1000);

      return () => clearTimeout(getData);
    } else {
      getProvisionDeviceApi(pageSize, page, searchFreeText);
    }
  }, [page, searchFreeText, sortBy, sortOrder]);

  const columns = [
    {
      title: "",
      dataIndex: "circle",
      key: "circle",
      width: "90px",
      render: (_: any, record: { status: string }) => (
        <>
          <span
            className={`circle ${record.status.toLowerCase() === "true" ? "green" : "red"
              }`}
          ></span>
        </>
      ),
      sortable: false,
      id: "",
    },
    {
      title: "Serial #",
      dataIndex: `serialNo`,
      sortable: true,
      key: `serialNo`,
    },
    {
      title: "Type",
      dataIndex: "type",
      sortable: true,
      key: "type",
    },
    {
      title: "Site Name",
      dataIndex: "sites",
      sortable: true,
      key: "sites",
      render: (_: any, record: any) => (
        <>
          {record?.sites[0]?.siteName}
        </>
      ),
    },
    {
      title: "Customer",
      dataIndex: "customers",
      sortable: true,
      key: "customers",
      render: (_: any, record: any) => (
        <>
          {record?.sites[0]?.customers}
        </>
      ),
    },
    {
      title: "Make",
      dataIndex: "make",
      sortable: true,
      key: "make",
    },
    {
      title: "Model",
      dataIndex: "model",
      sortable: true,
      key: "model",
    },
    {
      title: "Status",
      dataIndex: "status",
      sortable: true,
      key: "status",
      render: (_: any, record: { status: string }) => (
        <>
          <span
            className={`badge ${record?.status.toLowerCase() === "true"
              ? "badge-green"
              : "badge-red"
              }`}
          >
            {record?.status.toLowerCase() === "true" ? "Active" : "Inactive"}
          </span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "icon",
      sortable: false,
      key: "icon",
      render: (_: any, record: { _id: string; userId: string }) => (
        <>
          <Link to={`/addeditdevice/${record?._id}`}>
            <Tooltip content={"Edit"} direction='top'>
              <FiEdit />
            </Tooltip>
          </Link>
        </>
      ),
    },
  ];

  const getProvisionDeviceApi = async (
    pageSize: number,
    page: number,
    searchText: string = "",
  ) => {
    setIsLoading(true);
    try {
      const request: any = {
        pageSize: pageSize,
        pageNumber: page,
        searchText: searchText,
        sortOrder: sortOrder,
        sortBy: sortBy,
      };
      const response = await ProvisionDeviceService.getProvisionDeviceSummary(
        request
      );
      if (response?.statusCode === 200 && response?.success) {
        setProDeviceData(response?.data?.provisionData);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortChange = (order: string, title: string) => {
    setSortBy(title);
    setSortOrder(order);
  };

  const handlePageNumberChange = (pageNumber: number) => setPage(pageNumber);

  const handleInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFreeText(event.target.value);
    setPage(1);
  };

  const handleReduxCleanup = () => {
    dispatch(setDeviceSites(null));
    dispatch(setDevicePumps(null));
    dispatch(setDeviceTanks(null));
    navigate("/addeditdevice");
  };

  const handleExcelDownload = async () => {
    try {
      setIsLoading(true);
      const request: any = {
        pageSize: 0,
        pageNumber: 0,
        searchText: "",
        sortOrder: sortOrder,
        sortBy: sortBy,
      };
      const response = await ProvisionDeviceService.getProvisionDeviceSummary(
        request
      );
      if (response?.statusCode === 200 && response?.success) {
        let proDeviceDataDeepCopy = JSON.parse(
          JSON.stringify(response?.data?.provisionData)
        );
        if (proDeviceDataDeepCopy?.length > 0) {

          proDeviceDataDeepCopy?.map((val: any, i: any) => {
            val.sites = val?.sites[0]?.siteName;
          });
          proDeviceDataDeepCopy?.map((val: any, i: any) => {
            val.customer = val?.customer?.name;
          });
          proDeviceDataDeepCopy?.map((val: any, i: any) => {
            val.status = val?.status.toLocaleLowerCase() === "true" ? "Active" : "Inactive";
          });
          Helpers.exportToCSV(
            proDeviceDataDeepCopy,
            "Provision_Device",
            "Provision_Device",
            {
              serialNo: "Serial",
              type: "Type",
              sites: "Site Name",
              customer: "Customer Name",
              make: "Make",
              model: "Model",
              status: "Status",
            },
            [
              "serialNo",
              "type",
              "sites",
              "customer",
              "make",
              "model",
              "status",
            ]
          );
        }
      }

    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      <Loading isLoading={isLoading} text={""} />
      <div className="content-wrapper">
        <Header home={"Device management"} className="device-header" />
        <div className="bg-darkcolor-700">
          <div className="flex flex-wrap items-center justify-between pl-[15px] xl:pl-[32px] pr-[15px] xl:pr-[32px] py-[12px]">
            <div className="provText">Provisioned devices</div>
            <div className="">
              <div className="flex justify-center items-center col-end-12">
                <div className="mr-[12px] cursor-pointer">
                  <Tooltip content={"Add Device"} direction='top'>
                    <FaPlus onClick={handleReduxCleanup} />
                  </Tooltip>
                </div>
                <div className="mr-[12px] cursor-pointer">
                  <Tooltip content={"Download"} direction='top'>
                    <MdOutlineFileDownload
                      onClick={handleExcelDownload}
                      size={20}
                    /></Tooltip>
                </div>
                <div className="cursor-pointer">
                  <div className="grow input-with-lefticon sidebar-search-input-block">
                    <FiSearch className="input-icon" />
                    <input
                      className="input-outline-4"
                      placeholder="Search"
                      onChange={handleInputSearch}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 pl-[15px] xl:pl-[32px] pr-[15px] xl:pr-[32px]">
          <TableComponent
            data={proDeviceData}
            columns={columns}
            handlePageNumberChange={handlePageNumberChange}
            page={page}
            pagniation={true}
            totalPages={totalPages}
            handleSortChange={handleSortChange}
          />
        </div>
      </div>
    </>
  );
};

export default DeviceManagement;
