import React, { useState } from "react";

interface AlertsProps {
    size?: number | string;
    className?: string;
    onClick?: () => void;
    color?: string;
}

const Alerts: React.FC<AlertsProps> = ({
    size = 20,
    className,
    onClick,
    color,
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const style = size ? { width: size, height: size } : undefined;
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className={className}
            style={style}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <path
                d='M4.16666 2.5L1.66666 5M18.3333 5L15.8333 2.5M5 15.8333L3.33333 17.5M15 15.8333L16.6667 17.5M10 7.5V10.8333L11.6667 12.5M10 17.5C11.7681 17.5 13.4638 16.7976 14.714 15.5474C15.9643 14.2971 16.6667 12.6014 16.6667 10.8333C16.6667 9.06522 15.9643 7.36953 14.714 6.11929C13.4638 4.86905 11.7681 4.16667 10 4.16667C8.23189 4.16667 6.5362 4.86905 5.28595 6.11929C4.03571 7.36953 3.33333 9.06522 3.33333 10.8333C3.33333 12.6014 4.03571 14.2971 5.28595 15.5474C6.5362 16.7976 8.23189 17.5 10 17.5Z'
                stroke={isHovered ? "#0094BD" : color}
                strokeWidth='1.67'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default Alerts;
