import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

interface LineChartProps {
    width?: string;
    chartId: string;
    height?: string;
    opacity?: number;
    fillColor: string;
    strokeColor: string;
    xAxisLabel: boolean;
    yAxisLabel: boolean;
    data: { date: string; value: number }[];
}

const LineChart: React.FC<LineChartProps> = ({
    data,
    width,
    height,
    opacity,
    chartId,
    fillColor,
    strokeColor,
    xAxisLabel,
    yAxisLabel,
}) => {
    useEffect(() => {
        am4core.options.commercialLicense = true;
        const chart = am4core.create(chartId, am4charts.XYChart);
        chart.data = data;

        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.labels.template.disabled = xAxisLabel; // Hide X-axis labels
        dateAxis.renderer.labels.template.fill = am4core.color(fillColor);
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.labels.template.disabled = yAxisLabel; // Hide Y-axis labels
        valueAxis.renderer.labels.template.fill = am4core.color(fillColor);
        valueAxis.renderer.labels.template.fontSize = "12";

        const series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.fillOpacity = opacity || 0.5;
        series.strokeWidth = 2;
        series.stroke = am4core.color(strokeColor);
        series.fill = am4core.color(fillColor);
        series.tooltipText = "{valueY.value}" + " GAL";

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.snapToSeries = series;
        chart.cursor.xAxis = dateAxis;

        return () => {
            chart.dispose();
        };
    }, [data, chartId, strokeColor, fillColor, xAxisLabel, yAxisLabel, opacity]);

    return <div id={chartId} style={{ width: width, height: height }}></div>;
};

export default LineChart;
